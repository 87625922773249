.gallery-wrap-layout1 {
	position: relative;
	z-index: 1;
	&:after {
		content: "";
		position: absolute;
		z-index: -1;
		height: 60%;
		width: 100%;
		top: 0;
		left: 0;
		background-image: linear-gradient( 60deg, rgb(90,73,248) 0%, rgb(122,100,242) 100%);
	}
	.container-fluid {
		padding-left: rem(30px);
		padding-right: rem(30px);
	}
}

.gallery-box-layout1 {
	position: relative;
	z-index: 1;
	margin-bottom: rem(30px);
	&:before {
		content: "";
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		height: rem(calc(100% - 20px));
		width: rem(calc(100% - 20px));
		border-radius: rem(4px);
		position: absolute;
		z-index: 2;
		background-color: rgba($color_light, 1);
		opacity: 0;
		visibility: hidden;
		transition: all 0.5s ease-in-out;
	}
	&:after {
		content: "";
		background-color: $color_primary;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		visibility: hidden;
		border-radius: rem(4px);
		transform: scale(0.9);
		transition: all 0.3s ease-in-out;
	}
	.item-figure {
		img {
			border-radius: rem(4px);
		}
	}
	.item-content {
		text-align: center;
		position: absolute;
		z-index: 3;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		.item-icon {
			margin-bottom: rem(18px);
			font-size: rem(40px);
			transform: translateY(rem(-10px));
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s ease-in-out;
			a {
				color: $color_primary;
			}
		}
		.item-title {
			padding: rem(0 10px);
			line-height: rem(28px);
			font-weight: 600;
			margin-bottom: rem(0);
			opacity: 0;
			visibility: hidden;
			transform: translateY(rem(10px));
			transition: all 0.1s ease-in-out;
			a {
				color: #111111;
				transition: all 0.5s ease-in-out;
				&:hover {
					color: $color_primary;
				}
			}
		}
		p {
			color: #777779;
			margin-bottom: 0;
			opacity: 0;
			visibility: hidden;
			transform: translateY(rem(10px));
			transition: all 0.3s ease-in-out;
		}
	}

	&:hover {
		&:before {
			opacity: 1;
			visibility: visible;
		}
		&:after {
			opacity: 1;
			visibility: visible;
			transform: scale(1);
			transition: all 0.5s ease-in-out;
			transition-delay: 0.2s;
		}
		.item-content {
			.item-icon {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;
				transition-delay: 0.7s;
			}
			.item-title {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;
				transition-delay: 0.5s;
			}
			p {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;
				transition-delay: 0.6s;
			}
		}
	}
}

.gallery-box-layout2 {
	position: relative;
	z-index: 1;
	&:before {
		content: "";
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		height: rem(calc(100% - 20px));
		width: rem(calc(100% - 20px));
		position: absolute;
		z-index: 2;
		background-color: rgba($color_light, 1);
		opacity: 0;
		visibility: hidden;
		transition: all 0.5s ease-in-out;
	}
	&:after {
		content: "";
		background-color: $color_primary;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		visibility: hidden;
		transform: scale(0.9);
		transition: all 0.3s ease-in-out;
	}
	.item-content {
		text-align: center;
		position: absolute;
		z-index: 3;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		.item-icon {
			margin-bottom: rem(18px);
			font-size: rem(40px);
			transform: translateY(rem(-10px));
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s ease-in-out;
			a {
				color: $color_primary;
			}
		}
		.item-title {
			line-height: rem(28px);
			font-weight: 600;
			margin-bottom: rem(0);
			opacity: 0;
			visibility: hidden;
			transform: translateY(rem(10px));
			transition: all 0.1s ease-in-out;
			a {
				color: #111111;
				transition: all 0.5s ease-in-out;
				&:hover {
					color: $color_primary;
				}
			}
		}
		p {
			color: #212127;
			margin-bottom: 0;
			opacity: 0;
			visibility: hidden;
			transform: translateY(rem(10px));
			transition: all 0.3s ease-in-out;
		}
	}

	&:hover {
		&:before {
			opacity: 1;
			visibility: visible;
		}
		&:after {
			opacity: 1;
			visibility: visible;
			transform: scale(1);
			transition: all 0.5s ease-in-out;
			transition-delay: 0.2s;
		}
		.item-content {
			.item-icon {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;
				transition-delay: 0.7s;
			}
			.item-title {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;
				transition-delay: 0.5s;
			}
			p {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;
				transition-delay: 0.6s;
			}
		}
	}
}

.gallery-box-layout3 {
	box-shadow: rem(0 0 24px 0) rgba(#000000, 0.05);
	background-color: #ffffff;
	text-align: center;
	border-radius: rem(5px);
	margin-bottom: rem(30px);
	.item-figure {
		position: relative;
		z-index: 1;
		&:before {
			content: "";
			height: 100%;
			width: 100%;
			background-color: rgba(#000000, 0.6);
			border-radius: rem(5px 5px 0 0);
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s ease-in-out;
		}
		img {
			border-radius: rem(5px 5px 0 0);
		}
	}
	.item-icon {
		font-size: rem(30px);
		position: absolute;
		top: 30%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		opacity: 0;
		visibility: hidden;
		text-align: center;
		transition: all 0.3s ease-in-out;
		a {
			color: #ffffff;
		}
	}
	.item-content {
		padding: rem(30px 15px);
		.item-title {
			line-height: rem(28px);
			font-size: rem(20px);
			font-weight: 600;
			margin-bottom: rem(0);
			transition: all 0.1s ease-in-out;
			a {
				color: #111111;
				transition: all 0.5s ease-in-out;
				&:hover {
					color: $color_primary;
				}
			}
		}
		p {
			color: #212127;
			margin-bottom: 0;
			transition: all 0.3s ease-in-out;
		}
	}

	&:hover {
		.item-figure {
			&:before {
				opacity: 1;
				visibility: visible;
			}
		}
		.item-icon {
			opacity: 1;
			visibility: visible;
			top: 50%;
			transition: all 0.3s ease-in-out;
		}
	}
}

.gallery-wrap-layout10 {
	background-color: #f9fbfe;
	@media only screen and (min-width: 992px) {
		padding-left: rem(100px);
		padding-right: rem(100px);
	}
	@media only screen and (min-width: 767px) {
		padding-left: rem(50px);
		padding-right: rem(50px);
	}
}

.gallery-box-layout4 {
	position: relative;
	z-index: 1;
	margin-bottom: rem(30px);
	&:after {
		content: "";
		background-color: rgba(#000000, 0.75);
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		visibility: hidden;
		border-radius: rem(4px);
		transform: scale(0.9);
		transition: all 0.3s ease-in-out;
	}
	.item-figure {
		img {
			border-radius: rem(4px);
		}
	}
	.item-content {
		text-align: center;
		position: absolute;
		z-index: 3;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		.item-icon {
			margin-bottom: rem(25px);
			font-size: rem(40px);
			transform: translateY(rem(-10px));
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s ease-in-out;
			a {
				color: #ffffff;
				transition: all 0.3s ease-in-out;
				&:hover {
					color: $color_primary;
				}
			}
		}
		.item-title {
			font-size: rem(20px);
			padding: rem(0 10px);
			line-height: rem(28px);
			font-weight: 600;
			margin-bottom: rem(5px);
			opacity: 0;
			visibility: hidden;
			transform: translateY(rem(10px));
			transition: all 0.1s ease-in-out;
			a {
				color: #ffffff;
				transition: all 0.5s ease-in-out;
				&:hover {
					color: $color_primary;
				}
			}
		}
		p {
			color: #f9f8f8;
			margin-bottom: 0;
			opacity: 0;
			visibility: hidden;
			transform: translateY(rem(10px));
			transition: all 0.3s ease-in-out;
		}
	}

	&:hover {
		&:before {
			opacity: 1;
			visibility: visible;
		}
		&:after {
			opacity: 1;
			visibility: visible;
			transform: scale(1);
			transition: all 0.5s ease-in-out;
			transition-delay: 0.2s;
		}
		.item-content {
			.item-icon {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;
				transition-delay: 0.7s;
			}
			.item-title {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;
				transition-delay: 0.5s;
			}
			p {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition: all 0.3s ease-in-out;
				transition-delay: 0.6s;
			}
		}
	}
}

.gallery-details-box {
	.item-figure {
		margin-bottom: rem(50px);
		img {
			border-radius: rem(4px);
		}
		@media only screen and (max-width: 767px) {
			margin-bottom: rem(30px);
		}
	}
	.item-content {
		.item-title {
			font-weight: 600;
			margin-bottom: rem(15px);
		}
	}
}
.gallery-details-box2 {
	.item-figure {
		margin-bottom: rem(30px);
	}
	.item-content {
		margin-bottom: rem(30px);
		padding-left: rem(30px);
		@media only screen and (max-width: 991px) {
			padding-left: 0;
		}
		.item-title {
			font-weight: 600;
			margin-bottom: rem(15px);
		}
		.item-inner-title {
			font-weight: 600;
			color: #111111;
			font-size: rem(24px);
			margin-bottom: rem(10px);
		}
		.list-item {
			margin-top: rem(30px);
			ul {
				li {
					margin-bottom: rem(5px);
					font-weight: 700;
					font-size: rem(18px);
					color: #111111;
					span {
						margin-left: rem(15px);
						color: #646464;
						font-weight: 400;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
		.social-wrap {
			margin-top: rem(45px);
			display: flex;
			align-items: center;
			.social-title {
				margin-right: rem(20px);
				font-size: rem(18px);
			}
			.item-social {
				a {
					margin-right: rem(10px);
					margin-bottom: rem(5px);
					font-weight: 500;
					height: rem(35px);
					width: rem(35px);
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					transition: all 0.3s ease-in-out;

					&:last-child {
						margin-right: 0;
					}

					&:hover {
						color: $color_light;
					}
				}
				.facebook {
					color: #315db6;
					border: rem(1px) solid #315db6;
					&:hover {
						background-color: #315db6;
					}
				}
				.twitter {
					color: #00a7e6;
					border: rem(1px) solid #00a7e6;
					&:hover {
						background-color: #00a7e6;
					}
				}
				.pinterest {
					color: #dd2a7b;
					border: rem(1px) solid #dd2a7b;
					&:hover {
						background-color: #dd2a7b;
					}
				}
				.linkedin {
					color: #0074b2;
					border: rem(1px) solid #0074b2;
					&:hover {
						background-color: #0074b2;
					}
				}
			}
		}
	}
}

.related-gallery-carousel {
	margin-top: rem(83px);
	@media only screen and (max-width: 991px) {
		margin-top: rem(30px);
	}
	@media only screen and (max-width: 767px) {
		margin-top: rem(15px);
	}
	@media only screen and (max-width: 575px) {
		margin-top: 0;
	}
}


