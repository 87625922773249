/*========================================================================
03. Page Layout
=========================================================================*/

/*--------------------------------------
	3.1 Footers
---------------------------------------*/
@import "page-layout/footers";

/*--------------------------------------
	3.2 Headers
---------------------------------------*/
@import "page-layout/headers";

/*--------------------------------------
	3.3 Miscellaneous
---------------------------------------*/
@import "page-layout/miscellaneous";

/*--------------------------------------
	3.4 Widgets
---------------------------------------*/
@import "page-layout/widgets";
