/*--- Main Banner Layout One ---*/
.main-banner-wrap-layout1 {
	padding: rem(170px 0 120px);
	position: relative;
	z-index: 0;
	height: 100vh;
	overflow: hidden;
	@media only screen and (max-width: 991px) {
		height: 100%;
		padding: rem(55px 0 105px);
	}
	@media only screen and (max-width: 767px) {
		padding: rem(50px 0 90px);
	}
	@media only screen and (max-width: 575px) {
		padding: rem(45px 0 75px);
	}
	.animated-figure {
		@media only screen and (max-width: 1199px) {
			display: none;
		}
        li {
            position: absolute;
            z-index: -1;

            &:first-child {
                top: 50%;
                transform: translateY(-50%);
                left: rem(10px);
                right: inherit;
            }

            &:last-child {
                top: 0;
                right: 0;
                left: inherit;
                transform: translateY(0);
            }
        }
    }

    .compress-left-side {
		padding-right: rem(50px);
		padding-left: rem(calc(calc(100% - 1170px)/2));

		@media only screen and (max-width: 1199px) {
			padding-right: 0;
			padding-left: rem(calc(calc(100% - 930px)/2));
		}

		@media only screen and (max-width: 991px) {
			padding-right: rem(calc(calc(100% - 690px)/2));
			padding-left: rem(calc(calc(100% - 690px)/2));
		}

		@media only screen and (max-width: 767px) {
			padding-right: rem(calc(calc(100% - 510px)/2));
			padding-left: rem(calc(calc(100% - 510px)/2));
		}

		@media only screen and (max-width: 575px) {
			padding-right: rem(15px);
			padding-left: rem(15px);
		}
	}

	.compress-right-side {
		padding-left: rem(50px);
		padding-right: rem(calc(calc(100% - 1170px)/2));

		@media only screen and (max-width: 1199px) {
			padding-left: rem(calc(calc(100% - 930px)/2));
			padding-right: rem(calc(calc(100% - 930px)/2));
		}

		@media only screen and (max-width: 991px) {
			padding-left: rem(calc(calc(100% - 690px)/2));
			padding-right: rem(calc(calc(100% - 690px)/2));
		}

		@media only screen and (max-width: 767px) {
			padding-left: rem(calc(calc(100% - 510px)/2));
			padding-right: rem(calc(calc(100% - 510px)/2));
		}

		@media only screen and (max-width: 575px) {
			padding-left: rem(15px);
			padding-right: rem(15px);
		}
	}
}

.main-banner-box-layout1 {
	margin-top: rem(20px);
	@media only screen and (max-width: 991px) {
		margin-top: rem(50px);
	}
	@media only screen and (max-width: 767px) {
		margin-top: rem(40px);
	}
	@media only screen and (max-width: 575px) {
		margin-top: rem(30px);
		text-align: center;
	}
	.item-figure {
		margin-top: rem(50px);
		animation: up_down 15s infinite;
		animation-delay: 4s;
		@media only screen and (max-width: 767px) {
			animation: inherit;
		}
	}
	.item-sub-title {
		color: $color_light;
		margin-bottom: rem(15px);
		font-size: rem(20px);
		letter-spacing: rem(4px);
	}
	.item-main-title {
		font-size: rem(50px);
		color: $color_light;
		font-weight: 600;
		line-height: rem(65px);
		@media only screen and (max-width: 1199px) {
		    font-size: rem(42px);
		    line-height: rem(60px);
		}
		@media only screen and (max-width: 991px) {
			font-size: rem(38px);
			line-height: rem(55px);
		}
		@media only screen and (max-width: 767px) {
			font-size: rem(34px);
			line-height: rem(50px);
		}
		@media only screen and (max-width: 575px) {
			font-size: rem(30px);
			line-height: rem(45px);
		}
	}
	p {
		color: $color_light;
		width: 80%;
		margin-bottom: rem(50px);
		font-size: rem(18px);
		@media only screen and (max-width: 767px) {
			margin-bottom: rem(40px);
		}
		@media only screen and (max-width: 767px) {
			width: 100%;
		}
	}
	.banner-btns {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: rem(15px);
		@media only screen and (max-width: 575px) {
			display: block;
		}
		a {
			margin-bottom: rem(15px);
			margin-right: rem(30px);
			@media only screen and (max-width: 575px) {
				margin-right: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		.item-btn {
			padding: rem(10px 29px);
		}
		.play-btn {
			display: flex;
			align-items: center;
			@media only screen and (max-width: 575px) {
				justify-content: center;
			}
			.item-icon {
				position: relative;
				z-index: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				height: rem(45px);
				width: rem(45px);
				border-radius: 50%;
				margin-right: rem(10px);
				color: $color_primary;
				transition: all 0.5s ease-in-out;
				&:before {
					border-radius: 50%;
					position: absolute;
					z-index: -1;
					content: "";
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					background-image: linear-gradient( 180deg, rgb(169,93,241) 0%, rgb(117,14,213) 100%);
					color: $color_primary;
					font-size: rem(14px);
					opacity: 0;
					transition: all 0.5s ease-in-out;
				}
				&:after {
					border-radius: 50%;
					position: absolute;
					z-index: -1;
					content: "";
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					opacity: 1;
					background-color: $color_light;
					color: $color_primary;
					font-size: rem(14px);
					transition: all 0.5s ease-in-out;
				}
				i {
					margin-left: rem(5px);
				}
			}
			span {
				color: $color_light;
				transition: all 0.5s ease-in-out;
			}
			&:hover {
				.item-icon {
					color: $color_light;
					&:before {
						opacity: 1;
					}
					&:after {
						opacity: 0;
					}
				}
			}
		}
	}
	@keyframes up_down {
		0% {
			transform: translateY(rem(0)) translateX(rem(0));
		}
		25% {
			transform: translateY(rem(-30px)) translateX(rem(0));
		}
		75% {
			transform: translateY(rem(30px)) translateX(rem(0));
		}
	}
}

/*--- Main Banner Layout Two ---*/
.main-banner-wrap-layout2 {
	padding: rem(120px 0 90px);
	position: relative;
	overflow: hidden;
	z-index: 0;
	height: 100vh;
	display: flex;
	align-items: center;
	@media only screen and (max-width: 991px) {
		height: 100%;
		padding: rem(59px 0 105px);
		display: block;
	}
	@media only screen and (max-width: 767px) {
		padding: rem(46px 0 0);
	}
	@media only screen and (max-width: 575px) {
		padding: rem(31px 0 0);
	}
	.animated-figure-wrap1 {
		@media only screen and (max-width: 767px) {
			display: none;
		}
        li {
            position: absolute;
            z-index: -1;

            &:nth-child(1n) {
                top: 0;
                transform: translateX(rem(0px)) translateY(rem(0px));
                left: 0;
                right: inherit;
            }

            &:nth-child(2n) {
                top: 92%;
                transform: translateX(rem(0px)) translateY(-50%);
                left: rem(-70px);
                right: inherit;
            }

            &:nth-child(3n){
                top: 0;
                right: 0;
                left: inherit;
                transform: translateX(0) translateY(0);
            }
        }
	}
	.animated-figure-wrap2 {
		@media only screen and (max-width: 767px) {
			display: none;
		}
        li {
            position: absolute;
            z-index: -1;

            &:nth-child(1n) {
                top: 0;
                transform: translateX(0) translateY(0);
                left: 0;
            }

            &:nth-child(2n) {
                top: 90%;
                transform: translateX(0) translateY(-50%);
                left: 0;
            }

            &:nth-child(3n) {
                top: 90%;
                transform: translateX(0) translateY(-50%);
                left: 0;
            }
        }
	}
	.animated-shape-small {
		@media only screen and (max-width: 991px) {
			display: none;
		}
    	li {
    		position: absolute;
			z-index: 1;
			&:nth-child(1n) {
			    top: 20%;
			    right: 70%;
			    animation: shape_one 100s alternate infinite linear;
			}
			&:nth-child(2n) {
				top: 30%;
			    right: 60%;
			    animation: shape_two 100s alternate infinite linear;
			}
			&:nth-child(3n) {
				top: 20%;
			    right: 50%;
			    animation: shape_three 100s alternate infinite linear;
			}
			&:nth-child(4n) {
				top: 70%;
			    right: 70%;
			    animation: shape_four 100s alternate infinite linear;
			}
			&:nth-child(5n) {
				top: 90%;
			    right: 60%;
			    animation: shape_five 100s alternate infinite linear;
			}
			&:nth-child(6n) {
				top: 90%;
			    right: 50%;
			    animation: shape_six 100s alternate infinite linear;
			}
        }
	}
	@keyframes shape_one {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		20% {
		    transform: translate(rem(73px), rem(-100px)) rotate(36deg);
		}
		50% {
		    transform: translate(rem(141px), rem(72px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(83px), rem(122px)) rotate(108deg);
		}
		80% {
		    transform: translate(rem(40px), rem(-72px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
	@keyframes shape_two {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		10% {
		    transform: translate(rem(53px), rem(-80px)) rotate(36deg);
		}
		40% {
		    transform: translate(rem(161px), rem(72px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(83px), rem(112px)) rotate(108deg);
		}
		70% {
		    transform: translate(rem(-60px), rem(92px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
	@keyframes shape_three {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		30% {
		    transform: translate(rem(90px), rem(-36px)) rotate(36deg);
		}
		40% {
		    transform: translate(rem(101px), rem(32px)) rotate(72deg);
		}
		50% {
		    transform: translate(rem(103px), rem(102px)) rotate(108deg);
		}
		80% {
		    transform: translate(rem(-76px), rem(62px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
	@keyframes shape_four {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		10% {
		    transform: translate(rem(80px), rem(-36px)) rotate(36deg);
		}
		40% {
		    transform: translate(rem(191px), rem(72px)) rotate(72deg);
		}
		50% {
		    transform: translate(rem(93px), rem(122px)) rotate(108deg);
		}
		80% {
		    transform: translate(rem(-56px), rem(92px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
	@keyframes shape_five {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		30% {
		    transform: translate(rem(80px), rem(-36px)) rotate(36deg);
		}
		40% {
		    transform: translate(rem(141px), rem(-92px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(153px), rem(92px)) rotate(108deg);
		}
		80% {
		    transform: translate(rem(-99px), rem(62px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
	@keyframes shape_six {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		20% {
		    transform: translate(rem(95px), rem(-58px)) rotate(36deg);
		}
		30% {
		    transform: translate(rem(81px), rem(82px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(53px), rem(152px)) rotate(108deg);
		}
		70% {
		    transform: translate(rem(-56px), rem(32px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
}

.main-banner-box-layout2 {
	margin-top: rem(46px);
	@media only screen and (max-width: 991px) {
		text-align: center;
	}
	.item-sub-title {
		color: $color_primary;
		margin-bottom: rem(5px);
		font-size: rem(22px);
		letter-spacing: rem(4px);
		font-weight: 500;
		font-family: $font_heading;
	}
	.item-main-title {
		font-size: rem(60px);
		color: $color_dark;
		font-weight: 700;
		line-height: rem(80px);
		margin-bottom: rem(5px);
		@media only screen and (max-width: 1199px) {
		    font-size: rem(48px);
		    line-height: rem(70px);
		}
		@media only screen and (max-width: 991px) {
			font-size: rem(40px);
			line-height: rem(65px);
		}
		@media only screen and (max-width: 767px) {
			font-size: rem(36px);
			line-height: rem(50px);
		}
		@media only screen and (max-width: 575px) {
			font-size: rem(30px);
			line-height: rem(45px);
		}
	}
	p {
		color: $color_dark;
		width: 80%;
		margin-bottom: rem(50px);
		font-size: rem(18px);
		@media only screen and (max-width: 991px) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	.banner-btns {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		@media only screen and (max-width: 991px) {
			justify-content: center;
		}
		@media only screen and (max-width: 479px) {
			flex-direction: column;
		}
		.item-btn {
			margin-right: rem(30px);
			&:last-child {
				margin-right: 0;
			}
			@media only screen and (max-width: 479px) {
				margin-right: 0;
				margin-bottom: rem(10px);
			}
		}
		.btn-ghost {
			&:hover {
				background-color: $color_secondary;
				border: rem(2px) solid $color_secondary;
			}
		}
	}
	.item-figure {
		@media only screen and (max-width: 991px) {
			padding: 0 10%;
		}
	}
}

/*--- Main Banner Layout Three ---*/
.main-banner-wrap-layout3 {
	padding: rem(190px 0 106px);
	overflow: hidden;
	position: relative;
	z-index: 0;
	@media only screen and (max-width: 1199px) {
		padding: rem(150px 0 0);
	}
	@media only screen and (max-width: 991px) {
		padding: rem(105px 0 0);
	}

	@media only screen and (max-width: 767px) {
		padding: rem(90px 0 0);
	}

	@media only screen and (max-width: 575px) {
		padding: rem(75px 0 0);
	}
	.animated-figure {
		@media only screen and (max-width: 767px) {
			display: none;
		}
	    ul {
	        li {
	            position: absolute;
	            z-index: -1;
                &:nth-child(1n) {
                	top: rem(-30px);
                	left: inherit;
                	bottom: inherit;
                	right: 12%;
                	@media only screen and (max-width: 1579px) {
						right: 5%;
					}
					@media only screen and (max-width: 1399px) {
						right: 2%;
					}
					@media only screen and (max-width: 1200px) {
						right: 0;
					}
                }
                &:nth-child(2n) {
                	bottom: rem(200px);
                	left: rem(-300px);
                	top: inherit;
                	right: inherit;
                }
                &:nth-child(3n) {
                	bottom: rem(250px);
                	left: rem(-250px);
                	top: inherit;
                	right: inherit;
                }
                &:nth-child(4n) {
                	bottom: rem(300px);
                	left: rem(-200px);
                	top: inherit;
                	right: inherit;
                }
	        }
	    }
	}
	.compress-right-side {
		padding-left: rem(150px);
		padding-right: rem(calc(calc(100% - 1170px)/2));

		@media only screen and (max-width: 1199px) {
			padding-left: rem(calc(calc(100% - 930px)/2));
			padding-right: rem(calc(calc(100% - 930px)/2));
		}

		@media only screen and (max-width: 991px) {
			padding-left: rem(calc(calc(100% - 690px)/2));
			padding-right: rem(calc(calc(100% - 690px)/2));
		}

		@media only screen and (max-width: 767px) {
			padding-left: rem(calc(calc(100% - 510px)/2));
			padding-right: rem(calc(calc(100% - 510px)/2));
		}

		@media only screen and (max-width: 575px) {
			padding-left: rem(15px);
			padding-right: rem(15px);
		}
	}
}

.main-banner-box-layout3 {
	@media only screen and (max-width: 1199px) {
		text-align: center;
	}
	.item-figure {
		@media only screen and (max-width: 1199px) {
			margin-bottom: rem(50px)
		}
		@media only screen and (max-width: 991px) {
			padding: 0 10%;
			margin-bottom: rem(30px)
		}
	}
	.item-main-title {
		font-size: rem(50px);
		color: $color_heading;
		font-weight: 600;
		line-height: rem(65px);
		@media only screen and (max-width: 1199px) {
			font-size: rem(46px);
			line-height: rem(60px);
			width: 80%;
			margin-left: auto;
			margin-right: auto;
		}
		@media only screen and (max-width: 991px) {
			font-size: rem(40px);
			line-height: rem(55px);
		}
		@media only screen and (max-width: 767px) {
			font-size: rem(34px);
			line-height: rem(49px);
			width: 90%;
		}
		@media only screen and (max-width: 575px) {
			font-size: rem(30px);
			line-height: rem(45px);
			width: 100%;
		}
	}
	p {
		width: 80%;
		margin-bottom: rem(50px);
		font-size: rem(18px);
		@media only screen and (max-width: 1199px) {
			margin-left: auto;
			margin-right: auto;
			width: 60%;
			margin-bottom: rem(40px);
		}
		@media only screen and (max-width: 767px) {
			width: 90%;
			margin-bottom: rem(30px);
		}
		@media only screen and (max-width: 575px) {
			width: 100%;
		}
	}
	.banner-btns {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		@media only screen and (max-width: 1199px) {
			.item-btn {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

/*--- Main Banner Layout Four ---*/
.main-banner-wrap-layout4 {
	padding: rem(200px 0 42px);
	overflow: hidden;
	position: relative;
	z-index: 0;
	@media only screen and (max-width: 1199px) {
        padding: rem(200px 0 0);
    }
	@media only screen and (max-width: 991px) {
        padding: rem(100px 0 0);
    }
    @media only screen and (max-width: 767px) {
        padding: rem(85px 0 0);
    }
    @media only screen and (max-width: 575px) {
        padding: rem(70px 0 0);
    }
	.animated-figure {
		@media only screen and (max-width: 767px) {
	        display: none;
	    }
	    ul {
	        li {
	            position: absolute;
	            z-index: -1;
	            top: rem(-95px);
	            right: -7vw;
	            @media only screen and (max-width: 1879px) {
	            	right: -10vw;
	            }
			    @media only screen and (max-width: 1600px) {
			        right: -15vw;
			    }
			    @media only screen and (max-width: 1400px) {
			        right: -20vw;
			    }
			    @media only screen and (max-width: 1199px) {
			        top: -30%;
			        right: 0;
			    }
	        }
	    }
	}
}

.main-banner-box-layout4 {
	position: relative;
	z-index: 1;
	@media only screen and (max-width: 1199px) {
        margin-left: auto;
        margin-right: auto;
    }
	&.ml-5 {
		@media only screen and (max-width: 1199px) {
	        margin-left: auto!important;
	        margin-right: auto;
	    }
	}
	.item-main-title {
		font-size: rem(50px);
		color: $color_dark;
		font-weight: 700;
		line-height: rem(65px);
		@media only screen and (max-width: 1199px) {
	        font-size: rem(42px);
	        line-height: rem(60px);
	    }
	    @media only screen and (max-width: 991px) {
	       font-size: rem(38px);
	       line-height: rem(55px);
	    }
		@media only screen and (max-width: 767px) {
	       font-size: rem(34px);
	       line-height: rem(50px);
	    }
	    @media only screen and (max-width: 575px) {
	       font-size: rem(32px);
	       line-height: rem(45px);
	       text-align: center;
	    }
	}
	p {
		width: 90%;
		margin-bottom: rem(50px);
		font-size: rem(18px);
		@media only screen and (max-width: 1199px) {
	        margin-left: auto;
	        margin-right: auto;
	        text-align: center;
	    }
	    @media only screen and (max-width: 991px) {
	        width: 100%;
	    }
	}
	.banner-btns {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	    margin-bottom: rem(-10px);
	    @media only screen and (max-width: 1199px) {
	        justify-content: center;
	    }
	    @media only screen and (max-width: 479px) {
	        flex-direction: column;
	    }
		.item-btn {
			min-width: rem(180px);
			margin-bottom: rem(10px);
			margin-right: rem(30px);
			padding: rem(9px 29px);
			border: rem(1px) solid #e6e6e6;
			@media only screen and (max-width: 479px) {
		        margin-right: 0;
		    }
			i,
			img {
				margin-right: rem(10px);
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			&:hover {
				background-color: $color_primary;
				border: rem(1px) solid $color_primary;
				color: $color_light;
				i {
					transform: translateX(0);
				}
			}
		}
	}

	.item-figure {
		@media only screen and (max-width: 1199px) {
	        margin-bottom: rem(50px);
	    }
	    @media only screen and (max-width: 991px) {
	        margin-bottom: rem(40px);
	    }
		@media only screen and (max-width: 767px) {
	        padding: rem(0 60px);
	        margin-bottom: rem(30px);
	    }
		.inner-animated-figure {
			@media only screen and (max-width: 767px) {
		        display: none;
		    }
			ul {
				li {
					position: absolute;
					z-index: 2;
					&:nth-child(1n) {
						left: rem(-100px);
						right: inherit;
						top: rem(155px);
					}
					&:nth-child(2n) {
						right: inherit;
						left: rem(-75px);
						top: 70%;
						transform: translateY(rem(-50px));
					}
					&:nth-child(3n) {
						left: inherit;
						right: rem(-80px);
						top: 42%;
						transform: translateY(rem(-50px));
					}
				}
			}
		}
	}
}

/*--- Main Banner Layout Five ---*/
.main-banner-wrap-layout5 {
	padding: rem(120px 0);
	overflow: hidden;
	position: relative;
	z-index: 1;
	@media only screen and (max-width: 991px) {
        padding: rem(42px 0 105px);
    }
    @media only screen and (max-width: 767px) {
        padding: rem(38px 0 90px);
    }
    @media only screen and (max-width: 575px) {
        padding: rem(25px 0 75px);
    }
	.animated-buble {
		@media only screen and (max-width: 767px) {
	        display: none;
	    }
        li {
            position: absolute;
            z-index: -1;

            &:nth-child(1n) {
                top: rem(-230px);
                left: rem(-220px);
                right: inherit;
                bottom: inherit;
            }

            &:nth-child(2n) {
                top: rem(-74px);
                left: rem(145px);
                right: inherit;
                bottom: inherit;
            }

            &:nth-child(3n) {
                bottom: rem(-70px);
                left: rem(30%);
                right: inherit;
                top: inherit;
            }
        }
    }
    .animated-figure {
    	@media only screen and (max-width: 767px) {
	        display: none;
	    }
        li {
            position: absolute;
            z-index: -1;
            right: 0;
            bottom: 0;
        }
    }
    .animated-shape-small {
    	@media only screen and (max-width: 991px) {
	        display: none;
	    }
        ul {
        	li {
        		position: absolute;
				z-index: 0;
				&:nth-child(1n) {
				    top: 30%;
				    right: 60%;
				    animation: shape_one 30s alternate infinite linear;
				}
				&:nth-child(2n) {
					top: 10%;
				    right: 40%;
				    animation: shape_two 30s alternate infinite linear;
				}
				&:nth-child(3n) {
					top: 15%;
				    right: 20%;
				    animation: shape_three 30s alternate infinite linear;
				}
				&:nth-child(4n) {
					top: 20%;
				    right: 5%;
				    animation: shape_four 30s alternate infinite linear;
				}
				&:nth-child(5n) {
					top: 70%;
				    right: 60%;
				    animation: shape_five 30s alternate infinite linear;
				}
				&:nth-child(6n) {
					top: 80%;
				    right: 50%;
				    animation: shape_six 30s alternate infinite linear;
				}
        	}
        }
	}

	@keyframes shape_one {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		20% {
		    transform: translate(rem(73px), rem(-100px)) rotate(36deg);
		}
		50% {
		    transform: translate(rem(141px), rem(72px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(83px), rem(122px)) rotate(108deg);
		}
		80% {
		    transform: translate(rem(40px), rem(-72px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}

	@keyframes shape_two {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		10% {
		    transform: translate(rem(53px), rem(-80px)) rotate(36deg);
		}
		40% {
		    transform: translate(rem(161px), rem(72px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(83px), rem(112px)) rotate(108deg);
		}
		70% {
		    transform: translate(rem(-60px), rem(92px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}

	@keyframes shape_three {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		30% {
		    transform: translate(rem(90px), rem(-36px)) rotate(36deg);
		}
		40% {
		    transform: translate(rem(101px), rem(32px)) rotate(72deg);
		}
		50% {
		    transform: translate(rem(103px), rem(102px)) rotate(108deg);
		}
		80% {
		    transform: translate(rem(-76px), rem(62px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}

	@keyframes shape_four {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		10% {
		    transform: translate(rem(80px), rem(-36px)) rotate(36deg);
		}
		40% {
		    transform: translate(rem(191px), rem(72px)) rotate(72deg);
		}
		50% {
		    transform: translate(rem(93px), rem(122px)) rotate(108deg);
		}
		80% {
		    transform: translate(rem(-56px), rem(92px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}

	@keyframes shape_five {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		30% {
		    transform: translate(rem(80px), rem(-36px)) rotate(36deg);
		}
		40% {
		    transform: translate(rem(141px), rem(-92px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(153px), rem(92px)) rotate(108deg);
		}
		80% {
		    transform: translate(rem(-99px), rem(62px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}

	@keyframes shape_six {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		20% {
		    transform: translate(rem(95px), rem(-58px)) rotate(36deg);
		}
		30% {
		    transform: translate(rem(81px), rem(82px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(53px), rem(152px)) rotate(108deg);
		}
		70% {
		    transform: translate(rem(-56px), rem(32px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
}

.main-banner-box-layout5 {
	margin-top: rem(70px);
	@media only screen and (max-width: 991px) {
        text-align: center;
        margin-top: rem(50px);
    }
	@media only screen and (max-width: 767px) {
        margin-top: rem(40px);
    }

    .item-figure {
    	@media (min-width: 992px) and (max-width: 1579px) {
	        padding-right: rem(30px);
	    }
	    @media only screen and (max-width: 991px) {
	        padding: 0 10%;
	    }
    }

    .item-content {
		@media only screen and (max-width: 1579px) {
	        padding-left: rem(45px);
	    }
	    @media only screen and (max-width: 991px) {
	       padding-left: 0;
	    }
    }

	.item-sub-title {
		color: $color_light;
		margin-bottom: rem(15px);
		font-size: rem(20px);
		letter-spacing: rem(4px);
		@media only screen and (max-width: 991px) {
	       letter-spacing: 0;
	    }
	}

	.item-main-title {
		font-size: rem(50px);
		color: $color_light;
		font-weight: 700;
		line-height: rem(60px);
		@media only screen and (max-width: 991px) {
		    font-size: rem(42px);
		    line-height: rem(50px);
		    width: 85%;
		    margin-left: auto;
		    margin-right: auto;
		}
		@media only screen and (max-width: 767px) {
		    font-size: rem(36px);
		    line-height: rem(45px);
		}
		@media only screen and (max-width: 575px) {
		    width: 100%;
		    font-size: rem(30px);
		    line-height: rem(40px);
		}
	}

	p {
		color: $color_light;
		width: 80%;
		margin-bottom: rem(50px);
		font-size: rem(18px);
		@media only screen and (max-width: 991px) {
		    margin-left: auto;
		    margin-right: auto;
		}
		@media only screen and (max-width: 575px) {
		    width: 100%;
		    margin-bottom: rem(40px);
		}
	}
}

/*--- Main Banner Layout Six ---*/
.main-banner-wrap-layout6 {
	padding: rem(170px 0 120px);
	position: relative;
	overflow: hidden;
	z-index: 0;
	height: 100vh;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	&:before {
		content: "";
		background-image: url("../../media/element/element59.png");
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		right: 0;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		height: rem(84px);
	}
	@media only screen and (max-width: 991px) {
		height: 100%;
		padding: rem(55px 0 105px);
	}
	@media only screen and (max-width: 767px) {
		padding: rem(50px 0 90px);
	}
	@media only screen and (max-width: 575px) {
		padding: rem(45px 0 75px);
	}
	.animated-figure {
		@media only screen and (max-width: 1199px) {
			display: none;
		}
        li {
            position: absolute;
            z-index: -1;
			top: 0;
			right: -100px;
			transform: translateY(0);
        }
    }

    .compress-left-side {
		padding-right: rem(50px);
		padding-left: rem(calc(calc(100% - 1170px)/2));

		@media only screen and (max-width: 1199px) {
			padding-right: 0;
			padding-left: rem(calc(calc(100% - 930px)/2));
		}

		@media only screen and (max-width: 991px) {
			padding-right: rem(calc(calc(100% - 690px)/2));
			padding-left: rem(calc(calc(100% - 690px)/2));
		}

		@media only screen and (max-width: 767px) {
			padding-right: rem(calc(calc(100% - 510px)/2));
			padding-left: rem(calc(calc(100% - 510px)/2));
		}

		@media only screen and (max-width: 575px) {
			padding-right: rem(15px);
			padding-left: rem(15px);
		}
	}

	.compress-right-side {
		padding-left: rem(50px);
		padding-right: rem(calc(calc(100% - 1170px)/2));

		@media only screen and (max-width: 1199px) {
			padding-left: rem(calc(calc(100% - 930px)/2));
			padding-right: rem(calc(calc(100% - 930px)/2));
		}

		@media only screen and (max-width: 991px) {
			padding-left: rem(calc(calc(100% - 690px)/2));
			padding-right: rem(calc(calc(100% - 690px)/2));
		}

		@media only screen and (max-width: 767px) {
			padding-left: rem(calc(calc(100% - 510px)/2));
			padding-right: rem(calc(calc(100% - 510px)/2));
		}

		@media only screen and (max-width: 575px) {
			padding-left: rem(15px);
			padding-right: rem(15px);
		}
	}
}

.main-banner-box-layout6 {
	margin-top: rem(20px);
	@media only screen and (max-width: 991px) {
		margin-top: rem(50px);
	}
	@media only screen and (max-width: 767px) {
		margin-top: rem(30px);
	}
	@media only screen and (max-width: 575px) {
		margin-top: rem(20px);
		text-align: center;
	}
	.item-figure {
		margin-top: rem(50px);
		animation: up_down 15s infinite;
		animation-delay: 4s;
		@media only screen and (max-width: 767px) {
			animation: inherit;
			margin-top: rem(0px);
			margin-bottom: rem(60px);
		}
	}
	.item-sub-title {
		color: $color_light;
		margin-bottom: rem(15px);
		font-size: rem(20px);
		letter-spacing: rem(4px);
	}
	.item-main-title {
		font-size: rem(50px);
		color: $color_light;
		font-weight: 600;
		line-height: rem(65px);
		margin-bottom: 45px;
		@media only screen and (max-width: 1199px) {
		    font-size: rem(42px);
		    line-height: rem(60px);
		}
		@media only screen and (max-width: 991px) {
			font-size: rem(38px);
			line-height: rem(55px);
		}
		@media only screen and (max-width: 767px) {
			font-size: rem(34px);
			line-height: rem(50px);
		}
		@media only screen and (max-width: 575px) {
			font-size: rem(30px);
			line-height: rem(45px);
		}
	}
	p {
		color: $color_light;
		width: 80%;
		margin-bottom: rem(50px);
		font-size: rem(18px);
		@media only screen and (max-width: 767px) {
			margin-bottom: rem(40px);
		}
		@media only screen and (max-width: 767px) {
			width: 100%;
		}
	}
	.banner-btns {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: rem(15px);
		@media only screen and (max-width: 575px) {
			display: block;
		}
		a {
			margin-bottom: rem(15px);
			margin-right: rem(30px);
			@media only screen and (max-width: 575px) {
				margin-right: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		.item-btn {
			padding: rem(10px 29px);
		}
		.play-btn {
			display: flex;
			align-items: center;
			@media only screen and (max-width: 575px) {
				justify-content: center;
			}
			.item-icon {
				position: relative;
				z-index: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				height: rem(45px);
				width: rem(45px);
				border-radius: 50%;
				margin-right: rem(10px);
				color: $color_primary;
				transition: all 0.5s ease-in-out;
				&:before {
					border-radius: 50%;
					position: absolute;
					z-index: -1;
					content: "";
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					background-image: linear-gradient( 180deg, rgb(169,93,241) 0%, rgb(117,14,213) 100%);
					color: $color_primary;
					font-size: rem(14px);
					opacity: 0;
					transition: all 0.5s ease-in-out;
				}
				&:after {
					border-radius: 50%;
					position: absolute;
					z-index: -1;
					content: "";
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					opacity: 1;
					background-color: $color_light;
					color: $color_primary;
					font-size: rem(14px);
					transition: all 0.5s ease-in-out;
				}
				i {
					margin-left: rem(5px);
				}
			}
			span {
				color: $color_light;
				transition: all 0.5s ease-in-out;
			}
			&:hover {
				.item-icon {
					color: $color_light;
					&:before {
						opacity: 1;
					}
					&:after {
						opacity: 0;
					}
				}
			}
		}
	}
	@keyframes up_down {
		0% {
			transform: translateY(rem(0)) translateX(rem(0));
		}
		25% {
			transform: translateY(rem(-30px)) translateX(rem(0));
		}
		75% {
			transform: translateY(rem(30px)) translateX(rem(0));
		}
	}
}

/*--- Main Banner Layout Seven ---*/
.main-banner-wrap-layout7 {
	overflow: hidden;
	padding: rem(220px 0 0);
	position: relative;
	z-index: 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@media only screen and (max-width: 991px) {
		height: 100%;
		padding: rem(55px 0 0);
	}
	@media only screen and (max-width: 767px) {
		padding: rem(50px 0 0);
	}
	@media only screen and (max-width: 575px) {
		padding: rem(45px 0 0);
	}
	.animated-figure {
		@media only screen and (max-width: 1199px) {
			display: none;
		}
        li {
            position: absolute;
            z-index: -1;
			top: 0;
			right: -100px;
			transform: translateY(0);
        }
	}
	.animated-shape-small {
		@media only screen and (max-width: 991px) {
			display: none;
		}
    	li {
    		position: absolute;
			z-index: 1;
			&:nth-child(1n) {
			    top: 20%;
			    left: 20%;
			    animation: home7_shape_one 100s alternate infinite linear;
			}
			&:nth-child(2n) {
				top: 40%;
			    left: 10%;
			    animation: home7_shape_two 100s alternate infinite linear;
			}
			&:nth-child(3n) {
				top: 10%;
			    left: 50%;
			    animation: home7_shape_three 100s alternate infinite linear;
			}
			&:nth-child(4n) {
				top: 50%;
			    left: 40%;
			    animation: home7_shape_four 100s alternate infinite linear;
			}
			&:nth-child(5n) {
				top: 20%;
			    right: 40%;
			    animation: home7_shape_five 100s alternate infinite linear;
			}
			&:nth-child(6n) {
				top: 40%;
			    right: 30%;
			    animation: home7_shape_six 100s alternate infinite linear;
			}
			&:nth-child(7n) {
				top: 10%;
			    right: 30%;
			    animation: home7_shape_seven 100s alternate infinite linear;
			}
			&:nth-child(8n) {
				top: 30%;
			    right: 20%;
			    animation: home7_shape_eight 100s alternate infinite linear;
			}
        }
	}
	@keyframes home7_shape_one {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		20% {
		    transform: translate(rem(73px), rem(-100px)) rotate(36deg);
		}
		50% {
		    transform: translate(rem(141px), rem(72px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(83px), rem(122px)) rotate(108deg);
		}
		80% {
		    transform: translate(rem(40px), rem(-72px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
	@keyframes home7_shape_two {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		10% {
		    transform: translate(rem(53px), rem(-80px)) rotate(36deg);
		}
		40% {
		    transform: translate(rem(161px), rem(72px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(83px), rem(112px)) rotate(108deg);
		}
		70% {
		    transform: translate(rem(-60px), rem(92px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
	@keyframes home7_shape_three {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		30% {
		    transform: translate(rem(90px), rem(-36px)) rotate(36deg);
		}
		40% {
		    transform: translate(rem(101px), rem(32px)) rotate(72deg);
		}
		50% {
		    transform: translate(rem(103px), rem(102px)) rotate(108deg);
		}
		80% {
		    transform: translate(rem(-76px), rem(62px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
	@keyframes home7_shape_four {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		10% {
		    transform: translate(rem(80px), rem(-36px)) rotate(36deg);
		}
		40% {
		    transform: translate(rem(191px), rem(72px)) rotate(72deg);
		}
		50% {
		    transform: translate(rem(93px), rem(122px)) rotate(108deg);
		}
		80% {
		    transform: translate(rem(-56px), rem(92px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
	@keyframes home7_shape_five {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		30% {
		    transform: translate(rem(80px), rem(-36px)) rotate(36deg);
		}
		40% {
		    transform: translate(rem(11px), rem(-92px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(15px), rem(92px)) rotate(108deg);
		}
		80% {
		    transform: translate(rem(-99px), rem(62px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
	@keyframes home7_shape_six {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		20% {
		    transform: translate(rem(95px), rem(-58px)) rotate(36deg);
		}
		30% {
		    transform: translate(rem(81px), rem(82px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(53px), rem(152px)) rotate(108deg);
		}
		70% {
		    transform: translate(rem(-56px), rem(32px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
	@keyframes home7_shape_seven {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		20% {
		    transform: translate(rem(75px), rem(-55px)) rotate(36deg);
		}
		30% {
		    transform: translate(rem(92px), rem(82px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(58px), rem(132px)) rotate(108deg);
		}
		70% {
		    transform: translate(rem(-66px), rem(42px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
	@keyframes home7_shape_eight {
	    0% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}

		20% {
		    transform: translate(rem(65px), rem(-48px)) rotate(36deg);
		}
		30% {
		    transform: translate(rem(61px), rem(72px)) rotate(72deg);
		}
		60% {
		    transform: translate(rem(94px), rem(122px)) rotate(108deg);
		}
		70% {
		    transform: translate(rem(-63px), rem(35px)) rotate(144deg);
		}
		100% {
		    transform: translate(rem(0px), rem(0px)) rotate(0deg);
		}
	}
}

.main-banner-box-layout7 {
	margin-top: rem(20px);
	@media only screen and (max-width: 991px) {
		margin-top: rem(50px);
	}
	@media only screen and (max-width: 767px) {
		margin-top: rem(30px);
	}
	@media only screen and (max-width: 575px) {
		margin-top: rem(20px);
		text-align: center;
	}
	.item-figure {
		margin-top: rem(50px);
		@media only screen and (max-width: 767px) {
			animation: inherit;
			margin-top: rem(0px);
		}
	}
	.item-main-title {
		margin-left: auto;
		margin-right: auto;
		width: 70%;
		font-size: rem(50px);
		color: $color_light;
		font-weight: 600;
		line-height: rem(65px);
		@media only screen and (max-width: 1199px) {
		    font-size: rem(42px);
		    line-height: rem(60px);
		}
		@media only screen and (max-width: 991px) {
			font-size: rem(38px);
			line-height: rem(55px);
			width: 80%;
		}
		@media only screen and (max-width: 767px) {
			font-size: rem(34px);
			line-height: rem(50px);
			width: 90%;
		}
		@media only screen and (max-width: 575px) {
			font-size: rem(30px);
			line-height: rem(45px);
			width: 100%;
		}
	}
	p {
		margin-left: auto;
		margin-right: auto;
		color: $color_light;
		width: 50%;
		margin-bottom: rem(50px);
		font-size: rem(18px);
		@media only screen and (max-width: 767px) {
			margin-bottom: rem(40px);
		}
		@media only screen and (max-width: 767px) {
			width: 100%;
		}
	}
	.banner-btns {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: rem(15px);
		@media only screen and (max-width: 575px) {
			display: block;
		}
		a {
			margin-bottom: rem(15px);
			margin-right: rem(30px);
			@media only screen and (max-width: 575px) {
				margin-right: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		.item-btn {
			padding: rem(10px 29px);
		}
		.play-btn {
			display: flex;
			align-items: center;
			@media only screen and (max-width: 575px) {
				justify-content: center;
			}
			.item-icon {
				position: relative;
				z-index: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				height: rem(45px);
				width: rem(45px);
				border-radius: 50%;
				margin-right: rem(10px);
				color: $color_primary;
				transition: all 0.5s ease-in-out;
				&:before {
					border-radius: 50%;
					position: absolute;
					z-index: -1;
					content: "";
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					background-image: linear-gradient( 180deg, rgb(169,93,241) 0%, rgb(117,14,213) 100%);
					color: $color_primary;
					font-size: rem(14px);
					opacity: 0;
					transition: all 0.5s ease-in-out;
				}
				&:after {
					border-radius: 50%;
					position: absolute;
					z-index: -1;
					content: "";
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					opacity: 1;
					background-color: $color_light;
					color: $color_primary;
					font-size: rem(14px);
					transition: all 0.5s ease-in-out;
				}
				i {
					margin-left: rem(5px);
				}
			}
			span {
				color: $color_light;
				transition: all 0.5s ease-in-out;
			}
			&:hover {
				.item-icon {
					color: $color_light;
					&:before {
						opacity: 1;
					}
					&:after {
						opacity: 0;
					}
				}
			}
		}
	}
}

/*--- Main Banner Layout Eight ---*/
.main-banner-wrap-layout8 {
	padding: rem(190px 0 100px);
	position: relative;
	overflow: hidden;
	z-index: 0;
	background-color: #f8f8ff;
	@media only screen and (max-width: 991px) {
		height: 100%;
		padding: rem(45px 0 105px);
	}
	@media only screen and (max-width: 767px) {
		padding: rem(30px 0 90px);
	}
	@media only screen and (max-width: 575px) {
		padding: rem(15px 0 75px);
	}

    .compress-left-side {
		padding-right: rem(50px);
		padding-left: rem(calc(calc(100% - 1170px)/2));

		@media only screen and (max-width: 1199px) {
			padding-right: 0;
			padding-left: rem(calc(calc(100% - 930px)/2));
		}

		@media only screen and (max-width: 991px) {
			padding-right: rem(calc(calc(100% - 690px)/2));
			padding-left: rem(calc(calc(100% - 690px)/2));
		}

		@media only screen and (max-width: 767px) {
			padding-right: rem(calc(calc(100% - 510px)/2));
			padding-left: rem(calc(calc(100% - 510px)/2));
		}

		@media only screen and (max-width: 575px) {
			padding-right: rem(15px);
			padding-left: rem(15px);
		}
	}

	.compress-right-side {
		padding-left: rem(50px);
		padding-right: rem(calc(calc(100% - 1170px)/2));

		@media only screen and (max-width: 1199px) {
			padding-left: rem(calc(calc(100% - 930px)/2));
			padding-right: rem(calc(calc(100% - 930px)/2));
		}

		@media only screen and (max-width: 991px) {
			padding-left: rem(calc(calc(100% - 690px)/2));
			padding-right: rem(calc(calc(100% - 690px)/2));
		}

		@media only screen and (max-width: 767px) {
			padding-left: rem(calc(calc(100% - 510px)/2));
			padding-right: rem(calc(calc(100% - 510px)/2));
		}

		@media only screen and (max-width: 575px) {
			padding-left: rem(15px);
			padding-right: rem(15px);
		}
	}
}

.main-banner-box-layout8 {
	margin-top: rem(20px);
	@media only screen and (max-width: 991px) {
		margin-top: rem(50px);
	}
	@media only screen and (max-width: 767px) {
		margin-top: rem(40px);
	}
	@media only screen and (max-width: 575px) {
		margin-top: rem(30px);
	}
	.item-figure {
		margin-top: rem(50px);
		animation: up_down 15s infinite;
		animation-delay: 4s;
		@media only screen and (max-width: 767px) {
			animation: inherit;
			padding: rem(0 30px);
		}
	}
	.item-main-title {
		font-size: rem(50px);
		color: $color_dark;
		font-weight: 600;
		line-height: rem(65px);
		@media only screen and (max-width: 1199px) {
		    font-size: rem(42px);
		    line-height: rem(60px);
		}
		@media only screen and (max-width: 991px) {
			font-size: rem(38px);
			line-height: rem(55px);
		}
		@media only screen and (max-width: 767px) {
			font-size: rem(34px);
			line-height: rem(50px);
		}
		@media only screen and (max-width: 575px) {
			font-size: rem(30px);
			line-height: rem(45px);
		}
	}
	p {
		color: $color_dark;
		width: 80%;
		margin-bottom: rem(50px);
		font-size: rem(18px);
		@media only screen and (max-width: 767px) {
			margin-bottom: rem(40px);
		}
		@media only screen and (max-width: 767px) {
			width: 100%;
		}
	}
	@keyframes up_down {
		0% {
			transform: translateY(rem(0)) translateX(rem(0));
		}
		25% {
			transform: translateY(rem(-30px)) translateX(rem(0));
		}
		75% {
			transform: translateY(rem(30px)) translateX(rem(0));
		}
	}
}

/*--- Main Banner Layout Nine ---*/
.main-banner-wrap-layout9 {
	overflow: hidden;
	padding: rem(200px 0 120px);
	position: relative;
	z-index: 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@media only screen and (max-width: 991px) {
		height: 100%;
		padding: rem(65px 0 105px);
	}
	@media only screen and (max-width: 767px) {
		padding: rem(50px 0 90px);
	}
	@media only screen and (max-width: 575px) {
		padding: rem(35px 0 75px);
	}
}

.main-banner-box-layout9 {	
	@media only screen and (max-width: 991px) {
		margin-top: rem(30px);
	}
	.item-figure {
		margin-top: rem(100px);
		@media only screen and (max-width: 767px) {
			animation: inherit;
			margin-top: rem(0px);
		}
	}
	.item-main-title {
		margin-left: auto;
		margin-right: auto;
		width: 70%;
		font-size: rem(50px);
		color: $color_light;
		font-weight: 600;
		line-height: rem(65px);
		margin-bottom: rem(30px);
		@media only screen and (max-width: 1199px) {
		    font-size: rem(42px);
		    line-height: rem(60px);
		}
		@media only screen and (max-width: 991px) {
			font-size: rem(38px);
			line-height: rem(55px);
			width: 80%;
		}
		@media only screen and (max-width: 767px) {
			font-size: rem(34px);
			line-height: rem(50px);
			width: 90%;
		}
		@media only screen and (max-width: 575px) {
			font-size: rem(30px);
			line-height: rem(45px);
			width: 100%;
		}
	}
	.item-btn {
		padding: rem(8px 35px);
		border: rem(2px) solid #c3c3c3;
	}
	
}


/*--- Main Banner Layout Three ---*/
.main-banner-wrap-layout10 {
	padding: rem(130px 0 106px);
	background-size: cover;
	background-position: center;
	overflow: hidden;
	position: relative;
	z-index: 0;
	@media only screen and (max-width: 1199px) {
		padding: rem(150px 0 0);
	}
	@media only screen and (max-width: 991px) {
		padding: rem(105px 0 0);
	}

	@media only screen and (max-width: 767px) {
		padding: rem(90px 0 0);
	}

	@media only screen and (max-width: 575px) {
		padding: rem(75px 0 0);
	}
	.animated-figure {
		@media only screen and (max-width: 767px) {
			display: none;
		}
	    ul {
	        li {
	            position: absolute;
	            z-index: -1;
                &:nth-child(1n) {
                	top: rem(-30px);
                	left: inherit;
                	bottom: inherit;
                	right: 12%;
                	@media only screen and (max-width: 1579px) {
						right: 5%;
					}
					@media only screen and (max-width: 1399px) {
						right: 2%;
					}
					@media only screen and (max-width: 1200px) {
						right: 0;
					}
                }
                &:nth-child(2n) {
                	bottom: rem(200px);
                	left: rem(-300px);
                	top: inherit;
                	right: inherit;
                }
                &:nth-child(3n) {
                	bottom: rem(250px);
                	left: rem(-250px);
                	top: inherit;
                	right: inherit;
                }
                &:nth-child(4n) {
                	bottom: rem(300px);
                	left: rem(-200px);
                	top: inherit;
                	right: inherit;
                }
	        }
	    }
	}
	.compress-right-side {
		padding-right: rem(calc(calc(100% - 1170px)/2));
		padding-left: 15px;
		@media only screen and (max-width: 1199px) {
			padding-left: rem(calc(calc(100% - 930px)/2));
			padding-right: rem(calc(calc(100% - 930px)/2));
		}

		@media only screen and (max-width: 991px) {
			padding-left: rem(calc(calc(100% - 690px)/2));
			padding-right: rem(calc(calc(100% - 690px)/2));
		}

		@media only screen and (max-width: 767px) {
			padding-left: rem(calc(calc(100% - 510px)/2));
			padding-right: rem(calc(calc(100% - 510px)/2));
		}

		@media only screen and (max-width: 575px) {
			padding-left: rem(15px);
			padding-right: rem(15px);
		}
	}
}

.main-banner-box-layout10 {
	text-align: center;
	margin-bottom: rem(30px);
	.item-figure {
		@media only screen and (max-width: 1199px) {
			margin-bottom: rem(50px)
		}
		@media only screen and (max-width: 991px) {
			padding: 0 10%;
			margin-bottom: rem(30px)
		}
	}
	.item-sub-title {
		background-color: #ffffff;
		padding: rem(10px 35px);
		font-size: rem(18px);
		color: $color_primary;
		font-weight: 500;
		display: inline-block;
		border-radius: rem(4px);
		margin-bottom: rem(20px);
	}
	.item-main-title {
		font-size: rem(50px);
		color: $color_light;
		font-weight: 600;
		line-height: rem(65px);
		margin-bottom: rem(25px);
		@media only screen and (max-width: 1199px) {
			font-size: rem(46px);
			line-height: rem(60px);
			width: 80%;
			margin-left: auto;
			margin-right: auto;
		}
		@media only screen and (max-width: 991px) {
			font-size: rem(40px);
			line-height: rem(55px);
		}
		@media only screen and (max-width: 767px) {
			font-size: rem(34px);
			line-height: rem(49px);
			width: 90%;
		}
		@media only screen and (max-width: 575px) {
			font-size: rem(30px);
			line-height: rem(45px);
			width: 100%;
		}
	}
	p {
		color: $color_light;
		width: 100%;
		max-width: 450px;
		font-size: rem(22px);
		margin-left: auto;
		margin-right: auto;
		@media only screen and (max-width: 1199px) {	
			margin-bottom: rem(40px);
		}
		@media only screen and (max-width: 767px) {
			margin-bottom: rem(30px);
		}
	}
	.banner-btns {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		@media only screen and (max-width: 1199px) {
			.item-btn {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

/*--- Inner Banner Layout One ---*/
.inner-banner-wrap-layout1 {
	height: rem(450px);
}

/*--- Inner Page Banner ---*/
.page-banner {
	overflow: hidden;
	display: block;
	padding: rem(213px 0 130px);
	position: relative;
	z-index: 0;
	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba($color_dark, 0.8);
	}
	@media only screen and (max-width: 991px) {
		padding: rem(100px 0);
	}

	@media only screen and (max-width: 767px) {
		padding: rem(90px 0);
	}

	@media only screen and (max-width: 575px) {
		padding: rem(80px 0);
	}

	.shape-holder {
		li {
			position: absolute;
			left: 0;
			right: 0;
			z-index: -1;
			svg {
				margin: 0 auto;
			}
			&:nth-child(1n) {
				bottom: rem(45px);
			}
			&:nth-child(2n) {
				bottom: rem(145px);
			}
			&:nth-child(3n) {
				bottom: rem(245px);
			}
		}
	}

	.breadcrumbs-area {
		position: relative;
		z-index: 1;
		text-align: center;

		h1 {
			font-weight: 600;
			font-size: rem(36px);
			color: $color_light;
			margin-bottom: 0;
			@media only screen and (max-width: 1199px) {
				font-size: rem(34px);
				line-height: 1.5;
			}

			@media only screen and (max-width: 991px) {
				font-size: rem(32px);
				line-height: 1.3;
			}
		}

		ul {
			li {
				display: inline-block;
				position: relative;
				margin-right: rem(15px);
				padding-right: rem(5px);
				color: #cecece;
				font-size: rem(18px);
				&:before {
					content: "/";
					position: absolute;
					font-size: rem(18px);
					right: rem(-11px);
					top: 50%;
					transform: translateY(-50%);
					z-index: 5;
					color: #cecece;
				}

				a {
					color: $color_light;
					transition: all 0.5s ease-in-out;

					&:hover {
						color: $color_light;
					}
				}

				&:last-child {
					margin-right: 0;
					padding-right: 0;

					&:before {
						display: none;
					}
				}
			}
		}
	}
}