nav.template-main-menu {
	font-family: $font_heading;
	>ul {
		display: flex;
		justify-content: center;

		>li {
			position: relative;
			>a {
				font-size: rem(16px);
				font-weight: 500;
				line-height: 1;
				display: block;
				position: relative;
				z-index: 1;
				transition: all 0.5s ease-out;
				&:before {
					position: absolute;
					z-index: 1;
					content: "";
					height: rem(2px);
					background-color: $color_light;
					bottom: rem(26px);
					width: 0%;
					left: 0;
					right: 0;
					margin: 0 auto;
					transition: all 0.5s ease-out;
				}

				&:hover {
					&:before {
						width: 50%;
					}
				}
			}

			ul.dropdown-menu-col-1 {
				padding: rem(20px 0);
				border-top: rem(1px) solid $color_primary;
				background-color: #ffffff;
				width: rem(265px);
				visibility: hidden;
				opacity: 0;
				position: absolute;
				z-index: 0;
				top: 100%;
				left: 0;
				border-radius: rem(4px);
				transform: translateY(rem(-15px));
				box-shadow: rem(0 0 35px 0) rgba(0, 0, 0, 0.10);
				transition: all 0.3s ease-in-out;
				&:before {
					position: absolute;
					z-index: 1;
					background-color: $color_primary;
					height: rem(1px);
					border-radius: rem(4px);
					content: "";
					width: 98%;
					margin: 0 auto;
					left: 0;
					right: 0;
					bottom: 0;
				}
				li {
					a {
						font-weight: 500;
						width: 100%;
						padding: rem(5px 30px);
						font-size: rem(14px);
						color: #4c4b4b;
						display: inline-block;
						transition: all 0.5s ease-in-out;
						span {
							position: relative;
							z-index: 1;
							&:before {
								position: absolute;
								z-index: 1;
								content: "";
								background-color: $color_primary;
								height: rem(1px);
								width: 0;
								right: 0;
								left: inherit;
								bottom: 0;
								transition: all 0.7s cubic-bezier(.645,.045,.355,1);
							}
						}
						&:hover {
							color: $color_primary;
							span {
								&:before {
									width: 100%;
									left: 0;
									right: inherit;
								}
							}
						}
					}
				}
				li.has-child-second-level {
                    position: relative;
                    >a {
                        color: #4c4b4b;
                        &:after {
                            font-family: "Font Awesome 5 Free";
                            font-weight: 900;
                            content: "\f105";
                            float: right;
                        }
                    }
                    >ul.second-level {
                    	padding: rem(20px 0);
                        border-bottom: rem(1px) solid $color_primary;
                        background-color: #ffffff;
                        width: rem(265px);
                        position: absolute;
                        border-radius: rem(4px);
                        left: 100%;
                        top: 0;
                        opacity: 0;
                        transform: scaleY(0);
                        transform-origin: 0 0 0;
                        box-shadow: rem(0 0 35px 0) rgba(0, 0, 0, 0.10);
                        transition: all 0.3s ease-in-out;
                        >li {
                            a {
                                color: #4c4b4b;
                                span {
									position: relative;
									z-index: 1;
									&:before {
										position: absolute;
										z-index: 1;
										content: "";
										background-color: $color_primary;
										height: rem(1px);
										width: 0;
										right: 0;
										left: inherit;
										bottom: 0;
										transition: all 0.7s cubic-bezier(.645,.045,.355,1);
									}
								}
								&:hover {
									color: $color_primary;
									span {
										&:before {
											width: 100%;
											left: 0;
											right: inherit;
										}
									}
								}
                            }
                        }
                        li.has-child-third-level {
                            position: relative;
                            >a {
                                color: #4c4b4b;
                                &:after {
                                    font-family: "Font Awesome 5 Free";
                                    font-weight: 900;
                                    content: "\f105";
                                    float: right;
                                }
                            }
                            >ul.third-level {
                            	padding: rem(20px 0);
                            	border-radius: rem(4px);
                                border-bottom: rem(1px) solid $color_primary;
                                background-color: #ffffff;
                                width: rem(265px);
                                position: absolute;
                                left: 100%;
                                top: 0;
                                opacity: 0;
                                transform: scaleY(0);
                                transform-origin: 0 0 0;
                                box-shadow: rem(0 0 35px 0) rgba(0, 0, 0, 0.10);
                                transition: all 0.3s ease-in-out;
                                li {
                                    a {
                                        color: #4c4b4b;
                                    }
                                    &:hover {
                                        a {
                                            color: $color_primary;
                                        }
                                    }
                                }
                            }
                            &:hover {
                                a {
                                    color: $color_primary;
                                }
                                ul.third-level {
                                    opacity: 1;
                                    transform: scaleY(1);
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    &:hover {
                        a {
                            color: $color_primary;
                        }
                        ul.second-level {
                            opacity: 1;
                            transform: scaleY(1);
                            visibility: visible;
                        }
                    }
                }
			}

			ul.dropdown-menu-col-2 {
				display: flex;
				align-items: center;
				border-bottom: rem(1px) solid $color_primary;
				background-color: #ffffff;
				opacity: 0;
				visibility: hidden;
				position: absolute;
				z-index: 0;
				top: 100%;
				left: 0;
				transform: translateY(rem(-15px));
				box-shadow: rem(0 3px 20px) rgba(0, 0, 0, 0.05);
				transition: all 0.3s ease-in-out;

				li {
					min-width: rem(200px);

					a {
						font-size: rem(14px);
						color: #111111;
						padding: rem(8px 15px);
						display: block;
						transition: all 0.3s ease-in-out;

						&:hover {
							color: #ffffff;
							background-color: $color_primary;
						}

						&:last-child {
							padding-bottom: rem(15px);
						}
					}
				}
			}

			.mega-menu-container {
				opacity: 0;
				visibility: hidden;
				transform: translateY(rem(-15px));
				position: absolute;
				z-index: 0;
				left: 0;
				right: 0;
				top: 100%;
				padding: rem(30px 30px 0);
				background-color: $color_light;
				border-radius: rem(4px);
				box-shadow: rem(0 0 35px 0) rgba(0, 0, 0, 0.10);
				transition: all 0.3s ease-in-out;
				.mega-menu-box {
					margin-bottom: rem(30px);
					.mega-menu-figure {
						overflow: hidden;
						box-shadow: rem(0 0 35px 0) rgba($color_dark, 0.2);
						margin-bottom: rem(10px);
						border-radius: rem(4px);
						transition: all 0.5s ease-in-out;
						img {
							opacity: 1;
							transform: scale(1.05);
							border-radius: rem(4px);
							transition: all 0.5s ease-in-out;
						}
					}
					.mega-menu-title {
						text-align: center;
						.item-title {
							font-size: rem(16px);
							margin-bottom: 0;
							font-weight: 500;
							letter-spacing: rem(2px);
							a {
								color: $color_heading;
								transition: all 0.5s ease-in-out;
								&:hover {
									color: $color_primary;
								}
							}
						}
					}
					&:hover {
						.mega-menu-figure {
							background-color: $color_dark;
							border-radius: rem(4px);
							img {
								opacity: 0.7;
								transform: scale(1.15);
							}
						}
					}
				}
			}

			&:hover {
				ul.dropdown-menu-col-1 {
					z-index: 999;
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
					transition: all 0.3s ease-in-out;
				}

				ul.dropdown-menu-col-2 {
					z-index: 999;
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
					transition: all 0.3s ease-in-out;
				}

				.mega-menu-container {
					z-index: 999;
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
					transition: all 0.3s ease-in-out;
				}
			}

			>ul {
				>li {
					position: relative;

					a.third-level-arrow {
						&:after {
							position: absolute;
							content: "\f105";
							font-family: "Font Awesome 5 Free";
							font-weight: 600;
							opacity: 0.8;
							font-size: rem(20px);
							z-index: 8;
							right: rem(30px);
							top: 50%;
							transform: translateY(-50%);
							color: #111111;
							transition: all 1s cubic-bezier(0, 0, 0.15, 1.88);
						}
					}

					&:hover {
						.third-level-arrow:after {
							right: rem(15px);
						}

						ul.third-level {
							opacity: 1;
							visibility: visible;
							transform: translatey(0);
						}
					}
				}
			}
		}
	}
}

.sticky-on {
	.navbar-wrap {
		position: relative;
		z-index: 2;
		.navbar-layout1 {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
			z-index: 100;
		}
		.navbar-layout2 {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
			z-index: 100;
		}
		.navbar-layout3 {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
			z-index: 100;
		}
		.navbar-layout4 {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
			z-index: 100;
		}
		.navbar-layout5 {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
			z-index: 100;
		}
		.navbar-layout6 {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
			z-index: 100;
		}
		&.no-transparent {
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
			z-index: 100;
		}
	}
}

.sticky-on.sticky {
	.navbar-wrap {
		.navbar-layout1,
		.navbar-layout2,
		.navbar-layout3,
		.navbar-layout4,
		.navbar-layout5,
		.navbar-layout6 {
			position: fixed;
		}

		&.no-transparent {
			position: fixed;
		}
	}
}

@media only screen and (max-width: 991px) {
	header {
		display: none;
	}
}

.hide-on-mobile-menu {
	@media only screen and (max-width: 991px) {
		display: none;
	}
}

.hide-on-desktop-menu {
	@media only screen and (min-width: 992px) {
		display: none !important;
	}
}

.mean-container {
	.mean-bar {
		background: transparent !important;
		float: none;
		padding: 0 !important;
		position: fixed !important;
		top: 0;
		z-index: 7;

		&:after {
			content: "";
			clear: both;
			display: block;
		}

		a.logo-mobile-menu {
			width: 100%;
			background-color: rgba(222, 222, 222, 0.95);
			box-sizing: border-box;
		}

		.mobile-menu-nav-back {
			padding-left: rem(15px);
			border-bottom: rem(1px) solid #b2b2b2;
			position: relative;
			text-align: center;
			height: rem(50px);
			display: flex;
			align-items: center;

			&:before {
				position: absolute;
				content: "";
				height: 100%;
				width: 100%;
				background-color: rgba(248, 248, 248, 0.95);
				z-index: -1;
				top: 0;
				left: 0;
				right: 0;
			}
		}
	}

	.mean-nav {
		float: none !important;
		background: transparent !important;

		>ul {
			overflow-y: scroll;
			height: 100vh;

			&:after {
				content: '';
				display: block;
				clear: both;
			}

			li {
				a {
					font-size: rem(14px);
					color: $color_secondary;
					font-weight: 700;
					background-color: #ebebeb;
					transition: all 0.5s ease-out;

					&:hover {
						color: $color_primary;
					}
				}

				&:hover {
					>a {
						color: $color_primary;
						background-color: #ffffff;
					}
				}

				li {
					a {
						color: $color_secondary;
						opacity: 1;
						font-weight: 400;
						padding: 0.5em 10%;

						&:hover {
							background-color: #ffffff;
						}
					}
				}

				a.mean-expand {
					background-color: transparent;
					margin-top: 0;
					height: rem(20px);
					line-height: rem(27px);
					width: 100%;
					text-align: right;
					font-size: rem(14px);
					padding: rem(12px 20px 12px 12px) !important;
					top: rem(3px);

					&:hover {
						background-color: transparent;
					}
				}
			}
		}
	}

	a.meanmenu-reveal {
		float: none !important;
		position: absolute;
		top: 0;
		transition: unset;
		padding: rem(16px 14px 12px);
	}
}

.mean-container #wrapper {
	padding-top: rem(51px);
}

.hide-on-mobile-menu {
	@media only screen and (max-width: 991px) {
		display: none;
	}
}

.hide-on-desktop-menu {
	@media only screen and (min-width: 992px) {
		display: none !important;
	}
}

@import "navbar/navbar-layout1";
@import "navbar/navbar-layout2";
@import "navbar/navbar-layout3";
@import "navbar/navbar-layout4";
@import "navbar/navbar-layout5";
@import "navbar/navbar-layout6";
@import "navbar/navbar-layout7";
@import "navbar/pagepiling";