@charset "UTF-8";
/*!
  Theme Name: Digeco – Startup Agency Html Template
  Theme URI: https://www.radiustheme.com/demo/html/digeco
  Author: RadiusTheme
  Author URI: https://www.radiustheme.com/
  Version: 1.0.0
  */
/*----------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  01. Typography
  02. Vendor Custom Style
    2.1 Bootstrap Overwrite
    2.2 Owl Carousel Nav Style
    2.3 Select2 Style
  03. Page Layout
    3.1 Footer
    3.2 Header
    3.3 Miscellaneous
    3.4 Widgets
  04. Template Style
    4.1 404
    4.2 About
    4.3 Banner
    4.4 Blog
    4.5 Brand
    4.6 Call To Action
    4.7 Coming Soon
    4.8 Contact
    4.9 Faq
    4.10 Feature
    4.11 Gallery
    4.12 Map
    4.13 Newsletter
    4.14 Instagram
    4.15 Progress
    4.16 Process
    4.17 Pricing
    4.18 Service
    4.19 Screen Shot
    4.20 Team
    4.21 Testimonial
  05. Helper Classes
    5.1 Background Color
    5.2 Background Style
    5.3 Possition
    5.4 Section Spacing
    5.5 Text Color
    5.6 Text Row
  --------------------------------------------------------------*/
/*========================================================================
01. Typography
=========================================================================*/
html {
  overflow: inherit;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 30px;
  color: #646464;
  font-weight: 400;
  height: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: #ffffff;
  overflow: inherit;
  overflow-x: hidden;
}

p {
  margin: 0 0 20px 0;
  color: #646464;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  margin: 0 0 20px 0;
  font-weight: 500;
  color: #111111;
}

h1 {
  font-size: 46px;
  line-height: 56px;
}

@media only screen and (max-width: 991px) {
  h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 575px) {
  h1 {
    font-size: 32px;
  }
}

h2 {
  font-size: 36px;
  line-height: 50px;
}

@media only screen and (max-width: 1199px) {
  h2 {
    font-size: 32px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 991px) {
  h2 {
    font-size: 30px;
    line-height: 42px;
  }
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 26px;
    line-height: 38px;
  }
}

h3 {
  font-size: 22px;
  line-height: 36px;
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 20px;
  line-height: 30px;
}

@media only screen and (max-width: 767px) {
  h4 {
    font-size: 18px;
  }
}

h5 {
  font-size: 18px;
  line-height: 30px;
}

@media only screen and (max-width: 767px) {
  h5 {
    font-size: 17px;
  }
}

h6 {
  font-size: 16px;
  line-height: 30px;
}

/*========================================================================
02. Vendor Custom Style
=========================================================================*/
/*--------------------------------------
	2.1 Bootstrap Overwrite
---------------------------------------*/
/*--- Grid ---*/
@media only screen and (min-width: 1240px) {
  .container {
    max-width: 1200px;
  }
}

.container-fluid.full-width {
  padding-left: 0;
  padding-right: 0;
}

.container-fluid.full-width .row {
  margin-left: 0;
  margin-right: 0;
}

.container-fluid.full-width .row > [class^="col-"],
.container-fluid.full-width .row > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.row.gutters-2 {
  padding-left: 14px;
  padding-right: 14px;
}

.row.gutters-2 > [class^="col-"] {
  padding-left: 1px;
  padding-right: 1px;
}

.row.gutters-20 {
  padding-left: 5px;
  padding-right: 5px;
}

.row.gutters-20 > [class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (min-width: 992px) {
  .row.gutters-50 {
    margin-left: -25px;
    margin-right: -25px;
  }
  .row.gutters-50 > [class^="col-"] {
    padding-left: 25px;
    padding-right: 25px;
  }
  .row.gutters-80 {
    margin-left: -40px;
    margin-right: -40px;
  }
  .row.gutters-80 > [class^="col-"] {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (min-width: 1200px) {
  .col-xl-5th {
    flex: 0 0 20%;
    max-width: 20%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

/*--- Media Object ---*/
.media-body {
  margin-left: 30px;
}

.media-body.space-sm {
  margin-left: 15px;
}

.media-body.media-body-box {
  padding: 20px 20px 20px 30px;
  margin-left: 0;
}

.media-body.media-body-box p {
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .media-none--xs {
    display: block;
  }
  .media-none--xs > div {
    width: 100%;
  }
  .media-none--xs img {
    width: 100%;
  }
  .media-none--xs img.media-img-auto {
    width: auto;
  }
  .media-none--xs .media-body,
  .media-none--xs .media-body.space-xl,
  .media-none--xs .media-body.space-lg,
  .media-none--xs .media-body.space-md,
  .media-none--xs .media-body.space-sm,
  .media-none--xs .media-body.space-xs {
    margin-top: 20px;
    margin-left: 0;
  }
  .media-none--xs .media-body.media-body-box {
    margin-top: 0;
    margin-left: 0;
    padding: 30px;
  }
}

/*--------------------------------------
	2.2 Owl Carousel Nav Style
---------------------------------------*/
.nav-control-layout1 .owl-nav {
  margin-top: 0;
}

.nav-control-layout1 .owl-nav button {
  position: absolute;
  top: calc(50% - 15px);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  height: 60px;
  width: 60px;
  border-radius: 45px;
  border: 1px solid #d9d9d9 !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out;
}

.nav-control-layout1 .owl-nav button i {
  margin-top: 3px;
  color: #999797;
  transition: all 0.3s ease-out;
}

.nav-control-layout1 .owl-nav button i:before {
  font-size: 24px;
}

.nav-control-layout1 .owl-nav button:hover {
  background-color: #5a49f8 !important;
  border: 1px solid #5a49f8 !important;
}

.nav-control-layout1 .owl-nav button:hover i {
  color: #ffffff;
}

.nav-control-layout1 .owl-nav button:focus {
  outline: none;
}

@media only screen and (max-width: 991px) {
  .nav-control-layout1 .owl-nav button {
    height: 55px;
    width: 55px;
  }
  .nav-control-layout1 .owl-nav button i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-control-layout1 .owl-nav button {
    height: 50px;
    width: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .nav-control-layout1 .owl-nav button {
    height: 40px;
    width: 40px;
  }
  .nav-control-layout1 .owl-nav button i {
    font-size: 16px;
  }
}

.nav-control-layout1 .owl-nav .owl-prev {
  left: 15px;
}

.nav-control-layout1 .owl-nav .owl-next {
  right: 15px;
}

.nav-control-layout1:hover button {
  opacity: 1;
  visibility: visible;
}

.nav-control-layout1:hover .owl-prev {
  left: -15px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout1:hover .owl-prev {
    left: 0;
  }
}

.nav-control-layout1:hover .owl-next {
  right: -15px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout1:hover .owl-next {
    right: 0;
  }
}

.nav-control-layout2 .owl-nav {
  margin-top: 0;
  position: absolute;
  z-index: 1;
  bottom: -70px;
  left: 0;
  right: 0;
  text-align: center;
}

.nav-control-layout2 .owl-nav button {
  margin: 0 5px;
  padding: 0;
  height: 50px;
  width: 50px;
  border-radius: 45px;
  border: 1px solid #ffffff !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #eaeef2 !important;
  transition: all 0.3s ease-out;
}

.nav-control-layout2 .owl-nav button i {
  line-height: 1;
  color: #959595;
  transition: all 0.3s ease-out;
}

.nav-control-layout2 .owl-nav button i:before {
  font-size: 24px;
}

.nav-control-layout2 .owl-nav button:hover {
  background-color: #5a49f8 !important;
  border: 1px solid #5a49f8 !important;
}

.nav-control-layout2 .owl-nav button:hover i {
  color: #ffffff;
}

.nav-control-layout2 .owl-nav button:focus {
  outline: none;
}

@media only screen and (max-width: 991px) {
  .nav-control-layout2 .owl-nav button {
    height: 55px;
    width: 55px;
  }
  .nav-control-layout2 .owl-nav button i:before {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-control-layout2 .owl-nav button {
    height: 50px;
    width: 50px;
  }
  .nav-control-layout2 .owl-nav button i:before {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .nav-control-layout2 .owl-nav button {
    height: 40px;
    width: 40px;
  }
}

.nav-control-layout2 .owl-nav .owl-prev {
  left: 15px;
}

.nav-control-layout2 .owl-nav .owl-next {
  right: 15px;
}

.nav-control-layout2:hover .owl-prev {
  left: 50px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout2:hover .owl-prev {
    left: 0;
  }
}

.nav-control-layout2:hover .owl-next {
  right: 50px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout2:hover .owl-next {
    right: 0;
  }
}

.nav-control-layout3 .owl-nav {
  margin-top: 0;
}

.nav-control-layout3 .owl-nav button {
  position: absolute;
  top: calc(50% - 15px);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  height: 60px;
  width: 60px;
  border-radius: 45px;
  border: 1px solid #750ed5 !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #750ed5 !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out;
}

.nav-control-layout3 .owl-nav button i {
  font-size: 20px;
  color: #ffffff;
  transition: all 0.3s ease-out;
}

.nav-control-layout3 .owl-nav button:hover {
  background-color: transparent !important;
}

.nav-control-layout3 .owl-nav button:hover i {
  color: #750ed5;
}

.nav-control-layout3 .owl-nav button:focus {
  outline: none;
}

@media only screen and (max-width: 991px) {
  .nav-control-layout3 .owl-nav button {
    height: 55px;
    width: 55px;
  }
  .nav-control-layout3 .owl-nav button i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-control-layout3 .owl-nav button {
    height: 50px;
    width: 50px;
  }
  .nav-control-layout3 .owl-nav button i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .nav-control-layout3 .owl-nav button {
    height: 40px;
    width: 40px;
  }
  .nav-control-layout3 .owl-nav button i {
    font-size: 16px;
  }
}

.nav-control-layout3 .owl-nav .owl-prev {
  left: 15px;
}

.nav-control-layout3 .owl-nav .owl-next {
  right: 15px;
}

.nav-control-layout3:hover button {
  opacity: 1;
  visibility: visible;
}

.nav-control-layout3:hover .owl-prev {
  left: -50px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout3:hover .owl-prev {
    left: 0;
  }
}

.nav-control-layout3:hover .owl-next {
  right: -50px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout3:hover .owl-next {
    right: 0;
  }
}

.nav-control-layout4 .owl-nav {
  margin-top: 0;
}

.nav-control-layout4 .owl-nav button {
  position: absolute;
  top: calc(50% - 15px);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  height: 60px;
  width: 60px;
  border-radius: 45px;
  border: 1px solid #ffffff !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out;
}

.nav-control-layout4 .owl-nav button i {
  font-size: 20px;
  color: #5a49f8;
  transition: all 0.3s ease-out;
}

.nav-control-layout4 .owl-nav button:hover {
  background-color: transparent !important;
}

.nav-control-layout4 .owl-nav button:hover i {
  color: #ffffff;
}

.nav-control-layout4 .owl-nav button:focus {
  outline: none;
}

@media only screen and (max-width: 991px) {
  .nav-control-layout4 .owl-nav button {
    height: 55px;
    width: 55px;
  }
  .nav-control-layout4 .owl-nav button i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-control-layout4 .owl-nav button {
    height: 50px;
    width: 50px;
  }
  .nav-control-layout4 .owl-nav button i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .nav-control-layout4 .owl-nav button {
    height: 40px;
    width: 40px;
  }
  .nav-control-layout4 .owl-nav button i {
    font-size: 16px;
  }
}

.nav-control-layout4 .owl-nav .owl-prev {
  left: 15px;
}

.nav-control-layout4 .owl-nav .owl-next {
  right: 15px;
}

.nav-control-layout4:hover button {
  opacity: 1;
  visibility: visible;
}

.nav-control-layout4:hover .owl-prev {
  left: -50px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout4:hover .owl-prev {
    left: 0;
  }
}

.nav-control-layout4:hover .owl-next {
  right: -50px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout4:hover .owl-next {
    right: 0;
  }
}

.nav-control-layout5 .owl-nav {
  margin-top: 0;
}

.nav-control-layout5 .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  height: 60px;
  width: 60px;
  border-radius: 45px;
  border: 1px solid #750ed5 !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #750ed5 !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out;
}

.nav-control-layout5 .owl-nav button i {
  font-size: 20px;
  color: #ffffff;
  transition: all 0.3s ease-out;
}

.nav-control-layout5 .owl-nav button:hover {
  background-color: #5a49f8 !important;
  border: 1px solid #5a49f8 !important;
}

.nav-control-layout5 .owl-nav button:focus {
  outline: none;
}

@media only screen and (max-width: 991px) {
  .nav-control-layout5 .owl-nav button {
    height: 55px;
    width: 55px;
  }
  .nav-control-layout5 .owl-nav button i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-control-layout5 .owl-nav button {
    height: 50px;
    width: 50px;
  }
  .nav-control-layout5 .owl-nav button i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .nav-control-layout5 .owl-nav button {
    height: 40px;
    width: 40px;
  }
  .nav-control-layout5 .owl-nav button i {
    font-size: 16px;
  }
}

.nav-control-layout5 .owl-nav .owl-prev {
  left: 15px;
}

.nav-control-layout5 .owl-nav .owl-next {
  right: 15px;
}

.nav-control-layout5:hover button {
  opacity: 1;
  visibility: visible;
}

.nav-control-layout5:hover .owl-prev {
  left: 50px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout5:hover .owl-prev {
    left: 0;
  }
}

.nav-control-layout5:hover .owl-next {
  right: 50px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout5:hover .owl-next {
    right: 0;
  }
}

.nav-control-layout6 .owl-nav {
  margin-top: 0;
}

.nav-control-layout6 .owl-nav button {
  position: absolute;
  top: calc(50% - 15px);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  height: 60px;
  width: 60px;
  border-radius: 45px;
  border: 1px solid #d9d9d9 !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out;
}

.nav-control-layout6 .owl-nav button i {
  margin-top: 3px;
  color: #999797;
  transition: all 0.3s ease-out;
}

.nav-control-layout6 .owl-nav button i:before {
  font-size: 24px;
}

.nav-control-layout6 .owl-nav button:hover {
  background-color: #301ee4 !important;
  border: 1px solid #301ee4 !important;
}

.nav-control-layout6 .owl-nav button:hover i {
  color: #ffffff;
}

.nav-control-layout6 .owl-nav button:focus {
  outline: none;
}

@media only screen and (max-width: 991px) {
  .nav-control-layout6 .owl-nav button {
    height: 55px;
    width: 55px;
  }
  .nav-control-layout6 .owl-nav button i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-control-layout6 .owl-nav button {
    height: 50px;
    width: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .nav-control-layout6 .owl-nav button {
    height: 40px;
    width: 40px;
  }
  .nav-control-layout6 .owl-nav button i {
    font-size: 16px;
  }
}

.nav-control-layout6 .owl-nav .owl-prev {
  left: 15px;
}

.nav-control-layout6 .owl-nav .owl-next {
  right: 15px;
}

.nav-control-layout6:hover button {
  opacity: 1;
  visibility: visible;
}

.nav-control-layout6:hover .owl-prev {
  left: -15px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout6:hover .owl-prev {
    left: 0;
  }
}

.nav-control-layout6:hover .owl-next {
  right: -15px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout6:hover .owl-next {
    right: 0;
  }
}

.nav-control-layout7 .owl-nav {
  margin-top: 0;
}

.nav-control-layout7 .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  height: 60px;
  width: 60px;
  border-radius: 45px;
  border: 1px solid #ffffff !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out;
}

.nav-control-layout7 .owl-nav button i {
  font-size: 20px;
  color: #000000;
  transition: all 0.3s ease-out;
}

.nav-control-layout7 .owl-nav button:hover {
  background-color: #5a49f8 !important;
  border: 1px solid #5a49f8 !important;
}

.nav-control-layout7 .owl-nav button:hover i {
  color: #ffffff;
}

.nav-control-layout7 .owl-nav button:focus {
  outline: none;
}

@media only screen and (max-width: 991px) {
  .nav-control-layout7 .owl-nav button {
    height: 55px;
    width: 55px;
  }
  .nav-control-layout7 .owl-nav button i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-control-layout7 .owl-nav button {
    height: 50px;
    width: 50px;
  }
  .nav-control-layout7 .owl-nav button i {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .nav-control-layout7 .owl-nav button {
    height: 40px;
    width: 40px;
  }
  .nav-control-layout7 .owl-nav button i {
    font-size: 16px;
  }
}

.nav-control-layout7 .owl-nav .owl-prev {
  left: 15px;
}

.nav-control-layout7 .owl-nav .owl-next {
  right: 15px;
}

.nav-control-layout7:hover button {
  opacity: 1;
  visibility: visible;
}

.nav-control-layout7:hover .owl-prev {
  left: 50px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout7:hover .owl-prev {
    left: 0;
  }
}

.nav-control-layout7:hover .owl-next {
  right: 50px;
}

@media only screen and (max-width: 1199px) {
  .nav-control-layout7:hover .owl-next {
    right: 0;
  }
}

.owl-custom-nav-layout1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .owl-custom-nav-layout1 {
    display: none;
  }
}

.owl-custom-nav-layout1 span {
  padding: 5px 0;
  cursor: pointer;
  margin-left: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #646464;
  font-weight: 900;
  font-size: 22px;
  transition: all 0.3s ease-out;
}

.owl-custom-nav-layout1 span:hover {
  color: #5a49f8;
}

.owl-custom-nav-layout1 .custom-link a {
  color: #646464;
  transition: all 0.3s ease-out;
}

.owl-custom-nav-layout1 .custom-link a:hover {
  color: #5a49f8;
}

.dot-control-layout1 .owl-dots {
  margin-top: 15px;
  text-align: center;
  margin-bottom: 20px;
}

.dot-control-layout1 .owl-dots button {
  height: 5px;
  width: 10px;
  padding: 0;
  margin: 0 3px;
  background-color: #b7b7b7;
  border: none;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.dot-control-layout1 .owl-dots button:focus {
  outline: none;
}

.dot-control-layout1 .owl-dots button.active {
  height: 5px;
  width: 25px;
  background-color: #5a49f8;
}

.owl-nav-layout1 {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  bottom: -100px;
}

.owl-nav-layout1 span {
  cursor: pointer;
  margin-right: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #5a49f8;
  transition: all 0.3s ease-out;
}

.owl-nav-layout1 span i:before {
  margin-right: 0;
  font-weight: 900;
  font-size: 1.8rem;
  color: #adacac;
}

.owl-nav-layout1 span:hover i:before {
  color: #000000;
}

/*--------------------------------------
	2.3 Select2 Custom Style
---------------------------------------*/
.select2-container {
  box-shadow: none;
  border-radius: 0px;
}

.select2-container ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  border: 0;
  background-color: rgba(90, 73, 248, 0.3);
}

.select2-container ::-webkit-scrollbar {
  border-radius: 4px;
  width: 14px;
  height: 10px;
  background-color: rgba(90, 73, 248, 0.1);
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #d9d9d9;
  border-radius: 0px;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #d9d9d9;
  border-radius: 0px;
}

.select2-search--dropdown {
  padding: 5px;
}

.select2-results {
  background-color: transparent;
  padding: 5px 0 0;
  box-shadow: none;
}

.select2-results__option {
  font-size: 16px;
  color: #000000;
  padding: 10px;
  margin-right: 1px;
}

.select2-container--classic .select2-selection--single {
  border: 1px solid #d9d9d9;
  background-image: none;
  height: 50px;
  background-color: transparent;
  box-shadow: none;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  font-size: 16px;
  color: #c7c7c7;
  font-weight: 300;
  background-color: transparent;
  border-radius: 0px;
  line-height: 1.4;
  padding: 15px 10px 13px 20px;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  background-image: none;
  border-radius: 0px;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: transparent;
  background-image: none;
  border-left: 0;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
  background-color: transparent;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #d9d9d9 transparent transparent transparent;
  top: 50%;
  right: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  border-width: 7px 6px 0 6px;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #d9d9d9 transparent;
  border-width: 0 6px 7px 6px;
  transition: all 0.3s ease-in-out;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  background-image: none;
  border-top: 1px solid #d9d9d9;
}

.select2-container--classic .select2-dropdown--below {
  border-top: 1px solid #d9d9d9;
}

.select2-container--classic .select2-dropdown {
  background-color: #f5f5f5;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #f5f5f5;
  color: #5a49f8;
  border-radius: 0px;
  transition: all 0.3s ease-in-out;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #d9d9d9;
  padding: 5px 10px;
  border-radius: 0px;
  color: #000000;
  background-color: #f5f5f5;
  transition: all 0.3s ease-in-out;
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #d9d9d9;
  border-radius: 0px;
}

/*========================================================================
03. Page Layout
=========================================================================*/
/*--------------------------------------
	3.1 Footers
---------------------------------------*/
footer .footer-widgets {
  margin-bottom: 15px;
}

footer .footer-widget-heading {
  font-weight: 600;
  margin-bottom: 15px;
}

footer .footer-widget-heading.heading-light {
  color: #ffffff;
}

.footer-top-layout1 {
  padding: 120px 0 90px;
}

@media only screen and (max-width: 991px) {
  .footer-top-layout1 {
    padding: 105px 0 75px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-top-layout1 {
    padding: 90px 0 60px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-top-layout1 {
    padding: 75px 0 45px;
  }
}

.footer-top-layout1 .footer-logo {
  margin-bottom: 15px;
}

.footer-top-layout1 .footer-logo a {
  display: inline-block;
}

.footer-top-layout1 ul.footer-social {
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.footer-top-layout1 ul.footer-social li {
  margin-right: 10px;
  margin-bottom: 5px;
}

.footer-top-layout1 ul.footer-social li a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b0b0b0;
  padding: 5px 10px;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout1 ul.footer-social li a:hover {
  color: #5a49f8;
}

.footer-top-layout1 ul.footer-social li:first-child a {
  padding: 5px 10px 5px 0;
}

.footer-top-layout1 ul.footer-social li:last-child {
  margin-right: 0;
}

.footer-top-layout1 .footer-menu li {
  margin-bottom: 10px;
}

.footer-top-layout1 .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-top-layout1 .footer-menu li a {
  color: #646464;
  display: block;
  padding: 5px 0;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout1 .footer-menu li a:hover {
  color: #5a49f8;
}

.footer-top-layout1 .footer-contact li {
  position: relative;
  z-index: 1;
  padding-left: 40px;
  margin-bottom: 15px;
}

.footer-top-layout1 .footer-contact li:last-child {
  margin-bottom: 0;
}

.footer-top-layout1 .footer-contact li i {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}

.footer-top-layout1 .footer-contact li i:before {
  font-size: 24px;
  color: #5a49f8;
}

.footer-bottom-layout1 {
  padding: 30px 0 22px;
}

.footer-bottom-layout1 .copy-right-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout1 .copy-right-wrap {
    justify-content: center;
  }
}

.footer-bottom-layout1 .copy-right-wrap .copy-right-text {
  margin-bottom: 0;
}

.footer-bottom-layout1 .copy-right-wrap .copy-right-text a {
  color: #646464;
  transition: all 0.3s ease-in-out;
}

.footer-bottom-layout1 .copy-right-wrap .copy-right-text a:hover {
  color: #5a49f8;
}

.footer-wrap-layout2 {
  background-position: center bottom;
}

.footer-top-layout2 {
  padding: 120px 0 75px;
}

.footer-top-layout2 .footer-logo {
  margin-bottom: 15px;
}

.footer-top-layout2 .footer-logo a {
  display: inline-block;
}

.footer-top-layout2 ul.footer-social {
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.footer-top-layout2 ul.footer-social li {
  margin-right: 10px;
  margin-bottom: 5px;
}

.footer-top-layout2 ul.footer-social li a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b0b0b0;
  padding: 5px 10px;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout2 ul.footer-social li a:hover {
  color: #5a49f8;
}

.footer-top-layout2 ul.footer-social li:first-child a {
  padding: 5px 10px 5px 0;
}

.footer-top-layout2 ul.footer-social li:last-child {
  margin-right: 0;
}

.footer-top-layout2 .footer-menu li {
  margin-bottom: 10px;
}

.footer-top-layout2 .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-top-layout2 .footer-menu li a {
  color: #646464;
  display: block;
  padding: 5px 0;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout2 .footer-menu li a:hover {
  color: #5a49f8;
}

.footer-top-layout2 .footer-contact li {
  position: relative;
  z-index: 1;
  padding-left: 40px;
  margin-bottom: 15px;
}

.footer-top-layout2 .footer-contact li:last-child {
  margin-bottom: 0;
}

.footer-top-layout2 .footer-contact li i {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}

.footer-top-layout2 .footer-contact li i:before {
  font-size: 24px;
  color: #5a49f8;
}

.footer-bottom-layout2 {
  padding: 30px 0 22px;
}

.footer-bottom-layout2 .copy-right-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout2 .copy-right-wrap {
    justify-content: center;
  }
}

.footer-bottom-layout2 .copy-right-wrap .copy-right-text {
  margin-bottom: 0;
}

.footer-bottom-layout2 .copy-right-wrap .copy-right-text a {
  color: #646464;
  transition: all 0.3s ease-in-out;
}

.footer-bottom-layout2 .copy-right-wrap .copy-right-text a:hover {
  color: #5a49f8;
}

.footer-top-layout3 {
  padding: 120px 0 91px;
}

@media only screen and (max-width: 991px) {
  .footer-top-layout3 {
    padding: 105px 0 76px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-top-layout3 {
    padding: 90px 0 61px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-top-layout3 {
    padding: 75px 0 46px;
  }
}

.footer-top-layout3 .footer-logo {
  margin-bottom: 20px;
}

.footer-top-layout3 .footer-logo a {
  display: inline-block;
}

.footer-top-layout3 ul.footer-social {
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.footer-top-layout3 ul.footer-social li {
  margin-right: 10px;
  margin-bottom: 5px;
}

.footer-top-layout3 ul.footer-social li a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b0b0b0;
  padding: 5px 10px;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout3 ul.footer-social li a:hover {
  color: #5a49f8;
}

.footer-top-layout3 ul.footer-social li:first-child a {
  padding: 5px 10px 5px 0;
}

.footer-top-layout3 ul.footer-social li:last-child {
  margin-right: 0;
}

.footer-top-layout3 .footer-menu li {
  margin-bottom: 10px;
}

.footer-top-layout3 .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-top-layout3 .footer-menu li a {
  color: #646464;
  display: block;
  padding: 5px 0 5px 20px;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout3 .footer-menu li a:before {
  position: absolute;
  z-index: 1;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 18px;
  left: 0;
  top: 12px;
  line-height: 1;
}

.footer-top-layout3 .footer-menu li a:hover {
  color: #5a49f8;
}

.footer-top-layout3 .footer-post li {
  margin-bottom: 15px;
}

.footer-top-layout3 .footer-post li:last-child {
  margin-bottom: 0;
}

.footer-top-layout3 .footer-post li .item-date {
  color: #5a49f8;
  margin-bottom: 5px;
}

.footer-top-layout3 .footer-post li .item-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}

.footer-top-layout3 .footer-post li .item-title a {
  color: #111111;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout3 .footer-post li .item-title a:hover {
  color: #5a49f8;
}

.footer-top-layout3 .footer-contact {
  margin-bottom: 20px;
}

.footer-top-layout3 .footer-contact li {
  position: relative;
  z-index: 1;
  margin-bottom: 5px;
}

.footer-top-layout3 .footer-contact li:last-child {
  margin-bottom: 0;
}

.footer-top-layout3 .footer-contact .mail-address {
  font-weight: 300;
  color: #111111;
}

.footer-top-layout3 .footer-contact .phn-number {
  font-weight: 500;
  font-size: 18px;
  color: #111111;
}

.footer-bottom-layout3 {
  padding: 20px 0;
}

.footer-bottom-layout3 .copy-right-left-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout3 .copy-right-left-box {
    justify-content: center;
  }
}

.footer-bottom-layout3 .copy-right-left-box .copy-right-text {
  margin-bottom: 0;
  color: #646464;
}

.footer-bottom-layout3 .copy-right-left-box .copy-right-text a {
  color: #646464;
  transition: all 0.3s ease-in-out;
}

.footer-bottom-layout3 .copy-right-left-box .copy-right-text a:hover {
  color: #5a49f8;
}

.footer-bottom-layout3 .copy-right-right-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout3 .copy-right-right-box {
    justify-content: center;
  }
}

.footer-bottom-layout3 .copy-right-right-box p {
  margin-bottom: 0;
  color: #646464;
}

.footer-wrap-layout4 {
  padding: 110px 0 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .footer-wrap-layout4 {
    padding: 95px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer-wrap-layout4 {
    padding: 80px 0 0;
  }
}

@media only screen and (max-width: 575px) {
  .footer-wrap-layout4 {
    padding: 65px 0 0;
  }
}

.footer-wrap-layout4:after {
  content: "";
  background-image: url(../../media/element/element55.png);
  height: 139px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
}

.footer-wrap-layout4 .shape-holder {
  position: relative;
  z-index: 0;
  display: table-row;
}

@media only screen and (max-width: 767px) {
  .footer-wrap-layout4 .shape-holder {
    display: none;
  }
}

.footer-wrap-layout4 .shape-holder .single-shape {
  position: absolute;
  z-index: -1;
}

.footer-wrap-layout4 .shape-holder .shape1 {
  top: -200px;
  left: -300px;
}

.footer-wrap-layout4 .shape-holder .shape2 {
  bottom: -130px;
  left: -70px;
}

.footer-wrap-layout4 .shape-holder .shape3 {
  top: -650px;
  right: -300px;
}

.footer-top-layout4 {
  padding: 90px 0 90px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 991px) {
  .footer-top-layout4 {
    padding: 72px 0 75px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-top-layout4 {
    padding: 57px 0 60px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-top-layout4 {
    padding: 42px 0 45px;
  }
}

.footer-top-layout4 .footer-logo {
  margin-bottom: 20px;
}

.footer-top-layout4 .footer-logo a {
  display: inline-block;
}

.footer-top-layout4 ul.footer-social {
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.footer-top-layout4 ul.footer-social li {
  margin-right: 10px;
  margin-bottom: 5px;
}

.footer-top-layout4 ul.footer-social li a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 5px 10px;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout4 ul.footer-social li a:hover {
  color: #d9d9d9;
}

.footer-top-layout4 ul.footer-social li:first-child a {
  padding: 5px 10px 5px 0;
}

.footer-top-layout4 ul.footer-social li:last-child {
  margin-right: 0;
}

.footer-top-layout4 .footer-menu li {
  margin-bottom: 10px;
}

.footer-top-layout4 .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-top-layout4 .footer-menu li a {
  color: #ffffff;
  display: block;
  padding: 5px 0 5px 20px;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout4 .footer-menu li a:before {
  position: absolute;
  z-index: 1;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 18px;
  left: 0;
  top: 12px;
  line-height: 1;
}

.footer-top-layout4 .footer-menu li a:hover {
  color: #d9d9d9;
}

.footer-top-layout4 .footer-post li {
  margin-bottom: 15px;
}

.footer-top-layout4 .footer-post li:last-child {
  margin-bottom: 0;
}

.footer-top-layout4 .footer-post li .item-date {
  color: #ffffff;
  margin-bottom: 5px;
}

.footer-top-layout4 .footer-post li .item-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}

.footer-top-layout4 .footer-post li .item-title a {
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout4 .footer-post li .item-title a:hover {
  color: #d9d9d9;
}

.footer-top-layout4 .footer-contact {
  margin-bottom: 20px;
}

.footer-top-layout4 .footer-contact li {
  position: relative;
  z-index: 1;
  margin-bottom: 5px;
}

.footer-top-layout4 .footer-contact li:last-child {
  margin-bottom: 0;
}

.footer-top-layout4 .footer-contact .mail-address {
  font-weight: 300;
  color: #ffffff;
}

.footer-top-layout4 .footer-contact .phn-number {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.footer-bottom-layout4 {
  padding: 20px 0;
}

.footer-bottom-layout4 .copy-right-left-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout4 .copy-right-left-box {
    justify-content: center;
  }
}

.footer-bottom-layout4 .copy-right-left-box .copy-right-text {
  margin-bottom: 0;
  color: #ffffff;
}

.footer-bottom-layout4 .copy-right-left-box .copy-right-text a {
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.footer-bottom-layout4 .copy-right-left-box .copy-right-text a:hover {
  color: #750ed5;
}

.footer-bottom-layout4 .copy-right-right-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout4 .copy-right-right-box {
    justify-content: center;
  }
}

.footer-bottom-layout4 .copy-right-right-box p {
  margin-bottom: 0;
  color: #ffffff;
}

.footer-wrap-layout5 {
  position: relative;
  z-index: 1;
  margin-top: 235px;
}

@media only screen and (max-width: 1199px) {
  .footer-wrap-layout5 {
    margin-top: 115px;
  }
}

@media only screen and (max-width: 991px) {
  .footer-wrap-layout5 {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-wrap-layout5 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-wrap-layout5 {
    margin-top: 30px;
  }
}

.footer-wrap-layout5:before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0;
  right: 0;
  background-image: url(../../media/element/element57.png);
  height: 235px;
  width: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1199px) {
  .footer-wrap-layout5:before {
    background-size: contain;
    height: 115px;
  }
}

@media only screen and (max-width: 991px) {
  .footer-wrap-layout5:before {
    height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-wrap-layout5:before {
    height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-wrap-layout5:before {
    height: 30px;
  }
}

.footer-top-layout5 {
  padding: 50px 0 0;
}

.footer-top-layout5 .footer-logo {
  margin-bottom: 15px;
}

.footer-top-layout5 .footer-logo a {
  display: inline-block;
}

.footer-top-layout5 ul.footer-social {
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.footer-top-layout5 ul.footer-social li {
  margin-right: 10px;
  margin-bottom: 5px;
}

.footer-top-layout5 ul.footer-social li a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b0b0b0;
  padding: 5px 10px;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout5 ul.footer-social li a:hover {
  color: #5a49f8;
}

.footer-top-layout5 ul.footer-social li:first-child a {
  padding: 5px 10px 5px 0;
}

.footer-top-layout5 ul.footer-social li:last-child {
  margin-right: 0;
}

.footer-top-layout5 .footer-menu li {
  margin-bottom: 10px;
}

.footer-top-layout5 .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-top-layout5 .footer-menu li a {
  color: #646464;
  display: block;
  padding: 5px 0;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout5 .footer-menu li a:hover {
  color: #5a49f8;
}

.footer-top-layout5 .footer-contact li {
  position: relative;
  z-index: 1;
  padding-left: 40px;
  margin-bottom: 15px;
}

.footer-top-layout5 .footer-contact li:last-child {
  margin-bottom: 0;
}

.footer-top-layout5 .footer-contact li i {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}

.footer-top-layout5 .footer-contact li i:before {
  font-size: 24px;
  color: #5a49f8;
}

.footer-bottom-layout5 {
  padding: 30px 0 22px;
}

.footer-bottom-layout5 .copy-right-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout5 .copy-right-wrap {
    justify-content: center;
  }
}

.footer-bottom-layout5 .copy-right-wrap .copy-right-text {
  margin-bottom: 0;
}

.footer-bottom-layout5 .copy-right-wrap .copy-right-text a {
  color: #646464;
  transition: all 0.3s ease-in-out;
}

.footer-bottom-layout5 .copy-right-wrap .copy-right-text a:hover {
  color: #5a49f8;
}

.footer-wrap-layout6 {
  background-color: #17161c;
  position: relative;
  z-index: 1;
}

.footer-wrap-layout6 .shape-holder .single-shape {
  position: absolute;
  z-index: 1;
}

.footer-wrap-layout6 .shape-holder .shape1 {
  top: 0;
  left: 10%;
}

.footer-wrap-layout6 .shape-holder .shape2 {
  top: 2%;
  right: 5%;
}

.footer-wrap-layout6 .shape-holder .shape3 {
  bottom: 20%;
  left: 3%;
}

.footer-wrap-layout6 .shape-holder .shape4 {
  bottom: 30%;
  right: 10%;
}

.footer-wrap-layout6 .footer-widget-heading {
  color: #ffffff;
}

.footer-top-layout6 {
  padding: 120px 0 91px;
}

@media only screen and (max-width: 991px) {
  .footer-top-layout6 {
    padding: 105px 0 76px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-top-layout6 {
    padding: 90px 0 61px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-top-layout6 {
    padding: 75px 0 46px;
  }
}

.footer-top-layout6 .footer-logo {
  margin-bottom: 20px;
}

.footer-top-layout6 .footer-logo a {
  display: inline-block;
}

.footer-top-layout6 ul.footer-social {
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.footer-top-layout6 ul.footer-social li {
  margin-right: 10px;
  margin-bottom: 5px;
}

.footer-top-layout6 ul.footer-social li a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #727272;
  padding: 5px 10px;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout6 ul.footer-social li a:hover {
  color: #ffffff;
}

.footer-top-layout6 ul.footer-social li:first-child a {
  padding: 5px 10px 5px 0;
}

.footer-top-layout6 ul.footer-social li:last-child {
  margin-right: 0;
}

.footer-top-layout6 .footer-menu li {
  margin-bottom: 10px;
}

.footer-top-layout6 .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-top-layout6 .footer-menu li a {
  color: #a7a7a7;
  display: block;
  padding: 5px 0 5px 20px;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout6 .footer-menu li a:before {
  position: absolute;
  z-index: 1;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 18px;
  left: 0;
  top: 12px;
  line-height: 1;
}

.footer-top-layout6 .footer-menu li a:hover {
  color: #ffffff;
}

.footer-top-layout6 .footer-post li {
  margin-bottom: 15px;
}

.footer-top-layout6 .footer-post li:last-child {
  margin-bottom: 0;
}

.footer-top-layout6 .footer-post li .item-date {
  color: #ffffff;
  margin-bottom: 5px;
}

.footer-top-layout6 .footer-post li .item-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
}

.footer-top-layout6 .footer-post li .item-title a {
  color: #a7a7a7;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout6 .footer-post li .item-title a:hover {
  color: #ffffff;
}

.footer-top-layout6 .footer-contact {
  margin-bottom: 20px;
}

.footer-top-layout6 .footer-contact li {
  position: relative;
  z-index: 1;
  margin-bottom: 5px;
}

.footer-top-layout6 .footer-contact li:last-child {
  margin-bottom: 0;
}

.footer-top-layout6 .footer-contact .mail-address {
  font-weight: 300;
  color: #a7a7a7;
}

.footer-top-layout6 .footer-contact .phn-number {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.footer-bottom-layout6 {
  padding: 20px 0;
  background-color: #1b1a20;
}

.footer-bottom-layout6 .copy-right-left-box {
  display: flex;
  justify-content: flex-start;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout6 .copy-right-left-box {
    justify-content: center;
  }
}

.footer-bottom-layout6 .copy-right-left-box .copy-right-text {
  margin-bottom: 0;
  color: #a7a7a7;
}

.footer-bottom-layout6 .copy-right-left-box .copy-right-text a {
  color: #646464;
  transition: all 0.3s ease-in-out;
}

.footer-bottom-layout6 .copy-right-left-box .copy-right-text a:hover {
  color: #5a49f8;
}

.footer-bottom-layout6 .copy-right-right-box {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout6 .copy-right-right-box {
    justify-content: center;
  }
}

.footer-bottom-layout6 .copy-right-right-box p {
  margin-bottom: 0;
  color: #a7a7a7;
}

.footer-wrap-layout7 {
  background-color: #201e30;
  position: relative;
  z-index: 1;
}

.footer-wrap-layout7 .shape-holder .single-shape {
  position: absolute;
  z-index: 1;
}

.footer-wrap-layout7 .shape-holder .shape1 {
  top: 0;
  left: 10%;
}

.footer-wrap-layout7 .shape-holder .shape2 {
  top: 2%;
  right: 5%;
}

.footer-wrap-layout7 .shape-holder .shape3 {
  bottom: 20%;
  left: 3%;
}

.footer-wrap-layout7 .shape-holder .shape4 {
  bottom: 30%;
  right: 10%;
}

.footer-wrap-layout7 .footer-widget-heading {
  color: #ffffff;
}

.footer-top-layout7 {
  padding: 120px 0 91px;
}

@media only screen and (max-width: 991px) {
  .footer-top-layout7 {
    padding: 105px 0 76px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-top-layout7 {
    padding: 90px 0 61px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-top-layout7 {
    padding: 75px 0 46px;
  }
}

.footer-top-layout7 .footer-logo {
  margin-bottom: 20px;
}

.footer-top-layout7 .footer-logo a {
  display: inline-block;
}

.footer-top-layout7 .about-paragraph {
  color: #d0d0d0;
}

.footer-top-layout7 ul.footer-social {
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.footer-top-layout7 ul.footer-social li {
  margin-right: 10px;
  margin-bottom: 5px;
}

.footer-top-layout7 ul.footer-social li a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  height: 35px;
  width: 35px;
  border-radius: 45px;
  font-size: 14px;
  background-color: #31303f;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout7 ul.footer-social li a:hover {
  color: #5a49f8;
  background-color: #ffffff;
}

.footer-top-layout7 .footer-address li {
  color: #d1d1d1;
  margin-bottom: 20px;
}

.footer-top-layout7 .footer-address li:last-child {
  margin-bottom: 0;
}

.footer-top-layout7 .footer-address li span {
  display: block;
  margin-bottom: 2px;
}

.footer-top-layout7 .footer-menu li {
  margin-bottom: 5px;
  font-size: 18px;
}

.footer-top-layout7 .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-top-layout7 .footer-menu li a {
  color: #d1d1d1;
  display: block;
  padding: 5px 0;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout7 .footer-menu li a:hover {
  color: #ffffff;
}

.footer-top-layout7 .footer-post li {
  display: flex;
  margin-bottom: 20px;
}

.footer-top-layout7 .footer-post li:last-child {
  margin-bottom: 0;
}

.footer-top-layout7 .footer-post li .item-figure {
  margin-right: 10px;
  margin-top: 9px;
}

.footer-top-layout7 .footer-post li .item-figure img {
  border-radius: 5px;
}

.footer-top-layout7 .footer-post li .item-content {
  flex: 1;
}

.footer-top-layout7 .footer-post li .item-content .item-date {
  color: #ffffff;
  margin-bottom: 5px;
  font-size: 13px;
}

.footer-top-layout7 .footer-post li .item-content .item-title {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0;
}

.footer-top-layout7 .footer-post li .item-content .item-title a {
  color: #d1d1d1;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout7 .footer-post li .item-content .item-title a:hover {
  color: #ffffff;
}

.footer-bottom-layout7 {
  padding: 20px 0;
  background-color: #232034;
}

.footer-bottom-layout7 .copy-right-left-box {
  display: flex;
  justify-content: flex-start;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout7 .copy-right-left-box {
    justify-content: center;
  }
}

.footer-bottom-layout7 .copy-right-left-box .copy-right-text {
  margin-bottom: 0;
  color: #c3c3c3;
}

.footer-bottom-layout7 .copy-right-left-box .copy-right-text a {
  color: #c3c3c3;
  transition: all 0.3s ease-in-out;
}

.footer-bottom-layout7 .copy-right-left-box .copy-right-text a:hover {
  color: #5a49f8;
}

.footer-bottom-layout7 .copy-right-right-box {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout7 .copy-right-right-box {
    justify-content: center;
  }
}

.footer-bottom-layout7 .copy-right-right-box p {
  margin-bottom: 0;
  color: #c3c3c3;
}

.footer-wrap-layout8 {
  position: relative;
  z-index: 1;
  margin-top: 180px;
  background-color: #070d1e;
}

.footer-wrap-layout8:before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0;
  right: 0;
  background-image: url(../../media/element/element76.png);
  height: 180px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-wrap-layout8 .footer-widget-heading {
  color: #ffffff;
}

.footer-top-layout8 {
  padding: 50px 0 0;
}

.footer-top-layout8 .footer-widgets p {
  color: #c5c4c4;
}

.footer-top-layout8 .footer-logo {
  margin-bottom: 15px;
}

.footer-top-layout8 .footer-logo a {
  display: inline-block;
}

.footer-top-layout8 .about-paragraph {
  color: #c5c4c4;
}

.footer-top-layout8 ul.footer-social {
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.footer-top-layout8 ul.footer-social li {
  margin-right: 10px;
  margin-bottom: 5px;
}

.footer-top-layout8 ul.footer-social li a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b0b0b0;
  padding: 5px 10px;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout8 ul.footer-social li a:hover {
  color: #5a49f8;
}

.footer-top-layout8 ul.footer-social li:first-child a {
  padding: 5px 10px 5px 0;
}

.footer-top-layout8 ul.footer-social li:last-child {
  margin-right: 0;
}

.footer-top-layout8 .footer-menu li {
  margin-bottom: 10px;
}

.footer-top-layout8 .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-top-layout8 .footer-menu li a {
  color: #c5c4c4;
  display: block;
  padding: 5px 0;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout8 .footer-menu li a:hover {
  color: #5a49f8;
}

.footer-top-layout8 .footer-contact li {
  color: #c5c4c4;
  position: relative;
  z-index: 1;
  padding-left: 40px;
  margin-bottom: 15px;
}

.footer-top-layout8 .footer-contact li:last-child {
  margin-bottom: 0;
}

.footer-top-layout8 .footer-contact li i {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}

.footer-top-layout8 .footer-contact li i:before {
  font-size: 24px;
  color: #5a49f8;
}

.footer-bottom-layout8 {
  padding: 30px 0 22px;
}

.footer-bottom-layout8 .copy-right-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout8 .copy-right-wrap {
    justify-content: center;
  }
}

.footer-bottom-layout8 .copy-right-wrap .copy-right-text {
  margin-bottom: 0;
  color: #c5c4c4;
}

.footer-bottom-layout8 .copy-right-wrap .copy-right-text a {
  color: #c5c4c4;
  transition: all 0.3s ease-in-out;
}

.footer-bottom-layout8 .copy-right-wrap .copy-right-text a:hover {
  color: #5a49f8;
}

.footer-wrap-layout9 {
  background-color: #070d1e;
}

.footer-wrap-layout9 .footer-widget-heading {
  color: #ffffff;
}

.footer-top-layout9 {
  padding: 120px 0 91px;
}

@media only screen and (max-width: 991px) {
  .footer-top-layout9 {
    padding: 105px 0 76px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-top-layout9 {
    padding: 90px 0 61px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-top-layout9 {
    padding: 75px 0 46px;
  }
}

.footer-top-layout9 .footer-widgets p {
  color: #c5c4c4;
}

.footer-top-layout9 .footer-logo {
  margin-bottom: 15px;
}

.footer-top-layout9 .footer-logo a {
  display: inline-block;
}

.footer-top-layout9 .about-paragraph {
  color: #c5c4c4;
}

.footer-top-layout9 .footer-menu li {
  margin-bottom: 10px;
}

.footer-top-layout9 .footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-top-layout9 .footer-menu li a {
  color: #c5c4c4;
  display: block;
  padding: 5px 0 5px 25px;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.footer-top-layout9 .footer-menu li a:before {
  position: absolute;
  z-index: 1;
  content: "\f061";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 16px;
  top: 4px;
  left: 0;
  color: #5a49f8;
}

.footer-top-layout9 .footer-menu li a:hover {
  color: #5a49f8;
}

.footer-top-layout9 .footer-contact li {
  color: #c5c4c4;
  position: relative;
  z-index: 1;
  padding-left: 40px;
  margin-bottom: 15px;
}

.footer-top-layout9 .footer-contact li:last-child {
  margin-bottom: 0;
}

.footer-top-layout9 .footer-contact li i {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}

.footer-top-layout9 .footer-contact li i:before {
  font-size: 24px;
  color: #5a49f8;
}

.footer-bottom-layout9 {
  padding: 30px 0 22px;
}

.footer-bottom-layout9 .copy-right-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .footer-bottom-layout9 .copy-right-wrap {
    justify-content: center;
  }
}

.footer-bottom-layout9 .copy-right-wrap .copy-right-text {
  margin-bottom: 0;
  color: #c5c4c4;
}

.footer-bottom-layout9 .copy-right-wrap .copy-right-text a {
  color: #c5c4c4;
  transition: all 0.3s ease-in-out;
}

.footer-bottom-layout9 .copy-right-wrap .copy-right-text a:hover {
  color: #5a49f8;
}

/*--------------------------------------
	3.2 Headers
---------------------------------------*/
/*--- Header Common Style ---*/
.temp-logo {
  transition: all 1s ease-in-out;
}

.temp-logo a {
  display: block;
}

.header-action-items {
  display: flex;
  align-items: center;
}

.header-action-items .single-item {
  margin-right: 20px;
  position: relative;
  z-index: 1;
}

.header-action-items .single-item:last-child {
  margin-right: 0;
  padding-right: 0;
}

.header-action-items .single-item:last-child:before {
  display: none;
}

.header-action-items .item-btn {
  padding: 8px 15px;
}

button.offcanvas-menu-btn {
  margin-top: 8px;
  border: none;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0;
  border-radius: 4px;
}

button.offcanvas-menu-btn:focus {
  outline: none;
}

button.offcanvas-menu-btn .menu-btn-icon {
  padding: 10px;
  display: block;
  width: 50px;
  position: relative;
  height: 50px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
}

button.offcanvas-menu-btn .menu-btn-icon span {
  position: absolute;
  height: 2px;
  right: 13px;
  z-index: 2;
  background-color: #5a49f8;
  transition: 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}

button.offcanvas-menu-btn:hover .menu-btn-icon {
  background-color: #eeeeee;
}

button.offcanvas-menu-btn.menu-status-open .menu-btn-icon span:nth-child(1n) {
  width: 30%;
  animation: open_first_bar 0.8s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
}

button.offcanvas-menu-btn.menu-status-open .menu-btn-icon span:nth-child(2n) {
  width: 45%;
  animation: open_second_bar 0.8s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
}

button.offcanvas-menu-btn.menu-status-open .menu-btn-icon span:nth-child(3n) {
  width: 45%;
  animation: open_third_bar 0.8s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
}

button.offcanvas-menu-btn.menu-status-open .menu-btn-label .label-status-open {
  transform: translateY(-50%);
  opacity: 1;
}

button.offcanvas-menu-btn.menu-status-open .menu-btn-label .label-status-close {
  transform: translateY(50%);
  opacity: 0;
}

button.offcanvas-menu-btn.menu-status-close .menu-btn-icon span {
  width: 43%;
}

button.offcanvas-menu-btn.menu-status-close .menu-btn-icon span:nth-child(1n) {
  animation: close_first_bar 0.8s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
}

button.offcanvas-menu-btn.menu-status-close .menu-btn-icon span:nth-child(2n) {
  animation: close_second_bar 0.8s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
}

button.offcanvas-menu-btn.menu-status-close .menu-btn-icon span:nth-child(3n) {
  animation: close_third_bar 0.8s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
}

button.offcanvas-menu-btn.menu-status-close .menu-btn-label .label-status-open {
  transform: translateY(-150%);
  opacity: 0;
}

button.offcanvas-menu-btn.menu-status-close .menu-btn-label .label-status-close {
  transform: translateY(-50%);
  opacity: 1;
}

@keyframes open_first_bar {
  0% {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  to {
    top: 65%;
    transform: translateY(-50%);
  }
}

@keyframes close_first_bar {
  0% {
    top: 65%;
    transform: translateY(-50%);
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  to {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
}

@keyframes open_second_bar {
  0%,
  50% {
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
  }
  51%,
  to {
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }
}

@keyframes close_second_bar {
  0%,
  50% {
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }
  51%,
  to {
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
  }
}

@keyframes open_third_bar {
  0% {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  to {
    top: 35%;
    transform: translateY(-50%);
  }
}

@keyframes close_third_bar {
  0% {
    top: 35%;
    transform: translateY(-50%);
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  to {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
}

.offcanvas-menu-wrap {
  width: 300px;
  position: fixed;
  transform: translateX(-100%);
  top: 0;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999999;
  transition: all 0.3s ease-in-out;
}

.offcanvas-menu-wrap[data-position='right'] {
  left: auto;
  right: 0;
  transform: translateX(100%);
}

.offcanvas-menu-wrap .close-btn {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 25px;
  font-size: 24px;
  font-weight: 500;
  color: #292828;
  padding: 10px;
}

.offcanvas-menu-wrap .offcanvas-content {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 96px 0 88px;
  transition: all 0.3s ease-in-out;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-logo a {
  display: block;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-nav {
  text-align: center;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-nav .nav-item {
  margin-bottom: 20px;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-nav .nav-item a {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-nav .nav-item a:hover {
  color: #5a49f8;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer {
  text-align: center;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer .item-title {
  font-size: 15px;
  color: #acacac;
  margin-bottom: 14px;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer .offcanvas-social li {
  display: inline-block;
  margin-right: 6px;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer .offcanvas-social li:last-child {
  margin-right: 0;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer .offcanvas-social li a {
  color: #444444;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer .offcanvas-social li a:hover {
  color: #5a49f8;
}

.wrapper.open > .offcanvas-mask {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: transparent;
  z-index: 10;
}

.cart-wrapper {
  position: relative;
  margin-right: 25px;
  padding: 25px 0;
  transition: all 0.5s ease-in;
}

.cart-wrapper:after {
  height: 96px;
  width: 30px;
  background-color: transparent;
  z-index: 99;
  content: "";
  position: absolute;
  top: -23px;
  left: 0;
}

.cart-wrapper .cart-trigger-icon {
  position: relative;
  z-index: 2;
}

.cart-wrapper .cart-trigger-icon i:before {
  margin-left: 0;
  color: #969696;
  font-size: 24px;
}

.cart-wrapper .cart-trigger-icon span {
  position: absolute;
  top: -22px;
  right: -10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: #5a49f8;
  color: #ffffff;
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 14px;
  text-align: center;
  box-shadow: none;
}

.cart-wrapper:hover .cart-items {
  opacity: 1;
  visibility: visible;
  -webkit-transform: perspective(400) rotate3d(0, 0, 0, 0);
}

.cart-wrapper .cart-items {
  border-top: 3px solid #5a49f8;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  background-color: rgba(241, 247, 250, 0.98);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  padding: 30px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
  min-width: 450px;
  color: #111111;
  -webkit-transform: perspective(400) rotate3d(1, 0, 0, -15deg);
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  -o-transform-origin: 50% 0;
  transform-origin: 50% 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.cart-wrapper .cart-items .cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e6e6e6;
}

.cart-wrapper .cart-items .cart-item:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.cart-wrapper .cart-items .cart-item .cart-img {
  max-width: 80px;
  width: 100%;
}

.cart-wrapper .cart-items .cart-item > div {
  margin-right: 20px;
}

.cart-wrapper .cart-items .cart-item > div:last-child {
  margin-right: 0;
}

.cart-wrapper .cart-items .cart-item .cart-title {
  text-align: left;
}

.cart-wrapper .cart-items .cart-item .cart-title a {
  color: #111111;
  transition: all 0.3s ease-in-out;
}

.cart-wrapper .cart-items .cart-item .cart-title a:hover {
  color: #5a49f8;
}

.cart-wrapper .cart-items .cart-item .cart-title span {
  display: block;
  font-size: 14px;
}

.cart-wrapper .cart-items .cart-item .cart-trash a {
  color: #111111;
}

.cart-wrapper .cart-items .cart-item .cart-trash a:hover {
  color: #ff0600;
}

.cart-wrapper .cart-items .cart-item .total-amount {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cart-wrapper .cart-items .cart-item .total-amount span {
  font-weight: 700;
  font-size: 20px;
}

.cart-wrapper .cart-items .cart-item .cart-btn {
  width: 100%;
  padding: 10px 10px 0 10px;
  justify-content: center;
  display: flex;
}

.cart-wrapper .cart-items .cart-item .cart-btn a.item-btn {
  margin: 0 5px;
  display: inline-block;
  padding: 9px 30px;
  border: 1px solid;
  border-color: #5a49f8;
  background-color: #5a49f8;
  color: #ffffff;
  font-size: 15px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.cart-wrapper .cart-items .cart-item .cart-btn a.item-btn:hover {
  background-color: transparent;
  color: #5a49f8;
}

/*--- Navbar ---*/
nav.template-main-menu {
  font-family: "Poppins", sans-serif;
}

nav.template-main-menu > ul {
  display: flex;
  justify-content: center;
}

nav.template-main-menu > ul > li {
  position: relative;
}

nav.template-main-menu > ul > li > a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  display: block;
  position: relative;
  z-index: 1;
  transition: all 0.5s ease-out;
}

nav.template-main-menu > ul > li > a:before {
  position: absolute;
  z-index: 1;
  content: "";
  height: 2px;
  background-color: #ffffff;
  bottom: 26px;
  width: 0%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.5s ease-out;
}

nav.template-main-menu > ul > li > a:hover:before {
  width: 50%;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 {
  padding: 20px 0;
  border-top: 1px solid #5a49f8;
  background-color: #ffffff;
  width: 265px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 0;
  top: 100%;
  left: 0;
  border-radius: 4px;
  transform: translateY(-15px);
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1:before {
  position: absolute;
  z-index: 1;
  background-color: #5a49f8;
  height: 1px;
  border-radius: 4px;
  content: "";
  width: 98%;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li a {
  font-weight: 500;
  width: 100%;
  padding: 5px 30px;
  font-size: 14px;
  color: #4c4b4b;
  display: inline-block;
  transition: all 0.5s ease-in-out;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li a span {
  position: relative;
  z-index: 1;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li a span:before {
  position: absolute;
  z-index: 1;
  content: "";
  background-color: #5a49f8;
  height: 1px;
  width: 0;
  right: 0;
  left: inherit;
  bottom: 0;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li a:hover {
  color: #5a49f8;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li a:hover span:before {
  width: 100%;
  left: 0;
  right: inherit;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level {
  position: relative;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > a {
  color: #4c4b4b;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  float: right;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level {
  padding: 20px 0;
  border-bottom: 1px solid #5a49f8;
  background-color: #ffffff;
  width: 265px;
  position: absolute;
  border-radius: 4px;
  left: 100%;
  top: 0;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: 0 0 0;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level > li a {
  color: #4c4b4b;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level > li a span {
  position: relative;
  z-index: 1;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level > li a span:before {
  position: absolute;
  z-index: 1;
  content: "";
  background-color: #5a49f8;
  height: 1px;
  width: 0;
  right: 0;
  left: inherit;
  bottom: 0;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level > li a:hover {
  color: #5a49f8;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level > li a:hover span:before {
  width: 100%;
  left: 0;
  right: inherit;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level li.has-child-third-level {
  position: relative;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level li.has-child-third-level > a {
  color: #4c4b4b;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level li.has-child-third-level > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  float: right;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level li.has-child-third-level > ul.third-level {
  padding: 20px 0;
  border-radius: 4px;
  border-bottom: 1px solid #5a49f8;
  background-color: #ffffff;
  width: 265px;
  position: absolute;
  left: 100%;
  top: 0;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: 0 0 0;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level li.has-child-third-level > ul.third-level li a {
  color: #4c4b4b;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level li.has-child-third-level > ul.third-level li:hover a {
  color: #5a49f8;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level li.has-child-third-level:hover a {
  color: #5a49f8;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level > ul.second-level li.has-child-third-level:hover ul.third-level {
  opacity: 1;
  transform: scaleY(1);
  visibility: visible;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level:hover a {
  color: #5a49f8;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li.has-child-second-level:hover ul.second-level {
  opacity: 1;
  transform: scaleY(1);
  visibility: visible;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-2 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #5a49f8;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 0;
  top: 100%;
  left: 0;
  transform: translateY(-15px);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-2 li {
  min-width: 200px;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-2 li a {
  font-size: 14px;
  color: #111111;
  padding: 8px 15px;
  display: block;
  transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-2 li a:hover {
  color: #ffffff;
  background-color: #5a49f8;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-2 li a:last-child {
  padding-bottom: 15px;
}

nav.template-main-menu > ul > li .mega-menu-container {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-15px);
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: 100%;
  padding: 30px 30px 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li .mega-menu-container .mega-menu-box {
  margin-bottom: 30px;
}

nav.template-main-menu > ul > li .mega-menu-container .mega-menu-box .mega-menu-figure {
  overflow: hidden;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
}

nav.template-main-menu > ul > li .mega-menu-container .mega-menu-box .mega-menu-figure img {
  opacity: 1;
  transform: scale(1.05);
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
}

nav.template-main-menu > ul > li .mega-menu-container .mega-menu-box .mega-menu-title {
  text-align: center;
}

nav.template-main-menu > ul > li .mega-menu-container .mega-menu-box .mega-menu-title .item-title {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
  letter-spacing: 2px;
}

nav.template-main-menu > ul > li .mega-menu-container .mega-menu-box .mega-menu-title .item-title a {
  color: #111111;
  transition: all 0.5s ease-in-out;
}

nav.template-main-menu > ul > li .mega-menu-container .mega-menu-box .mega-menu-title .item-title a:hover {
  color: #5a49f8;
}

nav.template-main-menu > ul > li .mega-menu-container .mega-menu-box:hover .mega-menu-figure {
  background-color: #000000;
  border-radius: 4px;
}

nav.template-main-menu > ul > li .mega-menu-container .mega-menu-box:hover .mega-menu-figure img {
  opacity: 0.7;
  transform: scale(1.15);
}

nav.template-main-menu > ul > li:hover ul.dropdown-menu-col-1 {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li:hover ul.dropdown-menu-col-2 {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li:hover .mega-menu-container {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

nav.template-main-menu > ul > li > ul > li {
  position: relative;
}

nav.template-main-menu > ul > li > ul > li a.third-level-arrow:after {
  position: absolute;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  opacity: 0.8;
  font-size: 20px;
  z-index: 8;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  color: #111111;
  transition: all 1s cubic-bezier(0, 0, 0.15, 1.88);
}

nav.template-main-menu > ul > li > ul > li:hover .third-level-arrow:after {
  right: 15px;
}

nav.template-main-menu > ul > li > ul > li:hover ul.third-level {
  opacity: 1;
  visibility: visible;
  transform: translatey(0);
}

.sticky-on .navbar-wrap {
  position: relative;
  z-index: 2;
}

.sticky-on .navbar-wrap .navbar-layout1 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.sticky-on .navbar-wrap .navbar-layout2 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.sticky-on .navbar-wrap .navbar-layout3 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.sticky-on .navbar-wrap .navbar-layout4 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.sticky-on .navbar-wrap .navbar-layout5 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.sticky-on .navbar-wrap .navbar-layout6 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.sticky-on .navbar-wrap.no-transparent {
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.sticky-on.sticky .navbar-wrap .navbar-layout1,
.sticky-on.sticky .navbar-wrap .navbar-layout2,
.sticky-on.sticky .navbar-wrap .navbar-layout3,
.sticky-on.sticky .navbar-wrap .navbar-layout4,
.sticky-on.sticky .navbar-wrap .navbar-layout5,
.sticky-on.sticky .navbar-wrap .navbar-layout6 {
  position: fixed;
}

.sticky-on.sticky .navbar-wrap.no-transparent {
  position: fixed;
}

@media only screen and (max-width: 991px) {
  header {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .hide-on-mobile-menu {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .hide-on-desktop-menu {
    display: none !important;
  }
}

.mean-container .mean-bar {
  background: transparent !important;
  float: none;
  padding: 0 !important;
  position: fixed !important;
  top: 0;
  z-index: 7;
}

.mean-container .mean-bar:after {
  content: "";
  clear: both;
  display: block;
}

.mean-container .mean-bar a.logo-mobile-menu {
  width: 100%;
  background-color: rgba(222, 222, 222, 0.95);
  box-sizing: border-box;
}

.mean-container .mean-bar .mobile-menu-nav-back {
  padding-left: 15px;
  border-bottom: 1px solid #b2b2b2;
  position: relative;
  text-align: center;
  height: 50px;
  display: flex;
  align-items: center;
}

.mean-container .mean-bar .mobile-menu-nav-back:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(248, 248, 248, 0.95);
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
}

.mean-container .mean-nav {
  float: none !important;
  background: transparent !important;
}

.mean-container .mean-nav > ul {
  overflow-y: scroll;
  height: 100vh;
}

.mean-container .mean-nav > ul:after {
  content: '';
  display: block;
  clear: both;
}

.mean-container .mean-nav > ul li a {
  font-size: 14px;
  color: #750ed5;
  font-weight: 700;
  background-color: #ebebeb;
  transition: all 0.5s ease-out;
}

.mean-container .mean-nav > ul li a:hover {
  color: #5a49f8;
}

.mean-container .mean-nav > ul li:hover > a {
  color: #5a49f8;
  background-color: #ffffff;
}

.mean-container .mean-nav > ul li li a {
  color: #750ed5;
  opacity: 1;
  font-weight: 400;
  padding: 0.5em 10%;
}

.mean-container .mean-nav > ul li li a:hover {
  background-color: #ffffff;
}

.mean-container .mean-nav > ul li a.mean-expand {
  background-color: transparent;
  margin-top: 0;
  height: 20px;
  line-height: 27px;
  width: 100%;
  text-align: right;
  font-size: 14px;
  padding: 12px 20px 12px 12px !important;
  top: 3px;
}

.mean-container .mean-nav > ul li a.mean-expand:hover {
  background-color: transparent;
}

.mean-container a.meanmenu-reveal {
  float: none !important;
  position: absolute;
  top: 0;
  transition: unset;
  padding: 16px 14px 12px;
}

.mean-container #wrapper {
  padding-top: 51px;
}

@media only screen and (max-width: 991px) {
  .hide-on-mobile-menu {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .hide-on-desktop-menu {
    display: none !important;
  }
}

.navbar-layout1 {
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.navbar-layout1 .temp-logo {
  position: relative;
  z-index: 1;
}

.navbar-layout1 .temp-logo .default-logo {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

.navbar-layout1 .temp-logo .sticky-logo {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.navbar-layout1 nav.template-main-menu > ul > li > a {
  color: #ffffff;
  padding: 50px 20px;
}

@media only screen and (max-width: 1199px) {
  .navbar-layout1 nav.template-main-menu > ul > li > a {
    padding: 50px 10px;
  }
}

.navbar-layout1 nav.template-main-menu > ul > li ul.dropdown-menu-col-1 {
  border-top: 0;
}

.navbar-layout1 nav.template-main-menu > ul > li ul.dropdown-menu-col-2 {
  border-top: 0;
}

.navbar-layout1 .header-search {
  padding: 10px 0;
}

.navbar-layout1 .header-search i:before {
  font-size: 24px;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.navbar-layout1 .header-search:hover i:before {
  color: #eeeeee;
}

.sticky .navbar-layout1 {
  background-color: white;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
}

.sticky .navbar-layout1 .temp-logo .default-logo {
  opacity: 0;
  visibility: hidden;
}

.sticky .navbar-layout1 .temp-logo .sticky-logo {
  opacity: 1;
  visibility: visible;
}

.sticky .navbar-layout1 .header-search i:before {
  color: #000000;
}

.sticky .navbar-layout1 .header-search:hover i:before {
  color: #5a49f8;
}

.sticky .navbar-layout1 .item-btn {
  border: 2px solid #5a49f8;
  color: #5a49f8;
}

.sticky .navbar-layout1 .item-btn:hover {
  background-color: #5a49f8;
  color: #ffffff;
}

.sticky .navbar-layout1 nav.template-main-menu > ul > li > a {
  color: #111111;
  padding: 30px 20px;
}

@media only screen and (max-width: 1199px) {
  .sticky .navbar-layout1 nav.template-main-menu > ul > li > a {
    padding: 30px 10px;
  }
}

.sticky .navbar-layout1 button.offcanvas-menu-btn {
  background-color: #5a49f8;
}

.sticky .navbar-layout1 button.offcanvas-menu-btn .menu-btn-icon {
  background-color: #5a49f8;
}

.sticky .navbar-layout1 button.offcanvas-menu-btn .menu-btn-icon span {
  background-color: #ffffff;
}

.navbar-layout2 {
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.navbar-layout2 .temp-logo {
  position: relative;
  z-index: 1;
}

.navbar-layout2 .temp-logo .default-logo {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

.navbar-layout2 .temp-logo .sticky-logo {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.navbar-layout2 nav.template-main-menu > ul > li > a {
  color: #000000;
  padding: 50px 20px;
}

@media only screen and (max-width: 1199px) {
  .navbar-layout2 nav.template-main-menu > ul > li > a {
    padding: 50px 10px;
  }
}

.navbar-layout2 nav.template-main-menu > ul > li ul.dropdown-menu-col-1 {
  border-top: 0;
}

.navbar-layout2 nav.template-main-menu > ul > li ul.dropdown-menu-col-2 {
  border-top: 0;
}

.navbar-layout2 .header-search {
  padding: 10px 0;
}

.navbar-layout2 .header-search i:before {
  font-size: 24px;
  color: #5a5959;
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
}

.navbar-layout2 .header-search:hover i:before {
  color: #5a49f8;
}

.navbar-layout2 .item-btn.btn-fill.btn-light:hover {
  background-color: #5a49f8;
  border-color: #5a49f8;
}

.sticky .navbar-layout2 {
  background-color: white;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
}

.sticky .navbar-layout2 .temp-logo .default-logo {
  opacity: 0;
  visibility: hidden;
}

.sticky .navbar-layout2 .temp-logo .sticky-logo {
  opacity: 1;
  visibility: visible;
}

.sticky .navbar-layout2 .header-search i:before {
  color: #000000;
}

.sticky .navbar-layout2 .header-search:hover i:before {
  color: #5a49f8;
}

.sticky .navbar-layout2 .item-btn {
  border: 2px solid #5a49f8;
  color: #5a49f8;
}

.sticky .navbar-layout2 .item-btn:hover {
  background-color: #5a49f8;
  color: #ffffff;
}

.sticky .navbar-layout2 nav.template-main-menu > ul > li > a {
  color: #111111;
  padding: 30px 20px;
}

@media only screen and (max-width: 1199px) {
  .sticky .navbar-layout2 nav.template-main-menu > ul > li > a {
    padding: 30px 10px;
  }
}

.sticky .navbar-layout2 button.offcanvas-menu-btn {
  background-color: #5a49f8;
}

.sticky .navbar-layout2 button.offcanvas-menu-btn .menu-btn-icon {
  background-color: #5a49f8;
}

.sticky .navbar-layout2 button.offcanvas-menu-btn .menu-btn-icon span {
  background-color: #ffffff;
}

.navbar-layout3 {
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.navbar-layout3 .temp-logo {
  position: relative;
  z-index: 1;
}

.navbar-layout3 .temp-logo .default-logo {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

.navbar-layout3 .temp-logo .sticky-logo {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.navbar-layout3 nav.template-main-menu > ul > li > a {
  color: #ffffff;
  padding: 50px 20px;
}

@media only screen and (max-width: 1199px) {
  .navbar-layout3 nav.template-main-menu > ul > li > a {
    padding: 50px 10px;
  }
}

.navbar-layout3 nav.template-main-menu > ul > li ul.dropdown-menu-col-1 {
  border-top: 0;
}

.navbar-layout3 nav.template-main-menu > ul > li ul.dropdown-menu-col-2 {
  border-top: 0;
}

.navbar-layout3 .header-search {
  padding: 10px 0;
}

.navbar-layout3 .header-search i:before {
  font-size: 24px;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.navbar-layout3 .header-search:hover i:before {
  color: #eeeeee;
}

.sticky .navbar-layout3 {
  background-color: white;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
}

.sticky .navbar-layout3 .temp-logo .default-logo {
  opacity: 0;
  visibility: hidden;
}

.sticky .navbar-layout3 .temp-logo .sticky-logo {
  opacity: 1;
  visibility: visible;
}

.sticky .navbar-layout3 .header-search i:before {
  color: #000000;
}

.sticky .navbar-layout3 .header-search:hover i:before {
  color: #5a49f8;
}

.sticky .navbar-layout3 .item-btn {
  border: 2px solid #5a49f8;
  color: #5a49f8;
}

.sticky .navbar-layout3 .item-btn:hover {
  background-color: #5a49f8;
  color: #ffffff;
}

.sticky .navbar-layout3 nav.template-main-menu > ul > li > a {
  color: #111111;
  padding: 30px 20px;
}

@media only screen and (max-width: 1199px) {
  .sticky .navbar-layout3 nav.template-main-menu > ul > li > a {
    padding: 30px 10px;
  }
}

.navbar-layout4 {
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.navbar-layout4 .temp-logo {
  position: relative;
  z-index: 1;
}

.navbar-layout4 .temp-logo .default-logo {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

.navbar-layout4 .temp-logo .sticky-logo {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.navbar-layout4 nav.template-main-menu > ul > li > a {
  color: #ffffff;
  padding: 50px 20px;
}

@media only screen and (max-width: 1199px) {
  .navbar-layout4 nav.template-main-menu > ul > li > a {
    padding: 50px 10px;
  }
}

.navbar-layout4 nav.template-main-menu > ul > li ul.dropdown-menu-col-1 {
  border-top: 0;
}

.navbar-layout4 nav.template-main-menu > ul > li ul.dropdown-menu-col-2 {
  border-top: 0;
}

.navbar-layout4 .header-search {
  padding: 10px 0;
}

.navbar-layout4 .header-search i:before {
  font-size: 24px;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.navbar-layout4 .header-search:hover i:before {
  color: #eeeeee;
}

.navbar-layout4 .item-btn {
  padding: 9px 36px;
}

.navbar-layout4 .item-btn:focus {
  outline: none;
  box-shadow: none;
}

@media only screen and (max-width: 1199px) {
  .navbar-layout4 .item-btn {
    padding: 9px 20px;
  }
}

.sticky .navbar-layout4 {
  background-color: white;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
}

.sticky .navbar-layout4 .temp-logo .default-logo {
  opacity: 0;
  visibility: hidden;
}

.sticky .navbar-layout4 .temp-logo .sticky-logo {
  opacity: 1;
  visibility: visible;
}

.sticky .navbar-layout4 .item-btn {
  border: 2px solid #5a49f8;
  color: #5a49f8;
}

.sticky .navbar-layout4 .item-btn:hover {
  background-color: #5a49f8;
  color: #ffffff;
}

.sticky .navbar-layout4 nav.template-main-menu > ul > li > a {
  color: #111111;
  padding: 30px 20px;
}

@media only screen and (max-width: 1199px) {
  .sticky .navbar-layout4 nav.template-main-menu > ul > li > a {
    padding: 30px 10px;
  }
}

.navbar-layout5 {
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.navbar-layout5 .temp-logo {
  position: relative;
  z-index: 1;
}

.navbar-layout5 .temp-logo .default-logo {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

.navbar-layout5 .temp-logo .sticky-logo {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.navbar-layout5 nav.template-main-menu > ul > li > a {
  color: #ffffff;
  padding: 50px 20px;
}

@media only screen and (max-width: 1199px) {
  .navbar-layout5 nav.template-main-menu > ul > li > a {
    padding: 50px 10px;
  }
}

.navbar-layout5 nav.template-main-menu > ul > li ul.dropdown-menu-col-1 {
  border-top: 0;
}

.navbar-layout5 nav.template-main-menu > ul > li ul.dropdown-menu-col-2 {
  border-top: 0;
}

.navbar-layout5 .header-search {
  padding: 10px 0;
}

.navbar-layout5 .header-search i:before {
  font-size: 24px;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.navbar-layout5 .header-search:hover i:before {
  color: #eeeeee;
}

.sticky .navbar-layout5 {
  background-color: white;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
}

.sticky .navbar-layout5 .temp-logo .default-logo {
  opacity: 0;
  visibility: hidden;
}

.sticky .navbar-layout5 .temp-logo .sticky-logo {
  opacity: 1;
  visibility: visible;
}

.sticky .navbar-layout5 .header-search i:before {
  color: #000000;
}

.sticky .navbar-layout5 .header-search:hover i:before {
  color: #5a49f8;
}

.sticky .navbar-layout5 .item-btn {
  border: 2px solid #5a49f8;
  color: #5a49f8;
}

.sticky .navbar-layout5 .item-btn:hover {
  background-color: #5a49f8;
  color: #ffffff;
}

.sticky .navbar-layout5 nav.template-main-menu > ul > li > a {
  color: #111111;
  padding: 30px 20px;
}

@media only screen and (max-width: 1199px) {
  .sticky .navbar-layout5 nav.template-main-menu > ul > li > a {
    padding: 30px 10px;
  }
}

.navbar-layout6 {
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.navbar-layout6 .temp-logo {
  position: relative;
  z-index: 1;
}

.navbar-layout6 .temp-logo .default-logo {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

.navbar-layout6 .temp-logo .sticky-logo {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.navbar-layout6 nav.template-main-menu > ul > li > a {
  color: #000000;
  padding: 50px 20px;
}

@media only screen and (max-width: 1199px) {
  .navbar-layout6 nav.template-main-menu > ul > li > a {
    padding: 50px 10px;
  }
}

.navbar-layout6 nav.template-main-menu > ul > li ul.dropdown-menu-col-1 {
  border-top: 0;
}

.navbar-layout6 nav.template-main-menu > ul > li ul.dropdown-menu-col-2 {
  border-top: 0;
}

.navbar-layout6 .header-search {
  padding: 10px 0;
}

.navbar-layout6 .header-search i:before {
  font-size: 24px;
  color: #5a5959;
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
}

.navbar-layout6 .header-search:hover i:before {
  color: #5a49f8;
}

.navbar-layout6 .cart-wrapper {
  padding: 42px 0;
}

.sticky .navbar-layout6 {
  background-color: white;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
}

.sticky .navbar-layout6 .temp-logo .default-logo {
  opacity: 0;
  visibility: hidden;
}

.sticky .navbar-layout6 .temp-logo .sticky-logo {
  opacity: 1;
  visibility: visible;
}

.sticky .navbar-layout6 .header-search i:before {
  color: #000000;
}

.sticky .navbar-layout6 .header-search:hover i:before {
  color: #5a49f8;
}

.sticky .navbar-layout6 .cart-wrapper {
  padding: 25px 0;
}

.sticky .navbar-layout6 nav.template-main-menu > ul > li > a {
  color: #111111;
  padding: 30px 20px;
}

@media only screen and (max-width: 1199px) {
  .sticky .navbar-layout6 nav.template-main-menu > ul > li > a {
    padding: 30px 10px;
  }
}

.navbar-layout7 {
  background-color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.navbar-layout7 .temp-logo {
  position: relative;
  z-index: 1;
}

.navbar-layout7 .temp-logo .default-logo {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

.navbar-layout7 .temp-logo .sticky-logo {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.navbar-layout7 nav.template-main-menu > ul > li > a {
  color: #000000;
  padding: 50px 20px;
}

@media only screen and (max-width: 1199px) {
  .navbar-layout7 nav.template-main-menu > ul > li > a {
    padding: 50px 10px;
  }
}

.navbar-layout7 nav.template-main-menu > ul > li ul.dropdown-menu-col-1 {
  border-top: 0;
}

.navbar-layout7 nav.template-main-menu > ul > li ul.dropdown-menu-col-2 {
  border-top: 0;
}

.navbar-layout7 .header-search {
  padding: 10px 0;
}

.navbar-layout7 .header-search i:before {
  font-size: 24px;
  color: #5a5959;
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
}

.navbar-layout7 .header-search:hover i:before {
  color: #5a49f8;
}

.navbar-layout7 .cart-wrapper {
  padding: 42px 0;
}

.sticky .navbar-layout7 {
  background-color: white;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
}

.sticky .navbar-layout7 .temp-logo .default-logo {
  opacity: 0;
  visibility: hidden;
}

.sticky .navbar-layout7 .temp-logo .sticky-logo {
  opacity: 1;
  visibility: visible;
}

.sticky .navbar-layout7 .header-search i:before {
  color: #000000;
}

.sticky .navbar-layout7 .header-search:hover i:before {
  color: #5a49f8;
}

.sticky .navbar-layout7 .cart-wrapper {
  padding: 25px 0;
}

.sticky .navbar-layout7 nav.template-main-menu > ul > li > a {
  color: #111111;
  padding: 30px 20px;
}

@media only screen and (max-width: 1199px) {
  .sticky .navbar-layout7 nav.template-main-menu > ul > li > a {
    padding: 30px 10px;
  }
}

#pp-nav.right {
  right: 100px;
}

@media only screen and (max-width: 991px) {
  #pp-nav {
    display: none;
  }
}

#pp-nav ul li {
  height: 15px;
  margin: 0;
}

#pp-nav ul li a span {
  width: 30px;
  border-radius: 4px;
  height: 3px;
  border: 0;
  right: 0;
  left: inherit;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.5s ease-in-out;
}

#pp-nav ul li a.active span {
  width: 40px;
  background-color: rgba(255, 255, 255, 0.5);
}

/*--- Topbar ---*/
#topbar-wrap {
  position: relative;
  z-index: 9;
}

.login-form {
  margin-top: 30px;
  border-radius: 4px;
  right: -1px;
  position: absolute;
  z-index: 999;
  top: 100%;
  width: 540px;
  text-align: left;
  background: #ffffff;
  padding: 40px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.login-form .item-title {
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .login-form {
    margin-bottom: 15px;
  }
}

.login-form label {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #5a49f8;
}

.login-form input {
  height: 50px;
  padding: 0 10px;
  width: 100%;
  border-radius: 0;
  border: 1px solid #c5c5c5;
  margin-bottom: 20px;
  color: #000000;
  font-weight: 500;
  font-size: 15px;
}

.login-form input:focus {
  box-shadow: none;
  border-color: #aaaaaa;
}

.login-form span {
  margin-right: 15px;
  color: #5a49f8;
}

@media (min-width: 768px) and (max-width: 991px) {
  .login-form span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 479px) {
  .login-form span {
    display: inline-block;
    margin-left: 0;
    margin-top: 15px;
  }
}

.login-form span input {
  width: inherit;
  height: inherit;
  margin-right: 10px;
  padding: 30px;
}

.login-form .item-btn {
  margin-right: 10px;
  font-size: 16px;
  background-color: #5a49f8;
  color: #ffffff;
  border-radius: 4px;
  border: 1px solid #5a49f8;
  padding: 6px 30px;
  transition: all 0.3s ease-in-out;
}

.login-form .item-btn:last-child {
  margin-right: 0;
}

.login-form .item-btn:focus {
  outline: none;
}

.login-form .item-btn:hover {
  background-color: transparent;
  color: #5a49f8;
}

.login-form input.form-control::-webkit-input-placeholder {
  color: #646464;
  font-weight: 300;
}

.login-form input.form-control::-moz-placeholder {
  color: #646464;
  font-weight: 300;
}

.login-form input.form-control:-moz-placeholder {
  color: #646464;
  font-weight: 300;
}

.login-form input.form-control:-ms-input-placeholder {
  color: #646464;
  font-weight: 300;
}

.topbar-layout1 {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  background-color: #f9fbfc;
  transition: all 0.3s ease-in-out;
}

.topbar-layout1 .social-follow {
  margin-bottom: -30px;
}

.topbar-layout1 .social-follow a.follow-single-item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.topbar-layout1 .social-follow a.follow-single-item .item-icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebebeb;
  height: 36px;
  width: 36px;
  border-radius: 50% 50% 0 50%;
  transition: all 0.3s ease-in-out;
}

.topbar-layout1 .social-follow a.follow-single-item .item-icon.facebook {
  color: #395795;
}

.topbar-layout1 .social-follow a.follow-single-item .item-icon.twitter {
  color: #0ad2ea;
}

.topbar-layout1 .social-follow a.follow-single-item .item-icon.instagram {
  color: #cf2e80;
}

.topbar-layout1 .social-follow a.follow-single-item .item-icon.pinterest {
  color: #cb2027;
}

.topbar-layout1 .social-follow a.follow-single-item .item-icon.youtube {
  color: #ff0001;
}

.topbar-layout1 .social-follow a.follow-single-item .item-icon.linkedin {
  color: #0274b3;
}

.topbar-layout1 .social-follow a.follow-single-item .item-icon.behance {
  color: #1869ff;
}

.topbar-layout1 .social-follow a.follow-single-item .item-icon.dribbble {
  color: #ec498a;
}

.topbar-layout1 .social-follow a.follow-single-item .item-icon.skype {
  color: #019dd7;
}

.topbar-layout1 .social-follow a.follow-single-item .item-content {
  flex: 1;
  color: #6c757d;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 700;
  transform: translateY(10px);
}

.topbar-layout1 .social-follow a.follow-single-item:hover .item-icon {
  color: #ffffff;
}

.topbar-layout1 .social-follow a:hover .item-icon.facebook {
  background-color: #395795;
}

.topbar-layout1 .social-follow a:hover .item-icon.twitter {
  background-color: #0ad2ea;
}

.topbar-layout1 .social-follow a:hover .item-icon.instagram {
  background-color: #cf2e80;
}

.topbar-layout1 .social-follow a:hover .item-icon.pinterest {
  background-color: #cb2027;
}

.topbar-layout1 .social-follow a:hover .item-icon.youtube {
  background-color: #ff0001;
}

.topbar-layout1 .social-follow a:hover .item-icon.linkedin {
  background-color: #0274b3;
}

.topbar-layout1 .social-follow a:hover .item-icon.behance {
  background-color: #1869ff;
}

.topbar-layout1 .social-follow a:hover .item-icon.dribbble {
  background-color: #ec498a;
}

.topbar-layout1 .social-follow a:hover .item-icon.skype {
  background-color: #019dd7;
}

.topbar-layout1 .topbar-user {
  padding: 10px 0;
  line-height: 1;
  display: flex;
  align-items: center;
  color: #111111;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.topbar-layout1 .topbar-user i {
  margin-right: 10px;
}

.topbar-layout1 .topbar-user i:before {
  color: #5a49f8;
  font-size: 21px;
  transition: all 0.3s ease-in-out;
}

.topbar-layout1 .topbar-user:hover {
  color: #5a49f8;
}

.topbar-layout1 .topbar-user:hover i:before {
  color: #5a49f8;
}

.topbar-layout2 {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  background-color: black;
  transition: all 0.3s ease-in-out;
}

.topbar-layout2 .topbar-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.topbar-layout2 .topbar-menu li {
  margin-right: 5px;
  padding-right: 5px;
  font-size: 14px;
  position: relative;
  z-index: 1;
}

.topbar-layout2 .topbar-menu li:before {
  content: ".";
  position: absolute;
  z-index: 1;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
}

.topbar-layout2 .topbar-menu li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.topbar-layout2 .topbar-menu li:last-child:before {
  display: none;
}

.topbar-layout2 .topbar-menu li a {
  display: block;
  padding: 10px 10px;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.topbar-layout2 .topbar-menu li a:hover {
  color: #5a49f8;
}

.topbar-layout2 .topbar-social {
  display: flex;
  align-items: center;
}

.topbar-layout2 .topbar-social .single-item {
  margin-right: 10px;
  font-size: 15px;
}

.topbar-layout2 .topbar-social .single-item:last-child {
  margin-right: 0;
}

.topbar-layout2 .topbar-social .single-item a {
  display: block;
  height: 35px;
  width: 35px;
  background-color: #262626;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.topbar-layout2 .topbar-social .single-item a i {
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.topbar-layout2 .topbar-social .single-item a:hover {
  background-color: #5a49f8;
}

.topbar-layout2 .header-search {
  padding: 10px 0;
  border-radius: 45px;
  transition: all 0.5s ease-in-out;
}

.topbar-layout2 .header-search i:before {
  font-size: 19px;
  font-weight: 600;
  color: #d4d7de;
  transition: all 0.3s ease-in-out;
}

.topbar-layout2 .header-search:hover i:before {
  color: #5a49f8;
}

.topbar-layout3 {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  background-color: #111111;
  transition: all 0.3s ease-in-out;
}

.topbar-layout3 .topbar-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.topbar-layout3 .topbar-menu li {
  margin-right: 5px;
  padding-right: 5px;
  font-size: 14px;
  position: relative;
  z-index: 1;
}

.topbar-layout3 .topbar-menu li:before {
  content: ".";
  position: absolute;
  z-index: 1;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
}

.topbar-layout3 .topbar-menu li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.topbar-layout3 .topbar-menu li:last-child:before {
  display: none;
}

.topbar-layout3 .topbar-menu li a {
  display: block;
  padding: 5px 10px;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.topbar-layout3 .topbar-menu li a:hover {
  color: #5a49f8;
}

.topbar-layout3 .topbar-social {
  display: flex;
  align-items: center;
}

.topbar-layout3 .topbar-social .single-item {
  margin-left: 10px;
  font-size: 15px;
}

.topbar-layout3 .topbar-social .single-item:first-child {
  margin-left: 0;
}

.topbar-layout3 .topbar-social .single-item:first-child a {
  padding: 5px 10px 5px 0;
}

.topbar-layout3 .topbar-social .single-item a {
  display: block;
  padding: 5px 10px;
}

.topbar-layout3 .topbar-social .single-item a i {
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.topbar-layout3 .topbar-social .single-item a:hover i {
  color: #5a49f8;
}

.topbar-layout3 .header-search {
  padding: 10px 0;
  border-radius: 45px;
  transition: all 0.5s ease-in-out;
}

.topbar-layout3 .header-search i:before {
  font-size: 19px;
  font-weight: 600;
  color: #d4d7de;
  transition: all 0.3s ease-in-out;
}

.topbar-layout3 .header-search:hover i:before {
  color: #5a49f8;
}

.topbar-layout3 .topbar-user {
  padding: 10px 0;
  line-height: 1;
  display: block;
  color: #d4d7de;
  transition: all 0.3s ease-in-out;
}

.topbar-layout3 .topbar-user i {
  margin-right: 10px;
}

.topbar-layout3 .topbar-user i:before {
  color: #d4d7de;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}

.topbar-layout3 .topbar-user:hover {
  color: #5a49f8;
}

.topbar-layout3 .topbar-user:hover i:before {
  color: #5a49f8;
}

.topbar-layout4 {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  background-color: #e7f1ff;
  transition: all 0.3s ease-in-out;
}

.topbar-layout4 .topbar-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.topbar-layout4 .topbar-menu li {
  margin-right: 30px;
  padding-right: 30px;
  font-size: 15px;
  color: #646464;
  position: relative;
  z-index: 1;
}

.topbar-layout4 .topbar-menu li i {
  margin-right: 10px;
}

.topbar-layout4 .topbar-menu li:before {
  content: "";
  position: absolute;
  height: 25px;
  width: 1px;
  z-index: 1;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #c2c2c2;
}

.topbar-layout4 .topbar-menu li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.topbar-layout4 .topbar-menu li:last-child:before {
  display: none;
}

.topbar-layout4 .topbar-social {
  display: flex;
  align-items: center;
}

.topbar-layout4 .topbar-social .single-item {
  margin-right: 5px;
  font-size: 15px;
}

.topbar-layout4 .topbar-social .single-item:last-child {
  margin-right: 0;
}

.topbar-layout4 .topbar-social .single-item:last-child a {
  padding: 17px 0 17px 10px;
}

.topbar-layout4 .topbar-social .single-item a {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 10px;
  transition: all 0.3s ease-in-out;
}

.topbar-layout4 .topbar-social .single-item a i {
  color: #646464;
  transition: all 0.3s ease-in-out;
}

.topbar-layout4 .topbar-social .single-item a:hover i {
  color: #5a49f8;
}

/*--------------------------------------
	3.3 Miscellaneous
---------------------------------------*/
/*--- Back To Top ---*/
.return-to-top {
  overflow: hidden;
  position: fixed;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  bottom: -50px;
  right: 20px;
  text-align: center;
  z-index: 9999;
  background-color: #5a49f8;
  display: block;
  font-size: 16px;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0, 0, 0.15, 1.88);
}

.return-to-top i {
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.return-to-top:hover, .return-to-top:focus {
  color: #ffffff;
}

.return-to-top:hover i, .return-to-top:focus i {
  animation: toBottomFromTop 0.5s forwards;
}

.return-to-top.back-top {
  bottom: 20px;
}

@-webkit-keyframes toBottomFromTop {
  49% {
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toBottomFromTop {
  49% {
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}

/*--- Box Layout ---*/
@media only screen and (min-width: 1200px) {
  .box-layout .box-layout-child {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
  }
  .box-layout .box-layout-child .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 1580px) {
  .box-layout .box-layout-child {
    max-width: 1550px;
    width: 100%;
    margin: 0 auto;
  }
}

/*--- Buttons ---*/
.btn-fill {
  font-weight: 500;
  font-size: 16px;
  position: relative;
  z-index: 2;
  padding: 10px 27px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #5a49f8;
  border: 2px solid;
  border-color: #5a49f8;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.btn-fill i {
  margin-top: 2px;
  line-height: 1;
  margin-left: 12px;
  transform: translateX(0px);
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.btn-fill i:before {
  font-size: 18px;
}

.btn-fill:hover {
  color: #5a49f8;
  background-color: transparent;
}

.btn-fill:hover i {
  color: #5a49f8;
  transform: translateX(5px);
}

.btn-fill:focus {
  outline: none;
  box-shadow: none;
}

.btn-ghost {
  font-weight: 500;
  font-size: 16px;
  position: relative;
  z-index: 2;
  padding: 10px 27px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid;
  border-color: #5a49f8;
  color: #5a49f8;
  transition: all 0.5s ease-in-out;
}

.btn-ghost i {
  margin-top: 2px;
  line-height: 1;
  margin-left: 12px;
  transform: translateX(0px);
  color: #5a49f8;
  transition: all 0.5s ease-in-out;
}

.btn-ghost i:before {
  font-size: 18px;
}

.btn-ghost:hover {
  color: #ffffff;
  background-color: #5a49f8;
}

.btn-ghost:hover i {
  color: #ffffff;
  transform: translateX(5px);
}

.btn-ghost:focus {
  outline: none;
  box-shadow: none;
}

.btn-text {
  font-weight: 500;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  color: #5a49f8;
  transition: all 0.5s ease-in-out;
}

.btn-text i {
  margin-top: 3px;
  margin-left: 10px;
  line-height: 1;
  transform: translateX(0px);
  color: #5a49f8;
  transition: all 0.5s ease-in-out;
}

.btn-text i:before {
  font-size: 18px;
  transition: all 0.5s ease-in-out;
}

.btn-text:hover {
  color: #2b1bbb;
}

.btn-text:hover i {
  transform: translateX(5px);
  color: #2b1bbb;
}

.btn-text:focus {
  outline: none;
  box-shadow: none;
}

.btn-text.btn-light {
  color: #ffffff;
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.btn-text.btn-light i {
  margin-left: 10px;
  line-height: 1;
  transform: translateX(0px);
  color: #ffffff;
}

.btn-text.btn-light i:before {
  font-size: 25px;
}

.btn-text.btn-light:hover {
  color: #111111;
}

.btn-text.btn-light:hover i {
  color: #111111;
  transform: translateX(5px);
}

.btn-text.btn-dark {
  color: #000000;
  background-color: transparent;
  transition: all 0.5s ease-in-out;
}

.btn-text.btn-dark i {
  margin-left: 10px;
  line-height: 1;
  transform: translateX(0px);
  color: #000000;
}

.btn-text.btn-dark i:before {
  font-size: 25px;
}

.btn-text.btn-dark:hover {
  color: #5a49f8;
}

.btn-text.btn-dark:hover i {
  color: #5a49f8;
  transform: translateX(5px);
}

.btn-fill.btn-light {
  border-color: #ffffff;
  color: #1d2124;
  background-color: #ffffff;
}

.btn-fill.btn-light i {
  color: #1d2124;
}

.btn-fill.btn-light:hover {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
}

.btn-fill.btn-light:hover i {
  color: #ffffff;
}

.btn-ghost.btn-light {
  border-color: #ffffff;
  color: #ffffff;
  background-color: transparent;
}

.btn-ghost.btn-light:hover {
  color: #1d2124;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-fill.btn-dark {
  border-color: #1d2124;
  color: #ffffff;
  background-color: #1d2124;
}

.btn-fill.btn-dark:hover {
  color: #1d2124;
  background-color: transparent;
  border-color: #1d2124;
}

.btn-ghost.btn-dark {
  border-color: #1d2124;
  color: #1d2124;
  background-color: transparent;
}

.btn-ghost.btn-dark i {
  color: #1d2124;
}

.btn-ghost.btn-dark:hover {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #1d2124;
}

.btn-ghost.btn-dark:hover i {
  color: #ffffff;
}

.btn-fill.btn-gradient {
  border: 0;
  padding: 12px 28px;
}

.btn-fill.btn-gradient:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: inherit;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 4px;
  background: #5a49f8;
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

.btn-fill.btn-gradient:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: inherit;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 4px;
  background: #5a49f8;
  background: linear-gradient(245deg, #5a49f8 40%, #7a64f2 100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.btn-fill.btn-gradient:hover {
  color: #ffffff;
}

.btn-fill.btn-gradient:hover i {
  color: #ffffff;
}

.btn-fill.btn-gradient:hover:after {
  opacity: 0;
  visibility: hidden;
}

.btn-fill.btn-gradient:hover:before {
  opacity: 1;
  visibility: visible;
}

.btn-fill.btn-gradient2 {
  border: 0;
  padding: 12px 35px;
}

.btn-fill.btn-gradient2:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: inherit;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 4px;
  background: #5a49f8;
  background-image: linear-gradient(60deg, #00d7f7 0%, #2671fb 100%);
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

.btn-fill.btn-gradient2:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: inherit;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 4px;
  background: #5a49f8;
  background: linear-gradient(245deg, #2671fb 40%, #00d7f7 100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.btn-fill.btn-gradient2:hover {
  color: #ffffff;
}

.btn-fill.btn-gradient2:hover i {
  color: #ffffff;
}

.btn-fill.btn-gradient2:hover:after {
  opacity: 0;
  visibility: hidden;
}

.btn-fill.btn-gradient2:hover:before {
  opacity: 1;
  visibility: visible;
}

.btn-ghost.btn-gradient2 {
  color: #000000;
  border: 0;
  padding: 12px 35px;
  box-sizing: border-box;
  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(#fff, #fff), linear-gradient(245deg, #00d7f7, #2671fb);
  position: relative;
  z-index: 1;
}

.btn-ghost.btn-gradient2:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(245deg, #00d7f7, #2671fb);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.btn-ghost.btn-gradient2:hover {
  color: #ffffff;
}

.btn-ghost.btn-gradient2:hover:before {
  opacity: 1;
  visibility: visible;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  border-color: transparent;
  background-color: transparent;
}

.btn-play-layout1 {
  height: 90px;
  width: 90px;
  background-color: rgba(90, 73, 248, 0.8);
  color: #ffffff;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 30px;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
}

.btn-play-layout1 i {
  margin-left: 4px;
}

.btn-play-layout1:hover {
  background-color: #5a49f8;
  color: #ffffff;
}

@media only screen and (max-width: 1199px) {
  .btn-play-layout1 {
    height: 80px;
    width: 80px;
    font-size: 28px;
  }
}

@media only screen and (max-width: 991px) {
  .btn-play-layout1 {
    height: 70px;
    width: 70px;
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-play-layout1 {
    height: 60px;
    width: 60px;
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .btn-play-layout1 {
    height: 50px;
    width: 50px;
    font-size: 22px;
  }
}

.isotop-btn-layout1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 45px;
}

.isotop-btn-layout1 a.nav-item {
  padding: 5px 20px 4px;
  margin-left: 10px;
  margin-bottom: 10px;
  color: #111111;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.isotop-btn-layout1 a.nav-item:hover {
  color: #5a49f8;
}

.isotop-btn-layout1 a.nav-item.current {
  color: #ffffff;
  background-color: #5a49f8;
}

.isotop-btn-layout2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 45px;
}

.isotop-btn-layout2 a.nav-item {
  background-color: #f0eeff;
  padding: 10px 35px 9px;
  margin-left: 10px;
  margin-bottom: 10px;
  color: #646464;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.isotop-btn-layout2 a.nav-item:hover {
  color: #5a49f8;
}

.isotop-btn-layout2 a.nav-item.current {
  color: #ffffff;
  background-color: #5a49f8;
}

/*--- Global ---*/
a {
  color: #007bff;
}

a,
a:active,
a:hover,
a:focus {
  text-decoration: none;
  outline: 0 none;
}

input,
textarea {
  width: 100%;
  padding: 10px 12px;
  outline: 0;
}

button {
  cursor: pointer;
}

svg {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin-left: 0;
}

.form-response .alert-danger,
.form-response .alert.alert-info,
.form-response .alert.alert-success {
  margin-top: 10px;
  margin-bottom: 0;
}

.form-response .alert-danger p,
.form-response .alert.alert-info p,
.form-response .alert.alert-success p {
  margin-bottom: 0;
}

.list-unstyled li {
  color: #dcdcdc;
  font-weight: 300;
}

.owl-carousel.col-full-width [class^="col-"] {
  max-width: 100%;
  flex: 0 0 100%;
  padding-left: 0;
  padding-right: 0;
}

.owl-carousel.row {
  margin-left: 0;
  margin-right: 0;
}

ul.inline-list {
  display: flex;
  flex-wrap: wrap;
}

ul.inline-list li {
  margin-right: 15px;
  margin-bottom: 15px;
}

ul.inline-list li:last-child {
  margin-right: 0;
}

.item-icon {
  line-height: 1;
}

section {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .wow {
    animation-name: none !important;
    visibility: visible !important;
  }
}

body.pagepiling {
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  body.pagepiling {
    overflow: inherit;
  }
}

.compress-left-side {
  padding-right: 50px;
  padding-left: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .compress-left-side {
    padding-right: calc(calc(100% - 930px)/2);
    padding-left: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .compress-left-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .compress-left-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .compress-left-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.compress-right-side {
  padding-left: 50px;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .compress-right-side {
    padding-left: calc(calc(100% - 690px)/2);
    padding-right: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .compress-right-side {
    padding-left: calc(calc(100% - 510px)/2);
    padding-right: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .compress-right-side {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.box-bottom-margin {
  margin-bottom: 30px;
}

.box-top-margin {
  margin-top: 30px;
}

.mb--10 {
  margin-bottom: 10px;
}

.mb--60 {
  margin-bottom: 60px;
}

.mb--100 {
  margin-bottom: 100px;
}

.mt--100 {
  margin-top: 100px;
}

.mt--50 {
  margin-top: 50px;
}

.mt--70 {
  margin-top: 70px;
}

/*--- Pagination ---*/
ul.pagination-layout1 {
  margin-bottom: 20px;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  ul.pagination-layout1 {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  ul.pagination-layout1 {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 575px) {
  ul.pagination-layout1 {
    margin-top: 5px;
  }
}

ul.pagination-layout1 li {
  margin-right: 10px;
  margin-bottom: 10px;
}

ul.pagination-layout1 li a i {
  transition: all 0.3s ease-in-out;
}

ul.pagination-layout1 li:first-child a i {
  color: #bbbbbb;
}

ul.pagination-layout1 li:last-child {
  margin-right: 0;
}

ul.pagination-layout1 li:last-child a i {
  color: #bbbbbb;
}

ul.pagination-layout1 li a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #750ed5;
  background-color: transparent;
  border: 1px solid #e4e4e4;
  height: 45px;
  width: 45px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

ul.pagination-layout1 li a:hover {
  background-color: #5a49f8;
  color: #ffffff;
  border: 1px solid #5a49f8;
}

ul.pagination-layout1 li a:hover i {
  color: #ffffff;
}

ul.pagination-layout1 li.active a {
  background-color: #5a49f8;
  color: #ffffff;
  border: 1px solid #5a49f8;
}

ul.pagination-layout2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

ul.pagination-layout2 li .item-figure {
  margin-bottom: 0;
}

ul.pagination-layout2 li .item-figure img {
  border-radius: 50%;
}

ul.pagination-layout2 li .item-content p {
  color: #5a49f8;
  margin-bottom: 0;
}

ul.pagination-layout2 li .item-content .item-title {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
  color: #646464;
  line-height: 1.4;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  ul.pagination-layout2 li .item-content {
    display: none;
  }
}

ul.pagination-layout2 li:hover .item-content .item-title {
  color: #5a49f8;
}

ul.pagination-layout2 .next-btn {
  text-align: right;
}

ul.pagination-layout2 .center-btn a {
  height: 50px;
  width: 50px;
  background-color: #f3f3f3;
  color: #646464;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

ul.pagination-layout2 .center-btn a:hover {
  background-color: #5a49f8;
  color: #ffffff;
}

/*--- Preloader ---*/
#preloader {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  left: 0;
  overflow: visible;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 1;
}

.tlp-preloader .animation-preloader {
  position: relative;
  z-index: 1;
}

.tlp-preloader .animation-preloader img {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0.7);
  animation: zoominout 2s infinite linear;
  transition: all 0.9s ease-in-out;
}

.tlp-preloader .animation-preloader .tlp-spinner {
  animation: rotateZ 1s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.15);
  border-top-color: #5a49f8;
  height: 120px;
  width: 120px;
}

@keyframes rotateZ {
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes zoominout {
  0% {
    transform: translateY(-50%) translateX(-50%) scale(0.7);
  }
  50% {
    transform: translateY(-50%) translateX(-50%) scale(1);
  }
  100% {
    transform: translateY(-50%) translateX(-50%) scale(0.7);
  }
}

/*--- Section Heading ---*/
.section-heading {
  position: relative;
  z-index: 0;
  text-align: center;
  margin-bottom: 55px;
}

@media only screen and (max-width: 767px) {
  .section-heading {
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .section-heading {
    margin-bottom: 35px;
  }
}

.section-heading .heading-sub-title {
  text-align: center;
  color: #5a49f8;
  font-weight: 500;
  letter-spacing: 4px;
}

.section-heading .heading-main-title {
  font-weight: 700;
}

.section-heading .heading-paragraph {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1199px) {
  .section-heading .heading-paragraph {
    width: 70%;
  }
}

@media only screen and (max-width: 991px) {
  .section-heading .heading-paragraph {
    width: 100%;
  }
}

.section-heading.heading-dark .heading-main-title {
  color: #000000;
}

.section-heading.heading-dark .heading-icon {
  line-height: 1;
  color: #5a49f8;
  background: -webkit-linear-gradient(#5a49f8, #5a49f8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}

.section-heading.heading-dark .heading-icon i {
  margin-top: 5px;
}

.section-heading.heading-dark .heading-icon i:before {
  font-size: 30px;
}

.section-heading.heading-light .heading-main-title {
  color: #ffffff;
}

.section-heading.heading-light .heading-icon {
  line-height: 1;
  color: #fcf306;
  background: -webkit-linear-gradient(#fcf306, #fcf306);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-bottom: 20px;
}

.section-heading.heading-light .heading-icon:before {
  content: "";
  position: absolute;
  z-index: 1;
  width: 60px;
  height: 1px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.21);
  left: 50px;
  top: 50%;
}

.section-heading.heading-light .heading-icon:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 60px;
  height: 1px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.21);
  right: 50px;
  top: 50%;
}

.section-heading.heading-light .heading-icon i:before {
  font-size: 30px;
}

.section-heading.heading-light .heading-sub-title {
  color: #ffffff;
}

.section-heading.heading-light .heading-paragraph {
  color: #ffffff;
}

.section-heading.heading-layout1 .heading-main-title {
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .section-heading.heading-layout1 .heading-main-title {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .section-heading.heading-layout1 .heading-main-title {
    margin-bottom: 5px;
  }
}

.section-heading.heading-layout2 .heading-sub-title {
  display: inline-block;
  padding: 7px 22px 6px;
  border-radius: 4px;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.section-heading.heading-layout2 .heading-main-title {
  margin-bottom: 15px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 991px) {
  .section-heading.heading-layout2 .heading-main-title {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .section-heading.heading-layout2 .heading-main-title {
    margin-bottom: 10px;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .section-heading.heading-layout2 .heading-main-title {
    margin-bottom: 5px;
  }
}

.section-heading.heading-layout2.color-heliotrope .heading-sub-title {
  background-color: rgba(163, 74, 248, 0.21);
  color: #A34AF8;
}

.section-heading.heading-layout2.color-mountain-meadow .heading-sub-title {
  background-color: rgba(20, 193, 113, 0.21);
  color: #14C171;
}

.section-heading.heading-layout2.color-torch-red .heading-sub-title {
  background-color: rgba(255, 30, 69, 0.21);
  color: #FF1E45;
}

.section-heading.heading-layout3 {
  margin-bottom: 0;
  text-align: left;
}

.section-heading.heading-layout3 .heading-sub-title {
  display: inline-block;
  padding: 6px 22px 5px;
  border-radius: 4px;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.section-heading.heading-layout3.color-west-side .heading-sub-title {
  background-color: rgba(254, 145, 11, 0.21);
  color: #FE910B;
}

.section-heading.heading-layout3.color-persian-green .heading-sub-title {
  background-color: rgba(2, 183, 162, 0.21);
  color: #02B7A2;
}

.section-heading.heading-layout4 {
  margin-bottom: 45px;
}

.section-heading.heading-layout4 .heading-sub-title {
  display: inline-block;
  padding: 6px 22px 0;
  letter-spacing: 0;
}

.section-heading.heading-layout4 .heading-main-title {
  width: 45%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1199px) {
  .section-heading.heading-layout4 .heading-main-title {
    width: 60%;
  }
}

@media only screen and (max-width: 991px) {
  .section-heading.heading-layout4 .heading-main-title {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .section-heading.heading-layout4 .heading-main-title {
    width: 100%;
  }
}

.heading-wrap-layout5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.heading-wrap-layout5 .item-btn {
  margin-bottom: 60px;
}

.section-heading.heading-layout5 {
  flex: 1;
  text-align: left;
  margin-bottom: 45px;
}

@media only screen and (max-width: 767px) {
  .section-heading.heading-layout5 {
    flex: inherit;
    margin-bottom: 0;
  }
}

.section-heading.heading-layout5 .heading-sub-title {
  display: inline-block;
  padding: 0;
  letter-spacing: 0;
}

.section-heading.heading-layout5 .heading-main-title {
  width: 43%;
}

@media only screen and (max-width: 1199px) {
  .section-heading.heading-layout5 .heading-main-title {
    width: 70%;
  }
}

@media only screen and (max-width: 991px) {
  .section-heading.heading-layout5 .heading-main-title {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .section-heading.heading-layout5 .heading-main-title {
    width: 100%;
  }
}

.section-heading.heading-layout6 .heading-main-title {
  margin-bottom: 15px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 991px) {
  .section-heading.heading-layout6 .heading-main-title {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .section-heading.heading-layout6 .heading-main-title {
    margin-bottom: 10px;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .section-heading.heading-layout6 .heading-main-title {
    margin-bottom: 5px;
  }
}

.section-heading.heading-layout7 {
  margin-bottom: 30px;
  text-align: left;
}

@media only screen and (max-width: 1199px) {
  .section-heading.heading-layout7 {
    text-align: center;
  }
}

.section-heading.heading-layout7 .heading-main-title {
  max-width: 600px;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .section-heading.heading-layout7 .heading-main-title {
    margin-left: auto;
    margin-right: auto;
  }
}

.inner-section-heading {
  font-size: 30px;
  font-weight: 600;
}

.has-animation .heading-icon {
  display: inline-flex;
  align-items: flex-start;
}

.has-animation .heading-icon .dash-left {
  margin-right: 25px;
}

.has-animation .heading-icon .dash-left .dashed1 {
  stroke-dashoffset: 100;
}

.has-animation .heading-icon .dash-right {
  margin-left: 15px;
}

.has-animation .heading-icon .dash-right .dashed1 {
  stroke-dashoffset: -100;
}

.has-animation .heading-icon .dashed1 {
  stroke-dasharray: 100;
}

.has-animation .heading-icon .dashed2 {
  stroke-dasharray: 4;
}

@keyframes dash_animation {
  to {
    stroke-dashoffset: 0;
  }
}

.has-animation.active-animation .heading-icon .dashed1 {
  animation: dash_animation 1.5s linear forwards;
}

/*--- Template Search ---*/
.template-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  transition: all 0.5s ease-in-out;
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
}

.template-search .search-form {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.template-search .search-form input[type="search"] {
  width: 60%;
  color: white;
  font-size: 24px;
  text-align: left;
  border: none;
  margin: 0 auto;
  padding: 25px 10px 25px 30px;
  outline: none;
  background: linear-gradient(to right, transparent, rgba(200, 200, 200, 0.5), transparent);
}

@media only screen and (max-width: 575px) {
  .template-search .search-form input[type="search"] {
    width: 75%;
  }
}

.template-search .search-form .search-btn {
  padding: 24px 10px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.template-search .search-form .search-btn i:before {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  margin-left: 0;
}

.template-search .search-form .search-btn:focus {
  box-shadow: none;
  outline: none;
}

.template-search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #5a49f8;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  opacity: 1;
  visibility: visible;
  padding: 3px 15px 5px;
  font-size: 36px;
  font-weight: 300;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 1px 2px 0px rgba(255, 255, 255, 0.75);
  transition: all 0.3s ease-in-out;
}

.template-search .close:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.template-search .close:hover {
  background-color: white;
}

.template-search.open {
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  visibility: visible;
}

/*--------------------------------------
	3.4 Widgets
---------------------------------------*/
/*--- Widget Common Style ---*/
.template-sidebar {
  margin-bottom: 30px;
}

.template-sidebar .widget {
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
}

.template-sidebar .widget:last-child {
  margin-bottom: 0 !important;
}

.template-sidebar .widget.widget-padding {
  padding: 40px 45px;
}

.template-sidebar .widget.widget-border {
  border: 1px solid #d9d9d9;
}

.template-sidebar .widget.widget-box-shadow {
  box-shadow: 0 0 111px 0 rgba(21, 32, 53, 0.07);
}

.template-sidebar .theiaStickySidebar > div:nth-last-child(2) {
  margin-bottom: 0 !important;
}

.widget-section-heading .item-heading {
  display: inline-block;
  font-weight: 600;
  color: #111111;
}

.widget-section-heading.heading-dark .item-heading {
  color: #000000;
}

.widget-section-heading.heading-light .item-heading {
  color: #ffffff;
}

@media only screen and (max-width: 991px) {
  .template-sidebar {
    margin-top: 40px;
  }
}

/*--- Widget Info ---*/
.widget-info .item-figure {
  margin-bottom: 50px;
}

.widget-info .item-figure img {
  border-radius: 4px;
}

@media only screen and (max-width: 767px) {
  .widget-info .item-figure {
    margin-bottom: 30px;
  }
}

.widget-info .list-item li {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 18px;
  color: #111111;
}

.widget-info .list-item li span {
  margin-left: 15px;
  color: #646464;
  font-weight: 400;
}

.widget-info .list-item li:last-child {
  margin-bottom: 0;
}

/*--- Widget Post ---*/
.widget-post > ul > li {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eae7e7;
}

.widget-post > ul > li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .widget-post > ul > li {
    display: block;
  }
  .widget-post > ul > li .left-box {
    margin-bottom: 20px;
  }
  .widget-post > ul > li .media-body {
    margin-left: 0;
  }
}

.widget-post > ul > li .left-box {
  display: inline-block;
}

.widget-post > ul > li .left-box .item-figure {
  display: block;
  overflow: hidden;
  background-color: #000000;
  border-radius: 4px;
}

.widget-post > ul > li .left-box .item-figure img {
  border-radius: 4px;
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.widget-post > ul > li .entry-title {
  line-height: 24px;
  margin-bottom: 0;
  font-size: 18px;
}

.widget-post > ul > li .entry-meta {
  margin-bottom: 10px;
  line-height: 1.2;
}

.widget-post > ul > li:hover .left-box .item-figure img {
  opacity: 0.5;
  transform: scale(1.3);
}

/*--- Widget Category ---*/
.widget-category ul li {
  margin-bottom: 10px;
  font-size: 18px;
}

.widget-category ul li:last-child {
  margin-bottom: 0;
}

.widget-category ul li a {
  line-height: 35px;
  display: block;
  color: #646464;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}

.widget-category ul li a:hover {
  color: #5a49f8;
}

/*--- Widget Tag ---*/
.widget-tag {
  margin-bottom: -14px;
}

.widget-tag ul {
  display: flex;
  flex-wrap: wrap;
}

.widget-tag ul li {
  margin-right: 14px;
  margin-bottom: 14px;
  font-size: 16px;
}

.widget-tag ul li a {
  padding: 5px 14px;
  border-radius: 4px;
  background-color: #f5f5f5;
  display: block;
  color: #646464;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.widget-tag ul li a:before {
  position: absolute;
  border-radius: 4px;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.widget-tag ul li a:hover {
  color: #ffffff;
}

.widget-tag ul li a:hover:before {
  opacity: 1;
  visibility: visible;
}

/*--- Widget Search ---*/
.widget-search .stylish-input-group {
  background: #ffffff;
  border: none;
  height: 60px;
  border-radius: 4px;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .widget-search .stylish-input-group {
    height: 50px;
  }
}

.widget-search .stylish-input-group .form-control {
  padding: 0 10px 0 20px;
  border: 1px solid #d9d9d9;
  box-shadow: none;
  border-radius: 4px 0 0 4px;
  background: #ffffff;
  color: #111111;
  font-size: 16px;
  height: 100%;
}

.widget-search .stylish-input-group .input-group-addon {
  padding: 0;
  border: none;
  background: transparent !important;
  height: 100%;
}

.widget-search .stylish-input-group .input-group-addon button {
  border-radius: 0 4px 4px 0;
  padding: 0 25px;
  height: 100%;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border: 0;
  color: #ffffff;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.widget-search .stylish-input-group .input-group-addon button:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: inherit;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 0 4px 4px 0;
  background: #5a49f8;
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

.widget-search .stylish-input-group .input-group-addon button:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: inherit;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 0 4px 4px 0;
  background: #5a49f8;
  background: linear-gradient(245deg, #5a49f8 40%, #7a64f2 100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.widget-search .stylish-input-group .input-group-addon button:hover:after {
  opacity: 0;
  visibility: hidden;
}

.widget-search .stylish-input-group .input-group-addon button:hover:before {
  opacity: 1;
  visibility: visible;
}

.widget-search .stylish-input-group .input-group-addon button:focus {
  outline: none;
}

.widget-search .stylish-input-group input.form-control::-webkit-input-placeholder,
.widget-search .stylish-input-group textarea.form-control::-webkit-input-placeholder {
  color: #a2a0a0;
  font-size: 16px;
}

.widget-search .stylish-input-group input.form-control::-moz-placeholder,
.widget-search .stylish-input-group textarea.form-control::-moz-placeholder {
  color: #a2a0a0;
  font-size: 16px;
}

.widget-search .stylish-input-group input.form-control:-moz-placeholder,
.widget-search .stylish-input-group textarea.form-control:-moz-placeholder {
  color: #a2a0a0;
  font-size: 16px;
}

.widget-search .stylish-input-group input.form-control:-ms-input-placeholder,
.widget-search .stylish-input-group textarea.form-control:-ms-input-placeholder {
  color: #a2a0a0;
  font-size: 16px;
}

/*--- Widget Article ---*/
.widget-article .list-item li {
  margin-bottom: 12px;
  font-family: "Poppins", sans-serif;
}

.widget-article .list-item li a {
  color: #444444;
  transition: all 0.3s ease-in-out;
}

.widget-article .list-item li a:hover {
  color: #5a49f8;
}

.widget-article .list-item li:last-child {
  margin-bottom: 0;
}

/*========================================================================
04. Template Style
=========================================================================*/
/*--------------------------------------
	4.1 404 Error
---------------------------------------*/
.error-page-wrap {
  padding: 120px 0;
}

@media only screen and (max-width: 991px) {
  .error-page-wrap {
    padding: 105px 0;
  }
}

@media only screen and (max-width: 767px) {
  .error-page-wrap {
    padding: 90px 0;
  }
}

@media only screen and (max-width: 575px) {
  .error-page-wrap {
    padding: 75px 0;
  }
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-page .item-figure {
  margin-bottom: 50px;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .error-page .item-figure {
    margin-bottom: 40px;
  }
}

.error-page .item-content {
  text-align: center;
}

.error-page .item-content .item-title {
  color: #111111;
  font-weight: 600;
  font-size: 46px;
}

@media only screen and (max-width: 991px) {
  .error-page .item-content .item-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .error-page .item-content .item-title {
    font-size: 36px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .error-page .item-content .item-title {
    font-size: 30px;
  }
}

.error-page .item-content p {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .error-page .item-content p {
    width: 75%;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .error-page .item-content p {
    width: 85%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .error-page .item-content p {
    width: 100%;
  }
}

.error-page .item-content .item-btn {
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  padding: 14px 32px;
  background-color: #5a49f8;
  border-radius: 4px;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.error-page .item-content .item-btn i {
  margin-right: 10px;
}

.error-page .item-content .item-btn:hover {
  background-color: #fcf306;
}

/*--------------------------------------
	4.2 About
---------------------------------------*/
.about-wrap-layout1 {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout1 .animated-shape {
    display: none;
  }
}

.about-wrap-layout1 .animated-shape li {
  position: absolute;
  z-index: 0;
  bottom: 150px;
  right: 30px;
}

.about-wrap-layout1 .compress-right-side {
  padding-left: 0;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout1 .compress-right-side {
    padding-left: 0;
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .about-wrap-layout1 .compress-right-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout1 .compress-right-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .about-wrap-layout1 .compress-right-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.about-box-layout1 {
  margin-bottom: 30px;
}

.about-box-layout1 .figure-holder {
  position: relative;
  z-index: 1;
  margin-right: 50px;
  padding: 0 0 0 30px;
}

@media only screen and (max-width: 991px) {
  .about-box-layout1 .figure-holder {
    margin-right: 0;
  }
}

.about-box-layout1 .figure-holder .animated-bg {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 50px;
  top: 0;
}

.about-box-layout1 .figure-holder .animated-bg svg {
  margin: 0 auto;
}

@media only screen and (max-width: 1199px) {
  .about-box-layout1 .figure-holder .animated-bg {
    display: none;
  }
}

.about-box-layout1 .figure-holder .animated-figure {
  text-align: right;
}

@media only screen and (max-width: 991px) {
  .about-box-layout1 .figure-holder .animated-figure {
    text-align: center;
  }
}

.about-box-layout1 .content-holder .list-item {
  margin-bottom: 50px;
}

.about-box-layout1 .content-holder .list-item li {
  position: relative;
  z-index: 1;
  padding-left: 30px;
  margin-bottom: 10px;
}

.about-box-layout1 .content-holder .list-item li:before {
  position: absolute;
  z-index: 1;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 0;
  top: 0;
  color: #5a49f8;
}

.about-box-layout1 .content-holder .list-item li:last-child {
  margin-bottom: 0;
}

.about-box-layout1 .content-holder .item-title {
  font-weight: 700;
  margin-bottom: 15px;
}

.about-wrap-layout2 {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout2 .animated-shape-big {
    display: none;
  }
}

.about-wrap-layout2 .animated-shape-big li {
  position: absolute;
  z-index: 1;
  bottom: 150px;
  right: 30px;
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout2 .animated-shape-small {
    display: none;
  }
}

.about-wrap-layout2 .animated-shape-small li {
  position: absolute;
  z-index: 1;
}

.about-wrap-layout2 .animated-shape-small li:nth-child(1n) {
  top: 10%;
  right: 20%;
  animation: shape_one 30s alternate infinite linear;
}

.about-wrap-layout2 .animated-shape-small li:nth-child(2n) {
  top: 50%;
  right: 10%;
  animation: shape_two 30s alternate infinite linear;
}

.about-wrap-layout2 .animated-shape-small li:nth-child(3n) {
  top: 70%;
  right: 30%;
  animation: shape_three 30s alternate infinite linear;
}

@keyframes shape_one {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -100px) rotate(36deg);
  }
  50% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_two {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  10% {
    transform: translate(53px, -80px) rotate(36deg);
  }
  40% {
    transform: translate(161px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 112px) rotate(108deg);
  }
  70% {
    transform: translate(-60px, 92px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_three {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(90px, -36px) rotate(36deg);
  }
  40% {
    transform: translate(101px, 32px) rotate(72deg);
  }
  50% {
    transform: translate(103px, 102px) rotate(108deg);
  }
  80% {
    transform: translate(-76px, 62px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

.about-wrap-layout2 .compress-right-side {
  padding-left: 0;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout2 .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .about-wrap-layout2 .compress-right-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout2 .compress-right-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .about-wrap-layout2 .compress-right-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.about-box-layout2 {
  margin-bottom: 30px;
}

.about-box-layout2 .figure-holder {
  position: relative;
  z-index: 1;
}

.about-box-layout2 .figure-holder .animated-bg {
  position: absolute;
  z-index: 0;
  right: 0;
  left: 0;
  top: 0;
}

.about-box-layout2 .figure-holder .animated-bg svg {
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .about-box-layout2 .figure-holder .animated-bg {
    display: none;
  }
}

.about-box-layout2 .figure-holder .animated-figure .mask-image {
  mask-image: url(../../media/svg/svg2.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center center;
  position: relative;
  display: block;
}

.about-box-layout2 .content-holder .section-title {
  color: #5a49f8;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 500;
}

.about-box-layout2 .content-holder .item-title {
  font-weight: 600;
  margin-bottom: 10px;
}

.about-box-layout2 .content-holder .sub-title {
  font-size: 22px;
  color: #444444;
  font-style: italic;
  margin-bottom: 5px;
}

.about-box-layout2 .content-holder p {
  margin-bottom: 50px;
}

.about-wrap-layout3 {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout3 .animated-shape {
    display: none;
  }
}

.about-wrap-layout3 .animated-shape ul li {
  position: absolute;
  z-index: 0;
  top: 20%;
  right: 0;
}

.about-wrap-layout3 .compress-right-side {
  padding-left: 0;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout3 .compress-right-side {
    padding-left: 0;
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .about-wrap-layout3 .compress-right-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout3 .compress-right-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .about-wrap-layout3 .compress-right-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .about-box-layout3 {
    text-align: center;
  }
}

.about-box-layout3 .figure-holder {
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 991px) {
  .about-box-layout3 .figure-holder {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .about-box-layout3 .figure-holder {
    margin-bottom: 30px;
  }
}

.about-box-layout3 .figure-holder .animated-bg {
  position: absolute;
  z-index: -1;
  right: 115px;
  top: 55%;
  transform: translateY(-50%);
}

.about-box-layout3 .figure-holder .animated-bg svg {
  margin: 0 auto;
}

@media only screen and (max-width: 1440px) {
  .about-box-layout3 .figure-holder .animated-bg svg {
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 1199px) {
  .about-box-layout3 .figure-holder .animated-bg svg {
    transform: scale(0.5);
  }
}

@media only screen and (max-width: 991px) {
  .about-box-layout3 .figure-holder .animated-bg svg {
    transform: scale(0.9);
  }
}

@media only screen and (max-width: 1440px) {
  .about-box-layout3 .figure-holder .animated-bg {
    right: -60px;
  }
}

@media only screen and (max-width: 1199px) {
  .about-box-layout3 .figure-holder .animated-bg {
    right: -40%;
  }
}

@media only screen and (max-width: 991px) {
  .about-box-layout3 .figure-holder .animated-bg {
    right: -2%;
    top: 53%;
  }
}

@media only screen and (max-width: 767px) {
  .about-box-layout3 .figure-holder .animated-bg {
    display: none;
  }
}

.about-box-layout3 .figure-holder .animated-figure {
  transform: translateX(-130px);
}

@media only screen and (max-width: 1440px) {
  .about-box-layout3 .figure-holder .animated-figure {
    transform: translateX(-70px);
  }
}

@media only screen and (max-width: 1199px) {
  .about-box-layout3 .figure-holder .animated-figure {
    transform: translateX(-60px);
  }
}

.about-box-layout3 .figure-holder .animated-figure .play-btn {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.about-box-layout3 .figure-holder .animated-figure .play-btn .item-icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  margin-right: 10px;
  color: #ffffff;
  background-color: #5a49f8;
  font-size: 30px;
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .about-box-layout3 .figure-holder .animated-figure .play-btn .item-icon {
    height: 90px;
    width: 90px;
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .about-box-layout3 .figure-holder .animated-figure .play-btn .item-icon {
    height: 70px;
    width: 70px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .about-box-layout3 .figure-holder .animated-figure .play-btn .item-icon {
    height: 60px;
    width: 60px;
    font-size: 20px;
  }
}

.about-box-layout3 .figure-holder .animated-figure .play-btn .item-icon:before {
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, #a95df1 0%, #750ed5 100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.about-box-layout3 .figure-holder .animated-figure .play-btn .item-icon i {
  margin-left: 5px;
}

.about-box-layout3 .figure-holder .animated-figure .play-btn:hover .item-icon:before {
  opacity: 1;
  visibility: visible;
}

.about-box-layout3 .item-content .sub-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #5a49f8;
}

.about-box-layout3 .item-content .item-title {
  font-weight: 700;
  margin-bottom: 15px;
}

.about-box-layout3 .item-content p {
  margin-bottom: 50px;
}

.about-box-layout3 .mask-image {
  mask-image: url(../../media/svg/svg1.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center center;
  position: relative;
  display: block;
}

.about-wrap-layout4 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 120px 0 90px;
}

@media only screen and (max-width: 991px) {
  .about-wrap-layout4 {
    padding: 105px 0 75px;
  }
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout4 {
    padding: 90px 0 60px;
  }
}

@media only screen and (max-width: 575px) {
  .about-wrap-layout4 {
    padding: 75px 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout4 .animated-buble {
    display: none;
  }
}

.about-wrap-layout4 .animated-buble li {
  position: absolute;
  z-index: -1;
}

.about-wrap-layout4 .animated-buble li:nth-child(1n) {
  top: -230px;
  left: -220px;
  right: inherit;
  bottom: inherit;
}

.about-wrap-layout4 .animated-buble li:nth-child(2n) {
  top: -74px;
  left: 145px;
  right: inherit;
  bottom: inherit;
}

.about-wrap-layout4 .animated-buble li:nth-child(3n) {
  top: -130px;
  left: 37%;
  right: inherit;
  bottom: inherit;
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout4 .animated-shape {
    display: none;
  }
}

.about-wrap-layout4 .animated-shape li {
  position: absolute;
  z-index: 1;
  bottom: 150px;
  right: 30px;
}

.about-wrap-layout4 .compress-right-side {
  padding-left: 0;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout4 .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .about-wrap-layout4 .compress-right-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout4 .compress-right-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .about-wrap-layout4 .compress-right-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout4 .animated-figure {
    display: none;
  }
}

.about-wrap-layout4 .animated-figure li {
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
}

.about-wrap-layout4 .about-inner-wrap-layout4 {
  margin-top: 100px;
}

@media only screen and (max-width: 991px) {
  .about-wrap-layout4 .about-inner-wrap-layout4 {
    margin-top: 0;
  }
}

.about-box-layout4 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .about-box-layout4 {
    text-align: center;
  }
}

.about-box-layout4 .figure-holder {
  text-align: center;
  padding: 0 50px 0 0;
}

@media only screen and (max-width: 1199px) {
  .about-box-layout4 .figure-holder {
    padding: 0 30px 0 0;
  }
}

@media only screen and (max-width: 991px) {
  .about-box-layout4 .figure-holder {
    padding: 0 10%;
  }
}

.about-box-layout4 .content-holder .item-sub-title {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 20px;
}

.about-box-layout4 .content-holder .item-title {
  font-weight: 600;
  margin-bottom: 15px;
  color: #ffffff;
}

.about-box-layout4 .content-holder p {
  color: #ffffff;
  width: 85%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .about-box-layout4 .content-holder p {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .about-box-layout4 .content-holder p {
    width: 100%;
  }
}

.about-box-layout5 {
  margin-bottom: 30px;
}

.about-box-layout5 .figure-holder {
  position: relative;
  z-index: 1;
  margin-right: 50px;
}

.about-box-layout5 .figure-holder .animated-figure {
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .about-box-layout5 .figure-holder {
    margin-right: 0;
  }
}

.about-box-layout5 .content-holder .item-title {
  font-weight: 700;
  margin-bottom: 15px;
}

.about-box-layout5 .content-holder p {
  margin-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .about-wrap-layout6 .mb--100 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout6 .mb--100 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .about-wrap-layout6 .mb--100 {
    margin-bottom: 20px;
  }
}

.about-box-layout6 {
  margin-bottom: 30px;
}

.about-box-layout6 .figure-holder {
  position: relative;
  z-index: 1;
  margin-right: 50px;
}

.about-box-layout6 .figure-holder .animated-figure {
  text-align: center;
}

.about-box-layout6 .content-holder .item-title {
  font-weight: 700;
  margin-bottom: 15px;
}

.about-box-layout6 .content-holder p {
  margin-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .about-box-layout6 .content-holder p {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .about-box-layout6 .content-holder p {
    margin-bottom: 30px;
  }
}

.about-wrap-layout7 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 110px 0 80px;
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout7 {
    padding: 120px 0 60px;
  }
}

@media only screen and (max-width: 991px) {
  .about-wrap-layout7 {
    padding: 105px 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout7 {
    padding: 90px 0 23px;
  }
}

@media only screen and (max-width: 575px) {
  .about-wrap-layout7 {
    padding: 75px 0 8px;
  }
}

.about-wrap-layout7 .animated-shape li {
  position: absolute;
  z-index: 0;
  bottom: 150px;
  right: 30px;
}

.about-wrap-layout7 .compress-right-side {
  padding-left: 15px;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout7 .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .about-wrap-layout7 .compress-right-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout7 .compress-right-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .about-wrap-layout7 .compress-right-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.about-box-layout7 {
  margin-bottom: 30px;
}

.about-box-layout7 .figure-holder {
  position: relative;
  z-index: 1;
  margin-right: 50px;
}

@media only screen and (max-width: 1199px) {
  .about-box-layout7 .figure-holder {
    margin-right: 0;
  }
}

.about-box-layout7 .figure-holder .animated-bg {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 50px;
  top: 0;
}

.about-box-layout7 .figure-holder .animated-bg svg {
  margin: 0 auto;
}

.about-box-layout7 .figure-holder .animated-figure {
  text-align: right;
}

@media only screen and (max-width: 1199px) {
  .about-box-layout7 .figure-holder .animated-figure {
    text-align: center;
    padding: 0 30px;
  }
}

.about-box-layout7 .content-holder .item-title {
  font-weight: 700;
  margin-bottom: 15px;
}

.about-wrap-layout8 {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.about-wrap-layout8:before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  width: 50%;
  height: 100%;
  right: 0;
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout8:before {
    width: 100%;
  }
}

.about-wrap-layout8 .animated-buble li {
  position: absolute;
  z-index: 0;
}

.about-wrap-layout8 .animated-buble li:nth-child(1n) {
  top: 50%;
  transform: translateY(-50%);
  right: -14%;
  left: inherit;
  bottom: inherit;
}

.about-wrap-layout8 .animated-buble li:nth-child(2n) {
  top: 50%;
  transform: translateY(-50%);
  right: -11%;
  left: inherit;
  bottom: inherit;
}

.about-wrap-layout8 .animated-buble li:nth-child(3n) {
  top: 50%;
  transform: translateY(-50%);
  right: -10%;
  left: inherit;
  bottom: inherit;
}

.about-wrap-layout8 .compress-right-side {
  padding-left: 60px;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout8 .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .about-wrap-layout8 .compress-right-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout8 .compress-right-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .about-wrap-layout8 .compress-right-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.about-box-layout8 .figure-holder .play-btn {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.about-box-layout8 .figure-holder .play-btn .item-icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  margin-right: 10px;
  color: #ffffff;
  background-color: #5a49f8;
  font-size: 30px;
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .about-box-layout8 .figure-holder .play-btn .item-icon {
    height: 80px;
    width: 80px;
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .about-box-layout8 .figure-holder .play-btn .item-icon {
    height: 60px;
    width: 60px;
    font-size: 20px;
  }
}

.about-box-layout8 .figure-holder .play-btn .item-icon:before {
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, #a95df1 0%, #750ed5 100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.about-box-layout8 .figure-holder .play-btn .item-icon i {
  margin-left: 5px;
}

.about-box-layout8 .figure-holder .play-btn:hover .item-icon:before {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 1199px) {
  .about-box-layout8 .content-holder {
    padding: 113px 0 117px;
  }
}

@media only screen and (max-width: 991px) {
  .about-box-layout8 .content-holder {
    padding: 83px 0 87px;
  }
}

@media only screen and (max-width: 767px) {
  .about-box-layout8 .content-holder {
    padding: 68px 0 72px;
  }
}

@media only screen and (max-width: 575px) {
  .about-box-layout8 .content-holder {
    padding: 53px 0 57px;
  }
}

.about-box-layout8 .content-holder .item-title {
  font-weight: 700;
  margin-bottom: 10px;
  color: #ffffff;
}

.about-box-layout8 .content-holder p {
  color: #ffffff;
  margin-bottom: 30px;
}

.about-box-layout8 .content-holder .list-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
}

.about-box-layout8 .content-holder .list-item li {
  color: #ffffff;
  width: 50%;
  position: relative;
  z-index: 1;
  padding-left: 30px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .about-box-layout8 .content-holder .list-item li {
    width: 100%;
  }
}

.about-box-layout8 .content-holder .list-item li:before {
  position: absolute;
  z-index: 1;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
  color: #5a49f8;
  top: 5px;
  font-size: 10px;
  left: 0;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;
}

.about-box-layout9 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .about-box-layout9 {
    text-align: center;
  }
}

@media only screen and (max-width: 991px) {
  .about-box-layout9 .section-heading.heading-layout3 {
    text-align: center;
  }
}

.about-box-layout9 .content-holder {
  padding-right: 100px;
}

@media only screen and (max-width: 991px) {
  .about-box-layout9 .content-holder {
    padding-right: 0px;
    margin-bottom: 50px;
  }
}

.about-box-layout9 .item-title {
  font-weight: 700;
  margin-bottom: 15px;
}

.about-wrap-layout10 {
  overflow: hidden;
  background-color: #ffffff;
}

.about-box-layout10 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .about-box-layout10 .figure-holder {
    text-align: center;
    padding: 0 30px;
  }
}

.about-box-layout10 .content-holder .section-title {
  color: #5a49f8;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 500;
}

.about-box-layout10 .content-holder .item-title {
  font-weight: 600;
  margin-bottom: 30px;
}

.about-box-layout10 .content-holder .sub-title {
  font-size: 22px;
  color: #444444;
  font-style: italic;
  margin-bottom: 5px;
}

.about-box-layout10 .content-holder p {
  margin-bottom: 25px;
}

.about-box-layout10 .content-holder .btn-fill {
  margin-top: 20px;
  padding: 10px 41px;
}

.about-wrap-layout11 {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout11 .animated-shape {
    display: none;
  }
}

.about-wrap-layout11 .animated-shape li {
  position: absolute;
  z-index: 0;
  bottom: 150px;
  right: 30px;
}

.about-wrap-layout11 .compress-right-side {
  padding-left: 0;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout11 .compress-right-side {
    padding-left: 0;
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .about-wrap-layout11 .compress-right-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .about-wrap-layout11 .compress-right-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .about-wrap-layout11 .compress-right-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.about-box-layout11 {
  margin-bottom: 15px;
}

.about-box-layout11 .figure-holder {
  display: flex;
  align-items: center;
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.about-box-layout11 .figure-holder .mask-text {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 180px;
  width: 180px;
  background: linear-gradient(245deg, #7a64f2 0%, #5a49f8 100%);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.about-box-layout11 .figure-holder .mask-text .mask-title {
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 50px;
  font-weight: 500;
}

.about-box-layout11 .figure-holder .mask-text p {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 1.3;
}

.about-box-layout11 .figure-holder .left-holder {
  flex: 1;
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.about-box-layout11 .figure-holder .right-holder {
  flex: 1;
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.about-box-layout11 .figure-holder img {
  margin-bottom: 15px;
  border-radius: 5px;
}

.about-box-layout12 {
  margin-bottom: 30px;
}

.about-box-layout12 .content-holder .list-item {
  margin-bottom: 30px;
}

.about-box-layout12 .content-holder .list-item li {
  position: relative;
  z-index: 1;
  padding-left: 30px;
  margin-bottom: 10px;
}

.about-box-layout12 .content-holder .list-item li:before {
  position: absolute;
  z-index: 1;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 0;
  top: 0;
  color: #5a49f8;
}

.about-box-layout12 .content-holder .list-item li:last-child {
  margin-bottom: 0;
}

.about-box-layout12 .content-holder .sub-title {
  color: #5a49f8;
  font-size: 18px;
}

.about-box-layout12 .content-holder .item-title {
  font-weight: 700;
  margin-bottom: 15px;
}

/*--------------------------------------
	4.3 Banner
---------------------------------------*/
/*--- Main Banner Layout One ---*/
.main-banner-wrap-layout1 {
  padding: 170px 0 120px;
  position: relative;
  z-index: 0;
  height: 100vh;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout1 {
    height: 100%;
    padding: 55px 0 105px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout1 {
    padding: 50px 0 90px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout1 {
    padding: 45px 0 75px;
  }
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout1 .animated-figure {
    display: none;
  }
}

.main-banner-wrap-layout1 .animated-figure li {
  position: absolute;
  z-index: -1;
}

.main-banner-wrap-layout1 .animated-figure li:first-child {
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  right: inherit;
}

.main-banner-wrap-layout1 .animated-figure li:last-child {
  top: 0;
  right: 0;
  left: inherit;
  transform: translateY(0);
}

.main-banner-wrap-layout1 .compress-left-side {
  padding-right: 50px;
  padding-left: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout1 .compress-left-side {
    padding-right: 0;
    padding-left: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout1 .compress-left-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout1 .compress-left-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout1 .compress-left-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.main-banner-wrap-layout1 .compress-right-side {
  padding-left: 50px;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout1 .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout1 .compress-right-side {
    padding-left: calc(calc(100% - 690px)/2);
    padding-right: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout1 .compress-right-side {
    padding-left: calc(calc(100% - 510px)/2);
    padding-right: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout1 .compress-right-side {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.main-banner-box-layout1 {
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout1 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout1 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout1 {
    margin-top: 30px;
    text-align: center;
  }
}

.main-banner-box-layout1 .item-figure {
  margin-top: 50px;
  animation: up_down 15s infinite;
  animation-delay: 4s;
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout1 .item-figure {
    animation: inherit;
  }
}

.main-banner-box-layout1 .item-sub-title {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 20px;
  letter-spacing: 4px;
}

.main-banner-box-layout1 .item-main-title {
  font-size: 50px;
  color: #ffffff;
  font-weight: 600;
  line-height: 65px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout1 .item-main-title {
    font-size: 42px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout1 .item-main-title {
    font-size: 38px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout1 .item-main-title {
    font-size: 34px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout1 .item-main-title {
    font-size: 30px;
    line-height: 45px;
  }
}

.main-banner-box-layout1 p {
  color: #ffffff;
  width: 80%;
  margin-bottom: 50px;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout1 p {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout1 p {
    width: 100%;
  }
}

.main-banner-box-layout1 .banner-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout1 .banner-btns {
    display: block;
  }
}

.main-banner-box-layout1 .banner-btns a {
  margin-bottom: 15px;
  margin-right: 30px;
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout1 .banner-btns a {
    margin-right: 0;
  }
}

.main-banner-box-layout1 .banner-btns a:last-child {
  margin-right: 0;
}

.main-banner-box-layout1 .banner-btns .item-btn {
  padding: 10px 29px;
}

.main-banner-box-layout1 .banner-btns .play-btn {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout1 .banner-btns .play-btn {
    justify-content: center;
  }
}

.main-banner-box-layout1 .banner-btns .play-btn .item-icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin-right: 10px;
  color: #5a49f8;
  transition: all 0.5s ease-in-out;
}

.main-banner-box-layout1 .banner-btns .play-btn .item-icon:before {
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, #a95df1 0%, #750ed5 100%);
  color: #5a49f8;
  font-size: 14px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.main-banner-box-layout1 .banner-btns .play-btn .item-icon:after {
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: #ffffff;
  color: #5a49f8;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
}

.main-banner-box-layout1 .banner-btns .play-btn .item-icon i {
  margin-left: 5px;
}

.main-banner-box-layout1 .banner-btns .play-btn span {
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.main-banner-box-layout1 .banner-btns .play-btn:hover .item-icon {
  color: #ffffff;
}

.main-banner-box-layout1 .banner-btns .play-btn:hover .item-icon:before {
  opacity: 1;
}

.main-banner-box-layout1 .banner-btns .play-btn:hover .item-icon:after {
  opacity: 0;
}

@keyframes up_down {
  0% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-30px) translateX(0);
  }
  75% {
    transform: translateY(30px) translateX(0);
  }
}

/*--- Main Banner Layout Two ---*/
.main-banner-wrap-layout2 {
  padding: 120px 0 90px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  height: 100vh;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout2 {
    height: 100%;
    padding: 59px 0 105px;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout2 {
    padding: 46px 0 0;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout2 {
    padding: 31px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout2 .animated-figure-wrap1 {
    display: none;
  }
}

.main-banner-wrap-layout2 .animated-figure-wrap1 li {
  position: absolute;
  z-index: -1;
}

.main-banner-wrap-layout2 .animated-figure-wrap1 li:nth-child(1n) {
  top: 0;
  transform: translateX(0px) translateY(0px);
  left: 0;
  right: inherit;
}

.main-banner-wrap-layout2 .animated-figure-wrap1 li:nth-child(2n) {
  top: 92%;
  transform: translateX(0px) translateY(-50%);
  left: -70px;
  right: inherit;
}

.main-banner-wrap-layout2 .animated-figure-wrap1 li:nth-child(3n) {
  top: 0;
  right: 0;
  left: inherit;
  transform: translateX(0) translateY(0);
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout2 .animated-figure-wrap2 {
    display: none;
  }
}

.main-banner-wrap-layout2 .animated-figure-wrap2 li {
  position: absolute;
  z-index: -1;
}

.main-banner-wrap-layout2 .animated-figure-wrap2 li:nth-child(1n) {
  top: 0;
  transform: translateX(0) translateY(0);
  left: 0;
}

.main-banner-wrap-layout2 .animated-figure-wrap2 li:nth-child(2n) {
  top: 90%;
  transform: translateX(0) translateY(-50%);
  left: 0;
}

.main-banner-wrap-layout2 .animated-figure-wrap2 li:nth-child(3n) {
  top: 90%;
  transform: translateX(0) translateY(-50%);
  left: 0;
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout2 .animated-shape-small {
    display: none;
  }
}

.main-banner-wrap-layout2 .animated-shape-small li {
  position: absolute;
  z-index: 1;
}

.main-banner-wrap-layout2 .animated-shape-small li:nth-child(1n) {
  top: 20%;
  right: 70%;
  animation: shape_one 100s alternate infinite linear;
}

.main-banner-wrap-layout2 .animated-shape-small li:nth-child(2n) {
  top: 30%;
  right: 60%;
  animation: shape_two 100s alternate infinite linear;
}

.main-banner-wrap-layout2 .animated-shape-small li:nth-child(3n) {
  top: 20%;
  right: 50%;
  animation: shape_three 100s alternate infinite linear;
}

.main-banner-wrap-layout2 .animated-shape-small li:nth-child(4n) {
  top: 70%;
  right: 70%;
  animation: shape_four 100s alternate infinite linear;
}

.main-banner-wrap-layout2 .animated-shape-small li:nth-child(5n) {
  top: 90%;
  right: 60%;
  animation: shape_five 100s alternate infinite linear;
}

.main-banner-wrap-layout2 .animated-shape-small li:nth-child(6n) {
  top: 90%;
  right: 50%;
  animation: shape_six 100s alternate infinite linear;
}

@keyframes shape_one {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -100px) rotate(36deg);
  }
  50% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_two {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  10% {
    transform: translate(53px, -80px) rotate(36deg);
  }
  40% {
    transform: translate(161px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 112px) rotate(108deg);
  }
  70% {
    transform: translate(-60px, 92px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_three {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(90px, -36px) rotate(36deg);
  }
  40% {
    transform: translate(101px, 32px) rotate(72deg);
  }
  50% {
    transform: translate(103px, 102px) rotate(108deg);
  }
  80% {
    transform: translate(-76px, 62px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_four {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  10% {
    transform: translate(80px, -36px) rotate(36deg);
  }
  40% {
    transform: translate(191px, 72px) rotate(72deg);
  }
  50% {
    transform: translate(93px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-56px, 92px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_five {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(80px, -36px) rotate(36deg);
  }
  40% {
    transform: translate(141px, -92px) rotate(72deg);
  }
  60% {
    transform: translate(153px, 92px) rotate(108deg);
  }
  80% {
    transform: translate(-99px, 62px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_six {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(95px, -58px) rotate(36deg);
  }
  30% {
    transform: translate(81px, 82px) rotate(72deg);
  }
  60% {
    transform: translate(53px, 152px) rotate(108deg);
  }
  70% {
    transform: translate(-56px, 32px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

.main-banner-box-layout2 {
  margin-top: 46px;
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout2 {
    text-align: center;
  }
}

.main-banner-box-layout2 .item-sub-title {
  color: #5a49f8;
  margin-bottom: 5px;
  font-size: 22px;
  letter-spacing: 4px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.main-banner-box-layout2 .item-main-title {
  font-size: 60px;
  color: #000000;
  font-weight: 700;
  line-height: 80px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout2 .item-main-title {
    font-size: 48px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout2 .item-main-title {
    font-size: 40px;
    line-height: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout2 .item-main-title {
    font-size: 36px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout2 .item-main-title {
    font-size: 30px;
    line-height: 45px;
  }
}

.main-banner-box-layout2 p {
  color: #000000;
  width: 80%;
  margin-bottom: 50px;
  font-size: 18px;
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout2 p {
    margin-left: auto;
    margin-right: auto;
  }
}

.main-banner-box-layout2 .banner-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout2 .banner-btns {
    justify-content: center;
  }
}

@media only screen and (max-width: 479px) {
  .main-banner-box-layout2 .banner-btns {
    flex-direction: column;
  }
}

.main-banner-box-layout2 .banner-btns .item-btn {
  margin-right: 30px;
}

.main-banner-box-layout2 .banner-btns .item-btn:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 479px) {
  .main-banner-box-layout2 .banner-btns .item-btn {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.main-banner-box-layout2 .banner-btns .btn-ghost:hover {
  background-color: #750ed5;
  border: 2px solid #750ed5;
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout2 .item-figure {
    padding: 0 10%;
  }
}

/*--- Main Banner Layout Three ---*/
.main-banner-wrap-layout3 {
  padding: 190px 0 106px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout3 {
    padding: 150px 0 0;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout3 {
    padding: 105px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout3 {
    padding: 90px 0 0;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout3 {
    padding: 75px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout3 .animated-figure {
    display: none;
  }
}

.main-banner-wrap-layout3 .animated-figure ul li {
  position: absolute;
  z-index: -1;
}

.main-banner-wrap-layout3 .animated-figure ul li:nth-child(1n) {
  top: -30px;
  left: inherit;
  bottom: inherit;
  right: 12%;
}

@media only screen and (max-width: 1579px) {
  .main-banner-wrap-layout3 .animated-figure ul li:nth-child(1n) {
    right: 5%;
  }
}

@media only screen and (max-width: 1399px) {
  .main-banner-wrap-layout3 .animated-figure ul li:nth-child(1n) {
    right: 2%;
  }
}

@media only screen and (max-width: 1200px) {
  .main-banner-wrap-layout3 .animated-figure ul li:nth-child(1n) {
    right: 0;
  }
}

.main-banner-wrap-layout3 .animated-figure ul li:nth-child(2n) {
  bottom: 200px;
  left: -300px;
  top: inherit;
  right: inherit;
}

.main-banner-wrap-layout3 .animated-figure ul li:nth-child(3n) {
  bottom: 250px;
  left: -250px;
  top: inherit;
  right: inherit;
}

.main-banner-wrap-layout3 .animated-figure ul li:nth-child(4n) {
  bottom: 300px;
  left: -200px;
  top: inherit;
  right: inherit;
}

.main-banner-wrap-layout3 .compress-right-side {
  padding-left: 150px;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout3 .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout3 .compress-right-side {
    padding-left: calc(calc(100% - 690px)/2);
    padding-right: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout3 .compress-right-side {
    padding-left: calc(calc(100% - 510px)/2);
    padding-right: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout3 .compress-right-side {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout3 {
    text-align: center;
  }
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout3 .item-figure {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout3 .item-figure {
    padding: 0 10%;
    margin-bottom: 30px;
  }
}

.main-banner-box-layout3 .item-main-title {
  font-size: 50px;
  color: #111111;
  font-weight: 600;
  line-height: 65px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout3 .item-main-title {
    font-size: 46px;
    line-height: 60px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout3 .item-main-title {
    font-size: 40px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout3 .item-main-title {
    font-size: 34px;
    line-height: 49px;
    width: 90%;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout3 .item-main-title {
    font-size: 30px;
    line-height: 45px;
    width: 100%;
  }
}

.main-banner-box-layout3 p {
  width: 80%;
  margin-bottom: 50px;
  font-size: 18px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout3 p {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout3 p {
    width: 90%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout3 p {
    width: 100%;
  }
}

.main-banner-box-layout3 .banner-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout3 .banner-btns .item-btn {
    margin-left: auto;
    margin-right: auto;
  }
}

/*--- Main Banner Layout Four ---*/
.main-banner-wrap-layout4 {
  padding: 200px 0 42px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout4 {
    padding: 200px 0 0;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout4 {
    padding: 100px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout4 {
    padding: 85px 0 0;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout4 {
    padding: 70px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout4 .animated-figure {
    display: none;
  }
}

.main-banner-wrap-layout4 .animated-figure ul li {
  position: absolute;
  z-index: -1;
  top: -95px;
  right: -7vw;
}

@media only screen and (max-width: 1879px) {
  .main-banner-wrap-layout4 .animated-figure ul li {
    right: -10vw;
  }
}

@media only screen and (max-width: 1600px) {
  .main-banner-wrap-layout4 .animated-figure ul li {
    right: -15vw;
  }
}

@media only screen and (max-width: 1400px) {
  .main-banner-wrap-layout4 .animated-figure ul li {
    right: -20vw;
  }
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout4 .animated-figure ul li {
    top: -30%;
    right: 0;
  }
}

.main-banner-box-layout4 {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout4 {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout4.ml-5 {
    margin-left: auto !important;
    margin-right: auto;
  }
}

.main-banner-box-layout4 .item-main-title {
  font-size: 50px;
  color: #000000;
  font-weight: 700;
  line-height: 65px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout4 .item-main-title {
    font-size: 42px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout4 .item-main-title {
    font-size: 38px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout4 .item-main-title {
    font-size: 34px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout4 .item-main-title {
    font-size: 32px;
    line-height: 45px;
    text-align: center;
  }
}

.main-banner-box-layout4 p {
  width: 90%;
  margin-bottom: 50px;
  font-size: 18px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout4 p {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout4 p {
    width: 100%;
  }
}

.main-banner-box-layout4 .banner-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -10px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout4 .banner-btns {
    justify-content: center;
  }
}

@media only screen and (max-width: 479px) {
  .main-banner-box-layout4 .banner-btns {
    flex-direction: column;
  }
}

.main-banner-box-layout4 .banner-btns .item-btn {
  min-width: 180px;
  margin-bottom: 10px;
  margin-right: 30px;
  padding: 9px 29px;
  border: 1px solid #e6e6e6;
}

@media only screen and (max-width: 479px) {
  .main-banner-box-layout4 .banner-btns .item-btn {
    margin-right: 0;
  }
}

.main-banner-box-layout4 .banner-btns .item-btn i,
.main-banner-box-layout4 .banner-btns .item-btn img {
  margin-right: 10px;
  margin-left: 0;
}

.main-banner-box-layout4 .banner-btns .item-btn:last-child {
  margin-right: 0;
}

.main-banner-box-layout4 .banner-btns .item-btn:hover {
  background-color: #5a49f8;
  border: 1px solid #5a49f8;
  color: #ffffff;
}

.main-banner-box-layout4 .banner-btns .item-btn:hover i {
  transform: translateX(0);
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout4 .item-figure {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout4 .item-figure {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout4 .item-figure {
    padding: 0 60px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout4 .item-figure .inner-animated-figure {
    display: none;
  }
}

.main-banner-box-layout4 .item-figure .inner-animated-figure ul li {
  position: absolute;
  z-index: 2;
}

.main-banner-box-layout4 .item-figure .inner-animated-figure ul li:nth-child(1n) {
  left: -100px;
  right: inherit;
  top: 155px;
}

.main-banner-box-layout4 .item-figure .inner-animated-figure ul li:nth-child(2n) {
  right: inherit;
  left: -75px;
  top: 70%;
  transform: translateY(-50px);
}

.main-banner-box-layout4 .item-figure .inner-animated-figure ul li:nth-child(3n) {
  left: inherit;
  right: -80px;
  top: 42%;
  transform: translateY(-50px);
}

/*--- Main Banner Layout Five ---*/
.main-banner-wrap-layout5 {
  padding: 120px 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout5 {
    padding: 42px 0 105px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout5 {
    padding: 38px 0 90px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout5 {
    padding: 25px 0 75px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout5 .animated-buble {
    display: none;
  }
}

.main-banner-wrap-layout5 .animated-buble li {
  position: absolute;
  z-index: -1;
}

.main-banner-wrap-layout5 .animated-buble li:nth-child(1n) {
  top: -230px;
  left: -220px;
  right: inherit;
  bottom: inherit;
}

.main-banner-wrap-layout5 .animated-buble li:nth-child(2n) {
  top: -74px;
  left: 145px;
  right: inherit;
  bottom: inherit;
}

.main-banner-wrap-layout5 .animated-buble li:nth-child(3n) {
  bottom: -70px;
  left: 30%;
  right: inherit;
  top: inherit;
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout5 .animated-figure {
    display: none;
  }
}

.main-banner-wrap-layout5 .animated-figure li {
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout5 .animated-shape-small {
    display: none;
  }
}

.main-banner-wrap-layout5 .animated-shape-small ul li {
  position: absolute;
  z-index: 0;
}

.main-banner-wrap-layout5 .animated-shape-small ul li:nth-child(1n) {
  top: 30%;
  right: 60%;
  animation: shape_one 30s alternate infinite linear;
}

.main-banner-wrap-layout5 .animated-shape-small ul li:nth-child(2n) {
  top: 10%;
  right: 40%;
  animation: shape_two 30s alternate infinite linear;
}

.main-banner-wrap-layout5 .animated-shape-small ul li:nth-child(3n) {
  top: 15%;
  right: 20%;
  animation: shape_three 30s alternate infinite linear;
}

.main-banner-wrap-layout5 .animated-shape-small ul li:nth-child(4n) {
  top: 20%;
  right: 5%;
  animation: shape_four 30s alternate infinite linear;
}

.main-banner-wrap-layout5 .animated-shape-small ul li:nth-child(5n) {
  top: 70%;
  right: 60%;
  animation: shape_five 30s alternate infinite linear;
}

.main-banner-wrap-layout5 .animated-shape-small ul li:nth-child(6n) {
  top: 80%;
  right: 50%;
  animation: shape_six 30s alternate infinite linear;
}

@keyframes shape_one {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -100px) rotate(36deg);
  }
  50% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_two {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  10% {
    transform: translate(53px, -80px) rotate(36deg);
  }
  40% {
    transform: translate(161px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 112px) rotate(108deg);
  }
  70% {
    transform: translate(-60px, 92px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_three {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(90px, -36px) rotate(36deg);
  }
  40% {
    transform: translate(101px, 32px) rotate(72deg);
  }
  50% {
    transform: translate(103px, 102px) rotate(108deg);
  }
  80% {
    transform: translate(-76px, 62px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_four {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  10% {
    transform: translate(80px, -36px) rotate(36deg);
  }
  40% {
    transform: translate(191px, 72px) rotate(72deg);
  }
  50% {
    transform: translate(93px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-56px, 92px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_five {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(80px, -36px) rotate(36deg);
  }
  40% {
    transform: translate(141px, -92px) rotate(72deg);
  }
  60% {
    transform: translate(153px, 92px) rotate(108deg);
  }
  80% {
    transform: translate(-99px, 62px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes shape_six {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(95px, -58px) rotate(36deg);
  }
  30% {
    transform: translate(81px, 82px) rotate(72deg);
  }
  60% {
    transform: translate(53px, 152px) rotate(108deg);
  }
  70% {
    transform: translate(-56px, 32px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

.main-banner-box-layout5 {
  margin-top: 70px;
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout5 {
    text-align: center;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout5 {
    margin-top: 40px;
  }
}

@media (min-width: 992px) and (max-width: 1579px) {
  .main-banner-box-layout5 .item-figure {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout5 .item-figure {
    padding: 0 10%;
  }
}

@media only screen and (max-width: 1579px) {
  .main-banner-box-layout5 .item-content {
    padding-left: 45px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout5 .item-content {
    padding-left: 0;
  }
}

.main-banner-box-layout5 .item-sub-title {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 20px;
  letter-spacing: 4px;
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout5 .item-sub-title {
    letter-spacing: 0;
  }
}

.main-banner-box-layout5 .item-main-title {
  font-size: 50px;
  color: #ffffff;
  font-weight: 700;
  line-height: 60px;
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout5 .item-main-title {
    font-size: 42px;
    line-height: 50px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout5 .item-main-title {
    font-size: 36px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout5 .item-main-title {
    width: 100%;
    font-size: 30px;
    line-height: 40px;
  }
}

.main-banner-box-layout5 p {
  color: #ffffff;
  width: 80%;
  margin-bottom: 50px;
  font-size: 18px;
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout5 p {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout5 p {
    width: 100%;
    margin-bottom: 40px;
  }
}

/*--- Main Banner Layout Six ---*/
.main-banner-wrap-layout6 {
  padding: 170px 0 120px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.main-banner-wrap-layout6:before {
  content: "";
  background-image: url("../../media/element/element59.png");
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 84px;
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout6 {
    height: 100%;
    padding: 55px 0 105px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout6 {
    padding: 50px 0 90px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout6 {
    padding: 45px 0 75px;
  }
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout6 .animated-figure {
    display: none;
  }
}

.main-banner-wrap-layout6 .animated-figure li {
  position: absolute;
  z-index: -1;
  top: 0;
  right: -100px;
  transform: translateY(0);
}

.main-banner-wrap-layout6 .compress-left-side {
  padding-right: 50px;
  padding-left: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout6 .compress-left-side {
    padding-right: 0;
    padding-left: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout6 .compress-left-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout6 .compress-left-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout6 .compress-left-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.main-banner-wrap-layout6 .compress-right-side {
  padding-left: 50px;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout6 .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout6 .compress-right-side {
    padding-left: calc(calc(100% - 690px)/2);
    padding-right: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout6 .compress-right-side {
    padding-left: calc(calc(100% - 510px)/2);
    padding-right: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout6 .compress-right-side {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.main-banner-box-layout6 {
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout6 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout6 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout6 {
    margin-top: 20px;
    text-align: center;
  }
}

.main-banner-box-layout6 .item-figure {
  margin-top: 50px;
  animation: up_down 15s infinite;
  animation-delay: 4s;
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout6 .item-figure {
    animation: inherit;
    margin-top: 0px;
    margin-bottom: 60px;
  }
}

.main-banner-box-layout6 .item-sub-title {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 20px;
  letter-spacing: 4px;
}

.main-banner-box-layout6 .item-main-title {
  font-size: 50px;
  color: #ffffff;
  font-weight: 600;
  line-height: 65px;
  margin-bottom: 45px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout6 .item-main-title {
    font-size: 42px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout6 .item-main-title {
    font-size: 38px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout6 .item-main-title {
    font-size: 34px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout6 .item-main-title {
    font-size: 30px;
    line-height: 45px;
  }
}

.main-banner-box-layout6 p {
  color: #ffffff;
  width: 80%;
  margin-bottom: 50px;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout6 p {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout6 p {
    width: 100%;
  }
}

.main-banner-box-layout6 .banner-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout6 .banner-btns {
    display: block;
  }
}

.main-banner-box-layout6 .banner-btns a {
  margin-bottom: 15px;
  margin-right: 30px;
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout6 .banner-btns a {
    margin-right: 0;
  }
}

.main-banner-box-layout6 .banner-btns a:last-child {
  margin-right: 0;
}

.main-banner-box-layout6 .banner-btns .item-btn {
  padding: 10px 29px;
}

.main-banner-box-layout6 .banner-btns .play-btn {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout6 .banner-btns .play-btn {
    justify-content: center;
  }
}

.main-banner-box-layout6 .banner-btns .play-btn .item-icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin-right: 10px;
  color: #5a49f8;
  transition: all 0.5s ease-in-out;
}

.main-banner-box-layout6 .banner-btns .play-btn .item-icon:before {
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, #a95df1 0%, #750ed5 100%);
  color: #5a49f8;
  font-size: 14px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.main-banner-box-layout6 .banner-btns .play-btn .item-icon:after {
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: #ffffff;
  color: #5a49f8;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
}

.main-banner-box-layout6 .banner-btns .play-btn .item-icon i {
  margin-left: 5px;
}

.main-banner-box-layout6 .banner-btns .play-btn span {
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.main-banner-box-layout6 .banner-btns .play-btn:hover .item-icon {
  color: #ffffff;
}

.main-banner-box-layout6 .banner-btns .play-btn:hover .item-icon:before {
  opacity: 1;
}

.main-banner-box-layout6 .banner-btns .play-btn:hover .item-icon:after {
  opacity: 0;
}

@keyframes up_down {
  0% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-30px) translateX(0);
  }
  75% {
    transform: translateY(30px) translateX(0);
  }
}

/*--- Main Banner Layout Seven ---*/
.main-banner-wrap-layout7 {
  overflow: hidden;
  padding: 220px 0 0;
  position: relative;
  z-index: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout7 {
    height: 100%;
    padding: 55px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout7 {
    padding: 50px 0 0;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout7 {
    padding: 45px 0 0;
  }
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout7 .animated-figure {
    display: none;
  }
}

.main-banner-wrap-layout7 .animated-figure li {
  position: absolute;
  z-index: -1;
  top: 0;
  right: -100px;
  transform: translateY(0);
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout7 .animated-shape-small {
    display: none;
  }
}

.main-banner-wrap-layout7 .animated-shape-small li {
  position: absolute;
  z-index: 1;
}

.main-banner-wrap-layout7 .animated-shape-small li:nth-child(1n) {
  top: 20%;
  left: 20%;
  animation: home7_shape_one 100s alternate infinite linear;
}

.main-banner-wrap-layout7 .animated-shape-small li:nth-child(2n) {
  top: 40%;
  left: 10%;
  animation: home7_shape_two 100s alternate infinite linear;
}

.main-banner-wrap-layout7 .animated-shape-small li:nth-child(3n) {
  top: 10%;
  left: 50%;
  animation: home7_shape_three 100s alternate infinite linear;
}

.main-banner-wrap-layout7 .animated-shape-small li:nth-child(4n) {
  top: 50%;
  left: 40%;
  animation: home7_shape_four 100s alternate infinite linear;
}

.main-banner-wrap-layout7 .animated-shape-small li:nth-child(5n) {
  top: 20%;
  right: 40%;
  animation: home7_shape_five 100s alternate infinite linear;
}

.main-banner-wrap-layout7 .animated-shape-small li:nth-child(6n) {
  top: 40%;
  right: 30%;
  animation: home7_shape_six 100s alternate infinite linear;
}

.main-banner-wrap-layout7 .animated-shape-small li:nth-child(7n) {
  top: 10%;
  right: 30%;
  animation: home7_shape_seven 100s alternate infinite linear;
}

.main-banner-wrap-layout7 .animated-shape-small li:nth-child(8n) {
  top: 30%;
  right: 20%;
  animation: home7_shape_eight 100s alternate infinite linear;
}

@keyframes home7_shape_one {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -100px) rotate(36deg);
  }
  50% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes home7_shape_two {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  10% {
    transform: translate(53px, -80px) rotate(36deg);
  }
  40% {
    transform: translate(161px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 112px) rotate(108deg);
  }
  70% {
    transform: translate(-60px, 92px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes home7_shape_three {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(90px, -36px) rotate(36deg);
  }
  40% {
    transform: translate(101px, 32px) rotate(72deg);
  }
  50% {
    transform: translate(103px, 102px) rotate(108deg);
  }
  80% {
    transform: translate(-76px, 62px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes home7_shape_four {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  10% {
    transform: translate(80px, -36px) rotate(36deg);
  }
  40% {
    transform: translate(191px, 72px) rotate(72deg);
  }
  50% {
    transform: translate(93px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-56px, 92px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes home7_shape_five {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  30% {
    transform: translate(80px, -36px) rotate(36deg);
  }
  40% {
    transform: translate(11px, -92px) rotate(72deg);
  }
  60% {
    transform: translate(15px, 92px) rotate(108deg);
  }
  80% {
    transform: translate(-99px, 62px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes home7_shape_six {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(95px, -58px) rotate(36deg);
  }
  30% {
    transform: translate(81px, 82px) rotate(72deg);
  }
  60% {
    transform: translate(53px, 152px) rotate(108deg);
  }
  70% {
    transform: translate(-56px, 32px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes home7_shape_seven {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(75px, -55px) rotate(36deg);
  }
  30% {
    transform: translate(92px, 82px) rotate(72deg);
  }
  60% {
    transform: translate(58px, 132px) rotate(108deg);
  }
  70% {
    transform: translate(-66px, 42px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes home7_shape_eight {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(65px, -48px) rotate(36deg);
  }
  30% {
    transform: translate(61px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(94px, 122px) rotate(108deg);
  }
  70% {
    transform: translate(-63px, 35px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

.main-banner-box-layout7 {
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout7 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout7 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout7 {
    margin-top: 20px;
    text-align: center;
  }
}

.main-banner-box-layout7 .item-figure {
  margin-top: 50px;
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout7 .item-figure {
    animation: inherit;
    margin-top: 0px;
  }
}

.main-banner-box-layout7 .item-main-title {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  font-size: 50px;
  color: #ffffff;
  font-weight: 600;
  line-height: 65px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout7 .item-main-title {
    font-size: 42px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout7 .item-main-title {
    font-size: 38px;
    line-height: 55px;
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout7 .item-main-title {
    font-size: 34px;
    line-height: 50px;
    width: 90%;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout7 .item-main-title {
    font-size: 30px;
    line-height: 45px;
    width: 100%;
  }
}

.main-banner-box-layout7 p {
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  width: 50%;
  margin-bottom: 50px;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout7 p {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout7 p {
    width: 100%;
  }
}

.main-banner-box-layout7 .banner-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout7 .banner-btns {
    display: block;
  }
}

.main-banner-box-layout7 .banner-btns a {
  margin-bottom: 15px;
  margin-right: 30px;
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout7 .banner-btns a {
    margin-right: 0;
  }
}

.main-banner-box-layout7 .banner-btns a:last-child {
  margin-right: 0;
}

.main-banner-box-layout7 .banner-btns .item-btn {
  padding: 10px 29px;
}

.main-banner-box-layout7 .banner-btns .play-btn {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout7 .banner-btns .play-btn {
    justify-content: center;
  }
}

.main-banner-box-layout7 .banner-btns .play-btn .item-icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin-right: 10px;
  color: #5a49f8;
  transition: all 0.5s ease-in-out;
}

.main-banner-box-layout7 .banner-btns .play-btn .item-icon:before {
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(180deg, #a95df1 0%, #750ed5 100%);
  color: #5a49f8;
  font-size: 14px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.main-banner-box-layout7 .banner-btns .play-btn .item-icon:after {
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  background-color: #ffffff;
  color: #5a49f8;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
}

.main-banner-box-layout7 .banner-btns .play-btn .item-icon i {
  margin-left: 5px;
}

.main-banner-box-layout7 .banner-btns .play-btn span {
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.main-banner-box-layout7 .banner-btns .play-btn:hover .item-icon {
  color: #ffffff;
}

.main-banner-box-layout7 .banner-btns .play-btn:hover .item-icon:before {
  opacity: 1;
}

.main-banner-box-layout7 .banner-btns .play-btn:hover .item-icon:after {
  opacity: 0;
}

/*--- Main Banner Layout Eight ---*/
.main-banner-wrap-layout8 {
  padding: 190px 0 100px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: #f8f8ff;
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout8 {
    height: 100%;
    padding: 45px 0 105px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout8 {
    padding: 30px 0 90px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout8 {
    padding: 15px 0 75px;
  }
}

.main-banner-wrap-layout8 .compress-left-side {
  padding-right: 50px;
  padding-left: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout8 .compress-left-side {
    padding-right: 0;
    padding-left: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout8 .compress-left-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout8 .compress-left-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout8 .compress-left-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.main-banner-wrap-layout8 .compress-right-side {
  padding-left: 50px;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout8 .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout8 .compress-right-side {
    padding-left: calc(calc(100% - 690px)/2);
    padding-right: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout8 .compress-right-side {
    padding-left: calc(calc(100% - 510px)/2);
    padding-right: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout8 .compress-right-side {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.main-banner-box-layout8 {
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout8 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout8 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout8 {
    margin-top: 30px;
  }
}

.main-banner-box-layout8 .item-figure {
  margin-top: 50px;
  animation: up_down 15s infinite;
  animation-delay: 4s;
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout8 .item-figure {
    animation: inherit;
    padding: 0 30px;
  }
}

.main-banner-box-layout8 .item-main-title {
  font-size: 50px;
  color: #000000;
  font-weight: 600;
  line-height: 65px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout8 .item-main-title {
    font-size: 42px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout8 .item-main-title {
    font-size: 38px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout8 .item-main-title {
    font-size: 34px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout8 .item-main-title {
    font-size: 30px;
    line-height: 45px;
  }
}

.main-banner-box-layout8 p {
  color: #000000;
  width: 80%;
  margin-bottom: 50px;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout8 p {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout8 p {
    width: 100%;
  }
}

@keyframes up_down {
  0% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-30px) translateX(0);
  }
  75% {
    transform: translateY(30px) translateX(0);
  }
}

/*--- Main Banner Layout Nine ---*/
.main-banner-wrap-layout9 {
  overflow: hidden;
  padding: 200px 0 120px;
  position: relative;
  z-index: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout9 {
    height: 100%;
    padding: 65px 0 105px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout9 {
    padding: 50px 0 90px;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout9 {
    padding: 35px 0 75px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout9 {
    margin-top: 30px;
  }
}

.main-banner-box-layout9 .item-figure {
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout9 .item-figure {
    animation: inherit;
    margin-top: 0px;
  }
}

.main-banner-box-layout9 .item-main-title {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  font-size: 50px;
  color: #ffffff;
  font-weight: 600;
  line-height: 65px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout9 .item-main-title {
    font-size: 42px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout9 .item-main-title {
    font-size: 38px;
    line-height: 55px;
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout9 .item-main-title {
    font-size: 34px;
    line-height: 50px;
    width: 90%;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout9 .item-main-title {
    font-size: 30px;
    line-height: 45px;
    width: 100%;
  }
}

.main-banner-box-layout9 .item-btn {
  padding: 8px 35px;
  border: 2px solid #c3c3c3;
}

/*--- Main Banner Layout Three ---*/
.main-banner-wrap-layout10 {
  padding: 130px 0 106px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout10 {
    padding: 150px 0 0;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout10 {
    padding: 105px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout10 {
    padding: 90px 0 0;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout10 {
    padding: 75px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout10 .animated-figure {
    display: none;
  }
}

.main-banner-wrap-layout10 .animated-figure ul li {
  position: absolute;
  z-index: -1;
}

.main-banner-wrap-layout10 .animated-figure ul li:nth-child(1n) {
  top: -30px;
  left: inherit;
  bottom: inherit;
  right: 12%;
}

@media only screen and (max-width: 1579px) {
  .main-banner-wrap-layout10 .animated-figure ul li:nth-child(1n) {
    right: 5%;
  }
}

@media only screen and (max-width: 1399px) {
  .main-banner-wrap-layout10 .animated-figure ul li:nth-child(1n) {
    right: 2%;
  }
}

@media only screen and (max-width: 1200px) {
  .main-banner-wrap-layout10 .animated-figure ul li:nth-child(1n) {
    right: 0;
  }
}

.main-banner-wrap-layout10 .animated-figure ul li:nth-child(2n) {
  bottom: 200px;
  left: -300px;
  top: inherit;
  right: inherit;
}

.main-banner-wrap-layout10 .animated-figure ul li:nth-child(3n) {
  bottom: 250px;
  left: -250px;
  top: inherit;
  right: inherit;
}

.main-banner-wrap-layout10 .animated-figure ul li:nth-child(4n) {
  bottom: 300px;
  left: -200px;
  top: inherit;
  right: inherit;
}

.main-banner-wrap-layout10 .compress-right-side {
  padding-right: calc(calc(100% - 1170px)/2);
  padding-left: 15px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-wrap-layout10 .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-wrap-layout10 .compress-right-side {
    padding-left: calc(calc(100% - 690px)/2);
    padding-right: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-wrap-layout10 .compress-right-side {
    padding-left: calc(calc(100% - 510px)/2);
    padding-right: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-wrap-layout10 .compress-right-side {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.main-banner-box-layout10 {
  text-align: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout10 .item-figure {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout10 .item-figure {
    padding: 0 10%;
    margin-bottom: 30px;
  }
}

.main-banner-box-layout10 .item-sub-title {
  background-color: #ffffff;
  padding: 10px 35px;
  font-size: 18px;
  color: #5a49f8;
  font-weight: 500;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 20px;
}

.main-banner-box-layout10 .item-main-title {
  font-size: 50px;
  color: #ffffff;
  font-weight: 600;
  line-height: 65px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout10 .item-main-title {
    font-size: 46px;
    line-height: 60px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 991px) {
  .main-banner-box-layout10 .item-main-title {
    font-size: 40px;
    line-height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout10 .item-main-title {
    font-size: 34px;
    line-height: 49px;
    width: 90%;
  }
}

@media only screen and (max-width: 575px) {
  .main-banner-box-layout10 .item-main-title {
    font-size: 30px;
    line-height: 45px;
    width: 100%;
  }
}

.main-banner-box-layout10 p {
  color: #ffffff;
  width: 100%;
  max-width: 450px;
  font-size: 22px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout10 p {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .main-banner-box-layout10 p {
    margin-bottom: 30px;
  }
}

.main-banner-box-layout10 .banner-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1199px) {
  .main-banner-box-layout10 .banner-btns .item-btn {
    margin-left: auto;
    margin-right: auto;
  }
}

/*--- Inner Banner Layout One ---*/
.inner-banner-wrap-layout1 {
  height: 450px;
}

/*--- Inner Page Banner ---*/
.page-banner {
  overflow: hidden;
  display: block;
  padding: 213px 0 130px;
  position: relative;
  z-index: 0;
}

.page-banner:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 991px) {
  .page-banner {
    padding: 100px 0;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner {
    padding: 90px 0;
  }
}

@media only screen and (max-width: 575px) {
  .page-banner {
    padding: 80px 0;
  }
}

.page-banner .shape-holder li {
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
}

.page-banner .shape-holder li svg {
  margin: 0 auto;
}

.page-banner .shape-holder li:nth-child(1n) {
  bottom: 45px;
}

.page-banner .shape-holder li:nth-child(2n) {
  bottom: 145px;
}

.page-banner .shape-holder li:nth-child(3n) {
  bottom: 245px;
}

.page-banner .breadcrumbs-area {
  position: relative;
  z-index: 1;
  text-align: center;
}

.page-banner .breadcrumbs-area h1 {
  font-weight: 600;
  font-size: 36px;
  color: #ffffff;
  margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .page-banner .breadcrumbs-area h1 {
    font-size: 34px;
    line-height: 1.5;
  }
}

@media only screen and (max-width: 991px) {
  .page-banner .breadcrumbs-area h1 {
    font-size: 32px;
    line-height: 1.3;
  }
}

.page-banner .breadcrumbs-area ul li {
  display: inline-block;
  position: relative;
  margin-right: 15px;
  padding-right: 5px;
  color: #cecece;
  font-size: 18px;
}

.page-banner .breadcrumbs-area ul li:before {
  content: "/";
  position: absolute;
  font-size: 18px;
  right: -11px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  color: #cecece;
}

.page-banner .breadcrumbs-area ul li a {
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.page-banner .breadcrumbs-area ul li a:hover {
  color: #ffffff;
}

.page-banner .breadcrumbs-area ul li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.page-banner .breadcrumbs-area ul li:last-child:before {
  display: none;
}

/*--------------------------------------
	4.4 Blog
---------------------------------------*/
.entry-title {
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.entry-title a {
  transition: all 0.3s ease-in-out;
}

.entry-title.title-color-light a {
  color: #ffffff;
}

.entry-title.title-color-light a:hover {
  color: #5a49f8;
}

.entry-title.title-color-dark a {
  color: #111111;
}

.entry-title.title-color-dark a:hover {
  color: #5a49f8;
}

.entry-title.title-color-primary a {
  color: #5a49f8;
}

.entry-title.title-color-primary a:hover {
  color: #000000;
}

.entry-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.entry-meta li {
  margin-right: 30px;
  align-items: center;
  display: flex;
  font-size: 14px;
}

.entry-meta li a {
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.entry-meta li a span {
  color: #5a49f8;
  margin-left: 4px;
}

.entry-meta li img {
  border-radius: 50%;
  margin-right: 10px;
  width: auto;
}

.entry-meta li i {
  margin-right: 12px;
  color: #5a49f8;
}

.entry-meta li:last-child {
  margin-right: 0;
}

.entry-meta.meta-color-accent li a {
  color: #9b9b9b;
}

.entry-meta.meta-color-accent li a:hover {
  color: #5a49f8;
}

.entry-meta.meta-color-accent li i {
  margin-right: 10px;
  color: #5a49f8;
}

.entry-meta.meta-color-accent2 li a {
  color: #93959e;
}

.entry-meta.meta-color-accent2 li a:hover {
  color: #5a49f8;
}

.entry-meta.meta-color-accent2 li i {
  margin-right: 10px;
  color: #5a49f8;
}

.entry-meta.meta-color-light li a {
  color: #ffffff;
}

.entry-meta.meta-color-light li a:hover {
  color: #5a49f8;
}

.entry-meta.meta-color-dark li a {
  color: #000000;
}

.entry-meta.meta-color-dark li a:hover {
  color: #5a49f8;
}

.entry-meta.meta-color-primary li a {
  color: #5a49f8;
}

.entry-meta.meta-color-primary li a:hover {
  color: #000000;
}

.entry-meta.meta-divider-slash li {
  position: relative;
  z-index: 1;
  padding-right: 15px;
  margin-right: 15px;
}

.entry-meta.meta-divider-slash li:before {
  position: absolute;
  z-index: 1;
  content: "/";
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.entry-meta.meta-divider-slash li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.entry-meta.meta-divider-slash li:last-child:before {
  display: none;
}

.entry-meta.meta-divider-hyphen li {
  position: relative;
  z-index: 1;
  padding-right: 15px;
  margin-right: 15px;
}

.entry-meta.meta-divider-hyphen li:before {
  position: absolute;
  z-index: 1;
  content: "-";
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.entry-meta.meta-divider-hyphen li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.entry-meta.meta-divider-hyphen li:last-child:before {
  display: none;
}

.entry-meta.meta-divider-center {
  position: relative;
  z-index: 1;
}

.entry-meta.meta-divider-center:before {
  position: absolute;
  z-index: 1;
  content: "-";
  left: 50%;
  transform: translateX(-50%);
}

.entry-meta.meta-wrap-border-top {
  padding-top: 15px;
  border-top: 1px solid #d9d9d9;
}

/*--- Blog Box Layout One ---*/
.blog-box-layout1 {
  margin-bottom: 30px;
}

.blog-box-layout1 .item-figure {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: 4px 4px 0 0;
}

.blog-box-layout1 .item-figure a {
  display: block;
}

.blog-box-layout1 .item-figure:before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout1 .item-figure:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout1 .item-content {
  padding: 25px 30px 12px;
  box-shadow: 0px 0px 13px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
}

.blog-box-layout1 .item-content .entry-title {
  margin-bottom: 17px;
}

.blog-box-layout1:hover .item-figure:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout1:hover .item-figure:after {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout1:hover .item-content {
  box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.1);
}

/*--- Blog Box Layout Two ---*/
.blog-wrap-layout2 {
  padding: 110px 0 80px;
  background-color: #ffffff;
}

@media only screen and (max-width: 991px) {
  .blog-wrap-layout2 {
    padding: 95px 0 65px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-layout2 {
    padding: 80px 0 50px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-wrap-layout2 {
    padding: 65px 0 35px;
  }
}

.blog-box-layout2 {
  margin-bottom: 30px;
}

.blog-box-layout2 .item-figure {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .blog-box-layout2 .item-figure {
    margin-bottom: 20px;
  }
}

.blog-box-layout2 .item-figure a {
  display: block;
}

.blog-box-layout2 .item-figure:before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout2 .item-figure:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout2 .item-content .entry-meta {
  margin-bottom: 15px;
}

.blog-box-layout2:hover .item-figure:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout2:hover .item-figure:after {
  width: 100%;
  height: 100%;
  opacity: 0;
}

/*--- Blog Box Layout Three ---*/
.blog-box-layout3 {
  margin-bottom: 30px;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.07);
}

.blog-box-layout3 .item-figure {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: 4px 4px 0 0;
}

.blog-box-layout3 .item-figure a {
  display: block;
}

.blog-box-layout3 .item-figure:before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout3 .item-figure:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout3 .item-content {
  padding: 30px;
  background-color: #ffffff;
}

.blog-box-layout3 .item-content .entry-meta {
  margin-bottom: 13px;
}

.blog-box-layout3 .item-content .entry-title {
  margin-bottom: 0;
}

.blog-box-layout3 .item-content .btn-text {
  color: #646464;
}

.blog-box-layout3 .item-content .btn-text i {
  color: #646464;
}

.blog-box-layout3 .item-content .btn-text:hover {
  color: #5a49f8;
}

.blog-box-layout3 .item-content .btn-text:hover i {
  color: #5a49f8;
}

.blog-box-layout3:hover .item-figure:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout3:hover .item-figure:after {
  width: 100%;
  height: 100%;
  opacity: 0;
}

/*--- Blog Box Layout Four ---*/
.blog-wrap-layout4 {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-layout4 .animated-buble {
    display: none;
  }
}

.blog-wrap-layout4 .animated-buble li {
  position: absolute;
  z-index: -1;
}

.blog-wrap-layout4 .animated-buble li:nth-child(1n) {
  top: -230px;
  left: -220px;
  right: inherit;
  bottom: inherit;
}

.blog-wrap-layout4 .animated-buble li:nth-child(2n) {
  top: -74px;
  left: 145px;
  right: inherit;
  bottom: inherit;
}

.blog-wrap-layout4 .animated-buble li:nth-child(3n) {
  top: -150px;
  left: 40%;
  right: inherit;
  bottom: inherit;
}

.blog-box-layout4 {
  margin-bottom: 20px;
}

.blog-box-layout4 .item-figure {
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  border-radius: 4px 4px 0 0;
}

.blog-box-layout4 .item-figure a {
  display: block;
}

.blog-box-layout4 .item-figure a img {
  border-radius: 4px;
}

.blog-box-layout4 .item-figure:before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout4 .item-figure:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout4 .item-content .entry-title {
  margin-bottom: 17px;
}

.blog-box-layout4 .item-content .entry-title a:hover {
  color: #f0f0f0;
}

.blog-box-layout4 .item-content .entry-meta li a {
  color: #f1ebeb;
}

.blog-box-layout4 .item-content .entry-meta li a:hover {
  color: #ffffff;
}

.blog-box-layout4 .item-content .btn-text {
  color: #ffffff !important;
}

.blog-box-layout4 .item-content .btn-text i {
  color: #ffffff;
}

.blog-box-layout4 .item-content .btn-text:hover {
  color: #f5f5f5 !important;
}

.blog-box-layout4 .item-content .btn-text:hover i {
  color: #f5f5f5;
}

.blog-box-layout4:hover .item-figure:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout4:hover .item-figure:after {
  width: 100%;
  height: 100%;
  opacity: 0;
}

/*--- Blog Box Layout Five ---*/
.blog-box-layout5 {
  margin-bottom: 30px;
}

.blog-box-layout5 .item-figure {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: 4px 4px 0 0;
}

.blog-box-layout5 .item-figure a {
  display: block;
}

.blog-box-layout5 .item-figure:before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout5 .item-figure:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout5 .item-content {
  border-radius: 0 0 4px 4px;
  padding: 30px;
  background-color: #f7f7f7;
  margin-bottom: 30px;
}

.blog-box-layout5 .item-content .entry-title {
  margin-bottom: 20px;
}

.blog-box-layout5 .item-content .entry-meta {
  margin-bottom: 15px;
}

.blog-box-layout5:hover .item-figure:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout5:hover .item-figure:after {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout5.emty-figure .item-figure {
  display: none;
}

.blog-box-layout5.emty-figure .item-content {
  border-radius: 4px;
  border: 1px solid #bbbbbb;
  background-color: transparent;
}

/*--- Blog Box Layout Six ---*/
.blog-box-layout6 {
  margin-bottom: 50px;
}

.blog-box-layout6 .item-figure {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  margin-bottom: 30px;
}

.blog-box-layout6 .item-figure a {
  display: block;
}

.blog-box-layout6 .item-figure a img {
  border-radius: 4px;
}

.blog-box-layout6 .item-figure:before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout6 .item-figure:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout6 .item-content .entry-meta {
  margin-bottom: 15px;
}

.blog-box-layout6 .item-content .entry-title {
  font-size: 26px;
}

.blog-box-layout6:hover .item-figure:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout6:hover .item-figure:after {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout6 blockquote {
  text-align: center;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.07);
  padding: 60px 30px;
}

.blog-box-layout6 blockquote .item-icon {
  font-size: 70px;
  margin-bottom: 20px;
  color: #111111;
  opacity: 0.08;
}

.blog-box-layout6 blockquote p {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.blog-box-layout6 blockquote .item-sub-title {
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 600;
}

/*--- Blog Box Layout Seven ---*/
.blog-box-layout7 {
  margin-bottom: 30px;
}

.blog-box-layout7 .item-figure {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: 4px 4px 0 0;
  margin-bottom: 15px;
}

.blog-box-layout7 .item-figure a {
  display: block;
}

.blog-box-layout7 .item-figure:before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout7 .item-figure:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout7 .item-content .entry-meta {
  margin-bottom: 0;
}

.blog-box-layout7 .item-content .entry-meta li {
  font-size: 13px;
}

.blog-box-layout7 .item-content .entry-title {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.blog-box-layout7 .item-content .btn-text {
  color: #646464;
}

.blog-box-layout7 .item-content .btn-text i {
  color: #646464;
}

.blog-box-layout7 .item-content .btn-text:hover {
  color: #5a49f8;
}

.blog-box-layout7 .item-content .btn-text:hover i {
  color: #5a49f8;
}

.blog-box-layout7:hover .item-figure:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout7:hover .item-figure:after {
  width: 100%;
  height: 100%;
  opacity: 0;
}

/*--- Blog Box Layout Eight ---*/
.blog-box-layout8 {
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid #e5e5e5;
  padding: 15px;
  border-radius: 4px;
}

.blog-box-layout8 .item-figure {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: 4px 4px 0 0;
}

.blog-box-layout8 .item-figure a {
  display: block;
}

.blog-box-layout8 .item-figure:before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout8 .item-figure:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout8 .item-figure img {
  border-radius: 4px;
}

.blog-box-layout8 .item-content {
  padding: 30px 30px 10px;
  background-color: #ffffff;
}

.blog-box-layout8 .item-content .entry-meta {
  margin-bottom: 5px;
}

.blog-box-layout8 .item-content .entry-meta li {
  font-size: 20px;
}

.blog-box-layout8 .item-content .entry-title {
  margin-bottom: 0;
  font-size: 30px;
  line-height: 50px;
}

.blog-box-layout8 .item-content .btn-text {
  color: #646464;
}

.blog-box-layout8 .item-content .btn-text i {
  color: #646464;
}

.blog-box-layout8 .item-content .btn-text:hover {
  color: #5a49f8;
}

.blog-box-layout8 .item-content .btn-text:hover i {
  color: #5a49f8;
}

.blog-box-layout8:hover .item-figure:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout8:hover .item-figure:after {
  width: 100%;
  height: 100%;
  opacity: 0;
}

/*--- Blog Box Layout Nine ---*/
.blog-wrap-layout9 {
  background-color: #ffffff;
}

.blog-box-layout9 {
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 30px 30px 10px;
  margin-bottom: 30px;
}

.blog-box-layout9 .item-figure {
  margin-bottom: 15px;
}

@media only screen and (max-width: 991px) {
  .blog-box-layout9 {
    text-align: center;
  }
}

/*--- Blog Box Layout Two ---*/
.blog-wrap-layout10 {
  padding: 110px 0 80px;
  background-color: #ffffff;
}

@media only screen and (max-width: 991px) {
  .blog-wrap-layout10 {
    padding: 95px 0 65px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-layout10 {
    padding: 80px 0 50px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-wrap-layout10 {
    padding: 65px 0 35px;
  }
}

.blog-box-layout10 {
  margin-bottom: 30px;
}

.blog-box-layout10 .item-figure {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .blog-box-layout10 .item-figure {
    margin-bottom: 20px;
  }
}

.blog-box-layout10 .item-figure a {
  display: block;
}

.blog-box-layout10 .item-figure:before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout10 .item-figure:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout10 .item-content .entry-meta {
  margin-bottom: 15px;
}

.blog-box-layout10:hover .item-figure:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout10:hover .item-figure:after {
  width: 100%;
  height: 100%;
  opacity: 0;
}

/*--- Blog Box Layout Eleven ---*/
.blog-box-layout11 {
  margin-bottom: 30px;
}

.blog-box-layout11 .item-figure {
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: 4px 4px 0 0;
}

.blog-box-layout11 .item-figure a {
  display: block;
}

.blog-box-layout11 .item-figure:before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout11 .item-figure:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  content: '';
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 1;
  transition: all 0.8s ease-in-out;
}

.blog-box-layout11 .item-content {
  padding: 25px 30px 30px;
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;
}

.blog-box-layout11 .item-content .entry-title {
  margin-bottom: 17px;
}

.blog-box-layout11 .item-content .item-btn {
  color: #111111;
}

.blog-box-layout11 .item-content .item-btn:hover {
  color: #ffffff;
}

.blog-box-layout11:hover .item-figure:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.blog-box-layout11:hover .item-figure:after {
  width: 100%;
  height: 100%;
  opacity: 0;
}

/*--- Blog Details ---*/
.blog-details-box .blog-details-figure {
  margin-bottom: 30px;
}

.blog-details-box .blog-details-content .entry-title {
  line-height: 40px;
  font-size: 26px;
  margin-bottom: 15px;
}

.blog-details-box .blog-details-content blockquote {
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
  font-size: 22px;
  font-style: italic;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  line-height: 36px;
  z-index: 1;
  padding: 30px 40px 30px 150px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
  color: #111111;
}

@media only screen and (max-width: 767px) {
  .blog-details-box .blog-details-content blockquote {
    padding: 70px 20px 20px;
    font-size: 20px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details-box .blog-details-content blockquote {
    padding: 70px 20px 20px;
    font-size: 18px;
    line-height: 28px;
  }
}

.blog-details-box .blog-details-content blockquote:after {
  content: "\f10d";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  font-size: 60px;
  opacity: 0.1;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .blog-details-box .blog-details-content blockquote:after {
    top: 20px;
    left: 20px;
    transform: translateY(0);
    font-size: 42px;
  }
}

.blog-details-box .blog-details-content blockquote:before {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: #5a49f8;
}

.blog-details-box .blog-tag {
  margin-top: 45px;
  margin-bottom: 35px;
}

.blog-details-box .blog-tag ul {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .blog-details-box .blog-tag ul {
    display: block;
  }
}

.blog-details-box .blog-tag ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.blog-details-box .blog-tag ul .item-comments i {
  font-size: 20px;
  margin-right: 12px;
}

.blog-details-box .blog-tag ul .social-wrap .social-title {
  margin-right: 15px;
}

.blog-details-box .blog-tag ul .social-wrap .item-social a {
  color: #646464;
  margin-right: 10px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.blog-details-box .blog-tag ul .social-wrap .item-social a:last-child {
  margin-right: 0;
}

.blog-details-box .blog-tag ul .social-wrap .item-social a:hover {
  color: #5a49f8;
}

.blog-details-box .blog-tag ul .tag-wrap .tag-title {
  margin-right: 15px;
}

.blog-details-box .blog-tag ul .tag-wrap .item-tag a {
  color: #646464;
  margin-right: 10px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.blog-details-box .blog-tag ul .tag-wrap .item-tag a:last-child {
  margin-right: 0;
}

.blog-details-box .blog-tag ul .tag-wrap .item-tag a:hover {
  color: #5a49f8;
}

.blog-details-box .blog-author {
  margin-bottom: 45px;
  border-radius: 4px;
  border: 1px solid rgba(100, 100, 100, 0.14);
  padding: 35px 40px;
}

.blog-details-box .blog-author .media img {
  border-radius: 50%;
}

.blog-details-box .blog-author .media .media-body {
  position: relative;
}

.blog-details-box .blog-author .media .media-body .item-title {
  font-size: 18px;
  color: #5a49f8;
  font-weight: 600;
  margin-bottom: 10px;
}

.blog-details-box .blog-author .media .media-body p {
  margin-bottom: 0;
}

.blog-details-box .related-post {
  margin-bottom: 15px;
}

.blog-details-box .blog-comment {
  background-color: #ffffff;
  margin-bottom: 40px;
}

.blog-details-box .blog-comment ul li {
  position: relative;
  margin-bottom: 40px;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
}

.blog-details-box .blog-comment ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.blog-details-box .blog-comment ul li:nth-child(odd) {
  margin-left: 0;
}

.blog-details-box .blog-comment ul li:nth-child(even) {
  margin-left: 50px;
}

.blog-details-box .blog-comment ul li .comment-title {
  font-weight: 600;
  color: #111111;
  margin-bottom: 0;
  line-height: 24px;
  font-size: 16px;
}

.blog-details-box .blog-comment ul li .post-date {
  margin-bottom: 5px;
  display: block;
  color: #a1a1a1;
  font-size: 14px;
}

.blog-details-box .blog-comment ul li p {
  width: 85%;
}

.blog-details-box .blog-comment ul li .item-btn {
  border-radius: 4px;
  font-size: 15px;
  color: #111111;
  padding: 0px 17px 1px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  right: 0;
  margin-bottom: 30px;
  display: inline-block;
  transition: all 0.3s ease-out;
}

@media only screen and (max-width: 767px) {
  .blog-details-box .blog-comment ul li .item-btn {
    position: inherit;
  }
}

.blog-details-box .blog-comment ul li .item-btn:hover {
  background-color: #5a49f8;
  color: #ffffff;
}

.blog-details-box .leave-comment {
  background-color: #ffffff;
}

.blog-details-box .leave-comment .leave-form-box .form-group {
  margin-bottom: 20px;
}

.blog-details-box .leave-comment .leave-form-box .form-group .form-control {
  color: #000000;
  font-size: 16px;
  padding: 15px 20px;
  border: 1px solid #cccccc;
  background-color: transparent;
  border-radius: 4px;
}

.blog-details-box .leave-comment .leave-form-box .form-group .form-control:focus {
  outline: none;
  box-shadow: none;
}

.blog-details-box .leave-comment .leave-form-box .form-group input {
  height: 60px;
}

/*--------------------------------------
	4.5 Brand
---------------------------------------*/
.brand-wrap-layout1 {
  padding: 63px 0;
}

.brand-wrap-layout1 .owl-carousel.owl-drag .owl-item {
  text-align: center;
}

.brand-box-layout1 {
  display: inline-flex;
  align-items: center;
  cursor: all-scroll;
  filter: grayscale(100%);
  transition: all 0.5s ease-in-out;
}

.brand-box-layout1:hover {
  filter: grayscale(0);
}

.brand-wrap-layout2 {
  padding: 0 0 90px;
}

@media only screen and (max-width: 991px) {
  .brand-wrap-layout2 {
    padding: 0 0 75px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-wrap-layout2 {
    padding: 0 0 60px;
  }
}

@media only screen and (max-width: 575px) {
  .brand-wrap-layout2 {
    padding: 0 0 45px;
  }
}

.brand-box-layout2 {
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  height: 180px;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: grayscale(100%);
  transition: all 0.5s ease-in-out;
}

.brand-box-layout2:hover {
  filter: grayscale(0);
}

/*--------------------------------------
	4.6 Call To Action
---------------------------------------*/
.call-to-action-box-layout1 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .call-to-action-box-layout1 {
    text-align: center;
  }
}

.call-to-action-box-layout1 .item-title {
  color: #ffffff;
  margin-bottom: 0;
}

.call-to-action-wrap-layout2 {
  padding: 100px 0 90px;
  background: #fafafa;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .call-to-action-wrap-layout2 {
    padding: 85px 0 75px;
  }
}

@media only screen and (max-width: 767px) {
  .call-to-action-wrap-layout2 {
    padding: 70px 0 60px;
  }
}

@media only screen and (max-width: 575px) {
  .call-to-action-wrap-layout2 {
    padding: 55px 0 45px;
  }
}

.call-to-action-wrap-layout2:before {
  position: absolute;
  z-index: 0;
  content: url("../../media/banner/banner4.png");
  right: 0;
  bottom: -10px;
}

@media only screen and (max-width: 1199px) {
  .call-to-action-box-layout2 .item-content {
    text-align: center;
  }
}

.call-to-action-box-layout2 .item-figure {
  position: relative;
  z-index: 1;
  text-align: center;
  transform: translateY(100px);
}

.call-to-action-box-layout2 .button-group {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1199px) {
  .call-to-action-box-layout2 .button-group {
    justify-content: center;
  }
}

.call-to-action-box-layout2 .button-group li {
  margin-right: 15px;
  margin-bottom: 10px;
}

.call-to-action-box-layout2 .button-group li:last-child {
  margin-right: 0;
}

.call-to-action-wrap-layout6 {
  padding-top: 40px;
  padding-bottom: 90px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .call-to-action-wrap-layout6 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .call-to-action-wrap-layout6 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .call-to-action-wrap-layout6 {
    padding-bottom: 45px;
  }
}

.call-to-action-wrap-layout6:before {
  content: "";
  position: absolute;
  z-index: 0;
  background-color: rgba(48, 30, 228, 0.9);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.call-to-action-wrap-layout6 .tlp-border {
  background-color: rgba(0, 0, 0, 0.5);
  height: 1px;
  width: 100%;
  margin-bottom: 60px;
  margin-top: 10px;
}

.call-to-action-box-layout3 {
  margin-bottom: 30px;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1199px) {
  .call-to-action-box-layout3 {
    text-align: center;
  }
}

.call-to-action-box-layout3 .item-title {
  color: #ffffff;
  margin-bottom: 10px;
  font-weight: 300;
}

.call-to-action-box-layout3 p {
  color: #ffffff;
}

.call-to-action-box-layout3 .item-btn {
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.call-to-action-box-layout3 .item-btn:last-child {
  margin-right: 0;
}

.call-to-action-wrap-layout4 {
  position: relative;
  z-index: 1;
}

.call-to-action-wrap-layout4:before {
  content: "";
  background: #5a49f8;
  background: linear-gradient(60deg, rgba(90, 73, 248, 0.9) 0%, rgba(122, 100, 242, 0.9) 100%);
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.call-to-action-box-layout4 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .call-to-action-box-layout4 {
    text-align: center;
  }
}

.call-to-action-box-layout4 .item-title {
  color: #ffffff;
  margin-bottom: 0;
}

/*--------------------------------------
	4.7 Coming Soon
---------------------------------------*/
.coming-soon-wrap {
  padding-top: 205px;
  padding-bottom: 120px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .coming-soon-wrap {
    padding-top: 105px;
    padding-bottom: 105px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-wrap {
    display: block;
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@media only screen and (max-width: 575px) {
  .coming-soon-wrap {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 479px) {
  .coming-soon-wrap {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.coming-soon-wrap:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(35deg, #523fff 10%, #750ed5 100%);
}

.coming-soon-wrap .shape-holder li {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  opacity: 0;
}

@media only screen and (max-width: 767px) {
  .coming-soon-wrap .shape-holder li {
    left: inherit;
    right: inherit;
  }
}

.coming-soon-wrap .shape-holder li svg {
  margin: 0 auto;
}

.coming-soon-wrap .shape-holder li:nth-child(1n) {
  animation: circle_animation 14s infinite;
}

.coming-soon-wrap .shape-holder li:nth-child(2n) {
  animation: circle_animation 14s infinite;
  animation-delay: 2s;
}

.coming-soon-wrap .shape-holder li:nth-child(3n) {
  animation: circle_animation 14s infinite;
  animation-delay: 4s;
}

.coming-soon-wrap .shape-holder li:nth-child(4n) {
  animation: circle_animation 14s infinite;
  animation-delay: 6s;
}

.coming-soon-wrap .shape-holder li:nth-child(5n) {
  animation: circle_animation 14s infinite;
  animation-delay: 8s;
}

.coming-soon-wrap .shape-holder li:nth-child(6n) {
  animation: circle_animation 14s infinite;
  animation-delay: 10s;
}

.coming-soon-wrap .shape-holder li:nth-child(7n) {
  animation: circle_animation 14s infinite;
  animation-delay: 12s;
}

.coming-soon-wrap .coming-soon-box {
  text-align: center;
}

.coming-soon-wrap .coming-soon-box .countdown {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.coming-soon-wrap .coming-soon-box .countdown .countdown-section {
  margin: 0 15px 15px;
  background: transparent;
  height: 160px;
  width: 160px;
  border: 1px solid rgba(189, 169, 245, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 1199px) {
  .coming-soon-wrap .coming-soon-box .countdown .countdown-section {
    height: 130px;
    width: 130px;
    padding-top: 15px;
  }
}

.coming-soon-wrap .coming-soon-box .countdown .countdown-section h2 {
  font-size: 40px;
  color: #ffffff;
  margin-bottom: 0;
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .coming-soon-wrap .coming-soon-box .countdown .countdown-section h2 {
    font-size: 36px;
    line-height: 30px;
  }
}

.coming-soon-wrap .coming-soon-box .countdown .countdown-section h3 {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 300;
}

@media only screen and (max-width: 1199px) {
  .coming-soon-wrap .coming-soon-box .countdown .countdown-section h3 {
    font-size: 20px;
  }
}

.coming-soon-wrap .coming-soon-box .sub-title {
  color: #ffffff;
  font-size: 28px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 991px) {
  .coming-soon-wrap .coming-soon-box .sub-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-wrap .coming-soon-box .sub-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .coming-soon-wrap .coming-soon-box .sub-title {
    font-size: 20px;
  }
}

.coming-soon-wrap .coming-soon-box .main-title {
  color: #ffffff;
  font-size: 55px;
  font-weight: 500;
  width: 70%;
  line-height: 70px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
}

@media only screen and (max-width: 991px) {
  .coming-soon-wrap .coming-soon-box .main-title {
    font-size: 46px;
    line-height: 65px;
    width: 80%;
  }
}

@media only screen and (max-width: 991px) {
  .coming-soon-wrap .coming-soon-box .main-title {
    font-size: 40px;
    line-height: 58px;
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-wrap .coming-soon-box .main-title {
    font-size: 34px;
    line-height: 44px;
    width: 90%;
  }
}

@media only screen and (max-width: 575px) {
  .coming-soon-wrap .coming-soon-box .main-title {
    font-size: 28px;
    line-height: 38px;
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .coming-soon-wrap .coming-soon-box .main-title {
    font-size: 26px;
    line-height: 36px;
  }
}

.coming-soon-wrap .coming-soon-box .item-btn {
  padding: 12px 40px;
  font-size: 18px;
}

@keyframes circle_animation {
  from {
    z-index: -1;
    transform: translateY(-50%) scale(0);
    opacity: 1;
  }
  to {
    transform: translateY(-50%) scale(1);
    opacity: 0;
    z-index: -2;
  }
}

/*--------------------------------------
	4.8 Contact
---------------------------------------*/
.contact-wrap-layout1 {
  overflow: hidden;
}

.contact-box-layout1 {
  margin-bottom: 30px;
}

.contact-box-layout1 .item-title {
  font-weight: 600;
  margin-bottom: 5px;
}

.contact-box-layout1 p {
  margin-bottom: 30px;
}

.contact-box-layout1 .contact-form-box .form-group {
  margin-bottom: 20px;
}

.contact-box-layout1 .contact-form-box .form-group input {
  height: 50px;
}

.contact-box-layout1 .contact-form-box .form-group .form-control {
  font-size: 17px;
  padding: 15px 20px;
  color: #000000;
}

.contact-box-layout1 .contact-form-box .form-group .form-control:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.contact-box-layout1 input.form-control::-webkit-input-placeholder,
.contact-box-layout1 textarea.form-control::-webkit-input-placeholder {
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 300;
}

.contact-box-layout1 input.form-control::-moz-placeholder,
.contact-box-layout1 textarea.form-control::-moz-placeholder {
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 300;
}

.contact-box-layout1 input.form-control:-moz-placeholder,
.contact-box-layout1 textarea.form-control:-moz-placeholder {
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 300;
}

.contact-box-layout1 input.form-control:-ms-input-placeholder,
.contact-box-layout1 textarea.form-control:-ms-input-placeholder {
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 300;
}

.contact-wrap-layout2 {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 120px 0 112px;
}

@media only screen and (max-width: 991px) {
  .contact-wrap-layout2 {
    padding: 105px 0 97px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-wrap-layout2 {
    padding: 90px 0 82px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-wrap-layout2 {
    padding: 75px 0 67px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-wrap-layout2 .animated-buble {
    display: none;
  }
}

.contact-wrap-layout2 .animated-buble li {
  position: absolute;
  z-index: -1;
}

.contact-wrap-layout2 .animated-buble li:nth-child(1n) {
  top: -230px;
  left: -220px;
  right: inherit;
  bottom: inherit;
}

.contact-wrap-layout2 .animated-buble li:nth-child(2n) {
  top: -74px;
  left: 145px;
  right: inherit;
  bottom: inherit;
}

.contact-wrap-layout2 .animated-buble li:nth-child(3n) {
  bottom: -150px;
  left: 30px;
  right: inherit;
  top: inherit;
}

@media only screen and (max-width: 767px) {
  .contact-wrap-layout2 .animated-figure {
    display: none;
  }
}

.contact-wrap-layout2 .animated-figure li {
  position: absolute;
  z-index: -1;
}

.contact-wrap-layout2 .animated-figure li:nth-child(1n) {
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 991px) {
  .contact-box-layout2 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .contact-box-layout2 .contact-form-box {
    text-align: center;
  }
}

.contact-box-layout2 .contact-form-box .form-group {
  margin-bottom: 20px;
}

.contact-box-layout2 .contact-form-box .form-group input {
  height: 50px;
}

.contact-box-layout2 .contact-form-box .form-group .form-control {
  font-size: 17px;
  padding: 15px 20px;
  color: #ffffff;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
}

.contact-box-layout2 .contact-form-box .form-group .form-control:focus {
  border-color: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}

.contact-box-layout2 .contact-form-box input.form-control::-webkit-input-placeholder,
.contact-box-layout2 .contact-form-box textarea.form-control::-webkit-input-placeholder {
  color: #d4d4d4;
  font-size: 16px;
}

.contact-box-layout2 .contact-form-box input.form-control::-moz-placeholder,
.contact-box-layout2 .contact-form-box textarea.form-control::-moz-placeholder {
  color: #d4d4d4;
  font-size: 16px;
}

.contact-box-layout2 .contact-form-box input.form-control:-moz-placeholder,
.contact-box-layout2 .contact-form-box textarea.form-control:-moz-placeholder {
  color: #d4d4d4;
  font-size: 16px;
}

.contact-box-layout2 .contact-form-box input.form-control:-ms-input-placeholder,
.contact-box-layout2 .contact-form-box textarea.form-control:-ms-input-placeholder {
  color: #d4d4d4;
  font-size: 16px;
}

.address-box-layout1 {
  text-align: center;
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
}

.address-box-layout1:before {
  position: absolute;
  z-index: 1;
  background-color: rgba(233, 233, 233, 0.45);
  height: 70%;
  width: 1px;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  right: -15px;
}

@media only screen and (max-width: 767px) {
  .address-box-layout1:before {
    display: none;
  }
}

.address-box-layout1 .item-icon {
  color: #ffffff;
  margin-bottom: 25px;
}

.address-box-layout1 .item-icon i:before {
  font-size: 56px;
}

.address-box-layout1 .item-title {
  color: #ffffff;
  margin-bottom: 0;
  font-weight: 600;
}

.address-box-layout1 .list-item li {
  color: #dfdfdf;
  font-size: 18px;
}

[class^="col-"]:last-child .address-box-layout1:before {
  display: none;
}

.copy-right {
  position: absolute;
  z-index: 1;
  bottom: 55px;
  left: 30px;
  color: #dcdcdc;
  font-weight: 300;
  transform: rotate(270deg);
  transform-origin: 0 0;
}

@media only screen and (max-width: 1199px) {
  .copy-right {
    position: inherit;
    transform: inherit;
    margin-top: 30px;
    text-align: center;
  }
}

.address-box-layout2 {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
}

.address-box-layout2 .item-icon {
  color: #5a49f8;
  margin-right: 20px;
  margin-top: 5px;
}

.address-box-layout2 .item-icon i:before {
  font-size: 36px;
}

.address-box-layout2 .item-title {
  color: #000000;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
}

.address-box-layout2 .list-item li {
  font-size: 18px;
}

.single-item:last-child > .address-box-layout2 {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.contact-box-layout3 .contact-form-box .form-group {
  margin-bottom: 30px;
}

.contact-box-layout3 .contact-form-box .form-group input {
  height: 50px;
}

.contact-box-layout3 .contact-form-box .form-group .form-control {
  font-size: 17px;
  padding: 15px 20px;
  color: #000000;
  background-color: transparent;
  border-color: rgba(127, 127, 127, 0.25);
}

.contact-box-layout3 .contact-form-box .form-group .form-control:focus {
  border-color: rgba(127, 127, 127, 0.25);
  box-shadow: none;
}

.contact-box-layout3 .contact-form-box input.form-control::-webkit-input-placeholder,
.contact-box-layout3 .contact-form-box textarea.form-control::-webkit-input-placeholder {
  color: #b8b8b8;
  font-size: 16px;
}

.contact-box-layout3 .contact-form-box input.form-control::-moz-placeholder,
.contact-box-layout3 .contact-form-box textarea.form-control::-moz-placeholder {
  color: #b8b8b8;
  font-size: 16px;
}

.contact-box-layout3 .contact-form-box input.form-control:-moz-placeholder,
.contact-box-layout3 .contact-form-box textarea.form-control:-moz-placeholder {
  color: #b8b8b8;
  font-size: 16px;
}

.contact-box-layout3 .contact-form-box input.form-control:-ms-input-placeholder,
.contact-box-layout3 .contact-form-box textarea.form-control:-ms-input-placeholder {
  color: #b8b8b8;
  font-size: 16px;
}

/*--------------------------------------
	4.9 Faq
---------------------------------------*/
.faq-wrap {
  padding: 120px 0 90px;
}

@media only screen and (max-width: 991px) {
  .faq-wrap {
    padding: 97px 0 75px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-wrap {
    padding: 82px 0 60px;
  }
}

@media only screen and (max-width: 575px) {
  .faq-wrap {
    padding: 67px 0 45px;
  }
}

.faq-box {
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 30px;
}

.faq-box .single-item {
  border: none;
  border-bottom: 1px solid #f4f4f4;
}

.faq-box .single-item .item-nav {
  padding: 0;
  border: none;
  margin-bottom: 0;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
}

.faq-box .single-item .item-nav a {
  border-radius: 0;
  background-color: #f9fbfe;
  color: #5a49f8;
  width: 100%;
  display: block;
  padding: 25px 80px 30px 60px;
  font-size: 18px;
  position: relative;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.faq-box .single-item .item-nav a:after {
  position: absolute;
  content: "\f078";
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  font-size: 14px;
  z-index: 5;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: #5a49f8;
}

.faq-box .single-item .item-nav a.collapsed {
  background-color: #ffffff;
  color: #444444;
}

.faq-box .single-item .item-nav a.collapsed:after {
  color: #b9b9b9;
  content: "\f077";
}

.faq-box .single-item .item-nav a.collapsed:hover {
  background-color: #f9fbfe;
}

.faq-box .single-item .item-content-wrap .item-content {
  background-color: #f9fbfe;
  padding: 0 80px 25px 60px;
  transition: all 0.3s ease-in-out;
}

.faq-box .single-item .item-content-wrap .item-content p {
  margin-bottom: 0;
}

.faq-box .single-item:last-child {
  border-bottom: 0;
}

/*--------------------------------------
	4.10 Feature
---------------------------------------*/
/*--- Feature Style One ---*/
.feature-wrap-layout1 {
  overflow: hidden;
  padding: 120px 0 90px;
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout1 {
    padding: 105px 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-layout1 {
    padding: 90px 0 50px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-wrap-layout1 {
    padding: 75px 0 36px;
  }
}

.feature-wrap-layout1 .indicator-wrap {
  position: relative;
  z-index: 1;
  padding-bottom: 100px;
}

.feature-wrap-layout1 .indicator-wrap .indicator-img-right {
  position: absolute;
  z-index: 1;
  right: -15px;
  bottom: 30px;
}

.feature-wrap-layout1 .indicator-wrap .indicator-img-left {
  position: absolute;
  z-index: 1;
  left: -20px;
  bottom: 30px;
}

.feature-wrap-layout1 .indicator-wrap .dash-left .dashed1 {
  stroke-dashoffset: 0;
}

.feature-wrap-layout1 .indicator-wrap .dash-right .dashed1 {
  stroke-dashoffset: 0;
}

.feature-wrap-layout1 .indicator-wrap .dashed1 {
  stroke-dasharray: 872;
}

.feature-wrap-layout1 .indicator-wrap .dashed2 {
  stroke-dasharray: 4;
}

.feature-box-layout1 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout1 {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout1 {
    margin-bottom: 40px;
  }
}

.feature-box-layout1 .item-title {
  font-weight: 600;
  font-size: 24px;
}

.feature-box-layout1 .sub-title {
  font-weight: 500;
  font-size: 18px;
  font-style: italic;
  margin-bottom: 10px;
}

.feature-box-layout1 .list-layout1 {
  margin-bottom: -10px;
}

.feature-box-layout1 .list-layout1 li {
  position: relative;
  z-index: 1;
  padding-left: 30px;
  margin-bottom: 20px;
}

.feature-box-layout1 .list-layout1 li:last-child {
  margin-bottom: 0;
}

.feature-box-layout1 .list-layout1 li:before {
  position: absolute;
  z-index: 1;
  content: "\f058";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  left: 0;
  top: 0;
  color: #5a49f8;
  font-size: 18px;
}

.feature-box-layout1 .list-layout1 li .inner-item-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.feature-box-layout1 .list-layout1 li p {
  margin-bottom: 0;
}

.feature-box-layout1 .list-layout2 {
  margin-bottom: -10px;
}

.feature-box-layout1 .list-layout2 li {
  position: relative;
  z-index: 1;
  padding-left: 30px;
  margin-bottom: 10px;
}

.feature-box-layout1 .list-layout2 li:last-child {
  margin-bottom: 0;
}

.feature-box-layout1 .list-layout2 li:before {
  position: absolute;
  z-index: 1;
  content: "\f00c";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  left: 0;
  top: 0;
  color: #5a49f8;
  font-size: 16px;
}

.feature-box-layout1 .list-layout2 li .inner-item-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.feature-box-layout1 .list-layout2 li p {
  margin-bottom: 0;
}

/*--- Feature Style Two ---*/
.feature-wrap-layout2 {
  padding: 120px 0 0;
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout2 {
    padding: 105px 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-layout2 {
    padding: 90px 0 30px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-wrap-layout2 {
    padding: 75px 0 15px;
  }
}

.feature-box-layout2 {
  margin-bottom: 120px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout2 {
    margin-bottom: 60px;
  }
}

.feature-box-layout2 .item-figure {
  text-align: center;
}

.feature-box-layout2 .item-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout2 .item-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout2 .item-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout2 .item-title {
    font-size: 24px;
  }
}

.feature-box-layout2 .sub-title {
  font-weight: 500;
  font-size: 18px;
  font-style: italic;
  margin-bottom: 10px;
}

.feature-box-layout2 p {
  margin-bottom: 50px;
}

/*--- Feature Style Three ---*/
.feature-wrap-layout3 {
  background-color: #ffffff;
  padding: 112px 0 10px;
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout3 {
    padding: 97px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-layout3 {
    padding: 82px 0 10px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-wrap-layout3 {
    padding: 67px 0 10px;
  }
}

.feature-wrap-layout3 .compress-right-side {
  padding-left: 50px;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .feature-wrap-layout3 .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout3 .compress-right-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-layout3 .compress-right-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .feature-wrap-layout3 .compress-right-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.feature-wrap-layout3 .compress-right-side2 {
  padding-left: 50px;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .feature-wrap-layout3 .compress-right-side2 {
    padding-left: 0;
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout3 .compress-right-side2 {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-layout3 .compress-right-side2 {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .feature-wrap-layout3 .compress-right-side2 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.feature-wrap-layout3 .compress-left-side {
  padding-right: 0;
  padding-left: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .feature-wrap-layout3 .compress-left-side {
    padding-right: 0;
    padding-left: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout3 .compress-left-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-layout3 .compress-left-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .feature-wrap-layout3 .compress-left-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.feature-box-layout3 {
  position: relative;
  z-index: 1;
  margin-bottom: 80px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout3 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout3 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout3 {
    margin-bottom: 20px;
  }
}

.feature-box-layout3 .animated-shape1 {
  text-align: right;
}

@media only screen and (max-width: 575px) {
  .feature-box-layout3 .animated-shape1 {
    display: none;
  }
}

.feature-box-layout3 .animated-figure1 ul li {
  position: absolute;
  text-align: right;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .feature-box-layout3 .animated-figure1 ul li {
    transform: scale(0.9);
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout3 .animated-figure1 ul li {
    transform: scale(0.7);
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout3 .animated-figure1 ul li {
    text-align: center;
    transform: scale(1);
    left: 0;
    right: 0;
    position: inherit;
  }
}

.feature-box-layout3 .animated-figure1 ul li:nth-child(1n) {
  top: 0;
  right: 0;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(1n) {
    right: 7%;
  }
}

.feature-box-layout3 .animated-figure1 ul li:nth-child(2n) {
  top: 0;
  right: 42%;
}

@media only screen and (max-width: 1199px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(2n) {
    right: 39%;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(2n) {
    display: none;
  }
}

.feature-box-layout3 .animated-figure1 ul li:nth-child(3n) {
  top: 46%;
  right: 42%;
}

@media only screen and (max-width: 1239px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(3n) {
    top: 49%;
    right: 45%;
  }
}

@media only screen and (max-width: 1199px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(3n) {
    top: 44%;
    right: 40%;
  }
}

@media only screen and (max-width: 991px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(3n) {
    top: 34%;
    right: 38%;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(3n) {
    top: 37%;
    right: 42%;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(3n) {
    display: none;
  }
}

.feature-box-layout3 .animated-figure1 ul li:nth-child(4n) {
  top: 50%;
  right: 3%;
}

@media only screen and (max-width: 1239px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(4n) {
    top: 51%;
    right: 3%;
  }
}

@media only screen and (max-width: 1199px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(4n) {
    top: 47%;
    right: 3%;
  }
}

@media only screen and (max-width: 991px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(4n) {
    top: 35%;
    right: 7%;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(4n) {
    top: 36%;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout3 .animated-figure1 ul li:nth-child(4n) {
    display: none;
  }
}

.feature-box-layout3 .animated-figure2 .single-item {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout3 .animated-figure2 .single-item {
    text-align: center;
  }
}

.feature-box-layout3 .animated-figure2 .single-item img {
  box-shadow: 24px 0 59px 0 rgba(51, 58, 69, 0.15);
  border-radius: 4px;
}

.feature-box-layout3 .sub-title {
  font-size: 18px;
  font-weight: 500;
  color: #5a49f8;
  margin-bottom: 5px;
}

.feature-box-layout3 .item-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout3 .item-title {
    font-size: 32px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout3 .item-title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout3 .item-title {
    font-size: 28px;
  }
}

.feature-box-layout3 .item-btn {
  margin-top: 30px;
}

.feature-box-layout3 .list-item {
  margin-bottom: 50px;
}

.feature-box-layout3 .list-item li {
  position: relative;
  z-index: 1;
  padding-left: 30px;
  margin-bottom: 10px;
}

.feature-box-layout3 .list-item li:before {
  position: absolute;
  z-index: 1;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 0;
  top: 0;
  color: #5a49f8;
}

.feature-box-layout3 .list-item li:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  [class^="col-"]:last-child .feature-box-layout3 {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  [class^="col-"]:last-child .feature-box-layout3 {
    margin-bottom: 21px;
  }
}

@media only screen and (max-width: 575px) {
  [class^="col-"]:last-child .feature-box-layout3 {
    margin-bottom: 6px;
  }
}

/*--- Feature Style Four ---*/
.feature-wrap-layout4 {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .feature-wrap-layout4 .animated-buble {
    display: none;
  }
}

.feature-wrap-layout4 .animated-buble li {
  position: absolute;
  z-index: -1;
}

.feature-wrap-layout4 .animated-buble li:nth-child(1n) {
  top: -230px;
  left: -220px;
  right: inherit;
  bottom: inherit;
}

.feature-wrap-layout4 .animated-buble li:nth-child(2n) {
  top: -74px;
  left: 145px;
  right: inherit;
  bottom: inherit;
}

.feature-wrap-layout4 .animated-buble li:nth-child(3n) {
  top: -130px;
  left: 55%;
  right: inherit;
  bottom: inherit;
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout4 .animated-figure {
    display: none;
  }
}

.feature-wrap-layout4 .animated-figure li {
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
}

.feature-box-layout4 .feature-list li {
  padding-left: 30px;
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}

.feature-box-layout4 .feature-list li:before {
  position: absolute;
  z-index: 1;
  height: 9px;
  width: 9px;
  background-color: #ffffff;
  border-radius: 50%;
  content: "";
  top: 12px;
  left: 0;
}

.feature-box-layout4 .feature-list li:after {
  position: absolute;
  z-index: 1;
  height: calc(100% + 40px);
  width: 1px;
  background-color: rgba(255, 255, 255, 0.25);
  content: "";
  top: 21px;
  left: 4px;
}

.feature-box-layout4 .feature-list li:last-child {
  margin-bottom: 0;
}

.feature-box-layout4 .feature-list li:last-child:after {
  height: calc(100% - 30px);
}

.feature-box-layout4 .feature-list li .item-title {
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 0;
}

.feature-box-layout4 .feature-list li p {
  color: #ffffff;
}

.feature-box-layout4 .figure-holder {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 50px 0;
}

.feature-box-layout4 .figure-holder .inner-shape li {
  position: absolute;
  z-index: 1;
}

.feature-box-layout4 .figure-holder .inner-shape li:nth-child(1n) {
  left: inherit;
  bottom: inherit;
  right: 60px;
  top: -40px;
}

.feature-box-layout4 .figure-holder .inner-shape li:nth-child(2n) {
  left: -2px;
  bottom: 35px;
  right: inherit;
  top: inherit;
}

/*--- Feature Style Five ---*/
.feature-wrap-layout5 {
  background-color: #fafafa;
  padding: 120px 0 0;
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout5 {
    padding: 105px 0 45px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-layout5 {
    padding: 90px 0 30px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-wrap-layout5 {
    padding: 75px 0 15px;
  }
}

.feature-box-layout5 {
  margin-bottom: 120px;
}

@media only screen and (max-width: 1199px) {
  .feature-box-layout5 {
    margin-bottom: 90px;
  }
}

@media only screen and (max-width: 991px) {
  .feature-box-layout5 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout5 {
    margin-bottom: 30px;
  }
}

.feature-box-layout5 .item-figure {
  text-align: center;
  transition: all .3s ease-in-out;
}

.feature-box-layout5 .item-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout5 .item-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout5 .item-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout5 .item-title {
    font-size: 24px;
  }
}

.feature-box-layout5 .sub-title {
  font-weight: 500;
  font-size: 18px;
  font-style: italic;
  margin-bottom: 10px;
}

.feature-box-layout5 p {
  margin-bottom: 50px;
}

.feature-box-layout5 .list-layout1 li {
  display: flex;
  margin-bottom: 10px;
}

.feature-box-layout5 .list-layout1 li .item-icon {
  margin-right: 20px;
  margin-top: 8px;
}

.feature-box-layout5 .list-layout1 li .item-icon i:before {
  font-size: 42px;
}

.feature-box-layout5 .list-layout1 li .list-item-title {
  margin-bottom: 5px;
}

.feature-box-layout5 .list-layout1 li p {
  margin-bottom: 20px;
}

.feature-box-layout5 .list-layout1 li:last-child {
  margin-bottom: 0;
}

.feature-box-layout5 .list-layout1 li.color-dodger-blue-2 .item-icon i:before {
  color: #4771F9;
}

.feature-box-layout5 .list-layout1 li.color-radical-red .item-icon i:before {
  color: #F91D5B;
}

.feature-box-layout5 .list-layout2 li {
  padding-left: 35px;
  position: relative;
  z-index: 1;
}

.feature-box-layout5 .list-layout2 li:before {
  content: "\f00c";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  z-index: 1;
  left: 0;
  top: 8px;
  color: #ffffff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5a49f8;
}

.feature-box-layout5 .list-layout2 li .list-item-title {
  margin-bottom: 5px;
}

.feature-box-layout5 .list-layout2 li p {
  margin-bottom: 20px;
}

.feature-box-layout5 .list-layout2 li:last-child {
  margin-bottom: 0;
}

.feature-box-layout5 .list-layout2 li.color-torch-red:before {
  background-color: #FF1E45;
}

.feature-box-layout5 .list-layout2 li.color-mountain-meadow:before {
  background-color: #14C171;
}

.feature-box-layout5:hover .item-figure {
  transform: translate3d(-10px, 0, 0);
}

/*--- Feature Style Six ---*/
.feature-wrap-layout6 {
  position: relative;
  z-index: 1;
}

.feature-wrap-layout6 .animated-shape1 {
  position: absolute;
  bottom: -200px;
  left: 10%;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout6 .animated-shape1 {
    display: none;
  }
}

.feature-wrap-layout6 .compress-left-side {
  padding-right: 30px;
  padding-left: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .feature-wrap-layout6 .compress-left-side {
    padding-right: calc(calc(100% - 930px)/2);
    padding-left: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout6 .compress-left-side {
    padding-right: calc(calc(100% - 690px)/2);
    padding-left: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-layout6 .compress-left-side {
    padding-right: calc(calc(100% - 510px)/2);
    padding-left: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .feature-wrap-layout6 .compress-left-side {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.feature-wrap-layout6 .compress-right-side {
  padding-left: 30px;
  padding-right: calc(calc(100% - 1170px)/2);
}

@media only screen and (max-width: 1199px) {
  .feature-wrap-layout6 .compress-right-side {
    padding-left: calc(calc(100% - 930px)/2);
    padding-right: calc(calc(100% - 930px)/2);
  }
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout6 .compress-right-side {
    padding-left: calc(calc(100% - 690px)/2);
    padding-right: calc(calc(100% - 690px)/2);
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-layout6 .compress-right-side {
    padding-left: calc(calc(100% - 510px)/2);
    padding-right: calc(calc(100% - 510px)/2);
  }
}

@media only screen and (max-width: 575px) {
  .feature-wrap-layout6 .compress-right-side {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.feature-box-layout6 {
  margin-bottom: 120px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout6 {
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout6 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout6 {
    margin-bottom: 15px;
  }
}

.feature-box-layout6 .item-figure.text-left {
  transform: translateX(-15px);
}

.feature-box-layout6 .item-figure.text-right {
  transform: translateX(15px);
  position: relative;
  z-index: 1;
}

.feature-box-layout6 .item-figure.text-right .animated-shape2 {
  position: absolute;
  top: 0;
  right: 30%;
  z-index: -1;
}

.feature-box-layout6 .item-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout6 .item-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout6 .item-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout6 .item-title {
    font-size: 24px;
  }
}

.feature-box-layout6 .sub-title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
  color: #5a49f8;
}

.feature-box-layout6 p {
  margin-bottom: 50px;
}

.feature-box-layout6 .inner-item-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

/*--- Feature Style Seven ---*/
.feature-box-layout7 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout7 .item-figure {
    text-align: center;
  }
}

.feature-box-layout7 .main-title {
  font-weight: 600;
}

.feature-box-layout7 .feature-list li {
  background-color: #ffffff;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  padding: 30px;
  position: relative;
  z-index: 1;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.feature-box-layout7 .feature-list li:before {
  position: absolute;
  z-index: 1;
  height: 2px;
  width: 0;
  background-color: #5a49f8;
  content: "";
  right: 0;
  left: inherit;
  top: 0;
  border-radius: 3px 3px 0 0;
  transition: all 0.3s ease-in-out;
}

.feature-box-layout7 .feature-list li:last-child {
  margin-bottom: 0;
}

.feature-box-layout7 .feature-list li .inner-main-title {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
}

.feature-box-layout7 .feature-list li:hover {
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
}

.feature-box-layout7 .feature-list li:hover:before {
  width: 100%;
  left: 0;
  right: inherit;
}

.feature-box-layout7 .item-btn {
  background-color: #301ee4;
  border: 2px solid;
  border-color: #301ee4;
  font-weight: 500;
  font-size: 15px;
  position: relative;
  z-index: 2;
  padding: 9px 35px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.feature-box-layout7 .item-btn:hover {
  background-color: transparent;
  color: #301ee4;
}

/*--- Feature Style Eight ---*/
.feature-box-layout8 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout8 .item-figure {
    text-align: center;
    padding: 0 30px;
  }
}

.feature-box-layout8 .item-content {
  padding-left: 40px;
}

.feature-box-layout8 .item-content .item-title {
  font-weight: 600;
}

.feature-box-layout8 .item-content .list-item {
  margin-top: 30px;
}

.feature-box-layout8 .item-content .list-item li {
  margin-bottom: 20px;
  display: flex;
}

.feature-box-layout8 .item-content .list-item li:last-child {
  margin-bottom: 0;
}

.feature-box-layout8 .item-content .list-item li .item-icon {
  margin-right: 20px;
  height: 60px;
  width: 60px;
  background-color: #f5f4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #ffffff;
  margin-top: 8px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout8 .item-content .list-item li .item-icon {
    height: 55px;
    width: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout8 .item-content .list-item li .item-icon {
    height: 50px;
    width: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout8 .item-content .list-item li .item-icon {
    height: 45px;
    width: 45px;
  }
}

.feature-box-layout8 .item-content .list-item li .item-icon i:before {
  font-size: 24px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout8 .item-content .list-item li .item-icon i:before {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout8 .item-content .list-item li .item-icon i:before {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout8 .item-content .list-item li .item-icon i:before {
    font-size: 18px;
  }
}

.feature-box-layout8 .item-content .list-item li .inner-item-content {
  flex: 1;
}

.feature-box-layout8 .item-content .list-item li .inner-item-content .inner-title {
  margin-bottom: 0;
  font-weight: 500;
}

.feature-box-layout8 .item-content .list-item li .inner-item-content p {
  margin-bottom: 0;
}

.feature-box-layout8 .item-content .list-item li.icon-bg-dodger-blue-2 .item-icon {
  background-color: #4771F9;
}

.feature-box-layout8 .item-content .list-item li.icon-bg-radical-red .item-icon {
  background-color: #f91d5b;
}

/*--- Feature Style Nine ---*/
.feature-wrap-layout9 {
  background-color: #f6faff;
}

.feature-box-layout9 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout9 .item-figure {
    text-align: center;
    padding: 0 30px;
  }
}

.feature-box-layout9 .item-content {
  padding-left: 40px;
}

.feature-box-layout9 .item-content .item-title {
  font-size: 30px;
}

.feature-box-layout9 .item-content .list-item {
  margin-top: 30px;
}

.feature-box-layout9 .item-content .list-item li {
  margin-bottom: 20px;
  display: flex;
}

.feature-box-layout9 .item-content .list-item li:last-child {
  margin-bottom: 0;
}

.feature-box-layout9 .item-content .list-item li .item-icon {
  margin-right: 20px;
  height: 60px;
  width: 60px;
  background-color: #f5f4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #ffffff;
  margin-top: 8px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout9 .item-content .list-item li .item-icon {
    height: 55px;
    width: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout9 .item-content .list-item li .item-icon {
    height: 50px;
    width: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout9 .item-content .list-item li .item-icon {
    height: 45px;
    width: 45px;
  }
}

.feature-box-layout9 .item-content .list-item li .item-icon i:before {
  font-size: 24px;
}

@media only screen and (max-width: 991px) {
  .feature-box-layout9 .item-content .list-item li .item-icon i:before {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout9 .item-content .list-item li .item-icon i:before {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout9 .item-content .list-item li .item-icon i:before {
    font-size: 18px;
  }
}

.feature-box-layout9 .item-content .list-item li .inner-item-content {
  flex: 1;
}

.feature-box-layout9 .item-content .list-item li .inner-item-content .inner-title {
  margin-bottom: 0;
  font-weight: 500;
}

.feature-box-layout9 .item-content .list-item li .inner-item-content p {
  margin-bottom: 0;
}

.feature-box-layout9 .item-content .list-item li.icon-bg-dodger-blue-2 .item-icon {
  background-color: #4771F9;
}

.feature-box-layout9 .item-content .list-item li.icon-bg-radical-red .item-icon {
  background-color: #f91d5b;
}

.feature-box-layout9 .item-content .list-item li.icon-bg-west-side .item-icon {
  background-color: #FE910B;
}

/*--- Feature Style Ten ---*/
.feature-box-layout10 .tab-nav-list {
  border-bottom: 0;
  display: block;
  padding-left: 15px;
  margin-bottom: 30px;
}

.feature-box-layout10 .tab-nav-list .nav-item {
  margin-bottom: 10px;
  width: 100%;
}

.feature-box-layout10 .tab-nav-list .nav-item a {
  border-radius: 5px;
  width: 100%;
  padding: 35px 30px;
  background-color: #f7f7fe;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.feature-box-layout10 .tab-nav-list .nav-item a i {
  position: relative;
  z-index: 0;
  line-height: 1;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.feature-box-layout10 .tab-nav-list .nav-item a i:before {
  font-size: 26px;
  margin-left: 0;
  color: #ffffff;
}

.feature-box-layout10 .tab-nav-list .nav-item a span {
  margin-left: 12px;
  flex: 1;
  display: block;
  color: #111111;
  font-weight: 600;
  line-height: 1.8;
}

.feature-box-layout10 .tab-nav-list .nav-item a:hover {
  background-color: #ffffff;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
}

.feature-box-layout10 .tab-nav-list .nav-item a.active {
  background-color: #ffffff;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
}

.feature-box-layout10 .tab-nav-list .nav-item .icon-bg-california i {
  background-color: #ff930e;
}

.feature-box-layout10 .tab-nav-list .nav-item .icon-bg-mountain-meadow i {
  background-color: #14C171;
}

.feature-box-layout10 .tab-nav-list .nav-item .icon-bg-royal-blue i {
  background-color: #5a49f8;
}

.feature-box-layout10 .tab-nav-list .nav-item .icon-bg-torch-red i {
  background-color: #FF1E45;
}

.feature-box-layout10 .tab-nav-list .nav-item .icon-bg-turquoise i {
  background-color: #01daba;
}

.feature-box-layout10 .tab-nav-list .nav-item:last-child {
  margin-bottom: 0;
}

.feature-box-layout10 .tab-content {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .feature-box-layout10 .tab-content {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-box-layout10 .tab-content {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-box-layout10 .tab-content {
    margin-top: 15px;
  }
}

/*--- Feature Style Eleven ---*/
.feature-wrap-layout11 {
  padding: 108px 0 94px;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout11 {
    background-size: cover;
    padding: 93px 0 79px;
  }
}

@media only screen and (max-width: 767px) {
  .feature-wrap-layout11 {
    padding: 78px 0 64px;
  }
}

@media only screen and (max-width: 575px) {
  .feature-wrap-layout11 {
    padding: 63px 0 49px;
  }
}

.feature-wrap-layout11:before {
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 50%;
  background-color: #6b56f5;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout11:before {
    width: 100%;
    background-color: rgba(107, 86, 245, 0.9);
  }
}

.feature-wrap-layout11:after {
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 20%;
  background-image: linear-gradient(90deg, #6b56f5 0%, transparent 100%);
  left: 50%;
  top: 0;
}

@media only screen and (max-width: 991px) {
  .feature-wrap-layout11:after {
    display: none;
  }
}

.feature-box-layout11 {
  height: 100%;
  display: flex;
  align-items: center;
}

.feature-box-layout11 .item-content .item-title {
  color: #ffffff;
}

.feature-box-layout11 .item-content p {
  color: #ffffff;
}

.progress-wrap {
  margin-top: 10px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .progress-wrap {
    text-align: center;
  }
}

.progress-wrap .progress-circular {
  position: relative;
  display: inline-block;
}

.progress-wrap .progress-circular .knob {
  margin-top: 25px !important;
}

.progress-wrap .progress-circular .progress-title {
  top: 60%;
  transform: translateY(-50%) translateX(-50%);
  color: #ffffff;
  left: 50%;
  position: absolute;
  font-size: 16px;
}

.progress-wrap ul.list-item {
  margin-top: 10px;
}

.progress-wrap ul.list-item li {
  line-height: 24px;
  color: #ffffff;
}

/*--------------------------------------
	4.11 Gallery
---------------------------------------*/
.gallery-wrap-layout1 {
  position: relative;
  z-index: 1;
}

.gallery-wrap-layout1:after {
  content: "";
  position: absolute;
  z-index: -1;
  height: 60%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
}

.gallery-wrap-layout1 .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.gallery-box-layout1 {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.gallery-box-layout1:before {
  content: "";
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  border-radius: 4px;
  position: absolute;
  z-index: 2;
  background-color: white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.gallery-box-layout1:after {
  content: "";
  background-color: #5a49f8;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
  transform: scale(0.9);
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout1 .item-figure img {
  border-radius: 4px;
}

.gallery-box-layout1 .item-content {
  text-align: center;
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.gallery-box-layout1 .item-content .item-icon {
  margin-bottom: 18px;
  font-size: 40px;
  transform: translateY(-10px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout1 .item-content .item-icon a {
  color: #5a49f8;
}

.gallery-box-layout1 .item-content .item-title {
  padding: 0 10px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.1s ease-in-out;
}

.gallery-box-layout1 .item-content .item-title a {
  color: #111111;
  transition: all 0.5s ease-in-out;
}

.gallery-box-layout1 .item-content .item-title a:hover {
  color: #5a49f8;
}

.gallery-box-layout1 .item-content p {
  color: #777779;
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout1:hover:before {
  opacity: 1;
  visibility: visible;
}

.gallery-box-layout1:hover:after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
  transition-delay: 0.2s;
}

.gallery-box-layout1:hover .item-content .item-icon {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  transition-delay: 0.7s;
}

.gallery-box-layout1:hover .item-content .item-title {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  transition-delay: 0.5s;
}

.gallery-box-layout1:hover .item-content p {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  transition-delay: 0.6s;
}

.gallery-box-layout2 {
  position: relative;
  z-index: 1;
}

.gallery-box-layout2:before {
  content: "";
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  position: absolute;
  z-index: 2;
  background-color: white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.gallery-box-layout2:after {
  content: "";
  background-color: #5a49f8;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout2 .item-content {
  text-align: center;
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.gallery-box-layout2 .item-content .item-icon {
  margin-bottom: 18px;
  font-size: 40px;
  transform: translateY(-10px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout2 .item-content .item-icon a {
  color: #5a49f8;
}

.gallery-box-layout2 .item-content .item-title {
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.1s ease-in-out;
}

.gallery-box-layout2 .item-content .item-title a {
  color: #111111;
  transition: all 0.5s ease-in-out;
}

.gallery-box-layout2 .item-content .item-title a:hover {
  color: #5a49f8;
}

.gallery-box-layout2 .item-content p {
  color: #212127;
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout2:hover:before {
  opacity: 1;
  visibility: visible;
}

.gallery-box-layout2:hover:after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
  transition-delay: 0.2s;
}

.gallery-box-layout2:hover .item-content .item-icon {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  transition-delay: 0.7s;
}

.gallery-box-layout2:hover .item-content .item-title {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  transition-delay: 0.5s;
}

.gallery-box-layout2:hover .item-content p {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  transition-delay: 0.6s;
}

.gallery-box-layout3 {
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 30px;
}

.gallery-box-layout3 .item-figure {
  position: relative;
  z-index: 1;
}

.gallery-box-layout3 .item-figure:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px 5px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout3 .item-figure img {
  border-radius: 5px 5px 0 0;
}

.gallery-box-layout3 .item-icon {
  font-size: 30px;
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout3 .item-icon a {
  color: #ffffff;
}

.gallery-box-layout3 .item-content {
  padding: 30px 15px;
}

.gallery-box-layout3 .item-content .item-title {
  line-height: 28px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  transition: all 0.1s ease-in-out;
}

.gallery-box-layout3 .item-content .item-title a {
  color: #111111;
  transition: all 0.5s ease-in-out;
}

.gallery-box-layout3 .item-content .item-title a:hover {
  color: #5a49f8;
}

.gallery-box-layout3 .item-content p {
  color: #212127;
  margin-bottom: 0;
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout3:hover .item-figure:before {
  opacity: 1;
  visibility: visible;
}

.gallery-box-layout3:hover .item-icon {
  opacity: 1;
  visibility: visible;
  top: 50%;
  transition: all 0.3s ease-in-out;
}

.gallery-wrap-layout10 {
  background-color: #f9fbfe;
}

@media only screen and (min-width: 992px) {
  .gallery-wrap-layout10 {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media only screen and (min-width: 767px) {
  .gallery-wrap-layout10 {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.gallery-box-layout4 {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.gallery-box-layout4:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  border-radius: 4px;
  transform: scale(0.9);
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout4 .item-figure img {
  border-radius: 4px;
}

.gallery-box-layout4 .item-content {
  text-align: center;
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.gallery-box-layout4 .item-content .item-icon {
  margin-bottom: 25px;
  font-size: 40px;
  transform: translateY(-10px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout4 .item-content .item-icon a {
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout4 .item-content .item-icon a:hover {
  color: #5a49f8;
}

.gallery-box-layout4 .item-content .item-title {
  font-size: 20px;
  padding: 0 10px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 5px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.1s ease-in-out;
}

.gallery-box-layout4 .item-content .item-title a {
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.gallery-box-layout4 .item-content .item-title a:hover {
  color: #5a49f8;
}

.gallery-box-layout4 .item-content p {
  color: #f9f8f8;
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease-in-out;
}

.gallery-box-layout4:hover:before {
  opacity: 1;
  visibility: visible;
}

.gallery-box-layout4:hover:after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
  transition-delay: 0.2s;
}

.gallery-box-layout4:hover .item-content .item-icon {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  transition-delay: 0.7s;
}

.gallery-box-layout4:hover .item-content .item-title {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  transition-delay: 0.5s;
}

.gallery-box-layout4:hover .item-content p {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  transition-delay: 0.6s;
}

.gallery-details-box .item-figure {
  margin-bottom: 50px;
}

.gallery-details-box .item-figure img {
  border-radius: 4px;
}

@media only screen and (max-width: 767px) {
  .gallery-details-box .item-figure {
    margin-bottom: 30px;
  }
}

.gallery-details-box .item-content .item-title {
  font-weight: 600;
  margin-bottom: 15px;
}

.gallery-details-box2 .item-figure {
  margin-bottom: 30px;
}

.gallery-details-box2 .item-content {
  margin-bottom: 30px;
  padding-left: 30px;
}

@media only screen and (max-width: 991px) {
  .gallery-details-box2 .item-content {
    padding-left: 0;
  }
}

.gallery-details-box2 .item-content .item-title {
  font-weight: 600;
  margin-bottom: 15px;
}

.gallery-details-box2 .item-content .item-inner-title {
  font-weight: 600;
  color: #111111;
  font-size: 24px;
  margin-bottom: 10px;
}

.gallery-details-box2 .item-content .list-item {
  margin-top: 30px;
}

.gallery-details-box2 .item-content .list-item ul li {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 18px;
  color: #111111;
}

.gallery-details-box2 .item-content .list-item ul li span {
  margin-left: 15px;
  color: #646464;
  font-weight: 400;
}

.gallery-details-box2 .item-content .list-item ul li:last-child {
  margin-bottom: 0;
}

.gallery-details-box2 .item-content .social-wrap {
  margin-top: 45px;
  display: flex;
  align-items: center;
}

.gallery-details-box2 .item-content .social-wrap .social-title {
  margin-right: 20px;
  font-size: 18px;
}

.gallery-details-box2 .item-content .social-wrap .item-social a {
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 500;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.gallery-details-box2 .item-content .social-wrap .item-social a:last-child {
  margin-right: 0;
}

.gallery-details-box2 .item-content .social-wrap .item-social a:hover {
  color: #ffffff;
}

.gallery-details-box2 .item-content .social-wrap .item-social .facebook {
  color: #315db6;
  border: 1px solid #315db6;
}

.gallery-details-box2 .item-content .social-wrap .item-social .facebook:hover {
  background-color: #315db6;
}

.gallery-details-box2 .item-content .social-wrap .item-social .twitter {
  color: #00a7e6;
  border: 1px solid #00a7e6;
}

.gallery-details-box2 .item-content .social-wrap .item-social .twitter:hover {
  background-color: #00a7e6;
}

.gallery-details-box2 .item-content .social-wrap .item-social .pinterest {
  color: #dd2a7b;
  border: 1px solid #dd2a7b;
}

.gallery-details-box2 .item-content .social-wrap .item-social .pinterest:hover {
  background-color: #dd2a7b;
}

.gallery-details-box2 .item-content .social-wrap .item-social .linkedin {
  color: #0074b2;
  border: 1px solid #0074b2;
}

.gallery-details-box2 .item-content .social-wrap .item-social .linkedin:hover {
  background-color: #0074b2;
}

.related-gallery-carousel {
  margin-top: 83px;
}

@media only screen and (max-width: 991px) {
  .related-gallery-carousel {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .related-gallery-carousel {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .related-gallery-carousel {
    margin-top: 0;
  }
}

/*--------------------------------------
	4.12 Map
---------------------------------------*/
.map-layout1 {
  margin-top: 60px;
  height: 450px;
  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .map-layout1 {
    height: 350px;
  }
}

@media only screen and (max-width: 575px) {
  .map-layout1 {
    height: 300px;
  }
}

.map-layout2 {
  height: 450px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .map-layout2 {
    height: 350px;
  }
}

@media only screen and (max-width: 575px) {
  .map-layout2 {
    height: 300px;
  }
}

/*--------------------------------------
	4.13 Newsletter
---------------------------------------*/
/*--- Newsletter Layout One ---*/
.newsletter-form {
  width: 60%;
  margin: 0 auto 30px;
  display: inline-block;
}

@media only screen and (max-width: 991px) {
  .newsletter-form {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .newsletter-form {
    height: 110px;
  }
}

.newsletter-form .stylish-input-group {
  background: transparent;
  border: 0;
  height: 60px;
  border-radius: 4px;
}

@media only screen and (max-width: 575px) {
  .newsletter-form .stylish-input-group {
    display: block;
  }
}

.newsletter-form .stylish-input-group .item-icon {
  position: absolute;
  z-index: 4;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.newsletter-form .stylish-input-group .form-control {
  padding: 5px 20px 5px 50px;
  border: 1px solid #d9d9d9;
  box-shadow: none;
  border-radius: 4px 0 0 4px;
  background: #ffffff;
  color: #111111;
  height: 60px;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .newsletter-form .stylish-input-group .form-control {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
}

.newsletter-form .stylish-input-group .input-group-addon {
  padding: 0;
  border: none;
  border-radius: 0 4px 4px 0;
  background: #5a49f8;
  margin-bottom: 0;
  display: block;
}

@media only screen and (max-width: 575px) {
  .newsletter-form .stylish-input-group .input-group-addon {
    width: 100%;
    border-radius: 4px;
  }
}

.newsletter-form .stylish-input-group .input-group-addon:focus {
  outline: none;
}

.newsletter-form .stylish-input-group .input-group-addon button {
  display: block;
  padding: 0 40px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.newsletter-form .stylish-input-group .input-group-addon button:before {
  border-radius: 0 4px 4px 0;
  opacity: 1;
  visibility: visible;
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
  .newsletter-form .stylish-input-group .input-group-addon button:before {
    border-radius: 4px;
  }
}

.newsletter-form .stylish-input-group .input-group-addon button:after {
  border-radius: 0 4px 4px 0;
  opacity: 0;
  visibility: hidden;
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(60deg, #7a64f2 0%, #5a49f8 100%);
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
  .newsletter-form .stylish-input-group .input-group-addon button:after {
    border-radius: 4px;
  }
}

@media only screen and (max-width: 575px) {
  .newsletter-form .stylish-input-group .input-group-addon button {
    width: 100%;
    height: 50px;
  }
}

.newsletter-form .stylish-input-group .input-group-addon button span {
  color: #ffffff;
}

.newsletter-form .stylish-input-group .input-group-addon button:hover:after {
  opacity: 1;
  visibility: visible;
}

.newsletter-form .stylish-input-group .input-group-addon button:hover:before {
  opacity: 0;
  visibility: hidden;
}

.newsletter-form .stylish-input-group .input-group-addon button:focus {
  outline: none;
}

.newsletter-form .stylish-input-group input.form-control::-webkit-input-placeholder,
.newsletter-form .stylish-input-group textarea.form-control::-webkit-input-placeholder {
  color: #646464;
}

.newsletter-form .stylish-input-group input.form-control::-moz-placeholder,
.newsletter-form .stylish-input-group textarea.form-control::-moz-placeholder {
  color: #646464;
}

.newsletter-form .stylish-input-group input.form-control:-moz-placeholder,
.newsletter-form .stylish-input-group textarea.form-control:-moz-placeholder {
  color: #646464;
}

.newsletter-form .stylish-input-group input.form-control:-ms-input-placeholder,
.newsletter-form .stylish-input-group textarea.form-control:-ms-input-placeholder {
  color: #646464;
}

/*--- Newsletter Layout Two ---*/
.newsletter-form2 {
  width: 60%;
  margin: 0 auto 30px;
  display: inline-block;
}

@media only screen and (max-width: 991px) {
  .newsletter-form2 {
    width: 100%;
  }
}

.newsletter-form2 .stylish-input-group {
  background: transparent;
  border: 0;
  height: 60px;
  border-radius: 4px;
}

@media only screen and (max-width: 575px) {
  .newsletter-form2 .stylish-input-group {
    display: block;
  }
}

.newsletter-form2 .stylish-input-group .item-icon {
  position: absolute;
  z-index: 4;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 575px) {
  .newsletter-form2 .stylish-input-group .item-icon {
    top: 44%;
  }
}

.newsletter-form2 .stylish-input-group .form-control {
  padding: 5px 20px 5px 50px;
  border: 1px solid #d9d9d9;
  box-shadow: none;
  border-radius: 4px 0 0 4px;
  background: #ffffff;
  color: #111111;
  height: 60px;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .newsletter-form2 .stylish-input-group .form-control {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
}

.newsletter-form2 .stylish-input-group .input-group-addon {
  padding: 0;
  border: none;
  border-radius: 0 4px 4px 0;
  margin-bottom: 0;
  display: block;
}

@media only screen and (max-width: 575px) {
  .newsletter-form2 .stylish-input-group .input-group-addon {
    width: 100%;
    border-radius: 4px;
  }
}

.newsletter-form2 .stylish-input-group .input-group-addon:focus {
  outline: none;
}

.newsletter-form2 .stylish-input-group .input-group-addon button {
  display: block;
  padding: 0 40px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.newsletter-form2 .stylish-input-group .input-group-addon button:before {
  border-radius: 0 4px 4px 0;
  opacity: 1;
  visibility: visible;
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(60deg, #ffffff 0%, #ffffff 100%);
  transition: all 0.3s ease-in-out;
}

.newsletter-form2 .stylish-input-group .input-group-addon button:after {
  border-radius: 0 4px 4px 0;
  opacity: 0;
  visibility: hidden;
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(60deg, #7a64f2 0%, #5a49f8 100%);
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
  .newsletter-form2 .stylish-input-group .input-group-addon button {
    width: 100%;
    height: 50px;
  }
}

.newsletter-form2 .stylish-input-group .input-group-addon button span {
  color: #5a49f8;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.newsletter-form2 .stylish-input-group .input-group-addon button:hover:after {
  opacity: 1;
  visibility: visible;
}

.newsletter-form2 .stylish-input-group .input-group-addon button:hover:before {
  opacity: 0;
  visibility: hidden;
}

.newsletter-form2 .stylish-input-group .input-group-addon button:hover span {
  color: #ffffff;
}

.newsletter-form2 .stylish-input-group .input-group-addon button:focus {
  outline: none;
}

.newsletter-form2 .stylish-input-group input.form-control::-webkit-input-placeholder,
.newsletter-form2 .stylish-input-group textarea.form-control::-webkit-input-placeholder {
  color: #646464;
}

.newsletter-form2 .stylish-input-group input.form-control::-moz-placeholder,
.newsletter-form2 .stylish-input-group textarea.form-control::-moz-placeholder {
  color: #646464;
}

.newsletter-form2 .stylish-input-group input.form-control:-moz-placeholder,
.newsletter-form2 .stylish-input-group textarea.form-control:-moz-placeholder {
  color: #646464;
}

.newsletter-form2 .stylish-input-group input.form-control:-ms-input-placeholder,
.newsletter-form2 .stylish-input-group textarea.form-control:-ms-input-placeholder {
  color: #646464;
}

/*--------------------------------------
	4.14 Instagram
---------------------------------------*/
.instagram-wrap-layout1 {
  padding-left: 15px;
  padding-right: 15px;
}

.instagram-title-dark {
  text-align: center;
  font-weight: 700;
  color: #111111;
}

.instagram-title-light {
  text-align: center;
  font-weight: 700;
  color: #ffffff;
}

.instagram-box-layout1 {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

.instagram-box-layout1 .item-figure {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: block;
}

.instagram-box-layout1 .item-figure:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #750ed5;
}

.instagram-box-layout1 .item-figure img {
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.instagram-box-layout1 .entry-meta {
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.instagram-box-layout1 .entry-meta li {
  margin-right: 15px;
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease-in-out;
}

.instagram-box-layout1 .entry-meta li a {
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.instagram-box-layout1 .entry-meta li a:hover {
  color: #5a49f8;
}

.instagram-box-layout1 .entry-meta li i {
  margin-right: 5px;
}

.instagram-box-layout1 .entry-meta li:last-child {
  margin-right: 0;
}

.instagram-box-layout1:hover .item-figure img {
  opacity: 0.3;
  transform: scale(1.1);
}

.instagram-box-layout1:hover .entry-meta li {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.instagram-wrap-layout2 {
  background-color: #750ed5;
  padding-top: 45px;
}

/*--------------------------------------
	4.15 Progress
---------------------------------------*/
.progress-wrap-layout1 .progress-inner-wrap {
  padding: 105px 0 75px;
}

@media only screen and (max-width: 1199px) {
  .progress-wrap-layout1 .progress-inner-wrap {
    padding: 95px 0 65px;
  }
}

@media only screen and (max-width: 991px) {
  .progress-wrap-layout1 .progress-inner-wrap {
    padding: 85px 0 55px;
  }
}

@media only screen and (max-width: 767px) {
  .progress-wrap-layout1 .progress-inner-wrap {
    padding: 75px 0 45px;
  }
}

@media only screen and (max-width: 575px) {
  .progress-wrap-layout1 .progress-inner-wrap {
    padding: 65px 0 35px;
  }
}

.progress-box-layout1 {
  text-align: center;
  margin-bottom: 30px;
}

.progress-box-layout1 .counting-text {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 5px;
}

.progress-box-layout1 .item-label {
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .progress-box-layout1 .item-label {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .progress-box-layout1 .item-label {
    font-size: 18px;
  }
}

.progress-wrap-layout2 {
  padding: 100px 0 52px;
}

@media only screen and (max-width: 991px) {
  .progress-wrap-layout2 {
    padding: 90px 0 42px;
  }
}

@media only screen and (max-width: 767px) {
  .progress-wrap-layout2 {
    padding: 80px 0 32px;
  }
}

@media only screen and (max-width: 575px) {
  .progress-wrap-layout2 {
    padding: 70px 0 22px;
  }
}

.progress-box-layout2 {
  text-align: center;
  margin-bottom: 40px;
}

.progress-box-layout2 .item-icon {
  color: #ffffff;
  margin-bottom: 10px;
}

.progress-box-layout2 .item-icon i:before {
  font-size: 56px;
}

.progress-box-layout2 .counting-text {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0;
}

.progress-box-layout2 .item-label {
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
}

.progress-box-layout3 {
  text-align: center;
  margin-top: 50px;
}

.progress-box-layout3 .counting-text {
  color: #ffffff;
  font-weight: 300;
  font-size: 60px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1199px) {
  .progress-box-layout3 .counting-text {
    font-size: 52px;
  }
}

@media only screen and (max-width: 991px) {
  .progress-box-layout3 .counting-text {
    font-size: 48px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .progress-box-layout3 .counting-text {
    font-size: 42px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .progress-box-layout3 .counting-text {
    font-size: 36px;
  }
}

.progress-box-layout3 .item-label {
  font-size: 24px;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .progress-box-layout3 .item-label {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .progress-box-layout3 .item-label {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .inner-progress {
    margin-bottom: 22px;
  }
}

.progress-box-layout4 {
  margin-bottom: 23px;
}

@media only screen and (max-width: 767px) {
  .progress-box-layout4 {
    margin-bottom: 30px;
    text-align: center;
  }
}

.progress-box-layout4 .counting-text {
  color: #5a49f8;
  font-weight: 700;
  margin-bottom: 0;
}

.progress-box-layout4 .item-label {
  font-size: 22px;
  color: #111111;
}

@media only screen and (max-width: 767px) {
  .progress-box-layout4 .item-label {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .progress-box-layout4 .item-label {
    font-size: 18px;
  }
}

.progress-wrap-layout3 {
  padding: 80px 0;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .progress-wrap-layout3 {
    padding: 70px 0;
  }
}

@media only screen and (max-width: 767px) {
  .progress-wrap-layout3 {
    padding: 60px 0;
  }
}

@media only screen and (max-width: 575px) {
  .progress-wrap-layout3 {
    padding: 50px 0;
  }
}

.progress-wrap-layout3:before {
  content: "";
  background: #4538ba;
  background: linear-gradient(60deg, rgba(69, 56, 186, 0.8) 16%, rgba(43, 154, 212, 0.8) 100%);
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.progress-content-layout1 {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.progress-content-layout1 .item-title {
  color: #ffffff;
  font-weight: 300;
  font-size: 45px;
  line-height: 62px;
}

@media only screen and (max-width: 991px) {
  .progress-content-layout1 .item-title {
    font-size: 40px;
    line-height: 58px;
  }
}

@media only screen and (max-width: 767px) {
  .progress-content-layout1 .item-title {
    font-size: 36px;
    line-height: 52px;
  }
}

@media only screen and (max-width: 575px) {
  .progress-content-layout1 .item-title {
    font-size: 30px;
    line-height: 48px;
  }
}

.progress-content-layout1 p {
  color: #ffffff;
}

.progress-box-layout5 {
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid rgba(255, 255, 255, 0.45);
}

.progress-box-layout5 .counting-text {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0px;
  font-size: 30px;
}

.progress-box-layout5 .item-label {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.progress-box-layout6 {
  text-align: center;
  margin-bottom: 30px;
}

.progress-box-layout6 .counting-text {
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 5px;
}

.progress-box-layout6 .item-label {
  font-size: 22px;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .progress-box-layout6 .item-label {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .progress-box-layout6 .item-label {
    font-size: 18px;
  }
}

/*--------------------------------------
	4.16 Process
---------------------------------------*/
.process-wrap-layout1 {
  overflow: hidden;
}

.process-box-layout1 {
  margin-bottom: 30px;
}

.process-box-layout1 ul.content-holder {
  padding: 30px 30px 0;
}

.process-box-layout1 ul.content-holder li {
  margin-bottom: 125px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .process-box-layout1 ul.content-holder li {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .process-box-layout1 ul.content-holder li {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .process-box-layout1 ul.content-holder li {
    margin-bottom: 20px;
  }
}

.process-box-layout1 ul.content-holder li svg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
}

.process-box-layout1 ul.content-holder li svg .solid-line {
  stroke-dashoffset: 0;
}

@media only screen and (max-width: 1199px) {
  .process-box-layout1 ul.content-holder li svg {
    display: none;
  }
}

.process-box-layout1 ul.content-holder li:before {
  content: "";
  position: absolute;
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  top: 80%;
  left: 0;
  height: 100%;
  width: 100%;
}

.process-box-layout1 ul.content-holder li .item-icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 160px;
  width: 160px;
  border-radius: 50%;
  box-shadow: 0 0 35px 0 rgba(119, 119, 120, 0.13);
}

@media only screen and (max-width: 767px) {
  .process-box-layout1 ul.content-holder li .item-icon {
    height: 120px;
    width: 120px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .process-box-layout1 ul.content-holder li .item-icon {
    height: 100px;
    width: 100px;
    margin-top: 10px;
  }
}

.process-box-layout1 ul.content-holder li .item-icon:before {
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.process-box-layout1 ul.content-holder li .item-icon i {
  transition: all 0.5s ease-in-out;
}

.process-box-layout1 ul.content-holder li .item-icon i:before {
  font-size: 72px;
}

@media only screen and (max-width: 767px) {
  .process-box-layout1 ul.content-holder li .item-icon i:before {
    font-size: 42px;
  }
}

@media only screen and (max-width: 575px) {
  .process-box-layout1 ul.content-holder li .item-icon i:before {
    font-size: 36px;
  }
}

.process-box-layout1 ul.content-holder li .dodger-blue:before {
  background-color: #36bafe;
}

.process-box-layout1 ul.content-holder li .dodger-blue i {
  color: #36bafe;
}

.process-box-layout1 ul.content-holder li .sunset-orange:before {
  background-color: #ff3838;
}

.process-box-layout1 ul.content-holder li .sunset-orange i {
  color: #ff3838;
}

.process-box-layout1 ul.content-holder li .california:before {
  background-color: #ff930e;
}

.process-box-layout1 ul.content-holder li .california i {
  color: #ff930e;
}

.process-box-layout1 ul.content-holder li:nth-child(odd) .item-icon {
  margin-right: 20px;
}

.process-box-layout1 ul.content-holder li:nth-child(odd) .item-content {
  text-align: left;
}

.process-box-layout1 ul.content-holder li:nth-child(even) .item-icon {
  margin-left: 20px;
}

@media only screen and (max-width: 1199px) {
  .process-box-layout1 ul.content-holder li:nth-child(even) .item-icon {
    margin-left: 0;
    margin-right: 20px;
  }
}

.process-box-layout1 ul.content-holder li:nth-child(even) .item-content {
  text-align: right;
}

@media only screen and (max-width: 1199px) {
  .process-box-layout1 ul.content-holder li:nth-child(even) .item-content {
    text-align: left;
  }
}

.process-box-layout1 ul.content-holder li:last-child {
  margin-bottom: -10px;
}

.process-box-layout1 ul.content-holder li:last-child:before {
  display: none;
}

.process-box-layout1 ul.content-holder li:hover .item-icon:before {
  opacity: 1;
  visibility: visible;
}

.process-box-layout1 ul.content-holder li:hover .item-icon i {
  color: #ffffff;
}

.process-box-layout1 ul.content-holder li .item-content {
  flex: 1;
}

.process-box-layout1 ul.content-holder li .item-content .item-title {
  font-weight: 600;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .process-box-layout1 ul.content-holder li .item-content p {
    margin-bottom: 0;
  }
}

.process-box-layout1 .figure-holder {
  position: relative;
  z-index: 1;
}

.process-box-layout1 .figure-holder .animated-bg {
  position: absolute;
  z-index: -1;
  left: 50px;
  top: 0;
}

@media only screen and (max-width: 1199px) {
  .process-box-layout1 .figure-holder .animated-bg {
    display: none;
  }
}

.process-box-layout1 .figure-holder .animated-figure {
  text-align: center;
  padding: 0 30px;
}

@media only screen and (max-width: 767px) {
  .process-box-layout1 .figure-holder .animated-figure {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 1199px) {
  .process-wrap-layout2 .gutters-80 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .process-wrap-layout2 .gutters-80 > [class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.process-box-layout2 {
  text-align: center;
  margin-bottom: 30px;
  background-color: #ffffff;
  height: 220px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.process-box-layout2 .count-number {
  position: absolute;
  z-index: 1;
  font-size: 54px;
  color: #e0e0e0;
  font-weight: 700;
  left: 30px;
  line-height: 1;
  transition: all 0.5s ease-in-out;
}

.process-box-layout2 .item-content {
  margin: 30px 0;
}

.process-box-layout2 .item-content .item-title {
  font-weight: 600;
  margin-bottom: 0;
}

.process-box-layout2 .item-content .item-icon {
  margin-bottom: 10px;
}

.process-box-layout2 .item-content .item-icon i {
  transition: all 0.5s ease-in-out;
}

.process-box-layout2 .item-content .item-icon i:before {
  font-size: 72px;
}

.process-box-layout2 .california i {
  color: #ff930e;
}

.process-box-layout2 .emerald i {
  color: #18dc59;
}

.process-box-layout2 .royal-blue i {
  color: #5a49f8;
}

.process-box-layout2 .dodger-blue i {
  color: #36bafe;
}

.process-box-layout2:hover .count-number {
  color: #5a49f8;
  animation: zoom_animation 0.5s linear forwards;
}

@keyframes zoom_animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

[class*="col-"]:nth-child(odd) .process-box-layout2 {
  align-items: flex-end;
}

[class*="col-"]:nth-child(odd) .process-box-layout2 .count-number {
  top: 20px;
}

[class*="col-"]:nth-child(even) .process-box-layout2 {
  align-items: flex-start;
}

[class*="col-"]:nth-child(even) .process-box-layout2 .count-number {
  bottom: 20px;
}

.has-animation .process-shap-layout2 {
  position: relative;
  z-index: 0;
}

.has-animation .process-shap-layout2 svg {
  position: absolute;
  z-index: -1;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.has-animation .process-shap-layout2 .dashed1 {
  stroke-dashoffset: 1300;
}

.has-animation .process-shap-layout2 .dashed1 {
  stroke-dasharray: 1300;
}

.has-animation .process-shap-layout2 .dashed2 {
  stroke-dasharray: 8;
}

@keyframes dash_animation {
  to {
    stroke-dashoffset: 0;
  }
}

.has-animation.active-animation .process-shap-layout2 .dashed1 {
  animation: dash_animation 4s linear forwards;
  animation-delay: 1s;
}

.process-wrap-layout3 .shape-holder {
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 1250px) {
  .process-wrap-layout3 .shape-holder {
    display: none;
  }
}

.process-wrap-layout3 .shape-holder .animated-bg1 {
  position: absolute;
  z-index: -1;
  right: 60px;
  top: -400px;
}

.process-wrap-layout3 .shape-holder .animated-bg1 svg {
  text-align: right;
}

.process-wrap-layout3 .shape-holder .animated-bg2 {
  position: absolute;
  z-index: -1;
  right: 190px;
  top: -300px;
}

.process-wrap-layout3 .shape-holder .animated-bg2 svg {
  text-align: right;
}

.process-box-layout3 {
  text-align: center;
  margin-bottom: 21px;
}

.process-box-layout3 .item-icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 160px;
  width: 160px;
  border-radius: 50%;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .process-box-layout3 .item-icon {
    height: 120px;
    width: 120px;
  }
}

@media only screen and (max-width: 575px) {
  .process-box-layout3 .item-icon {
    height: 100px;
    width: 100px;
  }
}

.process-box-layout3 .item-icon i {
  transition: all 0.5s ease-in-out;
}

.process-box-layout3 .item-icon i:before {
  font-size: 72px;
}

@media only screen and (max-width: 767px) {
  .process-box-layout3 .item-icon i:before {
    font-size: 56px;
  }
}

@media only screen and (max-width: 575px) {
  .process-box-layout3 .item-icon i:before {
    font-size: 48px;
  }
}

.process-box-layout3 .california {
  box-shadow: 0 0 25px 0 rgba(255, 147, 14, 0.1);
}

.process-box-layout3 .california i {
  color: #ff930e;
}

.process-box-layout3 .emerald {
  box-shadow: 0 0 25px 0 rgba(24, 220, 89, 0.1);
}

.process-box-layout3 .emerald i {
  color: #18dc59;
}

.process-box-layout3 .royal-blue {
  box-shadow: 0 0 25px 0 rgba(90, 73, 248, 0.1);
}

.process-box-layout3 .royal-blue i {
  color: #5a49f8;
}

.process-box-layout3 .dodger-blue {
  box-shadow: 0 0 25px 0 rgba(54, 186, 254, 0.1);
}

.process-box-layout3 .dodger-blue i {
  color: #36bafe;
}

.process-box-layout3:hover .item-icon i {
  color: #ffffff;
}

.process-box-layout3:hover .california {
  background-color: #ff930e;
}

.process-box-layout3:hover .emerald {
  background-color: #18dc59;
}

.process-box-layout3:hover .royal-blue {
  background-color: #5a49f8;
}

.process-box-layout3:hover .dodger-blue {
  background-color: #36bafe;
}

.process-box-layout3 .item-content .item-title {
  font-weight: 600;
  margin-bottom: 0;
}

.has-animation .process-shap-layout3 {
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 1250px) {
  .has-animation .process-shap-layout3 {
    display: none;
  }
}

.has-animation .process-shap-layout3 .shap-point li {
  color: #c2c2c2;
}

.has-animation .process-shap-layout3 .shap-point .start-point {
  position: absolute;
  z-index: 0;
  top: -7px;
  left: 0;
}

.has-animation .process-shap-layout3 .shap-point .end-point {
  position: absolute;
  z-index: 0;
  top: -5px;
  right: 0;
}

.has-animation .process-shap-layout3 svg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
}

.has-animation .process-shap-layout3 .dashed1 {
  stroke-dashoffset: 1300;
}

.has-animation .process-shap-layout3 .dashed1 {
  stroke-dasharray: 1300;
}

.has-animation .process-shap-layout3 .dashed2 {
  stroke-dasharray: 4;
}

@-webkit-keyframes dash_animation {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash_animation {
  to {
    stroke-dashoffset: 0;
  }
}

.has-animation.active-animation .process-shap-layout3 .dashed1 {
  animation: dash_animation 4s linear forwards;
}

.process-wrap-layout4 {
  padding: 110px 0 37px;
}

@media only screen and (max-width: 991px) {
  .process-wrap-layout4 {
    padding: 95px 0 22px;
  }
}

@media only screen and (max-width: 767px) {
  .process-wrap-layout4 {
    padding: 80px 0 7px;
  }
}

@media only screen and (max-width: 575px) {
  .process-wrap-layout4 {
    padding: 65px 0 0;
  }
}

.process-box-layout4 {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  padding-bottom: 13px;
}

@media only screen and (max-width: 1199px) {
  .process-box-layout4 {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .process-box-layout4 {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .process-box-layout4 {
    margin-bottom: 10px;
    padding-bottom: 9px;
    padding-left: 0;
    padding-right: 0;
  }
}

.process-box-layout4:after {
  content: "";
  width: 80%;
  height: 1px;
  background-color: #d9d9d9;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  position: absolute;
  z-index: 1;
  display: none;
}

@media only screen and (max-width: 1199px) {
  .process-box-layout4:after {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .process-box-layout4:after {
    width: 60%;
  }
}

.process-box-layout4:before {
  content: "";
  width: 1px;
  height: 70%;
  background-color: #d9d9d9;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .process-box-layout4:before {
    top: 38%;
  }
}

@media only screen and (max-width: 767px) {
  .process-box-layout4:before {
    display: none;
  }
}

.process-box-layout4 .item-icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .process-box-layout4 .item-icon {
    margin-bottom: 15px;
  }
}

.process-box-layout4 .item-icon i {
  transition: all 0.5s ease-in-out;
}

.process-box-layout4 .item-icon i:before {
  font-size: 56px;
}

.process-box-layout4 .california i {
  color: #ff930e;
}

.process-box-layout4 .emerald i {
  color: #18dc59;
}

.process-box-layout4 .royal-blue i {
  color: #5a49f8;
}

.process-box-layout4 .sunset-orange i {
  color: #ff3838;
}

.process-box-layout4 .item-content {
  padding: 0 15px;
}

.process-box-layout4 .item-content .item-title {
  font-weight: 600;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .process-box-layout4 .item-content .item-title {
    margin-bottom: 0;
  }
}

.process-box-layout4:hover .item-icon {
  animation: zoom_animation 0.5s linear forwards;
}

@keyframes zoom_animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

[class^="col-"]:last-child .process-box-layout4:before {
  display: none;
}

@media only screen and (min-width: 1200px) {
  [class^="col-"]:nth-child(12n) .process-box-layout4:before,
  [class^="col-"]:nth-child(8n) .process-box-layout4:before,
  [class^="col-"]:nth-child(4n) .process-box-layout4:before {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  [class^="col-"]:nth-child(even) .process-box-layout4:before {
    display: none;
  }
}

[class^="col-"]:last-child .process-box-layout4:after,
[class^="col-"]:nth-last-child(2) .process-box-layout4:after {
  display: none;
}

@media only screen and (max-width: 767px) {
  [class^="col-"]:nth-last-child(2) .process-box-layout4:after {
    display: block;
  }
}

.process-wrap-layout5 {
  margin-bottom: 120px;
}

.process-box-layout5 {
  text-align: center;
  position: relative;
  z-index: 1;
  bottom: -120px;
}

.process-box-layout5 .item-img {
  position: relative;
  z-index: 1;
  display: inline-block;
}

.process-box-layout5 .item-img:after {
  position: absolute;
  content: "";
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}

.process-box-layout5 .item-img img {
  border-radius: 4px;
}

.process-box-layout5 .mask-item {
  margin-top: 15px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

@media only screen and (max-width: 575px) {
  .process-box-layout5 .mask-item {
    width: 95%;
  }
}

.process-box-layout5 .play-btn {
  margin-bottom: 30px;
  display: inline-block;
}

@media only screen and (max-width: 575px) {
  .process-box-layout5 .play-btn {
    margin-bottom: 15px;
  }
}

.process-box-layout5 .play-btn .item-icon {
  margin: 0 auto;
  position: relative;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #5a49f8;
  font-size: 30px;
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 575px) {
  .process-box-layout5 .play-btn .item-icon {
    height: 50px;
    width: 50px;
    font-size: 18px;
  }
}

.process-box-layout5 .play-btn .item-icon:after {
  border-radius: 50%;
  position: absolute;
  z-index: -3;
  content: "";
  height: 100px;
  width: 100px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: rgba(90, 73, 248, 0.6);
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 575px) {
  .process-box-layout5 .play-btn .item-icon:after {
    height: 75px;
    width: 75px;
  }
}

.process-box-layout5 .play-btn .item-icon i {
  margin-left: 5px;
}

.process-box-layout5 .play-btn:hover .item-icon {
  background-color: #750ed5;
}

.process-box-layout5 .item-title {
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .process-box-layout5 .item-title {
    font-size: 24px;
    line-height: 32px;
  }
}

.process-wrap-layout6 {
  padding: 110px 0 60px;
}

@media only screen and (max-width: 1199px) {
  .process-wrap-layout6 {
    padding: 110px 0 46px;
  }
}

@media only screen and (max-width: 991px) {
  .process-wrap-layout6 {
    padding: 95px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .process-wrap-layout6 {
    padding: 80px 0 25px;
  }
}

@media only screen and (max-width: 575px) {
  .process-wrap-layout6 {
    padding: 65px 0 10px;
  }
}

.process-box-layout6 {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  padding-bottom: 13px;
}

@media only screen and (max-width: 1199px) {
  .process-box-layout6 {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .process-box-layout6 {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .process-box-layout6 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.process-box-layout6:after {
  content: "";
  width: 20%;
  height: 1px;
  background-color: #d9d9d9;
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
  position: absolute;
  z-index: 1;
  display: none;
}

@media only screen and (max-width: 1199px) {
  .process-box-layout6:after {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .process-box-layout6:after {
    width: 20%;
  }
}

.process-box-layout6:before {
  content: "";
  width: 1px;
  height: 30%;
  background-color: #d9d9d9;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .process-box-layout6:before {
    top: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .process-box-layout6:before {
    display: none;
  }
}

.process-box-layout6 .item-icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .process-box-layout6 .item-icon {
    margin-bottom: 15px;
  }
}

.process-box-layout6 .item-icon i {
  transition: all 0.5s ease-in-out;
}

.process-box-layout6 .item-icon i:before {
  font-size: 56px;
}

.process-box-layout6 .item-content {
  padding: 0 15px;
}

.process-box-layout6 .item-content .item-title {
  font-weight: 600;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .process-box-layout6 .item-content .item-title {
    margin-bottom: 0;
  }
}

.process-box-layout6:hover .item-icon {
  animation: zoom_animation 0.5s linear forwards;
}

@keyframes zoom_animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

[class^="col-"]:last-child .process-box-layout6:before {
  display: none;
}

@media only screen and (min-width: 1200px) {
  [class^="col-"]:nth-child(12n) .process-box-layout6:before,
  [class^="col-"]:nth-child(8n) .process-box-layout6:before,
  [class^="col-"]:nth-child(4n) .process-box-layout6:before {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  [class^="col-"]:nth-child(even) .process-box-layout6:before {
    display: none;
  }
}

[class^="col-"]:last-child .process-box-layout6:after,
[class^="col-"]:nth-last-child(2) .process-box-layout6:after {
  display: none;
}

@media only screen and (max-width: 767px) {
  [class^="col-"]:nth-last-child(2) .process-box-layout6:after {
    display: block;
  }
}

.process-wrap-layout7 {
  position: relative;
  z-index: 1;
}

.process-wrap-layout7:after {
  content: "";
  background-color: #f9fbfe;
  height: 50%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 0;
}

.process-wrap-layout7:before {
  content: "";
  background-color: #ffffff;
  height: 50%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 0;
}

.process-box-layout7 {
  text-align: center;
  position: relative;
  z-index: 1;
}

.process-box-layout7 .item-img {
  position: relative;
  z-index: 1;
  display: inline-block;
}

.process-box-layout7 .item-img:after {
  position: absolute;
  content: "";
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  border-radius: 15px;
}

.process-box-layout7 .item-img img {
  border-radius: 15px;
}

.process-box-layout7 .mask-item {
  margin-top: 15px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

@media only screen and (max-width: 575px) {
  .process-box-layout7 .mask-item {
    width: 95%;
  }
}

.process-box-layout7 .play-btn {
  margin-bottom: 30px;
  display: inline-block;
}

@media only screen and (max-width: 575px) {
  .process-box-layout7 .play-btn {
    margin-bottom: 15px;
  }
}

.process-box-layout7 .play-btn .item-icon {
  margin: 0 auto;
  position: relative;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 22px;
  background: rgba(90, 73, 248, 0.7);
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 575px) {
  .process-box-layout7 .play-btn .item-icon {
    height: 50px;
    width: 50px;
    font-size: 18px;
  }
}

.process-box-layout7 .play-btn .item-icon:after {
  opacity: 1;
  visibility: visible;
  border-radius: 50%;
  position: absolute;
  z-index: -3;
  content: "";
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background: rgba(90, 73, 248, 0.7);
  background: linear-gradient(60deg, rgba(90, 73, 248, 0.7) 0%, rgba(122, 100, 242, 0.7) 100%);
  transition: all 0.3s ease-in-out;
}

.process-box-layout7 .play-btn .item-icon:before {
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  position: absolute;
  z-index: -3;
  content: "";
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background: rgba(122, 100, 242, 0.7);
  background: linear-gradient(60deg, rgba(122, 100, 242, 0.7) 0%, rgba(90, 73, 248, 0.7) 100%);
  transition: all 0.3s ease-in-out;
}

.process-box-layout7 .play-btn .item-icon i {
  margin-left: 5px;
}

.process-box-layout7 .play-btn:hover .item-icon:after {
  opacity: 0;
  visibility: hidden;
}

.process-box-layout7 .play-btn:hover .item-icon:before {
  opacity: 1;
  visibility: visible;
}

.process-box-layout8 {
  text-align: center;
  margin-bottom: 21px;
}

.process-box-layout8 .item-icon {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 160px;
  width: 160px;
  border-radius: 50%;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .process-box-layout8 .item-icon {
    height: 120px;
    width: 120px;
  }
}

@media only screen and (max-width: 575px) {
  .process-box-layout8 .item-icon {
    height: 100px;
    width: 100px;
  }
}

.process-box-layout8 .item-icon i {
  transition: all 0.5s ease-in-out;
}

.process-box-layout8 .item-icon i:before {
  font-size: 72px;
}

@media only screen and (max-width: 767px) {
  .process-box-layout8 .item-icon i:before {
    font-size: 56px;
  }
}

@media only screen and (max-width: 575px) {
  .process-box-layout8 .item-icon i:before {
    font-size: 48px;
  }
}

.process-box-layout8 .california {
  box-shadow: 0 0 25px 0 rgba(255, 147, 14, 0.1);
}

.process-box-layout8 .california i {
  color: #ff930e;
}

.process-box-layout8 .emerald {
  box-shadow: 0 0 25px 0 rgba(24, 220, 89, 0.1);
}

.process-box-layout8 .emerald i {
  color: #18dc59;
}

.process-box-layout8 .royal-blue {
  box-shadow: 0 0 25px 0 rgba(90, 73, 248, 0.1);
}

.process-box-layout8 .royal-blue i {
  color: #5a49f8;
}

.process-box-layout8 .dodger-blue {
  box-shadow: 0 0 25px 0 rgba(54, 186, 254, 0.1);
}

.process-box-layout8 .dodger-blue i {
  color: #36bafe;
}

.process-box-layout8:hover .item-icon i {
  color: #ffffff;
}

.process-box-layout8:hover .california {
  background-color: #ff930e;
}

.process-box-layout8:hover .emerald {
  background-color: #18dc59;
}

.process-box-layout8:hover .royal-blue {
  background-color: #5a49f8;
}

.process-box-layout8:hover .dodger-blue {
  background-color: #36bafe;
}

.process-box-layout8 .item-content .item-title {
  font-weight: 600;
  margin-bottom: 0;
}

.has-animation .process-shap-layout8 {
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 1250px) {
  .has-animation .process-shap-layout8 {
    display: none;
  }
}

.has-animation .process-shap-layout8 .shap-point li {
  color: #c2c2c2;
}

.has-animation .process-shap-layout8 .shap-point .start-point {
  position: absolute;
  z-index: 0;
  top: -7px;
  left: 0;
}

.has-animation .process-shap-layout8 .shap-point .end-point {
  position: absolute;
  z-index: 0;
  top: -5px;
  right: 0;
}

.has-animation .process-shap-layout8 svg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
}

.has-animation .process-shap-layout8 .dashed1 {
  stroke-dashoffset: 1300;
}

.has-animation .process-shap-layout8 .dashed1 {
  stroke-dasharray: 1300;
}

.has-animation .process-shap-layout8 .dashed2 {
  stroke-dasharray: 4;
}

@-webkit-keyframes dash_animation {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash_animation {
  to {
    stroke-dashoffset: 0;
  }
}

.has-animation.active-animation .process-shap-layout8 .dashed1 {
  animation: dash_animation 4s linear forwards;
}

/*--------------------------------------
	4.17 Pricing
---------------------------------------*/
.pricing-box-layout1 {
  text-align: center;
  background-color: #ffffff;
  padding: 62px 30px;
}

@media only screen and (max-width: 991px) {
  .pricing-box-layout1 {
    margin-bottom: 30px;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.04);
  }
}

.pricing-box-layout1 .item-icon {
  border-radius: 50%;
  height: 160px;
  width: 160px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.pricing-box-layout1 .item-icon:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: rgba(90, 73, 248, 0.1);
  border-radius: 50%;
  height: 100px;
  width: 100px;
  transition: all 0.5s ease-in-out;
}

.pricing-box-layout1 .item-icon:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: rgba(90, 73, 248, 0.1);
  border-radius: 50%;
  height: 160px;
  width: 160px;
  transition: all 0.5s ease-in-out;
}

.pricing-box-layout1 .item-icon i:before {
  font-size: 36px;
}

.pricing-box-layout1 .item-icon.color-california i {
  color: #ff930e;
}

.pricing-box-layout1 .item-icon.color-california:after {
  background-color: rgba(255, 147, 14, 0.1);
}

.pricing-box-layout1 .item-icon.color-california:before {
  background-color: rgba(255, 147, 14, 0.1);
}

.pricing-box-layout1 .item-icon.color-emerald i {
  color: #18dc59;
}

.pricing-box-layout1 .item-icon.color-emerald:after {
  background-color: rgba(24, 220, 89, 0.1);
}

.pricing-box-layout1 .item-icon.color-emerald:before {
  background-color: rgba(24, 220, 89, 0.1);
}

.pricing-box-layout1 .item-icon.color-sunset-orange i {
  color: #ff3838;
}

.pricing-box-layout1 .item-icon.color-sunset-orange:after {
  background-color: rgba(255, 56, 56, 0.1);
}

.pricing-box-layout1 .item-icon.color-sunset-orange:before {
  background-color: rgba(255, 56, 56, 0.1);
}

.pricing-box-layout1 .item-title {
  color: #000000;
  font-weight: 600;
}

.pricing-box-layout1 .block-list {
  margin-bottom: 30px;
}

.pricing-box-layout1 .block-list li {
  margin-bottom: 10px;
}

.pricing-box-layout1 .block-list li:last-child {
  margin-bottom: 0;
}

.pricing-box-layout1 .item-price {
  font-size: 48px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 35px;
}

@media only screen and (max-width: 991px) {
  .pricing-box-layout1 .item-price {
    font-size: 36px;
  }
}

.pricing-box-layout1 .item-price .item-duration {
  font-size: 18px;
  color: #646464;
  font-weight: 400;
  display: block;
  margin-top: 12px;
}

.pricing-box-layout1:hover .item-icon:before {
  animation: zoomOut 1s infinite;
}

[class^="col-"]:last-child .pricing-box-layout1 {
  margin-bottom: 0;
}

.pricing-shadow {
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.04);
}

@media only screen and (max-width: 991px) {
  .pricing-shadow {
    box-shadow: none;
  }
}

.pricing-box-devider > div {
  position: relative;
  z-index: 1;
}

.pricing-box-devider > div:before {
  content: "";
  height: 75%;
  width: 1px;
  background-color: #e0e0e0;
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 0;
}

@media only screen and (max-width: 991px) {
  .pricing-box-devider > div:before {
    display: none;
  }
}

.pricing-box-devider > div:last-child:before {
  display: none;
}

@keyframes zoomOut {
  0% {
    opacity: 1;
    transform: translateY(-50%) translateX(-50%) scale(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50%) translateX(-50%) scale(1.5);
  }
}

.pricing-wrap-layout2 {
  padding: 112px 0 118px;
}

@media only screen and (max-width: 991px) {
  .pricing-wrap-layout2 {
    padding: 97px 0 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-wrap-layout2 {
    padding: 82px 0 60px;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-wrap-layout2 {
    padding: 67px 0 45px;
  }
}

.pricing-box-layout2 {
  margin-bottom: 2px;
  text-align: center;
  background-color: #ffffff;
  padding: 80px 30px;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.pricing-box-layout2 .status-shape {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 80px solid #5a49f8;
  border-left: 80px solid transparent;
}

.pricing-box-layout2 .status-shape .status-text {
  position: absolute;
  z-index: 3;
  top: -65px;
  right: 0;
  transform: rotate(45deg);
  color: #ffffff;
}

.pricing-box-layout2 .item-icon {
  border-radius: 50%;
  height: 160px;
  width: 160px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.pricing-box-layout2 .item-icon:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: rgba(90, 73, 248, 0.05);
  border-radius: 50%;
  height: 100px;
  width: 100px;
  transition: all 0.5s ease-in-out;
}

.pricing-box-layout2 .item-icon:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: rgba(90, 73, 248, 0.05);
  border-radius: 50%;
  height: 160px;
  width: 160px;
  transition: all 0.5s ease-in-out;
}

.pricing-box-layout2 .item-icon i:before {
  font-size: 36px;
}

.pricing-box-layout2 .item-title {
  color: #646464;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 40px;
}

.pricing-box-layout2 .block-list {
  margin-bottom: 50px;
}

.pricing-box-layout2 .block-list li {
  margin-bottom: 20px;
}

.pricing-box-layout2 .block-list li:last-child {
  margin-bottom: 0;
}

.pricing-box-layout2 .item-price {
  font-family: "Poppins", sans-serif;
  display: inline-block;
  font-size: 36px;
  color: #5a49f8;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

.pricing-box-layout2 .item-price .super-script {
  position: absolute;
  z-index: 1;
  font-size: 16px;
  top: 0;
  right: -20px;
  line-height: 1;
}

.pricing-box-layout2:hover {
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 5;
  transform: scale(1.05);
}

.pricing-wrap-layout3 {
  padding: 112px 0 118px;
}

@media only screen and (max-width: 991px) {
  .pricing-wrap-layout3 {
    padding: 97px 0 103px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-wrap-layout3 {
    padding: 82px 0 88px;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-wrap-layout3 {
    padding: 67px 0 73px;
  }
}

.pricing-box-layout3 {
  margin-bottom: 2px;
  text-align: center;
  background-color: #ffffff;
  padding: 80px 30px;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .pricing-box-layout3 {
    margin-bottom: 30px;
  }
}

.pricing-box-layout3 .status-shape {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 80px solid #f43939;
  border-left: 80px solid transparent;
}

.pricing-box-layout3 .status-shape .status-text {
  position: absolute;
  z-index: 3;
  top: -68px;
  right: 6px;
  transform: rotate(45deg);
  color: #ffffff;
}

.pricing-box-layout3 .item-icon {
  border-radius: 50%;
  height: 160px;
  width: 160px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.pricing-box-layout3 .item-icon:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: rgba(90, 73, 248, 0.05);
  border-radius: 50%;
  height: 100px;
  width: 100px;
  transition: all 0.5s ease-in-out;
}

.pricing-box-layout3 .item-icon:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: rgba(90, 73, 248, 0.05);
  border-radius: 50%;
  height: 160px;
  width: 160px;
  transition: all 0.5s ease-in-out;
}

.pricing-box-layout3 .item-icon i:before {
  font-size: 36px;
}

.pricing-box-layout3 .item-title {
  color: #646464;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 40px;
}

.pricing-box-layout3 .block-list {
  margin-bottom: 50px;
}

.pricing-box-layout3 .block-list li {
  margin-bottom: 20px;
}

.pricing-box-layout3 .block-list li:last-child {
  margin-bottom: 0;
}

.pricing-box-layout3 .item-price {
  font-family: "Poppins", sans-serif;
  display: inline-block;
  font-size: 36px;
  background: -webkit-linear-gradient(-135deg, #00d7f7, #2671fb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

.pricing-box-layout3 .item-price .super-script {
  position: absolute;
  z-index: 1;
  font-size: 16px;
  top: 0;
  right: -20px;
  line-height: 1;
  background: -webkit-linear-gradient(-45deg, #00d7f7, #00d7f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pricing-box-layout3:hover {
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 5;
}

.pricing-box-layout3.active {
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 5;
}

.pricing-box-layout4 {
  background-color: #ffffff;
  margin-bottom: 30px;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
}

.pricing-box-layout4 .pricing-header {
  background-color: #5a49f8;
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
  text-align: center;
  padding: 40px 15px;
}

.pricing-box-layout4 .item-title {
  color: #ffffff;
  font-size: 30px;
  margin-bottom: 15px;
  font-weight: 300;
}

@media only screen and (max-width: 991px) {
  .pricing-box-layout4 .item-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-box-layout4 .item-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-box-layout4 .item-title {
    font-size: 24px;
  }
}

.pricing-box-layout4 .pricing-main-body {
  padding: 40px 40px 20px;
}

.pricing-box-layout4 .block-list li {
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
  padding-left: 30px;
  font-weight: 500;
}

.pricing-box-layout4 .block-list li:last-child {
  margin-bottom: 0;
}

.pricing-box-layout4 .block-list li:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  color: #5a49f8;
}

.pricing-box-layout4 .item-price {
  margin-right: 30px;
  font-family: "Poppins", sans-serif;
  display: inline-block;
  font-size: 48px;
  color: #ffffff;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .pricing-box-layout4 .item-price {
    font-size: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-box-layout4 .item-price {
    font-size: 36px;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-box-layout4 .item-price {
    font-size: 32px;
  }
}

.pricing-box-layout4 .item-price .per-time {
  font-weight: 300;
  margin-left: 10px;
  position: absolute;
  z-index: 1;
  font-size: 22px;
  bottom: 5px;
  left: 100%;
  line-height: 1;
  color: #ffffff;
}

.pricing-box-layout4 .item-price:after {
  content: "/";
  left: 100%;
  bottom: 7px;
  position: absolute;
  z-index: 1;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
}

.pricing-box-layout4 .pricing-footer {
  padding: 20px 40px 40px;
}

.pricing-box-layout4:hover {
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
}

/*--------------------------------------
	4.18 Service
---------------------------------------*/
/*--- Service Style One ---*/
.service-wrap-layout1 {
  overflow: hidden;
}

@media only screen and (max-width: 1199px) {
  .service-wrap-layout1 .animated-shape {
    display: none;
  }
}

.service-wrap-layout1 .animated-shape ul {
  position: relative;
  z-index: 0;
}

.service-wrap-layout1 .animated-shape ul li {
  position: absolute;
  z-index: -1;
}

.service-wrap-layout1 .animated-shape ul li:first-child {
  top: 300px;
  left: 30px;
  z-index: 1;
}

.service-wrap-layout1 .animated-shape ul li:last-child {
  top: 300px;
  right: 30px;
  z-index: 2;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-wrap-layout1 .animated-shape ul li img {
    width: 70%;
  }
  .service-wrap-layout1 .animated-shape ul li:first-child {
    top: 100px;
    left: 60px;
  }
  .service-wrap-layout1 .animated-shape ul li:last-child {
    top: 150px;
    left: 60px;
  }
}

.service-box-layout1 {
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 55px 40px 50px;
  border-radius: 4px;
  text-align: center;
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .service-box-layout1 {
    padding: 35px 15px 30px;
  }
}

.service-box-layout1 .icon-holder {
  position: relative;
  z-index: 1;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.service-box-layout1 .icon-holder .item-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  transition: all 0.5s ease-in-out;
}

.service-box-layout1 .icon-holder .item-icon i:before {
  font-size: 36px;
  margin-left: 0;
}

.service-box-layout1 .icon-holder .icon-bg-shape path {
  fill: #f5f5f5;
  transition: all 0.5s ease-in-out;
}

.service-box-layout1 .icon-color-california {
  color: #ff930e;
}

.service-box-layout1 .icon-color-emerald {
  color: #18dc59;
}

.service-box-layout1 .icon-color-royal-blue {
  color: #5a49f8;
}

.service-box-layout1 .icon-color-dodger-blue {
  color: #36bafe;
}

.service-box-layout1 .icon-color-sunset-orange {
  color: #ff3838;
}

.service-box-layout1 .icon-color-turquoise {
  color: #01daba;
}

.service-box-layout1 .item-title {
  font-weight: 600;
  margin-bottom: 10px;
}

.service-box-layout1 .item-title a {
  color: #000000;
  transition: all 0.5s ease-in-out;
}

.service-box-layout1 .item-title a:hover {
  color: #5a49f8;
}

.service-box-layout1 p {
  margin-bottom: 10px;
}

.service-box-layout1:hover {
  box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.1);
}

.service-box-layout1:hover .icon-holder.icon-bg-california .icon-bg-shape path {
  fill: #ff930e;
}

.service-box-layout1:hover .icon-holder.icon-bg-emerald .icon-bg-shape path {
  fill: #18dc59;
}

.service-box-layout1:hover .icon-holder.icon-bg-royal-blue .icon-bg-shape path {
  fill: #5a49f8;
}

.service-box-layout1:hover .icon-holder.icon-bg-dodger-blue .icon-bg-shape path {
  fill: #36bafe;
}

.service-box-layout1:hover .icon-holder.icon-bg-sunset-orange .icon-bg-shape path {
  fill: #ff3838;
}

.service-box-layout1:hover .icon-holder.icon-bg-turquoise .icon-bg-shape path {
  fill: #01daba;
}

.service-box-layout1:hover .icon-color-turquoise,
.service-box-layout1:hover .icon-color-sunset-orange,
.service-box-layout1:hover .icon-color-dodger-blue,
.service-box-layout1:hover .icon-color-royal-blue,
.service-box-layout1:hover .icon-color-emerald,
.service-box-layout1:hover .icon-color-california {
  color: #ffffff;
}

/*--- Service Style Two ---*/
.service-wrap-layout2 {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 767px) {
  .service-wrap-layout2 .animated-figure {
    display: none;
  }
}

.service-wrap-layout2 .animated-figure svg {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 20%;
  top: 50%;
  margin: 0 auto;
}

.service-wrap-layout2 .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.service-box-layout2 {
  box-shadow: 0 2px 35px 0 rgba(179, 179, 179, 0.22);
  padding: 50px 30px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: #ffffff;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .service-box-layout2 {
    padding: 40px 15px;
  }
}

.service-box-layout2:before {
  position: absolute;
  content: "";
  top: 99.5%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 0.8s;
}

.service-box-layout2 .item-icon {
  position: relative;
  z-index: 0;
  line-height: 1;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  transition: all 0.8s;
}

.service-box-layout2 .item-icon i:before {
  font-size: 36px;
  margin-left: 0;
}

.service-box-layout2 .item-icon:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.1;
  visibility: visible;
  transition: all 0.8s;
}

.service-box-layout2 .item-icon:before {
  position: absolute;
  content: "";
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.8s;
}

.service-box-layout2 .item-icon.icon-bg-california:after {
  background-color: #ff930e;
}

.service-box-layout2 .item-icon.icon-bg-royal-blue:after {
  background-color: #5a49f8;
}

.service-box-layout2 .item-icon.icon-bg-emerald:after {
  background-color: #18dc59;
}

.service-box-layout2 .item-icon.icon-bg-dodger-blue:after {
  background-color: #36bafe;
}

.service-box-layout2 .item-icon.icon-bg-sunset-orange:after {
  background-color: #ff3838;
}

.service-box-layout2 .icon-color-california {
  color: #ff930e;
  transition: all 0.5s ease-in-out;
}

.service-box-layout2 .icon-color-royal-blue {
  color: #5a49f8;
  transition: all 0.5s ease-in-out;
}

.service-box-layout2 .icon-color-emerald {
  color: #18dc59;
  transition: all 0.5s ease-in-out;
}

.service-box-layout2 .icon-color-dodger-blue {
  color: #36bafe;
  transition: all 0.5s ease-in-out;
}

.service-box-layout2 .icon-color-sunset-orange {
  color: #ff3838;
  transition: all 0.5s ease-in-out;
}

.service-box-layout2 .item-title {
  margin-bottom: 10px;
}

.service-box-layout2 .item-title a {
  color: #000000;
  font-weight: 600;
  transition: all 0.8s;
}

.service-box-layout2 p {
  transition: all 0.8s;
}

.service-box-layout2 .item-btn {
  color: #5a49f8;
  position: relative;
  z-index: 1;
  transition: all 1.2s ease-in-out;
}

.service-box-layout2 .item-btn i {
  color: #5a49f8;
  transition: color 0.2s ease-in-out, transform 0.3s ease-in-out;
}

.service-box-layout2:hover {
  border-radius: 4px;
  box-shadow: 0 5px 40px 0 rgba(179, 179, 179, 0.8);
}

.service-box-layout2:hover:before {
  border-radius: 4px;
  top: 0;
  bottom: 0;
}

.service-box-layout2:hover .item-icon.icon-bg-california:after,
.service-box-layout2:hover .item-icon.icon-bg-emerald:after,
.service-box-layout2:hover .item-icon.icon-bg-dodger-blue:after,
.service-box-layout2:hover .item-icon.icon-bg-royal-blue:after,
.service-box-layout2:hover .item-icon.icon-bg-sunset-orange:after {
  opacity: 0;
  visibility: hidden;
}

.service-box-layout2:hover .item-icon.icon-bg-california:before,
.service-box-layout2:hover .item-icon.icon-bg-emerald:before,
.service-box-layout2:hover .item-icon.icon-bg-dodger-blue:before,
.service-box-layout2:hover .item-icon.icon-bg-royal-blue:before,
.service-box-layout2:hover .item-icon.icon-bg-sunset-orange:before {
  opacity: 1;
  visibility: visible;
}

.service-box-layout2:hover .item-title a {
  color: #ffffff;
}

.service-box-layout2:hover p {
  color: #ffffff;
}

.service-box-layout2:hover .item-btn {
  color: #ffffff;
  transition: all 0.4s ease-in-out;
}

.service-box-layout2:hover .item-btn i {
  color: #ffffff;
  transition: color 0s ease-in-out, transform 0.3s ease-in-out;
}

.service-box-layout2.layout-bg-california:before {
  background-color: #ff930e;
}

.service-box-layout2.layout-bg-royal-blue:before {
  background-color: #5a49f8;
}

.service-box-layout2.layout-bg-emerald:before {
  background-color: #18dc59;
}

.service-box-layout2.layout-bg-dodger-blue:before {
  background-color: #36bafe;
}

.service-box-layout2.layout-bg-sunset-orange:before {
  background-color: #ff3838;
}

/*--- Service Style Three ---*/
.service-wrap-layout3 {
  overflow-x: hidden;
}

@media only screen and (max-width: 1199px) {
  .service-wrap-layout3 .animated-shape {
    display: none;
  }
}

.service-wrap-layout3 .animated-shape ul li {
  position: absolute;
  z-index: 0;
  top: -60%;
  right: -10%;
}

.service-box-layout3 {
  border: 1px solid #d9d9d9;
  padding: 50px 30px;
  text-align: center;
  margin-bottom: 30px;
  background-color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .service-box-layout3 {
    padding: 40px 20px;
  }
}

.service-box-layout3 .item-icon {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}

.service-box-layout3 .item-icon:before {
  position: absolute;
  z-index: -1;
  content: "";
  height: 60px;
  width: 60px;
  right: 0;
  left: -50px;
  top: 0;
  margin: 0 auto;
  border-radius: 50%;
  opacity: 0.08;
  animation: right_to_left 0.7s linear forwards;
  transition: all 0.5s ease-in-out;
}

.service-box-layout3 .item-icon i:before {
  font-size: 56px;
  color: #111111;
}

.service-box-layout3 .california:before {
  background-color: #ff930e;
}

.service-box-layout3 .emerald:before {
  background-color: #18dc59;
}

.service-box-layout3 .royal-blue:before {
  background-color: #5a49f8;
}

.service-box-layout3 .dodger-blue:before {
  background-color: #36bafe;
}

.service-box-layout3 .sunset-orange:before {
  background-color: #ff3838;
}

.service-box-layout3 .turquoise:before {
  background-color: #01daba;
}

.service-box-layout3 .item-title {
  font-weight: 600;
  margin-bottom: 10px;
}

.service-box-layout3 .item-title a {
  color: #111111;
  transition: all 0.5s ease-in-out;
}

.service-box-layout3 .item-title a:hover {
  color: #5a49f8;
}

.service-box-layout3:hover .item-icon:before {
  animation: left_to_right 0.7s linear forwards;
}

@keyframes left_to_right {
  0% {
    transform: translateX(0) translateY(0);
  }
  25% {
    transform: translateX(15px) translateY(-3px);
  }
  50% {
    transform: translateX(25px) translateY(-8px);
  }
  75% {
    transform: translateX(35px) translateY(-15px);
  }
  100% {
    transform: translateX(45px) translateY(-20px);
  }
}

@keyframes right_to_left {
  0% {
    transform: translateX(45px) translateY(-20px);
  }
  25% {
    transform: translateX(35px) translateY(-15px);
  }
  50% {
    transform: translateX(25px) translateY(-8px);
  }
  75% {
    transform: translateX(15px) translateY(-3px);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}

/*--- Service Style Four ---*/
.service-wrap-layout4 {
  overflow: hidden;
}

.service-wrap-layout4 .animated-shape {
  position: relative;
  z-index: 0;
}

@media only screen and (max-width: 767px) {
  .service-wrap-layout4 .animated-shape {
    display: none;
  }
}

.service-wrap-layout4 .animated-shape li {
  position: absolute;
  z-index: 0;
  top: 100px;
  right: -30px;
}

.service-box-layout4 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .service-box-layout4 {
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .service-box-layout4 {
    text-align: center;
  }
}

.service-box-layout4 .item-main-title {
  font-weight: 600;
  line-height: 50px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .service-box-layout4 .item-main-title {
    line-height: 40px;
  }
}

.service-box-layout4 .item-btn {
  margin-top: 30px;
}

.service-box-layout4 .service-list {
  padding: 40px 35px;
  background-color: #ffffff;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  margin-bottom: 30px;
  position: relative;
  z-index: 0;
  transition: all 0.5s ease-in-out;
}

.service-box-layout4 .service-list:before {
  border-radius: 4px;
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-image: linear-gradient(60deg, #5a49f8 0%, #7b64f2 100%);
  transition: all 0.5s ease-in-out;
}

.service-box-layout4 .service-list .item-icon {
  color: #5a49f8;
  margin-bottom: 20px;
  transition: all 0.5s ease-in-out;
}

.service-box-layout4 .service-list .item-icon i:before {
  font-size: 56px;
}

.service-box-layout4 .service-list .item-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
  transition: all 0.5s ease-in-out;
}

.service-box-layout4 .service-list p {
  margin-bottom: 0;
  transition: all 0.5s ease-in-out;
}

.service-box-layout4 .service-list:hover:before {
  opacity: 1;
  visibility: visible;
}

.service-box-layout4 .service-list:hover .item-icon {
  color: #ffffff;
}

.service-box-layout4 .service-list:hover .item-title {
  color: #ffffff;
}

.service-box-layout4 .service-list:hover p {
  color: #ffffff;
}

.service-box-layout4 .masonry-items .masonry-item {
  margin-top: 0;
}

.service-box-layout4 .masonry-items .masonry-item:nth-child(2) {
  margin-top: 20%;
}

@media only screen and (max-width: 1199px) {
  .service-box-layout4 .masonry-items .masonry-item:nth-child(2) {
    margin-top: 10%;
  }
}

@media only screen and (max-width: 767px) {
  .service-box-layout4 .masonry-items .masonry-item:nth-child(2) {
    margin-top: 0;
  }
}

.service-box-layout4 .masonry-items .masonry-item:nth-child(2) .service-list {
  padding: 60px 35px;
}

.service-box-layout4 .masonry-items .masonry-item:nth-child(3) .service-list {
  padding: 60px 35px;
}

/*--- Service Style Five ---*/
.service-wrap-layout7 {
  background-color: #f9fbfe;
  padding: 33px 0 90px;
}

@media only screen and (max-width: 991px) {
  .service-wrap-layout7 {
    padding: 18px 0 75px;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap-layout7 {
    padding: 3px 0 60px;
  }
}

@media only screen and (max-width: 575px) {
  .service-wrap-layout7 {
    padding: 0 0 45px;
    margin-top: -12px;
  }
}

.service-box-layout5 {
  text-align: center;
  padding: 0 30px;
}

.service-box-layout5 .item-icon {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-box-layout5 .item-icon i {
  color: #ffffff;
}

.service-box-layout5 .item-icon i:before {
  font-size: 28px;
}

.service-box-layout5 .item-icon.icon-bg-california {
  background-color: #ff930e;
  box-shadow: -7px 0 0 0 rgba(255, 147, 14, 0.2);
  transition: all 0.3s ease-in-out;
}

.service-box-layout5 .item-icon.icon-bg-emerald {
  background-color: #18dc59;
  box-shadow: -7px 0 0 0 rgba(24, 220, 89, 0.2);
  transition: all 0.3s ease-in-out;
}

.service-box-layout5 .item-icon.icon-bg-royal-blue {
  background-color: #5a49f8;
  box-shadow: -7px 0 0 0 rgba(90, 73, 248, 0.2);
  transition: all 0.3s ease-in-out;
}

.service-box-layout5 .item-icon.icon-bg-dodger-blue {
  background-color: #36bafe;
  box-shadow: -7px 0 0 0 rgba(54, 186, 254, 0.2);
  transition: all 0.3s ease-in-out;
}

.service-box-layout5 .item-icon.icon-bg-sunset-orange {
  background-color: #ff3838;
  box-shadow: -7px 0 0 0 rgba(255, 56, 56, 0.2);
  transition: all 0.3s ease-in-out;
}

.service-box-layout5 .item-icon.icon-bg-turquoise {
  background-color: #01daba;
  box-shadow: -7px 0 0 0 rgba(1, 218, 186, 0.2);
  transition: all 0.3s ease-in-out;
}

.service-box-layout5 .item-title {
  font-weight: 600;
}

.service-box-layout5 .item-title a {
  color: #111111;
  transition: all 0.3s ease-in-out;
}

.service-box-layout5 .item-title a:hover {
  color: #5a49f8;
}

.service-box-layout5:hover .item-icon.icon-bg-california {
  box-shadow: 7px 0 0 0 rgba(255, 147, 14, 0.2);
}

.service-box-layout5:hover .item-icon.icon-bg-emerald {
  box-shadow: 7px 0 0 0 rgba(24, 220, 89, 0.2);
}

.service-box-layout5:hover .item-icon.icon-bg-royal-blue {
  box-shadow: 7px 0 0 0 rgba(90, 73, 248, 0.2);
}

.service-box-layout5:hover .item-icon.icon-bg-dodger-blue {
  box-shadow: 7px 0 0 0 rgba(54, 186, 254, 0.2);
}

.service-box-layout5:hover .item-icon.icon-bg-sunset-orange {
  box-shadow: 7px 0 0 0 rgba(255, 56, 56, 0.2);
}

.service-box-layout5:hover .item-icon.icon-bg-turquoise {
  box-shadow: 7px 0 0 0 rgba(1, 218, 186, 0.2);
}

/*--- Service Style Six ---*/
.service-wrap-layout8 {
  background-color: #ffffff;
}

.service-box-layout6 {
  text-align: center;
  padding: 0 30px;
  background-color: #ffffff;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.05);
  padding: 50px 30px 30px;
  border-radius: 5px;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}

.service-box-layout6 .item-icon {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.35s cubic-bezier(0.38, 3, 0.57, 1.6);
}

.service-box-layout6 .item-title {
  font-weight: 600;
  margin-bottom: 10px;
}

.service-box-layout6 .item-title a {
  color: #111111;
  transition: all 0.3s ease-in-out;
}

.service-box-layout6 .item-title a:hover {
  color: #5a49f8;
}

.service-box-layout6:hover {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.service-box-layout6:hover .item-icon {
  transform: translate3d(0px, -6px, 0);
}

/*--- Service Style Seven ---*/
.service-wrap-layout9 {
  background-color: #f9fbff;
}

.service-box-layout7 {
  text-align: center;
  padding: 0 30px;
  background-color: transparent;
  border: 1px solid #e1e1e1;
  padding: 35px 15px 30px;
  border-radius: 5px;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}

.service-box-layout7 .icon-holder {
  position: relative;
  z-index: 1;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.service-box-layout7 .icon-holder .item-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  transition: all 0.5s ease-in-out;
}

.service-box-layout7 .icon-holder .item-icon i:before {
  font-size: 36px;
  margin-left: 0;
}

.service-box-layout7 .icon-holder .icon-bg-shape path {
  fill: #f5f5f5;
  transition: all 0.5s ease-in-out;
}

.service-box-layout7 .icon-holder.icon-bg-california .icon-bg-shape path {
  fill: #ff930e;
}

.service-box-layout7 .icon-holder.icon-bg-emerald .icon-bg-shape path {
  fill: #18dc59;
}

.service-box-layout7 .icon-holder.icon-bg-royal-blue .icon-bg-shape path {
  fill: #5a49f8;
}

.service-box-layout7 .icon-holder.icon-bg-dodger-blue .icon-bg-shape path {
  fill: #36bafe;
}

.service-box-layout7 .icon-holder.icon-bg-sunset-orange .icon-bg-shape path {
  fill: #ff3838;
}

.service-box-layout7 .icon-holder.icon-bg-turquoise .icon-bg-shape path {
  fill: #01daba;
}

.service-box-layout7 .icon-color-turquoise,
.service-box-layout7 .icon-color-sunset-orange,
.service-box-layout7 .icon-color-dodger-blue,
.service-box-layout7 .icon-color-royal-blue,
.service-box-layout7 .icon-color-emerald,
.service-box-layout7 .icon-color-california {
  color: #ffffff;
}

.service-box-layout7 .item-title {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
}

.service-box-layout7 .item-title a {
  color: #111111;
  transition: all 0.3s ease-in-out;
}

.service-box-layout7 .item-title a:hover {
  color: #5a49f8;
}

.service-box-layout7:hover {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

/*--- Service Style Eight ---*/
.service-wrap-layout10 {
  background-color: #ffffff;
}

.service-box-layout8 {
  padding: 50px 30px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: #f9fcff;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .service-box-layout8 {
    padding: 40px 15px;
  }
}

.service-box-layout8:after {
  content: "";
  background-image: url("../../media/element/element75.png");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.service-box-layout8:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.service-box-layout8 .item-icon {
  position: relative;
  z-index: 0;
  line-height: 1;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  transition: all 0.8s;
}

.service-box-layout8 .item-icon i:before {
  font-size: 32px;
  margin-left: 0;
  color: #ffffff;
}

.service-box-layout8 .item-icon:after {
  content: url("../../media/element/element74.png");
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.service-box-layout8 .item-icon:before {
  position: absolute;
  content: "";
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.8s;
}

.service-box-layout8 .item-icon.icon-bg-california:before {
  background-color: #ff930e;
}

.service-box-layout8 .item-icon.icon-bg-royal-blue:before {
  background-color: #5a49f8;
}

.service-box-layout8 .item-icon.icon-bg-emerald:before {
  background-color: #18dc59;
}

.service-box-layout8 .item-icon.icon-bg-dodger-blue:before {
  background-color: #36bafe;
}

.service-box-layout8 .item-icon.icon-bg-sunset-orange:before {
  background-color: #ff3838;
}

.service-box-layout8 .item-icon.icon-bg-turquoise:before {
  background-color: #01daba;
}

.service-box-layout8 .item-title {
  margin-bottom: 10px;
}

.service-box-layout8 .item-title a {
  color: #000000;
  font-weight: 600;
  transition: all 0.8s;
}

.service-box-layout8 .item-title a:hover {
  color: #5a49f8;
}

.service-box-layout8 p {
  transition: all 0.8s;
}

.service-box-layout8:hover {
  border-radius: 4px;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

.service-box-layout8:hover:after {
  opacity: 1;
  visibility: visible;
}

/*--- Service Style Nine ---*/
.service-box-layout9 {
  padding: 50px 30px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .service-box-layout9 {
    padding: 40px 15px;
  }
}

.service-box-layout9:before {
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.service-box-layout9 .item-icon {
  position: relative;
  z-index: 0;
  line-height: 1;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  background-color: #f5f4ff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  transition: all 0.3s;
}

.service-box-layout9 .item-icon i:before {
  font-size: 32px;
  margin-left: 0;
  color: #5a49f8;
}

.service-box-layout9 .item-title {
  margin-bottom: 10px;
}

.service-box-layout9 .item-title a {
  color: #000000;
  font-weight: 600;
  transition: all 0.3s;
}

.service-box-layout9 p {
  transition: all 0.3s;
}

.service-box-layout9:hover {
  box-shadow: none;
}

.service-box-layout9:hover:before {
  opacity: 1;
  visibility: visible;
}

.service-box-layout9:hover .item-title a {
  color: #ffffff;
}

.service-box-layout9:hover p {
  color: #ffffff;
}

.service-wrap-layout12 {
  background-color: #f9fbfe;
  padding: 33px 0 90px;
}

@media only screen and (max-width: 991px) {
  .service-wrap-layout12 {
    padding: 18px 0 75px;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap-layout12 {
    padding: 3px 0 60px;
  }
}

@media only screen and (max-width: 575px) {
  .service-wrap-layout12 {
    padding: 0 0 45px;
    margin-top: -12px;
  }
}

.service-box-layout12 {
  margin-bottom: 30px;
}

.service-box-layout12 .item-figure {
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .service-box-layout12 .item-figure {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .service-box-layout12 .item-figure {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .service-box-layout12 .item-figure {
    margin-top: 15px;
  }
}

.service-box-layout13 {
  margin-bottom: 30px;
  display: flex;
  padding: 15px;
  border-radius: 5px;
  border: 2px solid #ebebeb;
}

.service-box-layout13 .item-icon {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-box-layout13 .item-icon i {
  color: #ffffff;
}

.service-box-layout13 .item-icon i:before {
  font-size: 28px;
}

.service-box-layout13 .item-icon.icon-bg-california {
  background-color: #ff930e;
  box-shadow: -7px 0 0 0 rgba(255, 147, 14, 0.2);
  transition: all 0.3s ease-in-out;
}

.service-box-layout13 .item-icon.icon-bg-emerald {
  background-color: #18dc59;
  box-shadow: -7px 0 0 0 rgba(24, 220, 89, 0.2);
  transition: all 0.3s ease-in-out;
}

.service-box-layout13 .item-icon.icon-bg-royal-blue {
  background-color: #5a49f8;
  box-shadow: -7px 0 0 0 rgba(90, 73, 248, 0.2);
  transition: all 0.3s ease-in-out;
}

.service-box-layout13 .item-icon.icon-bg-dodger-blue {
  background-color: #36bafe;
  box-shadow: -7px 0 0 0 rgba(54, 186, 254, 0.2);
  transition: all 0.3s ease-in-out;
}

.service-box-layout13 .item-icon.icon-bg-sunset-orange {
  background-color: #ff3838;
  box-shadow: -7px 0 0 0 rgba(255, 56, 56, 0.2);
  transition: all 0.3s ease-in-out;
}

.service-box-layout13 .item-icon.icon-bg-turquoise {
  background-color: #01daba;
  box-shadow: -7px 0 0 0 rgba(1, 218, 186, 0.2);
  transition: all 0.3s ease-in-out;
}

.service-box-layout13 .item-content {
  margin-left: 15px;
  flex: 1;
}

.service-box-layout13 .item-content .item-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 5px;
}

.service-box-layout13 .item-content .item-title a {
  color: #111111;
  transition: all 0.3s ease-in-out;
}

.service-box-layout13 .item-content .item-title a:hover {
  color: #5a49f8;
}

.service-box-layout13 .item-content p {
  margin-bottom: 0;
  line-height: 1.6;
}

.service-box-layout13:hover .item-icon.icon-bg-california {
  box-shadow: 7px 0 0 0 rgba(255, 147, 14, 0.2);
}

.service-box-layout13:hover .item-icon.icon-bg-emerald {
  box-shadow: 7px 0 0 0 rgba(24, 220, 89, 0.2);
}

.service-box-layout13:hover .item-icon.icon-bg-royal-blue {
  box-shadow: 7px 0 0 0 rgba(90, 73, 248, 0.2);
}

.service-box-layout13:hover .item-icon.icon-bg-dodger-blue {
  box-shadow: 7px 0 0 0 rgba(54, 186, 254, 0.2);
}

.service-box-layout13:hover .item-icon.icon-bg-sunset-orange {
  box-shadow: 7px 0 0 0 rgba(255, 56, 56, 0.2);
}

.service-box-layout13:hover .item-icon.icon-bg-turquoise {
  box-shadow: 7px 0 0 0 rgba(1, 218, 186, 0.2);
}

.service-wrap-layout14 {
  background-color: #fbfbfb;
}

.service-box-layout14 {
  padding: 40px 20px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .service-box-layout14 {
    padding: 40px 15px;
  }
}

.service-box-layout14:before {
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.service-box-layout14 .item-icon {
  position: relative;
  z-index: 0;
  line-height: 1;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
}

.service-box-layout14 .item-icon:before {
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
  content: "";
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}

.service-box-layout14 .item-icon i:before {
  font-size: 32px;
  margin-left: 0;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.service-box-layout14 .item-title {
  margin-bottom: 10px;
}

.service-box-layout14 .item-title a {
  color: #000000;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.service-box-layout14 p {
  transition: all 0.3s ease-in-out;
}

.service-box-layout14 .item-btn {
  font-weight: 500;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  color: #5a49f8;
  transition: all 0.3s ease-in-out;
}

.service-box-layout14 .item-btn i {
  margin-top: 3px;
  margin-left: 10px;
  line-height: 1;
  transform: translateX(0px);
  color: #5a49f8;
  transition: all 0.3s ease-in-out;
}

.service-box-layout14 .item-btn i:before {
  font-size: 18px;
}

.service-box-layout14 .item-btn:hover {
  color: #2b1bbb;
}

.service-box-layout14 .item-btn:hover i {
  transform: translateX(5px);
  color: #2b1bbb;
}

.service-box-layout14 .item-btn:focus {
  outline: none;
  box-shadow: none;
}

.service-box-layout14:hover {
  box-shadow: none;
}

.service-box-layout14:hover:before {
  opacity: 1;
  visibility: visible;
}

.service-box-layout14:hover .item-icon {
  background-image: linear-gradient(60deg, #ffffff 0%, #ffffff 100%);
}

.service-box-layout14:hover .item-icon:before {
  opacity: 0;
  visibility: hidden;
}

.service-box-layout14:hover .item-icon i:before {
  color: #5a49f8;
}

.service-box-layout14:hover .item-title a {
  color: #ffffff;
}

.service-box-layout14:hover p {
  color: #ffffff;
}

.service-box-layout14:hover .item-btn {
  color: #ffffff;
}

.service-box-layout14:hover .item-btn i {
  color: #ffffff;
}

/*--- Single Service Style ---*/
.service-details-box {
  margin-bottom: 30px;
}

.service-details-box .item-figure {
  text-align: center;
}

.service-details-box .content-holder .section-title {
  color: #5a49f8;
  font-size: 20px;
  margin-bottom: 10px;
}

.service-details-box .content-holder .item-title {
  font-weight: 600;
  margin-bottom: 15px;
}

.service-details-box .content-holder .sub-paragraph {
  font-size: 18px;
  color: #444444;
  font-style: italic;
  font-weight: 600;
  margin-bottom: 10px;
}

.service-details-box .icon-holder {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #18dc59;
}

.service-details-box .icon-holder i:before {
  font-size: 36px;
}

.service-details-box .icon-holder.icon-color-emerald i {
  color: #ffffff;
}

.service-details-box .feature-item {
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 30px 20px;
  position: relative;
  z-index: 0;
  background-color: #ecf4ff;
}

@media only screen and (max-width: 767px) {
  .service-details-box .feature-item {
    padding: 30px 15px;
  }
}

.service-details-box .feature-item .feature-icon {
  position: absolute;
  transform: scale(1);
  z-index: -1;
  bottom: 10px;
  right: -30px;
  opacity: 0.1;
  transition: all 0.2s ease-in-out;
}

.service-details-box .feature-item .feature-icon i:before {
  font-size: 80px;
}

.service-details-box .feature-item .feature-number {
  height: 40px;
  width: 40px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  color: #ffffff;
  font-size: 20px;
}

.service-details-box .feature-item .feature-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 3px;
  flex: 1;
}

.service-details-box .feature-item.dodger-blue .feature-number {
  background-color: #36bafe;
}

.service-details-box .feature-item.dodger-blue .feature-icon i {
  color: #36bafe;
}

.service-details-box .feature-item.sunset-orange .feature-number {
  background-color: #ff3838;
}

.service-details-box .feature-item.sunset-orange .feature-icon i {
  color: #ff3838;
}

.service-details-box .feature-item.royal-blue .feature-number {
  background-color: #5a49f8;
}

.service-details-box .feature-item.royal-blue .feature-icon i {
  color: #5a49f8;
}

.service-details-box .feature-item.california .feature-number {
  background-color: #ff930e;
}

.service-details-box .feature-item.california .feature-icon i {
  color: #ff930e;
}

.service-details-box .feature-item:hover .feature-icon {
  transform: scale(1.2);
}

/*--------------------------------------
	4.19 Screen Shot
---------------------------------------*/
.screen-shot-carousel-wrap {
  margin-top: -36px;
}

.screen-shot-box-layout1 {
  box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.07);
  margin: 36px 15px;
  transition: all 0.5s ease-in-out;
}

.screen-shot-box-layout1 img {
  border-radius: 4px;
}

.center .screen-shot-box-layout1 {
  box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.3);
}

/*--------------------------------------
	4.20 Team
---------------------------------------*/
.team-wrap-layout1 {
  background-color: #ffffff;
}

.team-box-layout1 {
  margin-bottom: 20px;
}

.team-box-layout1 .maks-item {
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
  overflow: hidden;
  border-radius: 4px;
}

.team-box-layout1 .maks-item .item-figure img {
  border-radius: 4px;
}

.team-box-layout1 .maks-item .social-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.team-box-layout1 .maks-item .social-items li {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 15px;
  transform: translateY(15px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transition-delay: 0s;
}

.team-box-layout1 .maks-item .social-items li a {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  height: 50px;
  width: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .team-box-layout1 .maks-item .social-items li a {
    height: 40px;
    width: 40px;
  }
}

.team-box-layout1 .maks-item .social-items li a:hover {
  background-color: transparent;
  color: #ffffff;
}

.team-box-layout1 .maks-item .social-items li a i {
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .team-box-layout1 .maks-item .social-items li a i {
    font-size: 14px;
  }
}

.team-box-layout1 .maks-item .social-items li:last-child {
  margin-right: 0;
}

.team-box-layout1 .item-content {
  text-align: center;
}

.team-box-layout1 .item-content .item-title {
  font-weight: 600;
  margin-bottom: 0;
}

.team-box-layout1 .item-content .item-title a {
  color: #111111;
  transition: all 0.5s ease-in-out;
}

.team-box-layout1 .item-content .item-title a:hover {
  color: #5a49f8;
}

.team-box-layout1:hover .social-items li {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
}

.team-box-layout1:hover .social-items li:nth-child(1n) {
  transition-delay: 0.3s;
}

.team-box-layout1:hover .social-items li:nth-child(2n) {
  transition-delay: 0.5s;
}

.team-box-layout1:hover .social-items li:nth-child(3n) {
  transition-delay: 0.7s;
}

.team-box-layout1:hover .social-items li:nth-child(4n) {
  transition-delay: 0.9s;
}

.team-box-layout1:hover .social-items li:nth-child(5n) {
  transition-delay: 1.1s;
}

.team-box-layout1:hover .animted-bg-wrap .animted-bg {
  width: 1500px;
  height: 1500px;
}

.team-box-layout1 .animted-bg-wrap {
  overflow: hidden;
  position: relative;
  transition: all 1s ease-in-out;
  z-index: 1;
}

.team-box-layout1 .animted-bg-wrap .animted-bg {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: rgba(90, 73, 248, 0.9);
  transition: width 1s ease-in-out, height 1s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.team-wrap-layout2 {
  background-color: #ffffff;
}

.team-box-layout2 {
  margin-bottom: 20px;
}

.team-box-layout2 .maks-item {
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 50%;
}

.team-box-layout2 .maks-item .item-figure img {
  border-radius: 50%;
}

.team-box-layout2 .item-content {
  text-align: center;
}

.team-box-layout2 .item-content .item-title {
  font-weight: 700;
  margin-bottom: 0;
}

.team-box-layout2 .item-content .item-title a {
  color: #111111;
  transition: all 0.5s ease-in-out;
}

.team-box-layout2 .item-content .item-title a:hover {
  color: #5a49f8;
}

.team-box-layout2 .item-content .social-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

.team-box-layout2 .item-content .social-items li {
  margin-bottom: 10px;
  margin-right: 5px;
  transform: translateY(15px);
  transition: all 0.3s ease-in-out;
  transition-delay: 0s;
}

.team-box-layout2 .item-content .social-items li a {
  padding: 5px 10px;
  display: block;
  color: #000000;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .team-box-layout2 .item-content .social-items li a {
    height: 40px;
    width: 40px;
  }
}

.team-box-layout2 .item-content .social-items li a:hover {
  color: #5a49f8;
}

.team-box-layout2 .item-content .social-items li a i {
  font-size: 15px;
}

.team-box-layout2 .item-content .social-items li:last-child {
  margin-right: 0;
}

.team-box-layout2:hover .animted-bg-wrap .animted-bg {
  width: 1500px;
  height: 1500px;
}

.team-box-layout2 .animted-bg-wrap {
  overflow: hidden;
  position: relative;
  transition: all 1s ease-in-out;
  z-index: 1;
}

.team-box-layout2 .animted-bg-wrap .animted-bg {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: #5a49f8;
  background: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
  opacity: 0.75;
  transition: width 1s ease-in-out, height 1s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

/*--------------------------------------
	4.21 Testimonial
---------------------------------------*/
.testimonial-box-layout1 {
  text-align: center;
  margin-bottom: 21px;
}

.testimonial-box-layout1 .top-box {
  padding: 70px 50px 120px;
  position: relative;
  z-index: 1;
  mask-image: url(../../media/svg/svg3.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center center;
  display: block;
  margin-bottom: 40px;
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
}

@media only screen and (max-width: 479px) {
  .testimonial-box-layout1 .top-box {
    mask-image: none;
    padding: 40px 30px 47px;
  }
}

.testimonial-box-layout1 .top-box .item-icon {
  margin-bottom: 10px;
}

.testimonial-box-layout1 .top-box i {
  background: -webkit-linear-gradient(#5a49f8, #5235e9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 100px;
  opacity: 0.4;
}

.testimonial-box-layout1 .top-box i:before {
  font-size: 100px;
}

.testimonial-box-layout1 .top-box p {
  width: 75%;
  margin: 0 auto;
  color: #ffffff;
}

@media only screen and (max-width: 1199px) {
  .testimonial-box-layout1 .top-box p {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-box-layout1 .top-box p {
    width: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box-layout1 .top-box p {
    width: 100%;
  }
}

.testimonial-box-layout1 .bottom-box {
  display: inline-flex;
}

@media only screen and (max-width: 991px) {
  .testimonial-box-layout1 .bottom-box {
    margin-bottom: 20px;
  }
}

.testimonial-box-layout1 .bottom-box .media {
  display: inline-flex;
  align-items: center;
}

.testimonial-box-layout1 .bottom-box .media img {
  width: auto;
}

.testimonial-box-layout1 .bottom-box .media .media-body {
  text-align: left;
}

.testimonial-box-layout1 .bottom-box .media .media-body .item-title {
  margin-bottom: 0;
  line-height: 1.2;
}

.testimonial-box-layout1 .bottom-box .media .media-body .item-designation {
  font-size: 15px;
}

.testimonial-box-layout2 {
  text-align: center;
  padding: 60px 0 80px;
  mask-image: url(../../media/svg/svg4.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center center;
  display: block;
  margin-bottom: 30px;
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
}

@media only screen and (max-width: 991px) {
  .testimonial-box-layout2 {
    mask-image: none;
    padding: 40px 30px 45px;
    border-radius: 4px;
  }
}

.testimonial-box-layout2 .item-content .item-icon {
  margin-bottom: 10px;
}

.testimonial-box-layout2 .item-content i {
  background: -webkit-linear-gradient(#5a49f8, #5235e9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 100px;
  opacity: 0.4;
}

.testimonial-box-layout2 .item-content i:before {
  font-size: 100px;
}

.testimonial-box-layout2 .item-content p {
  width: 30%;
  margin: 0 auto 30px;
  color: #ffffff;
}

@media only screen and (max-width: 1199px) {
  .testimonial-box-layout2 .item-content p {
    width: 40%;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-box-layout2 .item-content p {
    width: 56%;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box-layout2 .item-content p {
    width: 100%;
  }
}

.testimonial-box-layout2 .item-content .media {
  display: inline-flex;
  align-items: center;
}

.testimonial-box-layout2 .item-content .media img {
  width: auto;
}

.testimonial-box-layout2 .item-content .media .media-body {
  text-align: left;
}

.testimonial-box-layout2 .item-content .media .media-body .item-title {
  margin-bottom: 0;
  line-height: 1.2;
  color: #ffffff;
}

.testimonial-box-layout2 .item-content .media .media-body .item-designation {
  font-size: 15px;
  color: #ffffff;
}

.testimonial-box-layout2 svg {
  margin: 0 auto;
}

.testimonial-wrap-layout3 .figure-holder {
  position: relative;
  z-index: 0;
}

.testimonial-wrap-layout3 .figure-holder .animated-bg {
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap-layout3 .figure-holder .animated-bg {
    display: none;
  }
}

.testimonial-wrap-layout3 .figure-holder .animated-bg svg {
  text-align: right;
}

.testimonial-box-layout3 {
  margin-bottom: 60px;
  padding: 60px 40px 30px;
  position: relative;
  background-color: #f9f8ff;
  border-radius: 4px;
}

.testimonial-box-layout3:before {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: #473f6f;
  opacity: 0.03;
  width: calc(100% - 40px);
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  border-radius: 4px;
}

.testimonial-box-layout3:after {
  content: "";
  position: absolute;
  z-index: -2;
  background-color: #473f6f;
  opacity: 0.03;
  height: 100%;
  width: calc(100% - 80px);
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
}

.testimonial-box-layout3 .media {
  margin-bottom: 30px;
}

.testimonial-box-layout3 .media img {
  width: auto;
  border-radius: 50%;
}

.testimonial-box-layout3 .media .media-body {
  text-align: left;
}

.testimonial-box-layout3 .media .media-body .item-title {
  margin-bottom: 0;
  line-height: 1.2;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}

.testimonial-box-layout3 .media .media-body .item-designation {
  font-size: 15px;
}

.testimonial-box-layout3 .rating-wrap {
  display: flex;
  position: absolute;
  z-index: 1;
  top: 70px;
  right: 50px;
}

.testimonial-box-layout3 .rating-wrap li {
  margin-right: 10px;
  align-items: center;
  color: #f8b81e;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .testimonial-box-layout3 .rating-wrap {
    position: inherit;
    top: inherit;
    right: inherit;
  }
}

.testimonial-box-layout3 .item-icon {
  position: absolute;
  z-index: 0;
  right: 40px;
  bottom: 25px;
  color: #000000;
  opacity: 0.05;
}

.testimonial-box-layout3 .item-icon i:before {
  font-size: 120px;
}

@media only screen and (max-width: 991px) {
  .testimonial-box-layout3 .item-icon i:before {
    font-size: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box-layout3 .item-icon i:before {
    font-size: 80px;
  }
}

.testimonial-wrap-layout4 {
  padding-top: 73px;
  padding-bottom: 189px;
}

@media only screen and (max-width: 991px) {
  .testimonial-wrap-layout4 {
    padding-top: 58px;
    padding-bottom: 174px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap-layout4 {
    padding-top: 43px;
    padding-bottom: 159px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-wrap-layout4 {
    padding-top: 28px;
    padding-bottom: 144px;
  }
}

.testimonial-wrap-layout4 .figure-holder {
  position: relative;
  z-index: 1;
}

.testimonial-wrap-layout4 .figure-holder .animated-bg {
  position: absolute;
  z-index: -1;
  right: 0;
  left: 0;
  top: -130px;
}

.testimonial-wrap-layout4 .figure-holder .animated-bg svg {
  text-align: center;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap-layout4 .figure-holder .animated-bg {
    display: none;
  }
}

.testimonial-box-layout4 {
  margin-bottom: 30px;
  padding: 45px 40px 30px;
  position: relative;
  background-color: #f7f6fb;
  border-radius: 4px;
}

.testimonial-box-layout4 .item-title {
  font-weight: 600;
  margin-bottom: 5px;
}

.testimonial-box-layout4 .media {
  margin-top: 10px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout4 .media {
    margin-bottom: 10px;
  }
}

.testimonial-box-layout4 .media img {
  width: auto;
  border-radius: 50%;
}

.testimonial-box-layout4 .media .media-body {
  text-align: left;
}

.testimonial-box-layout4 .media .media-body .inner-item-title {
  margin-bottom: 0;
  line-height: 1.2;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}

.testimonial-box-layout4 .media .media-body .inner-item-designation {
  font-size: 15px;
}

.testimonial-box-layout4 .rating-wrap {
  display: flex;
  margin-bottom: 30px;
}

.testimonial-box-layout4 .rating-wrap li {
  margin-right: 10px;
  align-items: center;
  color: #f8b81e;
  font-size: 15px;
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout4 .rating-wrap {
    margin-bottom: 15px;
  }
}

.testimonial-box-layout4 .item-icon {
  color: #000000;
  opacity: 0.05;
  margin-bottom: 10px;
  position: absolute;
  z-index: 0;
  right: 40px;
  top: 40px;
}

.testimonial-box-layout4 .item-icon i:before {
  font-size: 120px;
}

@media only screen and (max-width: 991px) {
  .testimonial-box-layout4 .item-icon i:before {
    font-size: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box-layout4 .item-icon i:before {
    font-size: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout4 .item-icon i:before {
    font-size: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout4 .item-content {
    display: block !important;
  }
}

.testimonial-wrap-layout5 {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap-layout5 .animated-buble {
    display: none;
  }
}

.testimonial-wrap-layout5 .animated-buble li {
  position: absolute;
  z-index: -1;
  bottom: -50px;
  left: 60px;
  right: inherit;
  top: inherit;
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap-layout5 .animated-figure {
    display: none;
  }
}

.testimonial-wrap-layout5 .animated-figure li {
  position: absolute;
  z-index: -1;
}

.testimonial-wrap-layout5 .animated-figure li:nth-child(1n) {
  right: 0;
  bottom: 0;
}

.testimonial-box-layout5 {
  text-align: center;
  position: relative;
  z-index: 1;
  margin-top: 80px;
}

.testimonial-box-layout5 .shape-wrap {
  position: relative;
  z-index: 0;
  display: inline-block;
}

.testimonial-box-layout5 .shape-wrap li:first-child {
  position: absolute;
  z-index: 1;
  right: 115px;
  top: 0;
}

.testimonial-box-layout5 svg {
  margin: 0 auto;
}

.testimonial-box-layout5 .item-content {
  position: absolute;
  z-index: 1;
  top: 58%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.testimonial-box-layout5 .item-content .item-icon {
  margin-bottom: 15px;
  opacity: 0.8;
}

.testimonial-box-layout5 .item-content i {
  color: #ffffff;
}

.testimonial-box-layout5 .item-content i:before {
  font-size: 70px;
}

.testimonial-box-layout5 .item-content p {
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
}

.testimonial-box-layout5 .item-content .item-title {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 0;
  line-height: 1.2;
}

.testimonial-box-layout5 .item-content .sub-title {
  font-size: 14px;
  color: #ffffff;
}

.testimonial-box-layout6 {
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.testimonial-box-layout6 .item-content {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  position: absolute;
  z-index: 2;
}

.testimonial-box-layout6 .item-content .item-icon {
  margin-bottom: 10px;
}

.testimonial-box-layout6 .item-content i {
  color: #ffffff;
  opacity: 0.2;
}

.testimonial-box-layout6 .item-content i:before {
  font-size: 80px;
}

.testimonial-box-layout6 .item-content p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: italic;
  line-height: 32px;
  width: 80%;
  margin: 0 auto 50px;
  font-weight: 300;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .testimonial-box-layout6 .item-content p {
    width: 90%;
  }
}

.testimonial-box-layout6 .item-content .media {
  transform: translateX(130px);
  display: inline-flex;
  align-items: center;
}

.testimonial-box-layout6 .item-content .media img {
  width: auto;
}

.testimonial-box-layout6 .item-content .media .media-body {
  text-align: left;
}

.testimonial-box-layout6 .item-content .media .media-body .item-title {
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

.testimonial-box-layout6 .item-content .media .media-body .item-designation {
  font-size: 15px;
  color: #ffffff;
  font-weight: 300;
}

.testimonial-content-layout1 {
  margin-left: 50px;
  position: relative;
  z-index: 1;
  margin-top: 60px;
  margin-bottom: 112px;
}

@media only screen and (max-width: 767px) {
  .testimonial-content-layout1 {
    margin-left: 0;
  }
}

.testimonial-content-layout1 .item-title {
  font-weight: 300;
  font-size: 30px;
}

@media only screen and (max-width: 767px) {
  .testimonial-content-layout1 .item-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-content-layout1 .item-title {
    font-size: 26px;
  }
}

.testimonial-wrap-layout7 {
  background-color: #fafafa;
  padding: 110px 0 190px;
}

@media only screen and (max-width: 991px) {
  .testimonial-wrap-layout7 {
    padding: 95px 0 175px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap-layout7 {
    padding: 80px 0 160px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-wrap-layout7 {
    padding: 65px 0 145px;
  }
}

.testimonial-box-layout7 {
  margin-bottom: 30px;
  padding: 45px 40px 30px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
}

.testimonial-box-layout7 .item-title {
  font-weight: 600;
  margin-bottom: 5px;
}

.testimonial-box-layout7 .media {
  margin-top: 10px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout7 .media {
    margin-bottom: 10px;
  }
}

.testimonial-box-layout7 .media img {
  width: auto;
  border-radius: 50%;
}

.testimonial-box-layout7 .media .media-body {
  text-align: left;
}

.testimonial-box-layout7 .media .media-body .inner-item-title {
  margin-bottom: 0;
  line-height: 1.2;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}

.testimonial-box-layout7 .media .media-body .inner-item-designation {
  font-size: 15px;
}

.testimonial-box-layout7 .rating-wrap {
  display: flex;
  margin-bottom: 30px;
}

.testimonial-box-layout7 .rating-wrap li {
  margin-right: 10px;
  align-items: center;
  color: #f8b81e;
  font-size: 15px;
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout7 .rating-wrap {
    margin-bottom: 15px;
  }
}

.testimonial-box-layout7 .item-icon {
  color: #5a49f8;
  opacity: 0.05;
  margin-bottom: 10px;
}

.testimonial-box-layout7 .item-icon i:before {
  font-size: 100px;
}

@media only screen and (max-width: 991px) {
  .testimonial-box-layout7 .item-icon i:before {
    font-size: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box-layout7 .item-icon i:before {
    font-size: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout7 .item-icon i:before {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout7 .item-content {
    display: block !important;
  }
}

.testimonial-wrap-layout8 {
  background-color: #f9fbff;
}

.testimonial-wrap-layout8 .section-heading {
  margin-bottom: 20px;
}

@media only screen and (max-width: 991px) {
  .testimonial-wrap-layout8 .section-heading {
    margin-bottom: 45px;
  }
}

.testimonial-box-layout8 {
  height: 436px;
  width: 550px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 30px 50px 0;
  mask-image: url(../../media/svg/svg7.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #301ee4;
}

@media only screen and (max-width: 991px) {
  .testimonial-box-layout8 {
    mask-image: none;
    width: 100%;
    height: auto;
    padding: 50px 30px;
  }
}

.testimonial-box-layout8 .item-content .item-icon {
  margin-bottom: 10px;
}

.testimonial-box-layout8 .item-content i {
  background: #2413d3;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 72px;
}

.testimonial-box-layout8 .item-content i:before {
  font-size: 72px;
}

.testimonial-box-layout8 .item-content p {
  width: 62%;
  margin: 0 auto 25px;
  color: #ffffff;
}

@media only screen and (max-width: 991px) {
  .testimonial-box-layout8 .item-content p {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box-layout8 .item-content p {
    width: 90%;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout8 .item-content p {
    width: 100%;
  }
}

.testimonial-box-layout8 .item-content .main-title {
  color: #ffffff;
  margin-bottom: 0;
}

.testimonial-box-layout8 .item-content .sub-title {
  color: #e3e3e3;
}

.testimonial-box-layout8 svg {
  margin: 0 auto;
}

.testimonial-box-layout9 {
  margin-bottom: 20px;
  padding-top: 15px;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.testimonial-box-layout9 .item-paragraph {
  font-size: 18px;
  color: #ffffff;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 991px) {
  .testimonial-box-layout9 .item-paragraph {
    width: 75%;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box-layout9 .item-paragraph {
    width: 85%;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout9 .item-paragraph {
    width: 100%;
  }
}

.testimonial-box-layout9 .media {
  margin-top: 30px;
  display: inline-flex;
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout9 .media {
    margin-bottom: 10px;
  }
}

.testimonial-box-layout9 .media img {
  width: auto;
  border-radius: 50%;
}

.testimonial-box-layout9 .media .media-body {
  text-align: left;
}

.testimonial-box-layout9 .media .media-body .inner-item-title {
  margin-bottom: 0;
  color: #ffffff;
  font-weight: 600;
}

.testimonial-box-layout9 .media .media-body .inner-item-designation {
  color: #a9b3bf;
}

.testimonial-box-layout9 .item-icon {
  color: #ffffff;
  opacity: 0.04;
  position: absolute;
  z-index: -1;
  top: -25px;
  left: 0;
  right: 0;
  text-align: center;
}

.testimonial-box-layout9 .item-icon i {
  display: inline-block;
}

.testimonial-box-layout9 .item-icon i:before {
  font-size: 200px;
}

@media only screen and (max-width: 991px) {
  .testimonial-box-layout9 .item-icon i:before {
    font-size: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box-layout9 .item-icon i:before {
    font-size: 140px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout9 .item-icon i:before {
    font-size: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box-layout9 .item-content {
    display: block !important;
  }
}

.testimonial-box-layout10 {
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.testimonial-box-layout10 .item-content {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  position: absolute;
  z-index: 2;
}

.testimonial-box-layout10 .item-content .item-icon {
  margin-bottom: 10px;
}

.testimonial-box-layout10 .item-content i {
  color: #ffffff;
  opacity: 0.2;
}

.testimonial-box-layout10 .item-content i:before {
  font-size: 80px;
}

.testimonial-box-layout10 .item-content p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: italic;
  line-height: 32px;
  width: 80%;
  margin: 0 auto 50px;
  font-weight: 300;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .testimonial-box-layout10 .item-content p {
    width: 90%;
  }
}

.testimonial-box-layout10 .item-content .media {
  transform: translateX(130px);
  display: inline-flex;
  align-items: center;
}

.testimonial-box-layout10 .item-content .media img {
  width: auto;
}

.testimonial-box-layout10 .item-content .media .media-body {
  text-align: left;
}

.testimonial-box-layout10 .item-content .media .media-body .item-title {
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

.testimonial-box-layout10 .item-content .media .media-body .item-designation {
  font-size: 15px;
  color: #ffffff;
  font-weight: 300;
}

.testimonial-content-layout2 {
  margin-left: 50px;
  position: relative;
  z-index: 1;
  margin-top: 30px;
  margin-bottom: 112px;
}

@media only screen and (max-width: 767px) {
  .testimonial-content-layout2 {
    margin-left: 0;
  }
}

.testimonial-content-layout2 .item-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.5;
}

/*--------------------------------------
	4.22 Why Choose
---------------------------------------*/
.why-choose-wrap-layout1 {
  background-color: #f9fbfe;
}

.why-choose-box-layout1 {
  background-color: #ffffff;
  padding: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .why-choose-box-layout1 {
    display: block;
  }
}

.why-choose-box-layout1 .item-icon {
  margin-right: 20px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #f5f4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .why-choose-box-layout1 .item-icon {
    height: 80px;
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .why-choose-box-layout1 .item-icon {
    height: 70px;
    width: 70px;
  }
}

@media only screen and (max-width: 575px) {
  .why-choose-box-layout1 .item-icon {
    height: 60px;
    width: 60px;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.why-choose-box-layout1 .item-icon:after {
  opacity: 0;
  visibility: hidden;
  content: "";
  background: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
  border-radius: 50%;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.why-choose-box-layout1 .item-icon i {
  color: #5a49f8;
  transition: all 0.3s ease-in-out;
}

.why-choose-box-layout1 .item-icon i:before {
  font-size: 36px;
}

@media only screen and (max-width: 991px) {
  .why-choose-box-layout1 .item-icon i:before {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .why-choose-box-layout1 .item-icon i:before {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .why-choose-box-layout1 .item-icon i:before {
    font-size: 28px;
  }
}

.why-choose-box-layout1 .item-content {
  flex: 1;
}

.why-choose-box-layout1 .item-content .item-title {
  margin-bottom: 0;
  font-weight: 600;
}

.why-choose-box-layout1 .item-content p {
  margin-bottom: 0;
}

.why-choose-box-layout1:hover .item-icon:after {
  opacity: 1;
  visibility: visible;
}

.why-choose-box-layout1:hover .item-icon i {
  color: #ffffff;
}

/*--------------------------------------
	4.22 Why Choose
---------------------------------------*/
.history-wrap-layout1 {
  padding: 106px 0 120px;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .history-wrap-layout1 {
    padding: 91px 0 105px;
  }
}

@media only screen and (max-width: 991px) {
  .history-wrap-layout1 {
    padding: 76px 0 90px;
  }
}

@media only screen and (max-width: 767px) {
  .history-wrap-layout1 {
    padding: 61px 0 75px;
  }
}

@media only screen and (max-width: 575px) {
  .history-wrap-layout1 {
    padding: 46px 0 60px;
  }
}

.history-wrap-layout1 .animated-figure {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 991px) {
  .history-wrap-layout1 .animated-figure {
    display: none;
  }
}

.history-inner-layout1 {
  position: relative;
  z-index: 1;
}

.history-inner-layout1:before {
  content: '';
  position: absolute;
  left: 50%;
  background-color: #e6e6e6;
  transform: translateX(-50%);
  top: 0;
  width: 2px;
  height: calc(100% - 26px);
}

@media only screen and (max-width: 991px) {
  .history-inner-layout1:before {
    left: 105px;
    height: calc(100% - 50px);
    top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .history-inner-layout1:before {
    display: none;
  }
}

.history-inner-layout1 .history-box-layout1 {
  display: flex;
  max-width: 500px;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .history-inner-layout1 .history-box-layout1 {
    max-width: 450px;
  }
}

@media only screen and (max-width: 991px) {
  .history-inner-layout1 .history-box-layout1 {
    max-width: inherit;
  }
}

@media only screen and (max-width: 575px) {
  .history-inner-layout1 .history-box-layout1 {
    display: block;
  }
}

.history-inner-layout1 .history-box-layout1:before {
  content: '';
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #5a49f8;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .history-inner-layout1 .history-box-layout1:before {
    display: none;
  }
}

.history-inner-layout1 .history-box-layout1 .item-year {
  font-size: 30px;
  color: #111111;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  position: absolute;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .history-inner-layout1 .history-box-layout1 .item-year {
    position: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .history-inner-layout1 .history-box-layout1 .item-year {
    font-size: 24px;
  }
}

.history-inner-layout1 .history-box-layout1:nth-child(odd) {
  left: 50%;
}

.history-inner-layout1 .history-box-layout1:nth-child(odd):before {
  left: -10px;
}

.history-inner-layout1 .history-box-layout1:nth-child(odd) .item-year {
  left: -115px;
}

.history-inner-layout1 .history-box-layout1:nth-child(odd) .item-content {
  margin-left: 50px;
}

@media only screen and (max-width: 991px) {
  .history-inner-layout1 .history-box-layout1:nth-child(odd) {
    left: 0;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(odd):before {
    left: 90px;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(odd) .item-year {
    left: 0;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(odd) .item-content {
    margin-left: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .history-inner-layout1 .history-box-layout1:nth-child(odd) .item-content {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .history-inner-layout1 .history-box-layout1:nth-child(odd) {
    text-align: center;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(odd) .item-content {
    margin-left: 0;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(odd) .item-year {
    margin-bottom: 15px;
  }
}

.history-inner-layout1 .history-box-layout1:nth-child(even) {
  left: calc(50% - 500px);
  text-align: right;
}

.history-inner-layout1 .history-box-layout1:nth-child(even):before {
  right: -10px;
}

.history-inner-layout1 .history-box-layout1:nth-child(even) .item-year {
  right: -115px;
}

.history-inner-layout1 .history-box-layout1:nth-child(even) .item-content {
  margin-right: 50px;
}

@media only screen and (max-width: 1199px) {
  .history-inner-layout1 .history-box-layout1:nth-child(even) {
    left: calc(50% - 450px);
  }
}

@media only screen and (max-width: 991px) {
  .history-inner-layout1 .history-box-layout1:nth-child(even) {
    left: 0;
    text-align: left;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(even):before {
    right: inherit;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(even) .item-year {
    right: inherit;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(even) .item-content {
    margin-right: inherit;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(even):before {
    left: 90px;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(even) .item-year {
    left: 0;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(even) .item-content {
    margin-left: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .history-inner-layout1 .history-box-layout1:nth-child(even) .item-content {
    margin-left: 30px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .history-inner-layout1 .history-box-layout1:nth-child(even) {
    text-align: center;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(even) .item-content {
    margin-left: 0;
  }
  .history-inner-layout1 .history-box-layout1:nth-child(even) .item-year {
    margin-bottom: 15px;
  }
}

.history-inner-layout1 .history-box-layout1 .item-content {
  background-color: #ffffff;
  padding: 30px 40px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.07);
}

.history-inner-layout1 .history-box-layout1 .item-content h3 {
  font-weight: 600;
  margin-bottom: 5px;
}

.history-inner-layout1 .history-box-layout1 .item-content p {
  margin-bottom: 0;
}

.history-inner-layout1 .history-box-layout1:last-child {
  margin-bottom: 0;
}

/*--------------------------------------
	4.22 Why Choose
---------------------------------------*/
.coverage-box-layout1 {
  margin-bottom: 30px;
}

.coverage-box-layout1 .item-content .sub-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #5a49f8;
}

.coverage-box-layout1 .item-content .item-title {
  font-weight: 700;
  margin-bottom: 15px;
}

.coverage-box-layout1 .item-content p {
  margin-bottom: 50px;
}

/*--------------------------------------
	4.22 Why Choose
---------------------------------------*/
.offer-wrap-layout1 {
  padding: 50px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 991px) {
  .offer-wrap-layout1 {
    padding: 100px 0 50px;
  }
}

.offer-box-layout1 {
  background-color: #5a49f8;
  padding: 70px 130px;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}

@media only screen and (max-width: 991px) {
  .offer-box-layout1 {
    padding: 100px 130px 70px;
  }
}

@media only screen and (max-width: 767px) {
  .offer-box-layout1 {
    padding: 100px 80px 50px;
  }
}

@media only screen and (max-width: 575px) {
  .offer-box-layout1 {
    padding: 100px 50px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .offer-box-layout1 {
    padding: 100px 30px 30px;
  }
}

.offer-box-layout1 .offer-heading {
  border-radius: 50%;
  height: 130px;
  width: 130px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  z-index: 1;
  color: #5a49f8;
  font-weight: 700;
  font-size: 24px;
  left: -65px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 991px) {
  .offer-box-layout1 .offer-heading {
    top: -65px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.offer-box-layout1 .item-title {
  color: #ffffff;
}

.offer-box-layout1 .offer-text {
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 18px;
}

.offer-box-layout1 .offer-text span {
  font-size: 36px;
  font-style: italic;
  font-weight: 700;
}

.offer-box-layout1 .list-item {
  margin-bottom: 30px;
}

.offer-box-layout1 .list-item li {
  color: #ffffff;
  margin-bottom: 12px;
  position: relative;
  z-index: 1;
  padding-left: 20px;
}

.offer-box-layout1 .list-item li:before {
  position: absolute;
  z-index: 1;
  content: "\f054";
  left: 0;
  top: 0;
  color: #ffffff;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.offer-box-layout1 .list-item li:last-child {
  margin-bottom: 0;
}

/*--------------------------------------
	4.22 Why Choose
---------------------------------------*/
.advantage-wrap-layout1 {
  background-color: transparent;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .advantage-wrap-layout1 {
    padding: 105px 0 75px;
  }
}

@media only screen and (max-width: 767px) {
  .advantage-wrap-layout1 {
    padding: 90px 0 60px;
  }
}

.advantage-box-layout1 {
  background-color: #ffffff;
  padding: 30px 15px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-50%);
}

@media only screen and (max-width: 991px) {
  .advantage-box-layout1 {
    transform: translateY(0);
    margin-bottom: 30px;
  }
}

.advantage-box-layout1 i {
  color: #5a49f8;
  font-weight: 600;
  font-size: 52px;
  margin-bottom: 10px;
}

.advantage-box-layout1 .item-title {
  font-weight: 600;
  margin-bottom: 0;
}

/*========================================================================
05. Helper Classes
=========================================================================*/
/*--------------------------------------
	5.1 Background Color
---------------------------------------*/
.bg-color-light {
  background-color: #ffffff;
}

.bg-color-accent {
  background-color: #f5f5f5;
}

.bg-color-accent2 {
  background-color: #f9fbfe;
}

.bg-color-accent3 {
  background-color: #fbfbfe;
}

.bg-color-accent4 {
  background-color: #f7fafe;
}

.bg-color-accent5 {
  background-color: #f8f7f7;
}

.bg-color-accent6 {
  background-color: #f8fbff;
}

.bg-color-accent7 {
  background-color: #f1f7ff;
}

.bg-gradient-layout1 {
  position: relative;
  z-index: 0;
}

.bg-gradient-layout1:before {
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(60deg, #523fff 0%, #750ed5 100%);
}

.bg-gradient-layout2 {
  position: relative;
  z-index: 0;
}

.bg-gradient-layout2:before {
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
}

.bg-gradient-layout3 {
  position: relative;
  z-index: 0;
}

.bg-gradient-layout3:before {
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(60deg, #fdfdfd 0%, #faf9ff 100%);
}

.bg-gradient-layout4 {
  position: relative;
  z-index: 0;
}

.bg-gradient-layout4:before {
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(60deg, #5a49f8 0%, #7a64f2 100%);
}

.bg-gradient-layout5 {
  position: relative;
  z-index: 0;
}

.bg-gradient-layout5:before {
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(35deg, #523fff 45%, #713aec 80%);
}

.bg-gradient-layout6 {
  position: relative;
  z-index: 0;
}

.bg-gradient-layout6:before {
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(60deg, #523fff 0%, #750ed5 93%);
}

/*--------------------------------------
	5.2 Background Style
---------------------------------------*/
.bg-position-center {
  background-position: center center;
}

.bg-size-cover {
  background-size: cover;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

/*--------------------------------------
	5.3 Possition
---------------------------------------*/
.possition-relative {
  position: relative !important;
}

.possition-absolute {
  position: absolute !important;
}

.possition-static {
  position: static !important;
}

.possition-inherit {
  position: inherit !important;
}

/*--------------------------------------
	5.4 Section Spacing
---------------------------------------*/
.section-padding-sm {
  padding-top: 80px;
  padding-bottom: 60px;
}

@media only screen and (max-width: 991px) {
  .section-padding-sm {
    padding-top: 65px;
    padding-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-sm {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .section-padding-sm {
    padding-top: 35px;
    padding-bottom: 15px;
  }
}

.section-padding-md {
  padding-top: 110px;
  padding-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .section-padding-md {
    padding-top: 95px;
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-md {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .section-padding-md {
    padding-top: 65px;
    padding-bottom: 45px;
  }
}

.section-padding-sm-equal {
  padding-top: 90px;
  padding-bottom: 60px;
}

@media only screen and (max-width: 991px) {
  .section-padding-sm-equal {
    padding-top: 75px;
    padding-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-sm-equal {
    padding-top: 60px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .section-padding-sm-equal {
    padding-top: 45px;
    padding-bottom: 15px;
  }
}

.section-padding-md-equal {
  padding-top: 120px;
  padding-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .section-padding-md-equal {
    padding-top: 105px;
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-md-equal {
    padding-top: 90px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .section-padding-md-equal {
    padding-top: 75px;
    padding-bottom: 45px;
  }
}

.section-padding-top-md {
  padding-top: 110px;
}

@media only screen and (max-width: 991px) {
  .section-padding-top-md {
    padding-top: 95px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-top-md {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .section-padding-top-md {
    padding-top: 65px;
  }
}

.section-padding-bottom-md {
  padding-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .section-padding-bottom-md {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-bottom-md {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .section-padding-bottom-md {
    padding-bottom: 45px;
  }
}

.section2-padding-md {
  padding-top: 108px;
  padding-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .section2-padding-md {
    padding-top: 93px;
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .section2-padding-md {
    padding-top: 78px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .section2-padding-md {
    padding-top: 63px;
    padding-bottom: 45px;
  }
}

.section2-padding-md-equal {
  padding-top: 120px;
  padding-bottom: 90px;
}

@media only screen and (max-width: 991px) {
  .section2-padding-md-equal {
    padding-top: 105px;
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .section2-padding-md-equal {
    padding-top: 90px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .section2-padding-md-equal {
    padding-top: 75px;
    padding-bottom: 45px;
  }
}

/*--------------------------------------
	5.5 Text Color
---------------------------------------*/
.color-primary {
  color: #5a49f8;
}

.color-secondary {
  color: #750ed5;
}

.color-light {
  color: #ffffff;
}

.color-dark {
  color: #000000;
}

.color-accent {
  color: #f5f5f5;
}

/*--------------------------------------
	5.6 Text Row
---------------------------------------*/
.text-row-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-row-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-row-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
