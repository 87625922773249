.media-body {
    margin-left: rem(30px);
}

.media-body.space-sm {
    margin-left: rem(15px);
}

.media-body.media-body-box {
    padding: rem(20px 20px 20px 30px);
    margin-left: 0;

    p {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 575px) {
    .media-none--xs {
        display: block;

        >div {
            width: 100%;
        }

        img {
            width: 100%;
        }

        img.media-img-auto {
            width: auto;
        }

        .media-body,
        .media-body.space-xl,
        .media-body.space-lg,
        .media-body.space-md,
        .media-body.space-sm,
        .media-body.space-xs {
            margin-top: rem(20px);
            margin-left: 0;
        }

        .media-body.media-body-box {
            margin-top: 0;
            margin-left: 0;
            padding: rem(30px);
        }
    }
}