.bg-position-center {
    background-position: center center;
}

.bg-size-cover {
    background-size: cover;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}