.template-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    transition: all 0.5s ease-in-out;
    transform: translate(rem(0px), -100%) scale(0, 0);
    opacity: 0;
    visibility: hidden;
    z-index: 999999;

    .search-form {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);

        input[type="search"] {
            width: 60%;
            color: rgb(255, 255, 255);
            font-size: rem(24px);
            text-align: left;
            border: none;
            margin: 0 auto;
            padding: rem(25px 10px 25px 30px);
            outline: none;
            background: linear-gradient(to right, transparent, rgba(200, 200, 200, 0.5), transparent);

            @media only screen and (max-width: 575px) {
                width: 75%;
            }
        }

        .search-btn {
            padding: rem(24px 10px);
            background-color: transparent;
            box-shadow: none;
            border: none;
            border-radius: rem(4px);
            cursor: pointer;

            i {
                &:before {
                    font-size: rem(24px);
                    font-weight: 700;
                    color: #ffffff;
                    margin-left: 0;
                }
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
    }

    .close {
        position: fixed;
        top: rem(15px);
        right: rem(15px);
        color: $color_primary;
        background-color: rgba(255, 255, 255, 0.8);
        border: none;
        opacity: 1;
        visibility: visible;
        padding: rem(3px 15px 5px);
        font-size: rem(36px);
        font-weight: 300;
        border-radius: rem(2px);
        cursor: pointer;
        box-shadow: rem(0 1px 2px 0px) rgba(255, 255, 255, 0.75);
        transition: all 0.3s ease-in-out;

        &:focus {
            box-shadow: none;
            border: none;
            outline: none;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 1);
        }
    }
}

.template-search.open {
    transform: translate(rem(0px), rem(0px)) scale(1, 1);
    opacity: 1;
    visibility: visible;
}