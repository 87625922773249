/*!
  Theme Name: Digeco – Startup Agency Html Template
  Theme URI: https://www.radiustheme.com/demo/html/digeco
  Author: RadiusTheme
  Author URI: https://www.radiustheme.com/
  Version: 1.0.0
  */
/*----------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  01. Typography
  02. Vendor Custom Style
    2.1 Bootstrap Overwrite
    2.2 Owl Carousel Nav Style
    2.3 Select2 Style
  03. Page Layout
    3.1 Footer
    3.2 Header
    3.3 Miscellaneous
    3.4 Widgets
  04. Template Style
    4.1 404
    4.2 About
    4.3 Banner
    4.4 Blog
    4.5 Brand
    4.6 Call To Action
    4.7 Coming Soon
    4.8 Contact
    4.9 Faq
    4.10 Feature
    4.11 Gallery
    4.12 Map
    4.13 Newsletter
    4.14 Instagram
    4.15 Progress
    4.16 Process
    4.17 Pricing
    4.18 Service
    4.19 Screen Shot
    4.20 Team
    4.21 Testimonial
  05. Helper Classes
    5.1 Background Color
    5.2 Background Style
    5.3 Possition
    5.4 Section Spacing
    5.5 Text Color
    5.6 Text Row
  --------------------------------------------------------------*/

@import "generate/variables";
@import "generate/mixins";
@import "template-style/typography";
@import "elements/vendor-custom-style";
@import "elements/page-layout";
@import "template-style/sections";
@import "elements/helper-classes";





