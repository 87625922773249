.possition-relative {
    position: relative !important;
}

.possition-absolute {
    position: absolute !important;
}

.possition-static {
    position: static !important;
}

.possition-inherit {
    position: inherit !important;
}