html {
	overflow: inherit;
}
body {
    font-family: $font_primary;
    font-size: rem(16px);
    line-height: rem(30px);
    color: $color_body;
    font-weight: 400;
    height: 100%;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    background-color: #ffffff;
    overflow: inherit;
    overflow-x: hidden;
}

p {
    margin: rem(0 0 20px 0);
    color: $color_body;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font_heading;
    margin: rem(0 0 20px 0);
    font-weight: 500;
    color: $color_heading;
}

h1 {
    font-size: rem(46px);
    line-height: rem(56px);

    @media only screen and (max-width: 991px) {
        font-size: rem(40px);
    }

    @media only screen and (max-width: 767px) {
        font-size: rem(36px);
    }

    @media only screen and (max-width: 575px) {
        font-size: rem(32px);
    }
}

h2 {
    font-size: rem(36px);
    line-height: rem(50px);

     @media only screen and (max-width: 1199px) {
        font-size: rem(32px);
        line-height: rem(45px);
    }

    @media only screen and (max-width: 991px) {
        font-size: rem(30px);
        line-height: rem(42px);
    }

    @media only screen and (max-width: 767px) {
        font-size: rem(28px);
        line-height: rem(40px);
    }

    @media only screen and (max-width: 575px) {
        font-size: rem(26px);
         line-height: rem(38px);
    }
}

h3 {
    font-size: rem(22px);
    line-height: rem(36px);

    @media only screen and (max-width: 767px) {
        font-size: rem(20px);
    }
}

h4 {
    font-size: rem(20px);
    line-height: rem(30px);

    @media only screen and (max-width: 767px) {
        font-size: rem(18px);
    }
}

h5 {
    font-size: rem(18px);
    line-height: rem(30px);
    @media only screen and (max-width: 767px) {
        font-size: rem(17px);
    }
}

h6 {
    font-size: rem(16px);
    line-height: rem(30px);
}