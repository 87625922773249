.coverage-box-layout1 {
	margin-bottom: rem(30px);
	.item-content {
		.sub-title {
			font-size: rem(18px);
			font-weight: 500;
			margin-bottom: rem(5px);
			color: $color_primary;
		}
		.item-title {
			font-weight: 700;
			margin-bottom: rem(15px);
		}
		p {
			margin-bottom: rem(50px);
		}
	}
}