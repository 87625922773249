.screen-shot-carousel-wrap {
	margin-top: rem(-36px);
}

.screen-shot-box-layout1 {
	box-shadow: rem(0px 0 35px 0) rgba(#000000, 0.07);
	margin: rem(36px 15px);
	transition: all 0.5s ease-in-out;
	img {
		border-radius: rem(4px);
	}
}

.center {
	.screen-shot-box-layout1 {
		box-shadow: rem(0px 0 35px 0) rgba(#000000, 0.3);
	}
}
