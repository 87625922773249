/*========================================================================
02. Vendor Custom Style
=========================================================================*/

/*--------------------------------------
	2.1 Bootstrap Overwrite
---------------------------------------*/
@import "vendor-custom-style/bootstrap-overwrite";

/*--------------------------------------
	2.2 Owl Carousel Nav Style
---------------------------------------*/
@import "vendor-custom-style/owl-carousel-nav-style";

/*--------------------------------------
	2.3 Select2 Custom Style
---------------------------------------*/
@import "vendor-custom-style/select2-custom-style";