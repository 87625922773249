footer {
	.footer-widgets {
	    margin-bottom: rem(15px);
	}
	.footer-widget-heading {
	    font-weight: 600;
	    margin-bottom: rem(15px);
	}
	.footer-widget-heading.heading-light {
	    color: $color_light;
	}
}

.footer-top-layout1 {
	padding: rem(120px 0 90px);
	@media only screen and (max-width: 991px) {
        padding: rem(105px 0 75px);
    }
	@media only screen and (max-width: 767px) {
        padding: rem(90px 0 60px);
    }
	@media only screen and (max-width: 575px) {
        padding: rem(75px 0 45px);
    }

	.footer-logo {
		margin-bottom: rem(15px);
		a {
			display: inline-block;
		}
	}

	ul.footer-social {
		justify-content: start;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: rem(20px);
		li {
			margin-right: rem(10px);
			margin-bottom: rem(5px);
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #b0b0b0;
				padding: rem(5px 10px);
				transition: all 0.3s ease-in-out;
				&:hover {
					color: $color_primary;
				}
			}
			&:first-child {
				a {
					padding: rem(5px 10px 5px 0);
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.footer-menu {
	    li {
	    	margin-bottom: rem(10px);
	    	&:last-child {
	    		margin-bottom: 0;
	    	}
	        a {
	        	color: #646464;
	        	display: block;
	        	padding: rem(5px 0);
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}

	.footer-contact {
		li {
			position: relative;
			z-index: 1;
			padding-left: rem(40px);
			margin-bottom: rem(15px);
			&:last-child {
				margin-bottom: 0;
			}
			i {
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				&:before {
					font-size: rem(24px);
					color: $color_primary;
				}
			}
		}
	}
}

.footer-bottom-layout1 {
    padding: rem(30px 0 22px);
    .copy-right-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    .copy-right-text {
	        margin-bottom: 0;
	        a {
	        	color: $color_body;
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}
}

.footer-wrap-layout2 {
	background-position: center bottom;
}

.footer-top-layout2 {
	padding: rem(120px 0 75px);

	.footer-logo {
		margin-bottom: rem(15px);
		a {
			display: inline-block;
		}
	}

	ul.footer-social {
		justify-content: start;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: rem(20px);
		li {
			margin-right: rem(10px);
			margin-bottom: rem(5px);
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #b0b0b0;
				padding: rem(5px 10px);
				transition: all 0.3s ease-in-out;
				&:hover {
					color: $color_primary;
				}
			}
			&:first-child {
				a {
					padding: rem(5px 10px 5px 0);
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.footer-menu {
	    li {
	    	margin-bottom: rem(10px);
	    	&:last-child {
	    		margin-bottom: 0;
	    	}
	        a {
	        	color: #646464;
	        	display: block;
	        	padding: rem(5px 0);
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}

	.footer-contact {
		li {
			position: relative;
			z-index: 1;
			padding-left: rem(40px);
			margin-bottom: rem(15px);
			&:last-child {
				margin-bottom: 0;
			}
			i {
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				&:before {
					font-size: rem(24px);
					color: $color_primary;
				}
			}
		}
	}
}

.footer-bottom-layout2 {
    padding: rem(30px 0 22px);
    .copy-right-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    .copy-right-text {
	        margin-bottom: 0;
	        a {
	        	color: $color_body;
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}
}

.footer-top-layout3 {
	padding: rem(120px 0 91px);
	@media only screen and (max-width: 991px) {
        padding: rem(105px 0 76px);
    }
    @media only screen and (max-width: 767px) {
        padding: rem(90px 0 61px);
    }
    @media only screen and (max-width: 575px) {
        padding: rem(75px 0 46px);
    }
	.footer-logo {
		margin-bottom: rem(20px);
		a {
			display: inline-block;
		}
	}

	ul.footer-social {
		justify-content: start;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: rem(20px);
		li {
			margin-right: rem(10px);
			margin-bottom: rem(5px);
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #b0b0b0;
				padding: rem(5px 10px);
				transition: all 0.3s ease-in-out;
				&:hover {
					color: $color_primary;
				}
			}
			&:first-child {
				a {
					padding: rem(5px 10px 5px 0);
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.footer-menu {
	    li {
	    	margin-bottom: rem(10px);
	    	&:last-child {
	    		margin-bottom: 0;
	    	}
	        a {
	        	color: #646464;
	        	display: block;
	        	padding: rem(5px 0 5px 20px);
		    	position: relative;
		    	z-index: 1;
	        	transition: all 0.3s ease-in-out;
		    	&:before {
		    		position: absolute;
		    		z-index: 1;
		    		content: "\f105";
		    		font-family: "Font Awesome 5 Free";
		    		font-weight: 900;
		    		font-size: rem(18px);
		    		left: 0;
		    		top: rem(12px);
		    		line-height: 1;
		    	}
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}

	.footer-post {
		li {
			margin-bottom: rem(15px);
			&:last-child {
				margin-bottom: 0;
			}
			.item-date {
				color: $color_primary;
				margin-bottom: rem(5px);
			}
			.item-title {
				font-weight: 500;
				font-size: rem(16px);
				line-height: rem(25px);
				a {
					color: #111111;
					transition: all 0.3s ease-in-out;
					&:hover {
						color: $color_primary;
					}
				}
			}
		}
	}

	.footer-contact {
		margin-bottom: rem(20px);
		li {
			position: relative;
			z-index: 1;
			margin-bottom: rem(5px);
			&:last-child {
				margin-bottom: 0;
			}
		}
		.mail-address {
			font-weight: 300;
			color: #111111;
		}
		.phn-number {
			font-weight: 500;
			font-size: rem(18px);
			color: #111111;
		}
	}
}

.footer-bottom-layout3 {
    padding: rem(20px 0);
    .copy-right-left-box {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    .copy-right-text {
	        margin-bottom: 0;
	        color: #646464;
	        a {
	        	color: #646464;
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}
	.copy-right-right-box {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    p {
	    	margin-bottom: 0;
	    	color: #646464;
	    }
	}
}

.footer-wrap-layout4 {
	padding: rem(110px 0 0);
	overflow: hidden;
	position: relative;
	z-index: 1;
	@media only screen and (max-width: 991px) {
        padding: rem(95px 0 0);
    }
    @media only screen and (max-width: 767px) {
        padding: rem(80px 0 0);
    }
    @media only screen and (max-width: 575px) {
        padding: rem(65px 0 0);
    }
	&:after {
		content: "";
		background-image: url(../../media/element/element55.png);
		height: rem(139px);
		width: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: absolute;
		z-index: 5;
		top: 0;
		left: 0;
		right: 0;
	}
	.shape-holder {
		position: relative;
		z-index: 0;
		display: table-row;
		@media only screen and (max-width: 767px) {
	        display: none;
	    }
		.single-shape {
			position: absolute;
			z-index: -1;
		}
		.shape1 {
			top: rem(-200px);
			left: rem(-300px);
		}
		.shape2 {
			bottom: rem(-130px);
			left: rem(-70px);
		}
		.shape3 {
			top: rem(-650px);
			right: rem(-300px);
		}
	}
}

.footer-top-layout4 {
	padding: rem(90px 0 90px);
	border-bottom: rem(1px) solid rgba($color_light, 0.2);
	@media only screen and (max-width: 991px) {
        padding: rem(72px 0 75px);
    }
    @media only screen and (max-width: 767px) {
        padding: rem(57px 0 60px);
    }
    @media only screen and (max-width: 575px) {
        padding: rem(42px 0 45px);
    }
	.footer-logo {
		margin-bottom: rem(20px);
		a {
			display: inline-block;
		}
	}

	ul.footer-social {
		justify-content: start;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: rem(20px);
		li {
			margin-right: rem(10px);
			margin-bottom: rem(5px);
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				color: $color_light;
				padding: rem(5px 10px);
				transition: all 0.3s ease-in-out;
				&:hover {
					color: $color_border;
				}
			}
			&:first-child {
				a {
					padding: rem(5px 10px 5px 0);
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.footer-menu {
	    li {
	    	margin-bottom: rem(10px);
	    	&:last-child {
	    		margin-bottom: 0;
	    	}
	        a {
	        	color: $color_light;
	        	display: block;
	        	padding: rem(5px 0 5px 20px);
		    	position: relative;
		    	z-index: 1;
	        	transition: all 0.3s ease-in-out;
		    	&:before {
		    		position: absolute;
		    		z-index: 1;
		    		content: "\f105";
		    		font-family: "Font Awesome 5 Free";
		    		font-weight: 900;
		    		font-size: rem(18px);
		    		left: 0;
		    		top: rem(12px);
		    		line-height: 1;
		    	}
	        	&:hover {
	        		color: $color_border;
	        	}
	        }
	    }
	}

	.footer-post {
		li {
			margin-bottom: rem(15px);
			&:last-child {
				margin-bottom: 0;
			}
			.item-date {
				color: $color_light;
				margin-bottom: rem(5px);
			}
			.item-title {
				font-weight: 500;
				font-size: rem(16px);
				line-height: rem(25px);
				a {
					color: $color_light;
					transition: all 0.3s ease-in-out;
					&:hover {
						color: $color_border;
					}
				}
			}
		}
	}

	.footer-contact {
		margin-bottom: rem(20px);
		li {
			position: relative;
			z-index: 1;
			margin-bottom: rem(5px);
			&:last-child {
				margin-bottom: 0;
			}
		}
		.mail-address {
			font-weight: 300;
			color: $color_light;
		}
		.phn-number {
			font-weight: 500;
			font-size: rem(18px);
			color: $color_light;
		}
	}
}

.footer-bottom-layout4 {
    padding: rem(20px 0);
    .copy-right-left-box {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    .copy-right-text {
	        margin-bottom: 0;
	        color: $color_light;
	        a {
	        	color: $color_light;
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_secondary;
	        	}
	        }
	    }
	}
	.copy-right-right-box {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    p {
	    	margin-bottom: 0;
	    	color: $color_light;
	    }
	}
}

.footer-wrap-layout5 {
	position: relative;
	z-index: 1;
	margin-top: rem(235px);
	@media only screen and (max-width: 1199px) {
		margin-top: rem(115px);
	}
	@media only screen and (max-width: 991px) {
		margin-top: rem(70px);
	}
	@media only screen and (max-width: 767px) {
		margin-top: rem(50px);
	}
	@media only screen and (max-width: 575px) {
		margin-top: rem(30px);
	}
	&:before {
		content: "";
		position: absolute;
		z-index: 1;
		bottom: 100%;
		left: 0;
		right: 0;
		background-image: url(../../media/element/element57.png);
		height: rem(235px);
		width: 100%;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
		@media only screen and (max-width: 1199px) {
			background-size: contain;
			height: rem(115px);
		}
		@media only screen and (max-width: 991px) {
			height: rem(70px);
		}
		@media only screen and (max-width: 767px) {
			height: rem(50px);
		}
		@media only screen and (max-width: 575px) {
			height: rem(30px);
		}
	}
}

.footer-top-layout5 {
	padding: rem(50px 0 0);

	.footer-logo {
		margin-bottom: rem(15px);
		a {
			display: inline-block;
		}
	}

	ul.footer-social {
		justify-content: start;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: rem(20px);
		li {
			margin-right: rem(10px);
			margin-bottom: rem(5px);
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #b0b0b0;
				padding: rem(5px 10px);
				transition: all 0.3s ease-in-out;
				&:hover {
					color: $color_primary;
				}
			}
			&:first-child {
				a {
					padding: rem(5px 10px 5px 0);
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.footer-menu {
	    li {
	    	margin-bottom: rem(10px);
	    	&:last-child {
	    		margin-bottom: 0;
	    	}
	        a {
	        	color: #646464;
	        	display: block;
	        	padding: rem(5px 0);
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}

	.footer-contact {
		li {
			position: relative;
			z-index: 1;
			padding-left: rem(40px);
			margin-bottom: rem(15px);
			&:last-child {
				margin-bottom: 0;
			}
			i {
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				&:before {
					font-size: rem(24px);
					color: $color_primary;
				}
			}
		}
	}
}

.footer-bottom-layout5 {
    padding: rem(30px 0 22px);
    .copy-right-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    .copy-right-text {
	        margin-bottom: 0;
	        a {
	        	color: $color_body;
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}
}

.footer-wrap-layout6 {
	background-color: #17161c;
	position: relative;
	z-index: 1;
	.shape-holder {
		.single-shape {
			position: absolute;
			z-index: 1;
		}
		.shape1 {
			top: 0;
			left: 10%;
		}
		.shape2 {
			top: 2%;
			right: 5%;
		}
		.shape3 {
			bottom: 20%;
			left: 3%;
		}
		.shape4 {
			bottom: 30%;
			right: 10%;
		}
	}
	.footer-widget-heading {
		color: #ffffff;
	}
}

.footer-top-layout6 {
	padding: rem(120px 0 91px);
	@media only screen and (max-width: 991px) {
        padding: rem(105px 0 76px);
    }
    @media only screen and (max-width: 767px) {
        padding: rem(90px 0 61px);
    }
    @media only screen and (max-width: 575px) {
        padding: rem(75px 0 46px);
    }
	.footer-logo {
		margin-bottom: rem(20px);
		a {
			display: inline-block;
		}
	}

	ul.footer-social {
		justify-content: start;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: rem(20px);
		li {
			margin-right: rem(10px);
			margin-bottom: rem(5px);
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #727272;
				padding: rem(5px 10px);
				transition: all 0.3s ease-in-out;
				&:hover {
					color: #ffffff;
				}
			}
			&:first-child {
				a {
					padding: rem(5px 10px 5px 0);
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.footer-menu {
	    li {
	    	margin-bottom: rem(10px);
	    	&:last-child {
	    		margin-bottom: 0;
	    	}
	        a {
	        	color: #a7a7a7;
	        	display: block;
	        	padding: rem(5px 0 5px 20px);
		    	position: relative;
		    	z-index: 1;
	        	transition: all 0.3s ease-in-out;
		    	&:before {
		    		position: absolute;
		    		z-index: 1;
		    		content: "\f105";
		    		font-family: "Font Awesome 5 Free";
		    		font-weight: 900;
		    		font-size: rem(18px);
		    		left: 0;
		    		top: rem(12px);
		    		line-height: 1;
		    	}
	        	&:hover {
	        		color: #ffffff;
	        	}
	        }
	    }
	}

	.footer-post {
		li {
			margin-bottom: rem(15px);
			&:last-child {
				margin-bottom: 0;
			}
			.item-date {
				color: #ffffff;
				margin-bottom: rem(5px);
			}
			.item-title {
				font-weight: 500;
				font-size: rem(16px);
				line-height: rem(25px);
				a {
					color: #a7a7a7;
					transition: all 0.3s ease-in-out;
					&:hover {
						color: #ffffff;
					}
				}
			}
		}
	}

	.footer-contact {
		margin-bottom: rem(20px);
		li {
			position: relative;
			z-index: 1;
			margin-bottom: rem(5px);
			&:last-child {
				margin-bottom: 0;
			}
		}
		.mail-address {
			font-weight: 300;
			color: #a7a7a7;
		}
		.phn-number {
			font-weight: 500;
			font-size: rem(18px);
			color: #ffffff;
		}
	}
}

.footer-bottom-layout6 {
	padding: rem(20px 0);
	background-color: #1b1a20;
    .copy-right-left-box {
		display: flex;
		justify-content: flex-start;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    .copy-right-text {
	        margin-bottom: 0;
	        color: #a7a7a7;
	        a {
	        	color: #646464;
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}
	.copy-right-right-box {
		display: flex;
		justify-content: flex-end;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    p {
	    	margin-bottom: 0;
	    	color: #a7a7a7;
	    }
	}
}

.footer-wrap-layout7 {
	background-color: #201e30;
	position: relative;
	z-index: 1;
	.shape-holder {
		.single-shape {
			position: absolute;
			z-index: 1;
		}
		.shape1 {
			top: 0;
			left: 10%;
		}
		.shape2 {
			top: 2%;
			right: 5%;
		}
		.shape3 {
			bottom: 20%;
			left: 3%;
		}
		.shape4 {
			bottom: 30%;
			right: 10%;
		}
	}
	.footer-widget-heading {
		color: #ffffff;
	}
}

.footer-top-layout7 {
	padding: rem(120px 0 91px);
	@media only screen and (max-width: 991px) {
        padding: rem(105px 0 76px);
    }
    @media only screen and (max-width: 767px) {
        padding: rem(90px 0 61px);
    }
    @media only screen and (max-width: 575px) {
        padding: rem(75px 0 46px);
    }
	.footer-logo {
		margin-bottom: rem(20px);
		a {
			display: inline-block;
		}
	}

	.about-paragraph {
		color: #d0d0d0;
	}

	ul.footer-social {
		justify-content: start;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: rem(20px);
		li {
			margin-right: rem(10px);
			margin-bottom: rem(5px);
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #ffffff;
				height: rem(35px);
				width: rem(35px);
				border-radius: rem(45px);
				font-size: rem(14px);
				background-color: #31303f;
				transition: all 0.3s ease-in-out;
				&:hover {
					color: $color_primary;
					background-color: #ffffff;
				}
			}
		}
	}

	.footer-address {
		li {
			color: #d1d1d1;
			margin-bottom: rem(20px);
			&:last-child {
				margin-bottom: 0;
			}
			span {
				display: block;
				margin-bottom: rem(2px);
			}
		}
	}

	.footer-menu {
	    li {
			margin-bottom: rem(5px);
			font-size: rem(18px);
	    	&:last-child {
	    		margin-bottom: 0;
	    	}
	        a {
	        	color: #d1d1d1;
	        	display: block;
	        	padding: rem(5px 0);
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: #ffffff;
	        	}
	        }
	    }
	}

	.footer-post {
		li {
			display: flex;
			margin-bottom: rem(20px);
			&:last-child {
				margin-bottom: 0;
			}
			.item-figure {
				margin-right: rem(10px);
				margin-top: rem(9px);
				img {
					border-radius: rem(5px);
				}
			}
			.item-content {
				flex: 1;
				.item-date {
					color: #ffffff;
					margin-bottom: rem(5px);
					font-size: rem(13px);
				}
				.item-title {
					font-size: rem(16px);
					line-height: rem(22px);
					margin-bottom: 0;
					a {
						color: #d1d1d1;
						transition: all 0.3s ease-in-out;
						&:hover {
							color: #ffffff;
						}
					}
				}
			}
		}
	}
}

.footer-bottom-layout7 {
	padding: rem(20px 0);
	background-color: #232034;
    .copy-right-left-box {
		display: flex;
		justify-content: flex-start;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    .copy-right-text {
	        margin-bottom: 0;
	        color: #c3c3c3;
	        a {
	        	color: #c3c3c3;
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}
	.copy-right-right-box {
		display: flex;
		justify-content: flex-end;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    p {
	    	margin-bottom: 0;
	    	color: #c3c3c3;
	    }
	}
}

.footer-wrap-layout8 {
	position: relative;
	z-index: 1;
	margin-top: rem(180px);
	background-color: #070d1e;
	&:before {
		content: "";
		position: absolute;
		z-index: 1;
		bottom: 100%;
		left: 0;
		right: 0;
		background-image: url(../../media/element/element76.png);
		height: rem(180px);
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.footer-widget-heading {
		color: #ffffff;
	}
}

.footer-top-layout8 {
	padding: rem(50px 0 0);

	.footer-widgets {
		p {
			color: #c5c4c4;
		}
	}

	.footer-logo {
		margin-bottom: rem(15px);
		a {
			display: inline-block;
		}
	}

	.about-paragraph {
		color: #c5c4c4;
	}

	ul.footer-social {
		justify-content: start;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: rem(20px);
		li {
			margin-right: rem(10px);
			margin-bottom: rem(5px);
			a {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #b0b0b0;
				padding: rem(5px 10px);
				transition: all 0.3s ease-in-out;
				&:hover {
					color: $color_primary;
				}
			}
			&:first-child {
				a {
					padding: rem(5px 10px 5px 0);
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.footer-menu {
	    li {
	    	margin-bottom: rem(10px);
	    	&:last-child {
	    		margin-bottom: 0;
	    	}
	        a {
	        	color: #c5c4c4;
	        	display: block;
	        	padding: rem(5px 0);
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}

	.footer-contact {
		li {
			color: #c5c4c4;
			position: relative;
			z-index: 1;
			padding-left: rem(40px);
			margin-bottom: rem(15px);
			&:last-child {
				margin-bottom: 0;
			}
			i {
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				&:before {
					font-size: rem(24px);
					color: $color_primary;
				}
			}
		}
	}
}

.footer-bottom-layout8 {
    padding: rem(30px 0 22px);
    .copy-right-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    .copy-right-text {
			margin-bottom: 0;
			color: #c5c4c4;
	        a {
	        	color: #c5c4c4;
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}
}

.footer-wrap-layout9 {
	background-color: #070d1e;
	.footer-widget-heading {
		color: #ffffff;
	}
}

.footer-top-layout9 {
	padding: rem(120px 0 91px);
	@media only screen and (max-width: 991px) {
        padding: rem(105px 0 76px);
    }
    @media only screen and (max-width: 767px) {
        padding: rem(90px 0 61px);
    }
    @media only screen and (max-width: 575px) {
        padding: rem(75px 0 46px);
    }

	.footer-widgets {
		p {
			color: #c5c4c4;
		}
	}

	.footer-logo {
		margin-bottom: rem(15px);
		a {
			display: inline-block;
		}
	}

	.about-paragraph {
		color: #c5c4c4;
	}

	.footer-menu {
	    li {
	    	margin-bottom: rem(10px);
	    	&:last-child {
	    		margin-bottom: 0;
	    	}
	        a {
	        	color: #c5c4c4;
	        	display: block;
				padding: rem(5px 0 5px 25px);
				position: relative;
				z-index: 1;				
				transition: all 0.3s ease-in-out;
				&:before {
					position: absolute;
					z-index: 1;
					content: "\f061";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					font-size: rem(16px);
					top: rem(4px);
					left: 0;
					color: $color_primary;
				}
				
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}

	.footer-contact {
		li {
			color: #c5c4c4;
			position: relative;
			z-index: 1;
			padding-left: rem(40px);
			margin-bottom: rem(15px);
			&:last-child {
				margin-bottom: 0;
			}
			i {
				position: absolute;
				z-index: 1;
				left: 0;
				top: 0;
				&:before {
					font-size: rem(24px);
					color: $color_primary;
				}
			}
		}
	}
}

.footer-bottom-layout9 {
    padding: rem(30px 0 22px);
    .copy-right-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		@media only screen and (max-width: 991px) {
	        justify-content: center;
	    }
	    .copy-right-text {
			margin-bottom: 0;
			color: #c5c4c4;
	        a {
	        	color: #c5c4c4;
	        	transition: all 0.3s ease-in-out;
	        	&:hover {
	        		color: $color_primary;
	        	}
	        }
	    }
	}
}





