/*========================================================================
04. Template Style
=========================================================================*/
/*--------------------------------------
	4.1 404 Error
---------------------------------------*/
@import "sections/404";

/*--------------------------------------
	4.2 About
---------------------------------------*/
@import "sections/about";

/*--------------------------------------
	4.3 Banner
---------------------------------------*/
@import "sections/banner";

/*--------------------------------------
	4.4 Blog
---------------------------------------*/
@import "sections/blog";

/*--------------------------------------
	4.5 Brand
---------------------------------------*/
@import "sections/brand";

/*--------------------------------------
	4.6 Call To Action
---------------------------------------*/
@import "sections/call-to-action";

/*--------------------------------------
	4.7 Coming Soon
---------------------------------------*/
@import "sections/coming-soon";

/*--------------------------------------
	4.8 Contact
---------------------------------------*/
@import "sections/contact";

/*--------------------------------------
	4.9 Faq
---------------------------------------*/
@import "sections/faq";

/*--------------------------------------
	4.10 Feature
---------------------------------------*/
@import "sections/feature";

/*--------------------------------------
	4.11 Gallery
---------------------------------------*/
@import "sections/gallery";

/*--------------------------------------
	4.12 Map
---------------------------------------*/
@import "sections/map";

/*--------------------------------------
	4.13 Newsletter
---------------------------------------*/
@import "sections/newsletter";

/*--------------------------------------
	4.14 Instagram
---------------------------------------*/
@import "sections/instagram";

/*--------------------------------------
	4.15 Progress
---------------------------------------*/
@import "sections/progress";

/*--------------------------------------
	4.16 Process
---------------------------------------*/
@import "sections/process";

/*--------------------------------------
	4.17 Pricing
---------------------------------------*/
@import "sections/pricing";

/*--------------------------------------
	4.18 Service
---------------------------------------*/
@import "sections/service";

/*--------------------------------------
	4.19 Screen Shot
---------------------------------------*/
@import "sections/screen-shot";

/*--------------------------------------
	4.20 Team
---------------------------------------*/
@import "sections/team";

/*--------------------------------------
	4.21 Testimonial
---------------------------------------*/
@import "sections/testimonial";

/*--------------------------------------
	4.22 Why Choose
---------------------------------------*/
@import "sections/why-choose";

/*--------------------------------------
	4.22 Why Choose
---------------------------------------*/
@import "sections/history";

/*--------------------------------------
	4.22 Why Choose
---------------------------------------*/
@import "sections/coverage";

/*--------------------------------------
	4.22 Why Choose
---------------------------------------*/
@import "sections/offer";

/*--------------------------------------
	4.22 Why Choose
---------------------------------------*/
@import "sections/advantage";














