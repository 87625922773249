.widget-tag {
	margin-bottom: rem(-14px);
	ul {
		display: flex;
		flex-wrap: wrap;
		li {
			margin-right: rem(14px);
			margin-bottom: rem(14px);
			font-size: rem(16px);
			a {
				padding: rem(5px 14px);
				border-radius: rem(4px);
				background-color: $color_accent;
				display: block;
				color: $color_body;
				position: relative;
				z-index: 1;
				transition: all 0.3s ease-in-out;
				&:before {
					position: absolute;
					border-radius: rem(4px);
					z-index: -1;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					content: "";
					background-image: linear-gradient( 60deg, #5a49f8 0%, #7a64f2 100%);
					opacity: 0;
					visibility: hidden;
					transition: all 0.3s ease-in-out;
				}
				&:hover {
					color: $color_light;
					&:before {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}
}
