.color-primary {
    color: $color_primary;
}

.color-secondary {
    color: $color_secondary;
}

.color-light {
    color: $color_light;
}

.color-dark {
    color: $color_dark;
}

.color-accent {
    color: $color_accent;
}